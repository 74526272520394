import React from 'react'
import Card from '@material-ui/core/Card'
import CardMedia from '@material-ui/core/CardMedia'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import { useHistory } from 'react-router-dom'
import withAppconfig from '../../AppContext'
import { Grid, Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { useCookies } from 'react-cookie'
import { getPosterUrl } from '../ViewUtils'

const ProductCard = ({ product, personname, appconfig, collection, isAuthRequired, isAuth }) => {
  const history = useHistory()
  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie] = useCookies(['gobackurl'])
  const { poster, id, variations } = product
  const path = [appconfig.pathPrefix, 'shop', collection, id].join('/')

  // let _optimizedPoster = ''
  // if (poster && typeof poster === 'object') {
    
  // if (poster && poster.indexOf('.svg') > -1) {
  //   _optimizedPoster = poster
  // } else {
  //   _optimizedPoster = poster
  //     ? poster
  //         .split('/')
  //         .splice(0, poster.split('/').length - 1)
  //         .join('/') +
  //       '/600_' +
  //       poster.split('/').splice(-1)
  //     : ''
  // }
  let price = ''
  if (variations && variations.length > 0) {
    price = (variations[0].price / 100).toFixed(2).replace('.', ',') + ' €'
  }

  const goToProduct = () => {
    if (isAuthRequired && !isAuth) {
      setCookie('gobackurl', path, { path: '/', maxAge: 60 })
      history.push(appconfig.pathPrefix + '/login')
      return
    } else {
      history.push(path)
    }
  }

  const posterUrl = getPosterUrl(poster, 300)

  return (
    <Card>
      {posterUrl && <CardMedia image={posterUrl} style={{ paddingTop: '56.25%' }} />}
      <CardContent onClick={goToProduct}>
        <Grid container direction='row' justifyContent='space-between' alignItems='center'>
          <Grid item xs={12}>
            <Typography variant='h6'>{product.displayName}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Button size='small' variant='contained' color='primary' disabled={isAuthRequired && !isAuth} onClick={goToProduct}>
              Osta
            </Button>
          </Grid>
          <Grid item xs={6} style={{ textAlign: 'right' }}>
            <Typography variant='subtitle1'>{price}</Typography>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions></CardActions>
    </Card>
  )
}

export default withAppconfig(ProductCard)
