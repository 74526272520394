import React, { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom' // if you use react-router
import TopNavigation from '../TopNavigation'
// import { useFirebase, isLoaded, firestoreConnect } from 'react-redux-firebase'
// import { compose } from 'redux'
import { useCookies } from 'react-cookie'
import { connect } from 'react-redux'
import withAppconfig from '../AppContext'
import { Container, FormGroup, Grid, TextField, Typography, MenuItem, Checkbox, Slider, Divider } from '@material-ui/core'
import { useParams } from 'react-router-dom/cjs/react-router-dom'
import { applications } from '../demodata.js'
import { useTranslation } from 'react-i18next'
import { Button } from 'reactstrap'
import moment from 'moment'
import 'moment/locale/en-gb'
import 'moment/locale/sv'
import 'moment/locale/fi'
import 'moment/locale/et'
import { isEmpty } from 'react-redux-firebase'

const formstyles = {
  div: { margin: '10px' },
  label: { margin: '10px' },
}
const valueMarks = Array.from(Array(48).keys()).map((i) => {
  return { value: i, label: i % 2 === 0 ? `${i / 2}:00` : `${Math.floor(i / 2)}:30` }
})
function valueLabelFormat(value) {
  return valueMarks ? valueMarks.find((mark) => mark.value === value).label : ''
}

function DayTimeSelector({ onChange }) {
  // const { t } = useTranslation()

  const [checked, setChecked] = useState([false, false, false, false, false, false, false])

  const [value, setValue] = useState([
    [0, 47],
    [0, 47],
    [0, 47],
    [0, 47],
    [0, 47],
    [0, 47],
    [0, 47],
  ])
  const handleChange = (event, newValue, i) => {
    setValue((prev) => {
      const _prev = [...prev]
      _prev[i] = newValue
      return _prev
    })
  }
  const handleOnChange = (event, newValue, i) => {
    const _e = { target: { name: 'daytimes' + i, value: newValue } }
    onChange(_e)
  }

  const handleCheck = (event, newValue, i) => {
    setChecked((prev) => {
      const _prev = [...prev]
      _prev[i] = newValue
      return _prev
    })
    if (!newValue) {
      const _e = { target: { name: 'daytimes' + i, value: null } }
      onChange(_e)
      setValue((prev) => {
        const _prev = [...prev]
        _prev[i] = [0, 47]
        return _prev
      })
    } else {
      const _e = { target: { name: 'daytimes' + i, value: value[i] } }
      onChange(_e)
    }
  }

  return (
    <Grid container spacing={3}>
      {Array.from(Array(7).keys()).map((i) => {
        return (
          <React.Fragment>
            <Grid item xs={4} sm={3}>
              <Checkbox color='primary' onChange={(e, n) => handleCheck(e, n, i)} name={'chekk' + i} />
              <Typography variant='caption'>{moment().isoWeekday(i+1).format('dddd')}</Typography>
            </Grid>
            <Grid item xs={8} sm={9}>
              <Slider
                name={'slider' + i}
                onChange={(e, n) => handleChange(e, n, i)}
                onChangeCommitted={(e, n) => handleOnChange(e, n, i)}
                // valueLabelDisplay="on"
                step={1}
                valueLabelFormat={valueLabelFormat}
                getAriaValueText={valueLabelFormat}
                valueLabelDisplay={checked[i] ? 'on' : 'off'}
                marks
                value={value[i]}
                max={47}
                disabled={!checked[i]}
              />
              {/* {JSON.stringify({ value: i, label: t(`days.${i}`) })}{JSON.stringify(valueMarks[i])} */}
            </Grid>
          </React.Fragment>
        )
      })}
    </Grid>
  )
}

function PersonDetailsForm({ fields, onChange, profile, onInit }) {
  const ref = useRef(null)
  if(!ref.current && fields) ref.current = fields

  useEffect(() => {
    if (profile && ref.current) {
      ref.current = ref.current.map((field) => {
        if (field.v) return field
        if (field.n === 'name') {
          if (profile.firstname || profile.lastname) {
            field.v = [profile.firstname || '', profile.lastname || ''].join(' ')
          }
        } else if (profile[field.n]) {
          field.v = profile[field.n]
        }
        if (field.v) {
          field.i = true
        }
        return field
      })
      const inits = ref.current.filter((field) => field.i)
      if (inits.length > 0) {
        onInit(inits)
        ref.current = ref.current.map((field) => {
          field.i = false
          return field
        })
      }
    }
  }, [profile, fields, onInit])

  const handleChange = (event) => {
    ref.current = ref.current.map((field) => {
      if (field.n === event.target.name) {
        field.v = event.target.value
      }
      return field
    })
    onChange(event)
  }

  return (
    <Grid container spacing={3}>
      {ref.current
        ? ref.current.map((field) => {
            return (
              <Grid item xs={12} sm={6}>
                <TextField label={field.t} fullWidth onChange={handleChange} name={field.n} required value={field.v || ''} />
              </Grid>
            )
          })
        : null}
    </Grid>
  )
}

function QuestionsForm({ fields, onChange }) {
  return fields
    ? fields.map((field) => {
        if (field.type === 'textarea') {
          return (
            <div className={formstyles.div}>
              <TextField
                multiline
                minRows={4}
                variant='outlined'
                name={field.title}
                label={field.title}
                fullWidth
                margin='normal'
                onChange={onChange}
                required={field.required}
              />
            </div>
          )
        } else if (field.type === 'select') {
          return (
            <div className={formstyles.div}>
              <TextField
                select
                variant='outlined'
                name={field.title}
                label={field.title}
                fullWidth
                margin='normal'
                onChange={onChange}
                required={field.required}
                defaultValue={''}
              >
                {field.options.map((option) => {
                  return (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  )
                })}
              </TextField>
            </div>
          )
        } else if (field.type === 'daytimeselector') {
          return <div style={{ marginTop: 40}}><DayTimeSelector onChange={onChange} /></div>
        } else {
          return (
            <div className={formstyles.div}>
              <TextField
                variant='outlined'
                name={field.title}
                label={field.title}
                fullWidth
                margin='normal'
                type={field.type}
                onChange={onChange}
                required={field.required}
              />
            </div>
          )
        }
      })
    : null
}

const enhance = connect(
  // Map redux state to component props
  ({ firebase: { auth, profile } }) => ({
    auth,
    profile,
  })
)

function FormScreen({ auth, profile, dispatch, ...props}) {
  const params = useParams()
  const history = useHistory()
  const { t } = useTranslation()
  const [formData, setFormData] = useState({})
  let defaultLanguage = 'FI'
  // const [loading, setLoading] = useState(false)
  // const [error, setError] = useState(null)
  const [application, setApplication] = useState(null)
  const [cookies] = useCookies(['selectedLanguage'])
  if (cookies.selectedLanguage) {
    defaultLanguage = cookies.selectedLanguage
  }

  useEffect(() => {
    if (defaultLanguage.toLowerCase() === 'se') {
      moment.locale('sv', { week: { dow: 3 } })
    } else if (defaultLanguage.toLowerCase() === 'ee') {
      moment.locale('et', { week: { dow: 3 } })
    } else {
      moment.locale(defaultLanguage.toLowerCase(), { week: { dow: 3 } })
    }
  }, [defaultLanguage])


  useEffect(() => {
    let _application = applications.find((a) => a.id === params.aid)
    if (_application) {
      const __application = { ..._application }
      __application.start = new Date(_application.start).toLocaleDateString()
      __application.end = new Date(_application.end).toLocaleDateString()
      __application.personaldetails = _application.personaldetails.map((field) => {
        return {
          n: field,
          t: t(field),
        }
      })
      _application = { ...__application }
    }
    setApplication(_application)
  }, [params.aid, t])

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
    console.log(e.target.value, e.target.name, e.target.type)
  }

  const handleInit = (inits) => {
    const _formData = { ...formData }
    inits.forEach((init) => {
      _formData[init.n] = init.v
    })
    setFormData(_formData)
  }

  return (
    <div className='full-page-background'>
      <TopNavigation />
      <Container className='front' maxWidth={'sm'} style={{ paddingTop: 20 }}>
        <Typography variant='h4' component='h1' gutterBottom>
          {application && application.title}
        </Typography>
        {/* <Typography gutterBottom>
          {application && application.start} - {application && application.end}
        </Typography> */}
        <div dangerouslySetInnerHTML={{ __html: application && application.description }} />
        <Divider style={{ marginTop: 20, marginBottom: 20 }} />
        <FormGroup>
          <PersonDetailsForm fields={application && application.personaldetails} onChange={handleChange} onInit={handleInit} profile={!isEmpty(auth) ? profile : null} />
          <QuestionsForm fields={application && application.questions} onChange={handleChange} />
          </FormGroup>
        <Button color='primary' onClick={() => history.push('/applications')}>
          {t('send')}
        </Button>
        {/* <code>{JSON.stringify(application, null, 2)}</code> */}
        <code>{JSON.stringify(formData, null, 2)}</code>
      </Container>
    </div>
  )
}

export default withAppconfig(enhance(FormScreen))
