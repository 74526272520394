import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom' // if you use react-router
import { useCookies } from 'react-cookie'
import { connect } from 'react-redux'
import withAppconfig from '../AppContext.js'
import { Grid, Avatar, IconButton, CircularProgress, Typography, Link } from '@material-ui/core'
// import { useTranslation } from 'react-i18next'
// import { Button } from 'reactstrap'
import moment from 'moment'
import 'moment/locale/en-gb'
import 'moment/locale/sv'
import 'moment/locale/fi'
import 'moment/locale/et'
import { t } from 'i18next'
import SimpleView from './SimpleView.js'
import List from '@material-ui/core/List'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'

const enhance = connect(({ firebase: { auth, profile } }) => ({
  auth,
  profile,
}))

function RecurringSalesCalendar({ appconfig, dispatch, campaign, freetimes, loading, onBack, terms }) {
  const history = useHistory()
  let defaultLanguage = 'FI'
  const [cookies] = useCookies(['selectedLanguage'])
  if (cookies.selectedLanguage) {
    defaultLanguage = cookies.selectedLanguage
  }
  const [selectedWeekday, setSelectedWeekday] = React.useState(1)

  useEffect(() => {
    if (defaultLanguage.toLowerCase() === 'se') {
      moment.locale('sv', { week: { dow: 3 } })
    } else if (defaultLanguage.toLowerCase() === 'ee') {
      moment.locale('et', { week: { dow: 3 } })
    } else {
      moment.locale(defaultLanguage.toLowerCase(), { week: { dow: 3 } })
    }
  }, [defaultLanguage])

  const selectRecurrent = (slot, d) => {
    slot.view = 'simple'
    slot.selectedduration = d
    slot.campaign = campaign
    slot.terms = terms
    dispatch({
      type: 'UPDATE_PAYABLE_RECURRENCY',
      payload: slot,
    })
    history.push(appconfig.pathPrefix + '/recs/checkout')
  }

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Link color='textSecondary' href='#' onClick={onBack}>
            <ChevronLeftIcon /> {t('back')}
          </Link>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='h6' style={{ textAlign: 'center' }}>
            {campaign && campaign.name}
          </Typography>
          <Typography variant='subtitle1' style={{ textAlign: 'center' }}>
            {campaign && campaign.seasonstart && campaign.seasonend ? (
              <span>
                {t('season')} {moment(campaign.seasonstart).format('D.M.YY')} -{' '}
                {moment(campaign.seasonend).format('D.M.YY')}
              </span>
            ) : null}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            {['ma', 'ti', 'ke', 'to', 'pe', 'la', 'su'].map((day, i) => (
              <IconButton style={{ padding: 10 }} key={day} onClick={() => setSelectedWeekday(i + 1)}>
                <Avatar
                  style={{
                    height: 24,
                    width: 24,
                    fontSize: '0.6rem',
                    backgroundColor: i === selectedWeekday - 1 ? '#007bff' : 'gray',
                  }}
                >
                  {t(day).toUpperCase()}
                </Avatar>
              </IconButton>
            ))}
          </div>
        </Grid>
      </Grid>
      {loading ? (
        <div style={{ width: '100%', textAlign: 'center', marginTop: 30 }}>
          <CircularProgress />
        </div>
      ) : null}
      {freetimes && freetimes.length > 0 ? (
        <List component='nav' aria-labelledby='nested-list-subheader' style={{ marginBottom: 50 }}>
          <SimpleView
            dailyFreeTimes={freetimes[selectedWeekday - 1].vacancies}
            selectedvenue={'all'}
            weather={null}
            viewEmptyTimes={false}
            showWeek={false}
            recurrentsales={true}
            onSelectSlot={selectRecurrent}
          />
        </List>
      ) : null}
    </div>
  )
}

export default withAppconfig(enhance(RecurringSalesCalendar))
