import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardMedia from '@material-ui/core/CardMedia'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Collapse from '@material-ui/core/Collapse'
import Avatar from '@material-ui/core/Avatar'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Chip from '@material-ui/core/Chip'
import ShareIcon from '@material-ui/icons/Share'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Badge from '@material-ui/core/Badge'
// import MoreVertIcon from '@material-ui/icons/MoreVert'
import { yellow, deepOrange, blue } from '@material-ui/core/colors'
import moment from 'moment-timezone'
import 'moment/locale/fi'
import 'moment/locale/sv'
import 'moment/locale/en-gb'
import 'moment/locale/et'
// import BookmarkIcon from '@material-ui/icons/Bookmark';
// import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import BookmarkTwoToneIcon from '@material-ui/icons/BookmarkTwoTone';
import { useTranslation } from 'react-i18next'
import { Col } from 'reactstrap'
import { getPosterUrl } from './ViewUtils'
import { Divider } from '@material-ui/core'
// import { ModeComment } from '@material-ui/icons'


const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 380,
    // maxHeight: 400,
    backgroundColor: 'rgba(253,253,253,0.8)',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
    boxShadow: 'inset -2px -20px 35px 12px rgba(255,255,255,1)',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: blue[500],
  },
  horizontalroot: {
    maxWidth: '100%',
    marginBottom: 30,
    boxShadow: 'inset 200px 180px 135px 0px rgba(255,255,255,1)',
  },
  horizontalroote: {
    maxWidth: '100%',
    marginBottom: 30,
    boxShadow: 'inset 200px 330px 135px 0px rgba(255,255,255,1)',
  },
  horizontalmedia: {
    height: '100%',
    // paddingLeft: '56.25%', // 16:9
    // boxShadow: 'inset -2px -20px 35px 12px rgba(255,255,255,1)',
    width: '100%',
    maxHeight: 150,
    objectFit: 'cover',
  },
}))

const PinnedAvatar = ({ type, classes, logo }) => {
  return (
    <Badge
      overlap='circular'
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      badgeContent={<BookmarkTwoToneIcon style={{ color: deepOrange[500], marginLeft: 5 }} />}
    >
      <Avatar variant='rounded' className={classes.avatar} src={logo}>
        {type.substring(0, 1).toUpperCase()}
      </Avatar>
    </Badge>
  )
}

const RegularAvatar = ({ type, classes, logo }) => {
  return (
    <Avatar variant='rounded' className={classes.avatar} src={logo}>
      {type.substring(0, 1).toUpperCase()}
    </Avatar>
  )
}

const SignupText = ({ price, isexternal, externaltext }) => {
  if (isexternal) {
    return <span>{externaltext || 'Ilmoittaudu'}</span>
  } else if (price > 0) {
    return <span>Ilmoittaudu {price}€</span>
  } else {
    return <span>Ilmoittaudu</span>
  }
}


export default function ActivityCard({
  activity,
  horizontal,
  paymentAction,
  cancelAction,
  uid,
  shareClick,
  gtime,
  defaultExpanded = false,
}) {
  let {
    name,
    poster,
    description,
    price,
    type,
    start,
    end,
    ingress,
    maxp,
    participants,
    logo,
    queue,
    showusers,
    hasqueue,
    sport,
    // displayName,
  } = activity
  const classes = useStyles()
  const [expanded, setExpanded] = React.useState(defaultExpanded)
  const { t } = useTranslation()

  if (!participants) participants = []
  if (!queue) queue = []

  const hasEntry = participants.find((x) => x.id === uid)
  const isInQueue = queue.find((x) => x.id === uid)
  const isFull = participants.length >= maxp
  const hasLittleRoom = participants.length > Math.floor(maxp * 0.75) && participants.length < maxp
  // const canSignUp = !hasEntry && !isInQueue && !isFull
  // const canQueue = !hasEntry && !isInQueue && participants.length >= maxp
  const signUpOpen = moment.utc(activity.entryend, 'YYYY-MM-DDTHH:mm').subtract(moment().tz('Europe/Helsinki').utcOffset(), 'minutes').isAfter(gtime.utc())
  // console.log(
  //   'signUpOpen',
  //   signUpOpen,
  //   'canSignUp',
  //   canSignUp,
  //   'canQueue',
  //   canQueue,
  //   gtime.format(),
  //   moment.utc(activity.entryend, 'YYYY-MM-DDTHH:mm').format()
  // )

  // console.log('isEntry', isEntry, 'isQueue', isQueue)

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }


  // const buttonSignUpText = price > 0 ? 'Ilmoittaudu ' + price + '€' : 'Ilmoittaudu'
  const buttonCancelText = t('cancel_participation')

  const actionButton = () => {
    if (signUpOpen) {
      if (hasEntry) {
        return (
          <Button variant='contained' color='secondary' onClick={() => cancelAction(activity)}>
            {buttonCancelText}
          </Button>
        )
      } else if (isInQueue) {
        return (
          <Button variant='contained' color='secondary' onClick={() => cancelAction(activity)}>
            {buttonCancelText}
          </Button>
        )
      } else if (!isFull) {
        return (
          <Button
            variant='contained'
            color={'primary'}
            style={hasLittleRoom ? { backgroundColor: yellow[400], color: 'black' } : {}}
            onClick={() => paymentAction(activity)}
          >
            <SignupText price={price} isexternal={activity.isexternal} externaltext={activity.externaltext} />
          </Button>
        )
      } else {
        if (hasqueue) {
          return (
            <Button
              variant='contained'
              color={'primary'}
              onClick={() => paymentAction(activity)}
              style={{ backgroundColor: '#a643c1', color: 'white' }}
            >
              {t('add_to_queue')}
            </Button>
          )
        } else {
          return (
            <Button
              variant='contained'
              color='primary'
              style={{ backgroundColor: yellow[400], color: 'black' }}
              disabled
            >
              {t('event_full')}
            </Button>
          )
        }
      }
    } else {
      // signup not open any more
      return (
        <Button variant='contained' color='secondary' disabled>
          {t('entry_closed')}
        </Button>
      )
    }
  }

  const imageUrl = getPosterUrl(poster, 300)

  return (
      <Col style={{ marginBottom: 30, paddingLeft: 6, paddingRight: 6 }}>
        <Card className={classes.root}>
          <CardHeader
            style={{ paddingBottom: 4 }}
            avatar={activity.pinned ? <PinnedAvatar type={type} classes={classes} logo={logo} /> : <RegularAvatar type={type} classes={classes} logo={logo} />}
            // action={
            //   <IconButton aria-label='settings'>
            //     <MoreVertIcon />
            //   </IconButton>
            // }
            title={name.fi ? name.fi.replace(/Ryhmäliikunta \//gi, '') : ''}
            subheader={sport ? sport : ''}
            // subheader={moment.utc(start).format('dddd D.M. H:mm') + ' - ' + moment.utc(end).format('H:mm')}
          />
          <div style={{ textAlign: 'center', paddingBottom: 5 }}>
            <Typography variant='body2' color='textPrimary'>
              {moment.utc(start).format('dddd D.M. H:mm') + ' - ' + moment.utc(end).format('H:mm')}
            </Typography>
        </div>
        {imageUrl ? (
          <CardMedia className={classes.media} image={imageUrl} title='' />
        ) : <Divider style={{ marginTop: 10, marginBottom: 30 }} />}

          <CardContent>
            <Typography variant='body2' color='textPrimary' component='p' style={{ marginTop: '-25px' }}>
              {ingress.fi}
            </Typography>
            {!activity.isexternal && !activity.hideentrycount ? (
              <Typography variant='body2' color='textPrimary' component='p'>
              {participants.length + '/' + maxp} {t('participants')}{' '} 
                {hasEntry || isInQueue ? (
                  <Chip
                    color={hasEntry ? 'primary' : 'seconday'}
                    label={hasEntry ? 'Olet ilmoittautunut' : 'Olet jonossa'}
                  />
                ) : null}
              </Typography>
          ) : null}
          {signUpOpen ? (
          <Typography variant='caption' color='textPrimary' component='p'>
            {t('entry_final_time')} {moment.utc(activity.entryend, 'YYYY-MM-DDTHH:mm').format('D.M. H:mm')}
            </Typography>
          ) : null}
          </CardContent>
          <CardActions disableSpacing>
          {actionButton()}
          
            <IconButton aria-label='share' onClick={() => shareClick(activity)}>
              <ShareIcon />
            </IconButton>
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: expanded,
              })}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label='show more'
            >
              <ExpandMoreIcon />
            </IconButton>
          </CardActions>
          <Collapse in={expanded} timeout='auto' unmountOnExit>
            <CardContent>
              <Typography paragraph>{description.fi}</Typography>
              {showusers ? (
                <Typography variant='overline' gutterBottom>
                  Ilmoittautuneet
                </Typography>
              ) : null}
              {showusers
                ? participants.map((p) => (
                    <div>
                      <Typography key={p.id} variant='caption' gutterBottom>
                        {p.firstname + ' ' + p.lastname}
                      </Typography>
                    </div>
                  ))
                : null}
            </CardContent>
          </Collapse>
        </Card>
      </Col>
    )
  // }
}
