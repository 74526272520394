import React, { useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'
import AppNavigator from './AppNavigator'
// import FirebaseAnalytics from './FirebaseAnalytics'
// import CookieConsent from 'react-cookie-consent'
import { AppconfigContext } from './appconfig-context'
import { firestoreConnect, isLoaded } from 'react-redux-firebase'
import { connect } from 'react-redux'
import { compose } from 'redux'
// import TawkTo from './components/TawkTo'
import moment from 'moment'
import { useInterval } from './utils/useInterval'
import { useUiGet } from './utils/useUiGet'

function App({ appconfig, auth, profile, dispatch }) {
  const uiGet = useUiGet({ appconfig })

  useInterval(() => {
    fetch('/favicon.ico?_cb=' + Date.now(), { method: 'HEAD' }).then((response) => {
      const time = moment(new Date(response.headers.get('Date')))
      dispatch({
        type: 'UPDATE_GLOBALTIME',
        payload: time,
      })
    })
  }, 60000)


  useEffect(() => {
    fetch('/favicon.ico?_cb=' + Date.now(), { method: 'HEAD' }).then((response) => {
      const time = moment(new Date(response.headers.get('Date')))
      dispatch({
        type: 'UPDATE_GLOBALTIME',
        payload: time,
      })
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  useEffect(() => {
    if (!appconfig) return
    if (!appconfig.resources) {
      uiGet({ q: 'getResources', customerid: appconfig.accountkey }).then((response) => {
        if (response.data.error) {
          // console.log(response.data)
        } else {
          dispatch({
            type: 'UPDATE_RESOURCES',
            payload: response.data,
          })
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (appconfig && isLoaded(appconfig)) {
    // console.log('appconfig', appconfig)
    return (
      <AppconfigContext.Provider value={appconfig}>
        <BrowserRouter>
          <AppNavigator />
          {/* <FirebaseAnalytics /> */}
          {/* {appconfig.tawkto ? <TawkTo profile={profile} {...appconfig.tawkto} /> : null} */}
          {/* <CookieConsent
        location='bottom'
        buttonText='Ok'
        cookieName='fa-cookie-consent'
        style={{ background: '#7a7a7a', borderRadius: 5 }}
        buttonStyle={{ color: '#4e503b', fontSize: '13px', borderRadius: 5 }}
        expires={150}
      >
        Käytämme . <span style={{ fontSize: '10px' }}>This bit of text is smaller :O</span>
      </CookieConsent> */}
        </BrowserRouter>
      </AppconfigContext.Provider>
    )
  } else {
    return <></>
  }
}

const withConfig = compose(
  connect(({ firebase: { auth, profile } }) => ({
    auth,
    profile,
  })),
  firestoreConnect((props) => [
    {
      collection: 'inits',
      where: [
        ['type', '==', 'webr'],
        document.location.hostname === 'utest.cintoia.com' || document.location.hostname === 'localhost'
          ? ['utest', '==', document.location.pathname.split('/')[1]]
          : ['hosts', 'array-contains', document.location.hostname],
      ],
      storeAs: 'init',
    }]),
  connect(({ firestore, resources }) => {
    if (firestore.data['init']) {
      const _appconfigs = Object.keys(firestore.data['init'])
      const appconfig = _appconfigs.map((a) => {
        return { ...firestore.data['init'][a] }
      })[0]

      if (document.location.hostname === 'utest.cintoia.com' || document.location.hostname === 'localhost') {
        appconfig.pathPrefix = '/' + appconfig.utest
      } else {
        appconfig.pathPrefix = ''
      }
      appconfig.isOnlyMembers = (appconfig.onlyMembers && appconfig.onlyMembers.memberships && appconfig.onlyMembers.memberships.length > 0) ? true : false

      if (!appconfig.resources) {
        appconfig.resources = resources ? resources.resources : null
      }

      return {
        appconfig,
      }
    } else {
      return {
        appconfig: null,
      }
    }
  })
)

export default withConfig(App)
