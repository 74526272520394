import React, { useEffect, useState } from 'react'
import { Button, ButtonGroup } from 'reactstrap'
import Box from '@material-ui/core/Box'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'
import { useCookies } from 'react-cookie'
import useWindowDimensions from './useWindowsDimensions'
import withAppconfig from './AppContext'
import { Row, Col } from 'reactstrap'

function TabButton({ onClick, active, text }) {
  return (
    <Button
      style={{
        borderBottom: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
      }}
      outline={!active}
      onClick={onClick}
      color='primary'
    >
      {text}
    </Button>
  )
}

function TabNavigation({ appconfig }) {
  const { t, i18n } = useTranslation()
  let defaultLanguage = 'FI'
  const history = useHistory()
  const { width } = useWindowDimensions()
  const [cookies, setCookie] = useCookies(['selectedLanguage'])
  if (cookies.selectedLanguage) {
    defaultLanguage = cookies.selectedLanguage
  }
  const [activeTab, setActiveTab] = useState(0)

  useEffect(() => {
    if (defaultLanguage) {
      let i18lang =
        defaultLanguage === 'GB' ? 'en' : defaultLanguage.toLowerCase()
      i18n.changeLanguage(i18lang.toLowerCase())
    }
  }, [defaultLanguage, i18n])

  useEffect(() => {
    const urlPath = window.location.pathname
    console.log('urlPath', urlPath)
    if (urlPath.indexOf('/activities/all') > -1) {
      setActiveTab(1)
    } else {
      setActiveTab(0)
    }
  }, [])

  if (width > 768) {
    return null
  }

  if (appconfig.activities) {
    return (
      <Row className='home-header border-bottom mb-2'>
        <Col xs={'12'} className='pt-2 text-left'>
          <Box elevation={3}>
            <ButtonGroup>
              <TabButton
                active={activeTab === 0}
                onClick={() => history.push(appconfig.pathPrefix + '/')}
                color='primary'
                text={t('vuoronvaraus')}
              >
                {t('vuoronvaraus')}
              </TabButton>
              {appconfig.activities ? (
                <TabButton
                  active={activeTab === 1}
                  onClick={() =>
                    history.push(appconfig.pathPrefix + '/activities/all')
                  }
                  color='primary'
                  text={t('activities')}
                >
                  {t('activities')}
                </TabButton>
              ) : null}
            </ButtonGroup>
          </Box>
        </Col>
      </Row>
    )
  } else {
    return null
  }
}

export default withAppconfig(TabNavigation)
