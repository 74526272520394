import { useState, useEffect } from 'react'

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  const isBsXs = width < 576
  const isBsSm = width >= 576
  const isBsMd = width >= 768
  const isBsLg = width >= 992
  const isBsXl = width >= 1200
  const isBsXxl = width >= 1400
  return {
    width,
    height,
    isBsXs,
    isBsSm,
    isBsMd,
    isBsLg,
    isBsXl,
    isBsXxl,
  };
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}