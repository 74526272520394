import React, { useEffect } from 'react'
// import { useHistory } from 'react-router-dom' // if you use react-router
import TopNavigation from '../TopNavigation.js'
import { useFirebase } from 'react-redux-firebase'
// import { compose } from 'redux'
import { useCookies } from 'react-cookie'
import { connect } from 'react-redux'
import withAppconfig from '../AppContext.js'
import { Container, Typography, Card, CardActions, CardContent, CircularProgress } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Button } from 'reactstrap'
import moment from 'moment'
import 'moment/locale/en-gb'
import 'moment/locale/sv'
import 'moment/locale/fi'
import 'moment/locale/et'
import { t } from 'i18next'
import { formatVacancies } from '../components/ViewUtils.js'
// import SimpleView from '../components/SimpleView.js'
// import List from '@material-ui/core/List'
import RecurringSalesCalendar from '../components/RecurringSalesCalendar.js'


const enhance = connect(
  ({ firebase: { auth, profile } }) => ({
    auth,
    profile,
  })
)

function ApplicationCard({ application, onClick }) {
  const { t } = useTranslation()
  return (
    <Card variant='outlined' style={{ marginTop: 20 }}>
      {/* <CardActionArea onClick={() => onClick(application.id)}> */}
      <CardContent>
        <Typography gutterBottom variant='h5' component='h2'>
          {application && application.name}
        </Typography>
        <Typography variant='body2' color='textSecondary' component='p'>
          {application && application.seasonstart
            ? t('season') +
              ' ' +
              moment(application.seasonstart).format('D.M.YY') +
              ' - ' +
              moment(application.seasonend).format('D.M.YY')
            : null}
          <br />
        </Typography>
        <div dangerouslySetInnerHTML={{ __html: application && application.description }} />
      </CardContent>
      <CardActions disableSpacing>
        <Button size='small' color='primary' onClick={() => onClick(application.id)} style={{ marginLeft: 'auto' }}>
          {t('open')}
        </Button>
      </CardActions>
    </Card>
  )
}




function RecurringSalesScreen2({ appconfig, dispatch }) {
  const firebase = useFirebase()
  let defaultLanguage = 'FI'
  const [cookies] = useCookies(['selectedLanguage'])
  if (cookies.selectedLanguage) {
    defaultLanguage = cookies.selectedLanguage
  }
  const [campaigns, setCampaigns] = React.useState([])
  const [terms, setTerms] = React.useState({})
  const [freetimes, setFreetimes] = React.useState([])
  const [selectedCampaign, setSelectedCampaign] = React.useState(null)
  const [loading, setLoading] = React.useState(true)
  const [loadingtimes, setLoadingTimes] = React.useState(true)

  useEffect(() => {
    if (defaultLanguage.toLowerCase() === 'se') {
      moment.locale('sv', { week: { dow: 3 } })
    } else if (defaultLanguage.toLowerCase() === 'ee') {
      moment.locale('et', { week: { dow: 3 } })
    } else {
      moment.locale(defaultLanguage.toLowerCase(), { week: { dow: 3 } })
    }
  }, [defaultLanguage])

  useEffect(() => {
    firebase.functions().region = 'europe-west1'
    const fn = appconfig.istest ? 'testui-get' : 'ui-get'
    const _uiGet = firebase.functions().httpsCallable(fn)
    _uiGet({ q: 'getRecurringSales', customerid: appconfig.accountkey }).then((response) => {
      if (response.data.error) {
        console.log(response.data)
      } else {
        console.log('campaigns', response.data)
        console.log('appconfig.resources', appconfig.resources)
        setCampaigns(response.data.campaigns)
        setTerms(response.data.terms)
      }
      setLoading(false)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (selectedCampaign === null) return
    firebase.functions().region = 'europe-west1'
    const fn = appconfig.istest ? 'testui-get' : 'ui-get'
    const _uiGet = firebase.functions().httpsCallable(fn)
    _uiGet({ q: 'getRecurringFreeTimes', customerid: appconfig.accountkey, campaignid: selectedCampaign }).then(
      (response) => {
        if (response.data.error) {
          console.log(response.data)
        } else {
          console.log('freetimes', response.data)
          const resources = response.data.resources
          const dailyFreeTimes = response.data.days.map((daily) => {
            return { date: daily.date, vacancies: formatVacancies(daily.data, daily.date, resources, true) }
          })
          // console.log('dailyFreeTimes', dailyFreeTimes)
          setFreetimes(dailyFreeTimes)
          setLoadingTimes(false)
        }
      }
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCampaign])

  const onClick = (id) => {
    setSelectedCampaign(id)
    setLoadingTimes(true)
  }

  return (
    <div className='full-page-background'>
      <TopNavigation />
      <Container className='front' maxWidth={'md'} style={{ paddingTop: 20 }}>
        <Typography variant='h4' component='h2' gutterBottom>
          {t('recurringsales')}
        </Typography>
        {loading ? <div style={{ width: '100%', textAlign: 'center' }}><CircularProgress /></div> : null}
        {campaigns && campaigns.length > 0 && selectedCampaign === null
          ? campaigns.map((campaign) => <ApplicationCard application={campaign} onClick={onClick} key={campaign.id} />)
          : null}
        {selectedCampaign && !loading ? (
          <RecurringSalesCalendar freetimes={freetimes} campaign={campaigns.find((c) => c.id === selectedCampaign)} terms={terms} loading={loadingtimes} onBack={() => setSelectedCampaign(null)}/>
        ) : null}
      </Container>
    </div>
  )
}

export default withAppconfig(enhance(RecurringSalesScreen2))
