import React from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router'
import { isEmpty } from 'react-redux-firebase'
import { useTranslation } from 'react-i18next'
// import { useCookies } from 'react-cookie'
// import ReactFlagsSelect from 'react-flags-select'
// import useWindowDimensions from './useWindowsDimensions'
import withAppconfig from './AppContext'
import BottomNavigation from '@material-ui/core/BottomNavigation'
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction'
import Box from '@material-ui/core/Box'

// import FolderIcon from '@material-ui/icons/Folder'
import RestoreIcon from '@material-ui/icons/Restore'
import FavoriteIcon from '@material-ui/icons/Favorite'
import LocationOnIcon from '@material-ui/icons/LocationOn'

const enhance = connect(({ firebase: { auth, profile } }) => ({
  auth,
  profile,
}))

function BottomNav({ auth, profile, appconfig }) {
  const { t } = useTranslation()
  // let defaultLanguage = 'FI'
  const history = useHistory()
  // const { width } = useWindowDimensions()
  // const [isOpen, setIsOpen] = useState(false)
  const [value, setValue] = React.useState('2')
  const isLoggedIn = !isEmpty(auth)
  // const [cookies, setCookie] = useCookies(['selectedLanguage'])
  // if (cookies.selectedLanguage) {
  //   defaultLanguage = cookies.selectedLanguage
  // }

  const handleChange = (event, newValue) => {
    console.log('newValue', newValue)
    setValue(newValue)
  }

  return (
    <Box
      sx={{
        display: { xs: 'block', sm: 'none' },
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 9999,
        marginBottom: 'env(safe-area-inset-bottom)',
      }}
      elevation={3}
    >
      <BottomNavigation value={value} onChange={handleChange} showLabels>
        <BottomNavigationAction
          label={t('vuoronvaraus')}
          value='1'
          icon={<RestoreIcon />}
          onClick={() => history.push(appconfig.pathPrefix + '/')}
        />
        {isLoggedIn ? (
          <>
            <BottomNavigationAction
              label={t('omat_varaukset')}
              value='2'
              icon={<FavoriteIcon />}
              onClick={() => history.push(appconfig.pathPrefix + '/reservations')}
            />
            <BottomNavigationAction
              label={t('omat_tiedot')}
              value='3'
              icon={<LocationOnIcon />}
              onClick={() => history.push(appconfig.pathPrefix + '/profile')}
            />
          </>
        ) : null}
      </BottomNavigation>
    </Box>
  )
}

export default enhance(withAppconfig(BottomNav))
