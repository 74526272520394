import React, { useEffect, useState } from 'react'
import withAppconfig from '../../AppContext'
import { useUiGet } from '../../utils/useUiGet'
import { CircularProgress } from '@material-ui/core'

function SaveCard({ appconfig }) {
  const uiGet = useUiGet({ appconfig })
  const [loading, setLoading] = useState(true)
  const [tokenizingData, setTokenizingData] = useState({})

  useEffect(() => {
    if (!tokenizingData || Object.keys(tokenizingData).length === 0) {
      uiGet({ q: 'getCardTokenizingParamsPc', customerid: appconfig.accountkey }).then((response) => {
        if (response.data.error) {
          console.log(response.data)
        } else {
          console.log('tokenizingData', response.data)
          setTokenizingData(response.data)
        }
        setLoading(false)
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return <React.Fragment>{loading ? <CircularProgress /> :
    <div>
      {JSON.stringify(tokenizingData)}
      <form id='addcard-form' action='https://services.paytrail.com/tokenization/addcard-form' method='post'>
        {Object.keys(tokenizingData.postparameters).map((key) => {
          return <input type='hidden' name={key} value={tokenizingData.postparameters[key]} />
        })}
        <input type='submit' value='Save Card' />
      </form>
      {/* <button onClick={onClick}>Save Card</button> */}
    </div>
  }</React.Fragment>
}

export default withAppconfig(SaveCard) // Wrap the component with withAppConfig HOC
