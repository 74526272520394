import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom' // if you use react-router
import TopNavigation from '../TopNavigation'
import { useFirebase, isLoaded, firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import EditIcon from '@material-ui/icons/Edit'
import SettingsEthernetIcon from '@material-ui/icons/SettingsEthernet'
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Card,
  CardBody,
  CardSubtitle,
  CardText,
  Modal,
  ModalBody,
  ModalFooter,
  CardTitle,
  ButtonGroup,
  ModalHeader,
  Alert,
  Spinner,
} from 'reactstrap'
import moment from 'moment'
import 'moment/locale/fi'
import ViewColumnIcon from '@material-ui/icons/ViewColumn'
import ViewListIcon from '@material-ui/icons/ViewList'
import CalendarViewDayIcon from '@material-ui/icons/CalendarViewDay'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
// import InputLabel from '@material-ui/core/InputLabel'
// import IconButton from '@material-ui/core/IconButton'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import MuiButton from '@material-ui/core/Button'
// import { Typography } from '@material-ui/core'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import withAppconfig from '../AppContext'
import mapWalletsStateToProps from '../components/state-utils'
import { Divider } from '@material-ui/core'
import { RATING_LABELS, APPINITLANG } from '../config'
//import BottomNav from '../BottomNav'

const styles = {
  tranactionrow: {
    marginLeft: 5,
    marginRight: 5,
    paddingTop: 3,
    paddingBottom: 3,
    fontSize: 'small',
  },
}

const withWallet = compose(
  connect((state) => ({ auth: state.firebase.auth })),
  firestoreConnect((props) => [
    {
      collection: 'users',
      doc: `${props.auth.uid}`,
      subcollections: [
        {
          collection: 'paymentinfo',
          doc: 'saldo',
          subcollections: [{ collection: `${props.appconfig.accountkey}` }],
        },
      ],
      storeAs: 'wallets',
    },
    {
      collection: 'users',
      doc: `${props.auth.uid}`,
      subcollections: [
        {
          collection: 'paymentinfo',
          doc: 'storage',
          subcollections: [{ collection: `${props.appconfig.accountkey}` }],
        },
      ],
      storeAs: 'storages',
    },
    {
      collection: 'users',
      doc: `${props.auth.uid}`,
      subcollections: [
        {
          collection: 'memberships',
          doc: 'active',
          subcollections: [{ collection: `${props.appconfig.accountkey}` }],
        },
      ],
      storeAs: 'memberships',
    },
    {
      collection: 'users',
      doc: `${props.auth.uid}`,
      subcollections: [
        {
          collection: 'benefitcards',
          doc: 'active',
          subcollections: [{ collection: `${props.appconfig.accountkey}` }],
        },
      ],
      storeAs: 'benefitcards',
    },
    {
      collection: 'users',
      doc: `${props.auth.uid}`,
      subcollections: [
        {
          collection: 'cards',
        },
      ],
      storeAs: 'cards',
    },
  ]),
  connect(mapWalletsStateToProps)
)

// function capitalizeFirstLetter(string) {
//   return string[0].toUpperCase() + string.slice(1)
// }

function StorageRow({ storage, selected, onClick }) {
  console.log('storagerow1', storage)
  if (!storage) return null
  console.log('storagerow2', storage)
  if (storage.separatesports) {
    const sports = Object.keys(storage.sums).filter((s) => s !== 'sum')
    return sports.map((sport) => {
      const sportlabel = sport.charAt(0).toUpperCase() + sport.slice(1)
      return (
        <CardText key={'sportstr-' + sport}>
          {sportlabel}:{' '}
          {storage.category === 'minutes'
            ? storage.sums[sport] + ' min'
            : (storage.sums[sport] / 100).toString() + ' €'}
        </CardText>
      )
    })
  } else {
    return (
      <CardText>
        {storage.label[APPINITLANG]}:{' '}
        {storage.category === 'minutes'
          ? storage.sum + ' min'
          : (storage.sum / 100).toString() + ' €'}
      </CardText>
    )
  }
}

// eslint-disable-next-line no-unused-vars
function RatingsSector({ ratings, clubid, editToggle }) {
  const { t } = useTranslation()
  if (!ratings) return null

  if (Object.keys(ratings).length === 0) {
    return <CardText>{t('no_rating')}</CardText>
  }

  return Object.keys(ratings)
    .sort()
    .map((sport) => {
      if (Object.keys(ratings[sport]).length > 0) {
        return Object.keys(ratings[sport]).map((source) => {
          let _source = source
          if (source === clubid) {
            _source = t('club_rating')
          } else if (RATING_LABELS[sport] && RATING_LABELS[sport][source]) {
            _source = RATING_LABELS[sport][source].short
          }
          return (
            <Row key={'rating-' + sport + '-' + source}>
              <Col>
                {t(sport)} - {_source}: {ratings[sport][source].value}
              </Col>
            </Row>
          )
        })
      } else {
        return (
          <Row key={'rating-' + sport}>
            <Col>
              {t(sport)} - {t('no_rating')}
            </Col>
          </Row>
        )
      }
    })
}

function RatingDialog({
  ratings,
  clubid,
  norating,
  clubrating,
  open,
  onClose,
  title,
  closeTitle,
  onSave,
  saveTitle,
}) {
  const { t } = useTranslation()
  const [selectedSport, setSelectedSport] = useState('')
  const [unsavedRatings, setUnsavedRatings] = useState(ratings)

  const onSavePress = () => {
    console.log('onSavePress')
    onSave(unsavedRatings)
  }

  useEffect(() => {
    setUnsavedRatings(ratings)
  }, [ratings])

  const hasManySources = (sport) => {
    if (!RATING_LABELS[sport]) return false
    return Object.keys(RATING_LABELS[sport]).length > 1
  }

  return (
    <Modal isOpen={open} toggle={onClose} size='lg'>
      <ModalHeader toggle={onClose}>{title}</ModalHeader>
      <ModalBody>
        {/* <CardText>{t('select_sport')}</CardText> */}
        <FormControl
          variant='outlined'
          style={{ width: '100%', marginBottom: 30 }}
        >
          <Select
            labelId={(selectedSport || 'dropdown') + '-select-label'}
            id={(selectedSport || 'dropdown') + '-select'}
            value={selectedSport}
            onChange={(e) => setSelectedSport(e.target.value)}
            style={{ width: '100%' }}
            displayEmpty
          >
            <MenuItem disabled value=''>{t('select_sport')}</MenuItem>
            {Object.keys(RATING_LABELS)
              .sort()
              .map((sport) => {
                return <MenuItem value={sport}>{t(sport)}</MenuItem>
              })}
          </Select>
        </FormControl>
        {selectedSport && selectedSport !== '' && (
        <Row>
          <Col></Col>
          <Col>{t('external_rating_id')}</Col>
          <Col>{t('external_rating_value')}</Col>
        </Row>
        )}
        <Form className=''>
          {selectedSport &&
            selectedSport !== '' &&
            Object.keys(RATING_LABELS[selectedSport]).map((source) => {
              let _source = source
              if (source === clubid) {
                _source = clubrating
              } else if (RATING_LABELS[selectedSport][source]) {
                _source = RATING_LABELS[selectedSport][source].short
              }
              return (
                <Row key={'rating-' + selectedSport + '-' + source}>
                  <Col className='my-auto'>
                    <h6>
                      {_source} -{' '}
                      {RATING_LABELS[selectedSport][source]
                        ? RATING_LABELS[selectedSport][source].long
                        : source}
                    </h6>
                  </Col>
                  <Col>
                    <FormGroup className='loose-form'>
                      {/* <Label for='external_rating_id'> */}
                        {/* {t('external_rating_id')} * */}
                      {/* </Label> */}
                      <Input
                        label={t('external_rating_id')}
                        type='text'
                        name={selectedSport + '-' + source}
                        id={'external_rating_id'}
                        value={
                          unsavedRatings[selectedSport] &&
                          unsavedRatings[selectedSport][source]
                            ? unsavedRatings[selectedSport][source].id
                            : ''
                        }
                        onChange={(e) => {
                          const _ratings = { ...unsavedRatings }
                          if (!_ratings[selectedSport]) {
                            _ratings[selectedSport] = {}
                            _ratings[selectedSport][source] = {}
                          } else if (!_ratings[selectedSport][source]) {
                            _ratings[selectedSport][source] = {}
                          }
                          _ratings[selectedSport][source].id = e.target.value
                          setUnsavedRatings(_ratings)
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col className='my-auto'>
                    {RATING_LABELS[selectedSport][source].automatic ? (
                      <CardText>{t('fetching_automatic')}</CardText>
                    )
                      : (
                        <FormGroup className='loose-form'>
                          {/* <Label for='external_rating_value'> */}
                            {/* {t('external_rating_value')} * */}
                          {/* </Label> */}
                          <Input
                            label={t('rating')}
                            type='text'
                            id={'external_rating_value'}
                            disabled={
                              RATING_LABELS[selectedSport][source].automatic
                            }
                            name={selectedSport + '-' + source}
                            value={
                              unsavedRatings[selectedSport] &&
                                unsavedRatings[selectedSport][source]
                                ? unsavedRatings[selectedSport][source].value
                                : ''
                            }
                            onChange={(e) => {
                              const _ratings = { ...unsavedRatings }
                              if (!_ratings[selectedSport]) {
                                _ratings[selectedSport] = {}
                                _ratings[selectedSport][source] = {}
                              } else if (!_ratings[selectedSport][source]) {
                                _ratings[selectedSport][source] = {}
                              }
                              _ratings[selectedSport][source].value = e.target.value
                              setUnsavedRatings(_ratings)
                            }}
                          />
                        </FormGroup>
                      )}
                  </Col>
                </Row>
              )
            })}
        </Form>
        <Row>
          <Col style={{ marginTop: 30 }}>
            {hasManySources(selectedSport) && (
              <Alert color='info'>
                {t('rating_source_help')}
              </Alert>
            )}
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color='primary' onClick={onSavePress}>
          {saveTitle}
        </Button>{' '}
        <Button color='primary' onClick={onClose}>
          {closeTitle}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

function ProfileScreen({
  auth,
  profile,
  saldotransactions,
  memberships,
  mems,
  balances,
  accesscode,
  storages,
  benefitcards,
  storagetransactions,
  appconfig,
  gtime,
  cards,
}) {
  // eslint-disable-next-line no-unused-vars
  let history = useHistory()
  const { t, i18n } = useTranslation()
  const [email, setEmail] = useState(profile.email)
  const [newemail, setNewEmail] = useState(null)
  const [newemail2, setNewEmail2] = useState(null)
  const [newpassword, setNewPassword] = useState('')
  const [newpassword2, setNewPassword2] = useState('')
  const [newpasswordErrorMessage, setNewErrorMessage] = useState('')
  const [confirmationcode, setConfirmationcode] = useState(null)
  const [firstname, setFirstname] = useState(profile.firstname)
  const [lastname, setLastname] = useState(profile.lastname)
  const [phone, setPhone] = useState(profile.phone)
  const [streetaddress, setStreetAddress] = useState(profile.streetaddress)
  const [zip, setZip] = useState(profile.zip)
  const [city, setCity] = useState(profile.city)
  const [defaultview, setDefaultView] = useState(profile.defaultview)
  const [selectedwallettype, setSelectedWalletType] = useState(null)
  const [selectedstoragetype, setSelectedStorageType] = useState(null)
  const firebase = useFirebase()
  const [editOpen, setEditOpen] = useState(false)
  const [passwordMatch, setPasswordMatch] = useState(false)
  const [editEmailOpen, setEditEmailOpen] = useState(false)
  const [saveModalOpen, setSaveModalOpen] = useState(false)
  const [messageModalOpen, setMessageModalOpen] = useState(false)
  const [confirmationcodeOpen, setConfirmationcodeOpen] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [confirmationMessage, setConfirmationMessage] = useState(null)
  const [confirmationError, setConfirmationError] = useState(null)
  const [confirmProgress, setConfirmProgress] = useState(false)
  const [messagetitle, setMessageTitle] = useState(null)
  const [messagebody, setMessageBody] = useState()
  const [messagecategory, setMessageCategory] = useState('Saldot')
  // eslint-disable-next-line no-unused-vars
  const [messagethankyou, setMessageThankyou] = useState(false)
  const [membershipproducts, setMembershipProducts] = useState(null)
  const [passwordModalOpen, setPasswordModalOpen] = useState(false)
  const [ratings, setRatings] = useState({})
  const [ratingDialogOpen, setRatingDialogOpen] = useState(false)
  const editToggle = (state) => setEditOpen(state)
  const editEmailToggle = (state) => setEditEmailOpen(state)
  const confirmationcodeToggle = (state) => setConfirmationcodeOpen(state)
  const saveToggle = () => setSaveModalOpen(!saveModalOpen)
  const messageToggle = () => setMessageModalOpen(!messageModalOpen)

  const passwordModalToggle = () => setPasswordModalOpen(!passwordModalOpen)

  const toggleWalletType = (type) => {
    if (selectedwallettype === type) {
      setSelectedWalletType(null)
    } else {
      setSelectedWalletType(type)
    }
  }

  const toggleStorageType = (type) => {
    if (selectedstoragetype === type) {
      setSelectedStorageType(null)
    } else {
      setSelectedStorageType(type)
    }
  }

  const onSavePress = () => {
    const _p = { firstname, lastname, phone }
    if (streetaddress) _p.streetaddress = streetaddress
    if (zip) _p.zip = zip
    if (city) _p.city = city
    if (defaultview) _p.defaultview = defaultview
    return firebase.updateProfile(_p)
  }

  const onPasswordUpdate = (target) => {
    if (target.name === 'newpassword') {
      const strength = checkPasswordStrength()
      setNewPassword(target.value)
      if (
        target.value === newpassword2 &&
        target.value.length >= 7 &&
        strength >= 3
      ) {
        setNewErrorMessage(null)
        setPasswordMatch(true)
      } else {
        if (target.value !== newpassword2) {
          setNewErrorMessage(t('not_matching_password'))
        } else if (strength < 3) {
          setNewErrorMessage(t('too_weak_password'))
        } else {
          setNewErrorMessage(t('too_short_password'))
        }
        setPasswordMatch(false)
      }
    } else {
      const strength = checkPasswordStrength()
      setNewPassword2(target.value)
      if (
        target.value === newpassword &&
        newpassword.length >= 7 &&
        strength >= 3
      ) {
        setPasswordMatch(true)
        setNewErrorMessage(null)
      } else {
        if (target.value !== newpassword) {
          setNewErrorMessage(t('not_matching_password'))
        } else if (strength < 3) {
          setNewErrorMessage(t('too_weak_password'))
        } else {
          setNewErrorMessage(t('too_short_password'))
        }
        setPasswordMatch(false)
      }
    }
  }

  const checkPasswordStrength = () => {
    let pwdCheck = 0
    const validateRegex = ['[A-Z]', '[a-z]', '[0-9]', '\\W']
    validateRegex.forEach((regex, i) => {
      if (new RegExp(regex).test(newpassword)) {
        pwdCheck += 1
      }
    })
    return pwdCheck
  }

  const onChangePassword = () => {
    console.log('onChangePassword')
    firebase.functions().region = 'europe-west1'
    const updatePassword = firebase
      .functions()
      .httpsCallable('users-updatePassword')
    return updatePassword({ newpassword }).then((response) => {
      console.log('response.data', response.data)
      if (response.data.error) {
        setConfirmationMessage(response.data.message)
        return Promise.resolve(response.data)
      } else {
        // const message = t('password_changed')
        setNewPassword('')
        setNewPassword2('')
        passwordModalToggle()
      }
    })
  }

  const onChangeEmail = () => {
    firebase.functions().region = 'europe-west1'
    const createConfirmation = firebase
      .functions()
      .httpsCallable('users-createConfirmation')
    return createConfirmation({ cid: appconfig.accountkey, newemail }).then(
      (response) => {
        if (response.data.error) {
          setConfirmationMessage(response.data.message)
          return Promise.resolve(response.data)
        } else {
          const message = t('confirmation_send')
          setConfirmationMessage(message)
          return Promise.resolve(response.data)
        }
      }
    )
  }

  const confirmEmailChange = () => {
    return fetch(
      'https://europe-west1-falcon-328a1.cloudfunctions.net/users-confirmEmail?code=' +
        confirmationcode
    ).then((response) => {
      return Promise.resolve(response.json())
    })
  }

  const onSendPress = () => {
    let time = new Date()
    return firebase.push('supportmessages/' + auth.uid, {
      email,
      messagetitle,
      messagebody,
      send: time.toString(),
      messagecategory,
      customer: appconfig.accountkey,
    })
  }

  const getMembershipProducts = () => {
    firebase.functions().region = 'europe-west1'
    const fn = appconfig.istest
      ? 'testmemberships-getmemberships'
      : 'memberships-getmemberships'
    const getmemberships = firebase.functions().httpsCallable(fn)
    getmemberships({ customerid: appconfig.accountkey }).then((response) => {
      let memberships = []
      if (response.data && response.data.memberships) {
        memberships = response.data.memberships
      }
      setMembershipProducts(memberships)
    })
  }

  const ratingDialogToggle = () => {
    setRatingDialogOpen(!ratingDialogOpen)
  }

  const onRatingSavePress = (data) => {
    const _p = { ratings: data }
    console.log('onRatingSavePress', data)
    firebase.updateProfile(_p).then(() => {
      ratingDialogToggle()
    })
  }

  const loaded =
    isLoaded(profile) &&
    isLoaded(saldotransactions) &&
    isLoaded(storagetransactions) &&
    isLoaded(mems)
  useEffect(() => {
    setEmail(profile.email)
    setFirstname(profile.firstname)
    setLastname(profile.lastname)
    setPhone(profile.phone)
    setZip(profile.zip)
    setStreetAddress(profile.address || profile.streetaddress)
    setCity(profile.city)
    setDefaultView(profile.defaultview)
    setMessageCategory('Saldot')
    setRatings(profile.ratings || {})
    if (!membershipproducts && isLoaded(mems)) {
      getMembershipProducts()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile, mems])

  const getTransactionRow = (i, first, second, third, fourth) => {
    return (
      <Row
        key={'rtrans-' + i}
        style={styles.tranactionrow}
        className='border-bottom'
      >
        <Col className='text-left' xs={'4'} sm={'2'} md={'12'} lg={'2'}>
          {first}
        </Col>
        <Col
          className='text-left'
          xs={'8'}
          sm={'6'}
          md={'12'}
          lg={'6'} /*style={{ paddingLeft: 10, paddingRight: 0 }}*/
        >
          {second}
        </Col>
        <Col xs={'6'} sm={'2'} md={'6'} lg={'2'}>
          {third}
        </Col>
        <Col xs={'6'} sm={'2'} md={'6'} lg={'2'}>
          {fourth}
        </Col>
      </Row>
    )
  }

  const _selectedWalletTransactions =
    selectedwallettype &&
    saldotransactions[selectedwallettype.venue]?.[selectedwallettype.category]
      ? saldotransactions[selectedwallettype.venue][selectedwallettype.category]
      : []
  // do some formatting for transactions
  // eslint-disable-next-line array-callback-return
  let _s = 0
  let selectedWalletTransactions = _selectedWalletTransactions
    .map((ta, i) => {
      if (ta.amount) {
        _s = _s + ta.amount
      }
      let timestamp = ta.validuntil ? (
        <>
          <div>
            {moment.utc(ta.timestamp, 'YYYY.MM.DD HH:mm').format('D.M.YY')}
          </div>
          <div style={{ fontSize: 'x-small' }}>
            {'(' +
              moment.utc(ta.validuntil, 'YYYY.MM.DD HH:mm').format('D.M.YY') +
              ')'}
          </div>
        </>
      ) : (
        moment.utc(ta.timestamp, 'YYYY.MM.DD  HH:mm').format('D.M.YY')
      )

      if (!ta.timestamp) {
        if (ta.type === 'purchase') {
          timestamp = moment
            .utc(ta.purchase.occurency, 'YYYY-MM-DD')
            .format('D.M.YY')
        }
      }

      if (ta.purchasetype === 'reservation' || ta.purchasetype === 'activity') {
        if (ta.type === 'refund') {
          return getTransactionRow(
            i,
            ta.description.startsWith('Peruttu')
              ? t('cancellation')
              : t('sell'),
            ta.description,
            timestamp,
            <>
              <div>{(ta.amount / 100).toString() + ' €'}</div>
              <div style={{ fontSize: 'x-small' }}>
                {'(' + (_s / 100).toString() + '€)'}
              </div>
            </>
          )
        } else if (ta.type === 'purchase') {
          return getTransactionRow(
            i,
            t('reservation'),
            ta.description,
            timestamp,
            <>
              <div>{(ta.amount / 100).toString() + ' €'}</div>
              <div style={{ fontSize: 'x-small' }}>
                {'(' + (_s / 100).toString() + '€)'}
              </div>
            </>
          )
        }
      } else {
        let title = ''
        if (
          ta.type === 'compensation' ||
          ta.type === 'transfer' ||
          ta.type === 'deposit'
        ) {
          title = t(ta.type)
        } else {
          return <></>
        }
        return getTransactionRow(
          i,
          title,
          ta.description,
          timestamp,
          <>
            <div>{(ta.amount / 100).toString() + ' €'}</div>
            <div style={{ fontSize: 'x-small' }}>
              {'(' + (_s / 100).toString() + '€)'}
            </div>
          </>
        )
      }
      return null
    })
    .filter((ta) => ta !== null)
  selectedWalletTransactions = [...selectedWalletTransactions].reverse()
  if (_selectedWalletTransactions.length < 1 && selectedwallettype) {
    selectedWalletTransactions = (
      <Row xs={'12'} sm={'12'} md={'12'} lg={'12'}>
        <Col>{t('no_balance_transactions')}</Col>
      </Row>
    )
  }

  const _selectedStorageTransactions =
    selectedstoragetype &&
    storagetransactions[selectedstoragetype.venue]?.[
      selectedstoragetype.category
    ]
      ? storagetransactions[selectedstoragetype.venue][
          selectedstoragetype.category
        ]
      : []
  const _storageType =
    selectedstoragetype && selectedstoragetype.category
      ? selectedstoragetype.category
      : null

  // do some formatting for transactions
  let _sa = 0
  // eslint-disable-next-line array-callback-return
  let selectedStorageTransactions = _selectedStorageTransactions.map(
    (ta, i) => {
      if (ta.amount) {
        _sa = _sa + ta.amount
      }
      console.log('timestamp', ta.timestamp)

      const timestamp = ta.validuntil ? (
        <>
          <div>
            {moment.utc(ta.timestamp, 'YYYY.MM.DD HH:mm').format('D.M.YY')}
          </div>
          <div style={{ fontSize: 'x-small' }}>
            {'(' +
              moment.utc(ta.validuntil, 'YYYY.MM.DD HH:mm').format('D.M.YY') +
              ')'}
          </div>
        </>
      ) : (
        moment.utc(ta.timestamp, 'YYYY.MM.DD HH:mm').format('D.M.YY')
      )
      if (ta.type === 'refund' || ta.amount > 0) {
        return getTransactionRow(
          i,
          t('to_storage'),
          ta.description || '',
          timestamp,
          <>
            <div>
              {_storageType === 'minutes'
                ? ta.amount + ' min'
                : (ta.amount / 100).toString() + ' €'}
            </div>
            <div style={{ fontSize: 'x-small' }}>
              (
              {_storageType === 'minutes'
                ? _sa + 'min'
                : (_sa / 100).toString() + '€'}
              )
            </div>
          </>
        )
      } else if (ta.type === 'purchase' || ta.amount < 0) {
        return getTransactionRow(
          i,
          t('from_storage'),
          ta.description || '',
          timestamp,
          <>
            <div>
              {_storageType === 'minutes'
                ? ta.amount + ' min'
                : (ta.amount / 100).toString() + ' €'}
            </div>
            <div style={{ fontSize: 'x-small' }}>
              (
              {_storageType === 'minutes'
                ? _sa + 'min'
                : (_sa / 100).toString() + '€'}
              )
            </div>
          </>
        )
      } else {
        return <></>
      }
    }
  )
  selectedStorageTransactions = [...selectedStorageTransactions].reverse()
  if (_selectedStorageTransactions.length < 1 && selectedstoragetype) {
    selectedStorageTransactions = (
      <Row xs={'12'} sm={'12'} md={'12'} lg={'12'}>
        <Col>{t('no_storage_transactions')}</Col>
      </Row>
    )
  }

  // if (selectedwallettype && selectedwallettype.expiring) {
  //   const _linedate = moment().subtract(selectedwallettype.expiring, 'days')
  //   const _ed = _selectedWalletTransactions.filter((ta) => moment.utc(ta.timestamp, 'YYYY.MM.DD HH:mm').isAfter(_linedate) && ta.amount > 0).map((ta) => moment.utc(ta.timestamp, 'YYYY.MM.DD HH:mm')).sort((a, b) => a.unix() - b.unix())
  //   console.log('_ed', _ed)
  //   console.log('_selectedWalletTransactions', _selectedWalletTransactions)
  //   console.log('expiring', selectedwallettype.expiring)
  //   if (_ed.length > 0) {
  //     const linedate = _ed[0]
  //     // const linedate = ed.add(selectedwallettype.expiring, 'days')
  //     console.log('linedate', linedate.format('YYYY.MM.DD HH:mm'))
  //     // stored fund before linedate
  //     const _s = _selectedWalletTransactions.reduce((acc, ta) => {
  //       if (moment.utc(ta.timestamp, 'YYYY.MM.DD HH:mm').isSameOrBefore(linedate) && ta.amount > 0) {
  //         return acc + ta.amount
  //       } else {
  //         return acc
  //       }
  //     }, 0)

  //     // purchases after linedate
  //     const _p = _selectedWalletTransactions.reduce((acc, ta) => {
  //       if (moment.utc(ta.timestamp, 'YYYY.MM.DD HH:mm').isSameOrAfter(linedate) && ta.type === 'purchase') {
  //         return acc + ta.amount
  //       } else {
  //         return acc
  //       }
  //     }, 0)
  //     console.log('s', _s)
  //     console.log('p', _p)
  //     if (_p + _s > 0) {
  //       console.log('p-s', _p + _s)
  //       const _d = moment(linedate).add(selectedwallettype.expiring, 'days')
  //       console.log('Seuraava saldon vanhenemispäivä', _d.format('D.M.YYYY'), 'vanhenevat saldot', (_p + _s)/100, '€')
  //     }
  //   }
  // }

  const isZipValid =
    !zip ||
    (zip && zip.trim() && zip.trim().length === 5 && !isNaN(parseInt(zip)))
  const isCityValid = !city || (city && city.trim() && city.trim().length > 1)
  const isStreetValid =
    !streetaddress ||
    (streetaddress && streetaddress.trim() && streetaddress.trim().length > 6)
  const isAddressValid = isZipValid && isCityValid && isStreetValid

  if (!loaded) {
    return (
      <div className='full-page-background'>
        <TopNavigation />
        <Container className='front'>
          <Row>
            <Col md='8' className='mx-auto'>
              <div className='logo mb-3'>
                <div className='col-md-12 text-center tesoma-page-title'>
                  <h2>{t('omat_tiedot')}</h2>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md='8' className='mx-auto text-center'>
              {t('loading')}
            </Col>
          </Row>
        </Container>
      </div>
    )
  } else {
    // console.log(i18n.language)
    return (
      <div className='full-page-background'>
        <TopNavigation />
        <Container className='front'>
          <Row>
            <Col md='8' className='mx-auto'>
              <div className='logo mb-3'>
                <div className='col-md-12 text-center tesoma-page-title'>
                  <h2>{t('omat_tiedot')}</h2>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            {!editOpen ? (
              <Col md='6' className=''>
                <Card>
                  <CardBody>
                    <CardTitle tag='h5'>
                      {firstname} {lastname}
                    </CardTitle>
                    <CardSubtitle tag='h6' className='mb-2 text-muted'>
                      {t('email')}
                    </CardSubtitle>
                    <CardText>{email}</CardText>
                    <CardSubtitle tag='h6' className='mb-2 text-muted'>
                      {t('phone')}
                    </CardSubtitle>
                    <CardText>{phone || t('not_set')}</CardText>
                    <CardSubtitle tag='h6' className='mb-2 text-muted'>
                      {t('address')}
                    </CardSubtitle>
                    <CardText>{streetaddress || t('not_set')}</CardText>
                    <CardSubtitle tag='h6' className='mb-2 text-muted'>
                      {t('zip')}
                    </CardSubtitle>
                    <CardText>{zip || t('not_set')}</CardText>
                    <CardSubtitle tag='h6' className='mb-2 text-muted'>
                      {t('city')}
                    </CardSubtitle>
                    <CardText>{city || t('not_set')}</CardText>

                    {accesscode && !appconfig.donotshowaccesscode ? (
                      <>
                        {/* <Divider /> */}
                        <CardSubtitle tag='h6' className='mb-2 mt-2 text-muted'>
                          {t('accesscode')}
                        </CardSubtitle>
                        <CardText>{accesscode}</CardText>
                      </>
                    ) : null}
                    <Row style={{ marginBottom: 20 }}>
                      <Col>
                      <CardSubtitle tag='h6' className='mb-2 text-muted'>
                      {t('defaultview')}
                    </CardSubtitle>
                    <CardText>
                      {profile.defaultview ? t(profile.defaultview) : ''}
                    </CardText>
                      </Col>
                      <Col className='text-right' >
                        <Button color='primary' onClick={editToggle}>
                          {t('edit')} <EditIcon fontSize='small' />
                        </Button>
                      </Col>
                    </Row>

                    <Divider />
                    <CardSubtitle tag='h6' className='mb-2 mt-2 text-muted'>
                      {t('memberships')}
                    </CardSubtitle>
                    {memberships &&
                    memberships.length > 0 &&
                    membershipproducts ? (
                      memberships.map((m) => {
                        const product = membershipproducts.find(
                          (p) => p.id === m.membershipid
                        )
                        if (m.permanent) {
                          return (
                            <CardText>
                              {product.displayName} - Pysyvä jäsenyys{' '}
                            </CardText>
                          )
                        } else {
                          return (
                            <CardText>
                              {product.displayName} - {t('valid')}{' '}
                              {moment.utc(m.start).isAfter(gtime)
                                ? moment.utc(m.start).format('D.M.YYYY') + ' - '
                                : null}{' '}
                              {moment.utc(m.end).format('D.M.YYYY')}{' '}
                              {t('until')}{' '}
                            </CardText>
                          )
                        }
                      })
                    ) : (
                      <CardText>{t('no_memberships')}</CardText>
                    )}
                    <Divider />
                    <CardSubtitle tag='h6' className='mb-2 mt-2 text-muted'>
                      {t('cards')}
                    </CardSubtitle>
                    <CardSubtitle tag='p' className='mb-2 mt-2 text-muted'>
                      {t('activities')}
                    </CardSubtitle>
                    {benefitcards && benefitcards.length > 0
                      ? benefitcards
                          .filter((x) => x.collection === 'activities')
                          .map((benefitcard) => {
                            if (benefitcard.type === 'count') {
                              return (
                                <CardText>
                                  {benefitcard.sport} - {benefitcard.name}:{' '}
                                  {benefitcard.remaining} {t('remaining')} /{' '}
                                  {t('valid')}{' '}
                                  {moment
                                    .utc(benefitcard.validuntil)
                                    .format('D.M.YY')}{' '}
                                  {t('until')}{' '}
                                </CardText>
                              )
                            } else {
                              return (
                                <CardText>
                                  {benefitcard.sport} - {benefitcard.name}:{' '}
                                  {t('valid')}{' '}
                                  {benefitcard.lastday.format('D.M.YY')}{' '}
                                  {t('until')}{' '}
                                </CardText>
                              )
                            }
                          })
                      : null}
                    <CardSubtitle tag='p' className='mb-2 mt-2 text-muted'>
                      {t('reservation')}
                    </CardSubtitle>
                    {benefitcards && benefitcards.length > 0
                      ? benefitcards
                          .filter((x) => x.collection === 'reservations')
                          .map((benefitcard) => {
                            return (
                              <CardText>
                                {benefitcard.name}: {benefitcard.remaining}{' '}
                                {t('remaining')} / {t('valid')}{' '}
                                {moment
                                  .utc(benefitcard.validuntil)
                                  .format('D.M.YY')}{' '}
                                {t('until')}{' '}
                              </CardText>
                            )
                          })
                      : null}

                    <Divider />
                    {/* <CardSubtitle tag='h6' className='mb-2 mt-2 text-muted'>
                      {t('ratings')}
                    <IconButton color='primary' onClick={ratingDialogToggle}>
                      <EditIcon fontSize='small' />
                    </IconButton>
                    </CardSubtitle>

                    <RatingsSector
                      ratings={ratings}
                      clubid={appconfig.accountkey}
                      // norating={t('no_rating')}
                      // clubrating={t('club_rating')}
                      editToggle={ratingDialogToggle}
                    />
                    <Divider style={{ marginTop: 20 }} /> */}

                    {/* <CardSubtitle tag='h6' className='mb-2 mt-2 text-muted'>
                      {t('storedcards')}
                    </CardSubtitle>
                    {cards && cards.length > 0 ? (
                      cards.map((card) => (
                        <CardText>
                          {card.card.type}  **** {card.card.partial_pan} { card.card.expire_month + '/' + card.card.expire_year} {card.card.funding} <img src={process.env.PUBLIC_URL + '/imgs/' + card.provider + '.svg'} alt={card.provider} style={{ height: 25 }} />
                        </CardText>
                      ))
                    ) : (
                      <CardText>{t('no_stored_cards')}</CardText>
                    )}
                    <Link href='addcard' variant="body2" underline='hover'>{t('add_card')}</Link>

                    <Divider style={{ marginTop: 10 }} /> */}
                    <CardSubtitle tag='h6' className='mb-2 mt-2 text-muted'>
                      {t('balance')}
                    </CardSubtitle>
                    {balances.map((balance) => (
                      <>
                        <CardText>
                          {balance.label[APPINITLANG]}: {balance.sum / 100} €
                        </CardText>
                        {balance.nextexpiring ? (
                          <CardText>
                            {balance.nextexpiring +
                              ' ' +
                              t('next_balance_expiring') +
                              ' ' +
                              balance.nextexpiringamount +
                              ' €'}
                          </CardText>
                        ) : null}
                      </>
                    ))}
                    {storages ? (
                      <CardSubtitle tag='h6' className='mb-2 text-muted'>
                        {t('storage')}
                      </CardSubtitle>
                    ) : null}
                    {storages
                      ? storages.map((storage, i) => (
                          <StorageRow
                            storage={storage}
                            key={'storage-row-' + i}
                          />
                          // <CardText>
                          //   {storage.label[APPINITLANG]}:{' '}
                          //   {storage.category === 'minutes'
                          //     ? storage.sum + ' min'
                          //     : (storage.sum / 100).toString() + ' €'}
                          // </CardText>
                        ))
                      : null}
                    <Divider style={{ marginBottom: 30 }} />
                    {/* {accesscode && !appconfig.donotshowaccesscode ? (
                      <>
                        <Divider />
                        <CardSubtitle tag='h6' className='mb-2 mt-2 text-muted'>
                          {t('accesscode')}
                        </CardSubtitle>
                        <CardText>{accesscode}</CardText>
                      </>
                    ) : null}
                    <CardSubtitle tag='h6' className='mb-2 text-muted'>
                      {t('defaultview')}
                    </CardSubtitle>
                    <CardText>
                      {profile.defaultview ? t(profile.defaultview) : ''}
                    </CardText> */}
                    <Row className='align-items-end'>
                      <Col>
                        <Button color='primary' onClick={editToggle}>
                          {t('change_password')} <EditIcon fontSize='small' />
                        </Button>
                      </Col>
                      <Col className='text-right'>
                        <Button
                          size='sm'
                          color='primary'
                          onClick={confirmationcodeToggle}
                        >
                          {t('confirmation_code')}{' '}
                          <SettingsEthernetIcon fontSize='small' />
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            ) : (
              <Col md='6' className=''>
                <Form className=''>
                  <FormGroup className='loose-form'>
                    <Label for='firstname'>{t('first_name')} *</Label>
                    <Input
                      type='text'
                      name='firstname'
                      id='firstname'
                      placeholder='Etunimi'
                      value={firstname}
                      autoComplete={'given-name'}
                      onChange={(event) => setFirstname(event.target.value)}
                    />
                  </FormGroup>
                  <FormGroup className='loose-form'>
                    <Label for='lastname'>{t('last_name')} *</Label>
                    <Input
                      type='text'
                      name='lastname'
                      id='lastname'
                      placeholder='Sukunimi'
                      value={lastname}
                      autoComplete={'family-name'}
                      onChange={(event) => setLastname(event.target.value)}
                    />
                  </FormGroup>
                  <FormGroup className='loose-form'>
                    <Label for='phone'>{t('phone')} *</Label>
                    <Input
                      type='text'
                      name='phone'
                      id='phone'
                      value={phone}
                      placeholder='Puhelin'
                      autoComplete={'tel'}
                      onChange={(event) => setPhone(event.target.value)}
                    />
                  </FormGroup>
                  <FormGroup className='loose-form'>
                    <Label for='streetaddress'>{t('address')}</Label>
                    <Input
                      invalid={!isStreetValid}
                      type='text'
                      name='streetaddress'
                      id='streetaddress'
                      value={streetaddress}
                      placeholder=''
                      autoComplete={'address'}
                      onChange={(event) => setStreetAddress(event.target.value)}
                    />
                  </FormGroup>
                  <FormGroup className='loose-form'>
                    <Label for='zip'>{t('zip')}</Label>
                    <Input
                      invalid={!isZipValid}
                      type='text'
                      name='zip'
                      id='zip'
                      value={zip}
                      placeholder=''
                      autoComplete={'zip'}
                      onChange={(event) => setZip(event.target.value)}
                    />
                  </FormGroup>
                  <FormGroup className='loose-form'>
                    <Label for='city'>{t('city')}</Label>
                    <Input
                      invalid={!isCityValid}
                      type='text'
                      name='city'
                      id='city'
                      value={city}
                      placeholder=''
                      autoComplete={'city'}
                      onChange={(event) => setCity(event.target.value)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for='defaultview'>{t('defaultview')}</Label>{' '}
                    <Select
                      id='defaultview'
                      value={defaultview || ''}
                      onChange={(event) => setDefaultView(event.target.value)}
                      displayEmpty
                    >
                      <MenuItem value={'simple'}>
                        <CalendarViewDayIcon
                          // color={defaultview === 'simple' ? '' : 'action'}
                          style={
                            defaultview === 'simple'
                              ? { color: 'rgba(0,0,0,1)' }
                              : { color: 'rgba(0,0,0,0.54)' }
                          }
                        />
                        {t('simple')}
                      </MenuItem>
                      <MenuItem value={'horizontal'}>
                        <ViewListIcon
                          // color={defaultview === 'horizontal' ? '' : 'action'}
                          style={
                            defaultview === 'horizontal'
                              ? { color: 'rgba(0,0,0,1)' }
                              : { color: 'rgba(0,0,0,0.54)' }
                          }
                        />
                        {t('horizontal')}
                      </MenuItem>
                      <MenuItem value={'vertical'}>
                        <ViewColumnIcon
                          // color={defaultview === 'vertical' ? '' : 'action'}
                          style={
                            defaultview === 'vertical'
                              ? { color: 'rgba(0,0,0,1)' }
                              : { color: 'rgba(0,0,0,0.54)' }
                          }
                        />
                        {t('vertical')}
                      </MenuItem>
                    </Select>
                  </FormGroup>
                  <Button
                    style={{ marginBottom: 40 }}
                    color='primary'
                    block
                    disabled={
                      !(firstname && lastname && phone && isAddressValid)
                    }
                    onClick={() =>
                      onSavePress().then((result) => {
                        saveToggle()
                        editToggle(false)
                      })
                    }
                  >
                    {t('save')}
                  </Button>

                  <FormGroup className='loose-form'>
                    <Label for='newpassword'>
                      <small>{t('password_guide')}</small>
                    </Label>
                    <Label for='newpassword'>{t('new_password')}</Label>
                    <Input
                      type='password'
                      name='newpassword'
                      id='password'
                      autoComplete={'new-password'}
                      value={newpassword}
                      // invalid={newpassword && !passwordMatch}
                      onChange={(event) => onPasswordUpdate(event.target)}
                    />
                    <Label for='newpassword2'>{t('new_password_again')}</Label>
                    <Input
                      type='password'
                      name='newpassword2'
                      id='password'
                      // invalid={newpassword && !passwordMatch}
                      autoComplete={'new-password'}
                      value={newpassword2}
                      onChange={(event) => onPasswordUpdate(event.target)}
                    />
                  </FormGroup>
                  <Button
                    color={newpassword && !passwordMatch ? 'danger' : 'primary'}
                    onClick={onChangePassword}
                    disabled={!passwordMatch}
                    style={{ marginBottom: 40 }}
                  >
                    {newpassword && !passwordMatch
                      ? newpasswordErrorMessage
                      : t('change_password')}
                  </Button>

                  <FormGroup className='loose-form'>
                    <Label for='email'>{t('email')}</Label>
                    <Input
                      type='email'
                      name='email'
                      id='email'
                      value={email}
                      placeholder='Sähköpostiosoite'
                      autoComplete={'email'}
                      disabled
                    />
                  </FormGroup>
                  <Button color='primary' onClick={editEmailToggle}>
                    {t('change_email')} <EditIcon fontSize='small' />
                  </Button>
                </Form>
              </Col>
            )}
            <Col md={6} className='text-center'>
              <Card style={{ marginTop: 0 }}>
                {appconfig.invoices ? (
                  <React.Fragment>
                    <CardBody>
                      <MuiButton
                        fullWidth
                        variant='text'
                        onClick={() =>
                          history.push(appconfig.pathPrefix + '/invoices')
                        }
                      >
                        {t('invoices')}
                      </MuiButton>
                    </CardBody>
                    <Divider style={{ marginTop: 20 }} />
                  </React.Fragment>
                ) : null}
                <CardBody>
                  <CardTitle>{t('balance_sheet')}</CardTitle>
                </CardBody>
                {appconfig.balances.map((c) => {
                  if (
                    selectedwallettype &&
                    selectedwallettype.category === c.category &&
                    selectedwallettype.venue === c.venue
                  ) {
                    return (
                      <MuiButton
                        startIcon={<ExpandLessIcon />}
                        fullWidth
                        key={c.category + '-' + c.venue}
                        variant='text'
                        onClick={() => toggleWalletType(c)}
                      >
                        {c.label[APPINITLANG]}
                      </MuiButton>
                    )
                  } else {
                    return (
                      <MuiButton
                        startIcon={<ExpandMoreIcon />}
                        fullWidth
                        key={c.category + '-' + c.venue}
                        variant='text'
                        onClick={() => toggleWalletType(c)}
                      >
                        {c.label[APPINITLANG]}
                      </MuiButton>
                    )
                  }
                })}
                {selectedWalletTransactions}
                <Divider style={{ marginTop: 20 }} />
                {appconfig.storages ? (
                  <React.Fragment>
                    <CardBody>
                      <CardTitle>{t('storage_events')}</CardTitle>
                    </CardBody>
                    <ButtonGroup
                      size={'sm'}
                      style={{
                        marginLeft: 20,
                        marginRight: 20,
                        marginBottom: 30,
                      }}
                    >
                      {appconfig.storages.map((c) => {
                        const isactive =
                          selectedstoragetype &&
                          selectedstoragetype.category === c.category &&
                          selectedstoragetype.venue === c.venue
                        return (
                          <MuiButton
                            startIcon={
                              isactive ? <ExpandLessIcon /> : <ExpandMoreIcon />
                            }
                            fullWidth
                            key={c.category + '-' + c.venue}
                            variant='text'
                            onClick={() => toggleStorageType(c)}
                          >
                            {c.label[APPINITLANG]}
                          </MuiButton>
                          // <Button
                          //   active={isactive}
                          //   outline
                          //   key={c.category + '-' + c.venue}
                          //   color='primary'
                          //   onClick={() => toggleStorageType(c)}
                          // >
                          //   {c.label[APPINITLANG]}
                          // </Button>
                        )
                      })}
                    </ButtonGroup>
                    {selectedStorageTransactions}
                    <Divider style={{ marginTop: 20 }} />
                  </React.Fragment>
                ) : null}
                {appconfig.sportmoney && appconfig.sportmoney.length > 0 ? (
                  <CardBody className='mb-5'>
                    <CardTitle>{t('add_balance')}</CardTitle>
                    <div
                      style={{ cursor: 'pointer', height: 30 }}
                      onClick={() =>
                        history.push(appconfig.pathPrefix + '/addbalance')
                      }
                    >
                      {appconfig.sportmoney.map((c) => (
                        <img
                          src={
                            process.env.PUBLIC_URL + '/imgs/' + c + '_logo.svg'
                          }
                          alt='add balance'
                          style={{ height: '100%', marginLeft: 15 }}
                        />
                      ))}
                    </div>
                  </CardBody>
                ) : null}
                {/* <CardBody>
                  {messagethankyou ? (
                    <>{t('help.part2')}</>
                  ) : (
                    <>
                      {t('help.part1')} <br />
                      <Button size='sm' color='info' style={{ marginTop: 5 }} onClick={messageToggle}>
                        {t('send_message')}
                      </Button>
                    </>
                  )}
                </CardBody> */}
              </Card>
            </Col>
            {/* </Row>
            </Col> */}
          </Row>
          <Row style={{ marginBottom: 10 }}>
            <Col>
              <Row>
                {appconfig.infotexts ? (
                  <Col md={12} className='text-center'>
                    <Card style={{ marginTop: 10 }}>
                      <CardBody>
                        <p>
                          {appconfig.infotexts.part1
                            ? appconfig.infotexts.part1[i18n.language] ||
                              appconfig.infotexts.part1['fi']
                            : null}
                        </p>
                        <p>
                          {appconfig.infotexts.part2
                            ? appconfig.infotexts.part2[i18n.language] ||
                              appconfig.infotexts.part2['fi']
                            : null}
                        </p>

                        {appconfig.infotexts.part3
                          ? appconfig.infotexts.part3[i18n.language] ||
                            appconfig.infotexts.part3['fi']
                          : null}
                      </CardBody>
                    </Card>
                  </Col>
                ) : null}
              </Row>
            </Col>
          </Row>

          <Modal isOpen={passwordModalOpen} toggle={passwordModalToggle}>
            <ModalBody>{t('password_changed')}</ModalBody>
            <ModalFooter>
              <Button color='primary' onClick={passwordModalToggle}>
                {t('close')}
              </Button>
            </ModalFooter>
          </Modal>
          <Modal isOpen={saveModalOpen} toggle={saveToggle}>
            <ModalBody>
              <div>{t('saved')}</div>
            </ModalBody>
            <ModalFooter>
              <Button color='primary' onClick={saveToggle}>
                Sulje
              </Button>
            </ModalFooter>
          </Modal>
          <Modal isOpen={messageModalOpen} toggle={messageToggle}>
            <ModalBody>
              <Form className=''>
                <FormGroup className='loose-form'>
                  <Label for='email'>{t('email')}</Label>
                  <Input
                    type='email'
                    name='email'
                    id='email'
                    value={email}
                    placeholder='Sähköpostiosoite'
                    autoComplete={'email'}
                    disabled
                  />
                </FormGroup>
                <FormGroup className='loose-form'>
                  <Label for='messagecategory'>
                    {t('supportmessage_topic')}
                  </Label>
                  <Input
                    type='messagecategory'
                    name='messagecategory'
                    id='messagecategory'
                    value={messagecategory}
                    disabled
                  />
                </FormGroup>
                <FormGroup className='loose-form'>
                  <Label for='messagetitle'>{t('supportmessage_title')}</Label>
                  <Input
                    type='text'
                    name='messagetitle'
                    id='messagetitle'
                    placeholder={t('supportmessage_title')}
                    value={messagetitle}
                    onChange={(event) => setMessageTitle(event.target.value)}
                  />
                </FormGroup>
                <FormGroup className='loose-form'>
                  <Label for='messagebody'>{t('supportmessage_body')}</Label>
                  <Input
                    type='textarea'
                    name='messagebody'
                    id='messagebody'
                    placeholder={t('supportmessage_body')}
                    rows={10}
                    value={messagebody}
                    onChange={(event) => setMessageBody(event.target.value)}
                  />
                </FormGroup>
              </Form>
            </ModalBody>
            <ModalFooter>
              <Button color='secondary' onClick={messageToggle}>
                {t('cancel')}
              </Button>
              <Button
                color='primary'
                onClick={() =>
                  onSendPress().then((result) => {
                    setMessageThankyou(true)
                    setMessageBody(null)
                    setMessageTitle(null)
                    messageToggle()
                  })
                }
              >
                {t('send')}
              </Button>
            </ModalFooter>
          </Modal>
          <Modal isOpen={editEmailOpen} toggle={editEmailToggle}>
            <ModalHeader>{t('email')}</ModalHeader>
            <ModalBody>{t('password_change_info')}</ModalBody>
            <ModalBody>
              <Form>
                <FormGroup className='loose-form'>
                  <Label for='email'>{t('email')}</Label>
                  <Input
                    type='email'
                    name='email'
                    id='email'
                    value={email}
                    placeholder='Sähköpostiosoite'
                    autoComplete={'email'}
                    disabled
                  />
                </FormGroup>
                <FormGroup className='loose-form'>
                  <Label for='newemail'>{t('new_email')}</Label>
                  <Input
                    invalid={(newemail || newemail2) && newemail !== newemail2}
                    valid={(newemail || newemail2) && newemail === newemail2}
                    type='newemail'
                    name='newemail'
                    id='newemail'
                    value={newemail}
                    placeholder={t('email')}
                    autoComplete={'email'}
                    onChange={(event) => setNewEmail(event.target.value)}
                  />
                </FormGroup>
                <FormGroup className='loose-form'>
                  <Label for='newemail2'>{t('new_email_again')}</Label>
                  <Input
                    invalid={(newemail || newemail2) && newemail !== newemail2}
                    valid={(newemail || newemail2) && newemail === newemail2}
                    type='newemail2'
                    name='newemail2'
                    id='newemail2'
                    value={newemail2}
                    placeholder={t('email')}
                    autoComplete={'email'}
                    onChange={(event) => setNewEmail2(event.target.value)}
                  />
                </FormGroup>
              </Form>
            </ModalBody>
            <ModalFooter>
              <Button
                color='secondary'
                disabled={confirmProgress}
                onClick={() => {
                  setNewEmail(null)
                  setNewEmail2(null)
                  setConfirmationcode(null)
                  editEmailToggle(false)
                  setConfirmationError(null)
                  setConfirmProgress(false)
                }}
              >
                {t('cancel')}
              </Button>
              <Button
                color='danger'
                disabled={
                  !(email && newemail && newemail2 && email !== newemail) ||
                  confirmProgress
                }
                onClick={() => {
                  setConfirmProgress(true)
                  onChangeEmail().then((result) => {
                    setNewEmail(null)
                    setNewEmail2(null)
                    setConfirmationcode(null)
                    setConfirmationError(null)

                    editEmailToggle(false)
                    editToggle(false)
                    confirmationcodeToggle(true)
                    setConfirmProgress(false)
                  })
                }}
              >
                {confirmProgress ? <Spinner size={'sm'} /> : t('save')}
              </Button>
            </ModalFooter>
          </Modal>
          <Modal isOpen={confirmationcodeOpen} toggle={confirmationcodeToggle}>
            <ModalHeader>{t('confirmation_code')}</ModalHeader>
            <ModalBody>{t('confirmation_code_info')}</ModalBody>
            {confirmationError ? (
              <ModalBody>
                <Alert color='danger'>{confirmationError}</Alert>
              </ModalBody>
            ) : null}
            <ModalBody>
              <Form>
                <FormGroup className='loose-form'>
                  <Label for='confirmationcode'>{t('confirmation_code')}</Label>
                  <Input
                    type='confirmationcode'
                    name='confirmationcode'
                    id='confirmationcode'
                    value={confirmationcode}
                    autoComplete={'confirmation_code'}
                    onChange={(event) =>
                      setConfirmationcode(event.target.value)
                    }
                  />
                </FormGroup>
              </Form>
            </ModalBody>
            <ModalFooter>
              <Button
                color='secondary'
                onClick={() => {
                  setNewEmail(null)
                  setNewEmail2(null)
                  setConfirmationcode(null)
                  editEmailToggle(false)
                  setConfirmationError(null)
                  confirmationcodeToggle(false)
                }}
              >
                {t('cancel')}
              </Button>
              <Button
                color='danger'
                disabled={!confirmationcode || confirmProgress}
                onClick={() => {
                  setConfirmProgress(true)
                  confirmEmailChange().then((result) => {
                    if (result.error) {
                      setConfirmationError(result.message.fi)
                      setConfirmProgress(false)
                    } else {
                      setNewEmail(null)
                      setNewEmail2(null)
                      setConfirmationcode(null)
                      setConfirmationError(null)

                      confirmationcodeToggle(false)
                      editToggle(false)
                      setConfirmProgress(false)
                    }
                  })
                }}
              >
                {confirmProgress ? <Spinner size={'sm'} /> : t('confirm')}
              </Button>
            </ModalFooter>
          </Modal>
          <RatingDialog
            open={ratingDialogOpen}
            onClose={ratingDialogToggle}
            title={t('ratings')}
            closeTitle={t('close')}
            onSave={onRatingSavePress}
            saveTitle={t('save')}
            ratings={ratings}
          />
        </Container>
      </div>
    )
  }
}

export default withAppconfig(withWallet(ProfileScreen))
