const _applications = [
  {
    id: '1',
    title: 'Virkistyspäivä',
    description: 'Virikepäivä',
    start: '2020-01-01T10:00:00.000Z',
    end: '2020-01-01T12:00:00.000Z',
    personaldetails: ['name', 'email', 'phone', 'streetaddress', 'zip', 'city'],
    questions: [{
      id: 1,
      title: 'Kysymys 1',
      type: 'textarea',
      required: true
    }, {
      id: 2,
      title: 'Kysymys 2',
      type: 'textarea',
      required: false
    }
    ],
  }, 
  {
    id: '2',
    title: 'Vakiovuorotiedustelu',
    description: 'Vakiovuorotiedustelu',
    start: '2023-01-01T10:00:00.000Z',
    end: '2023-12-01T12:00:00.000Z',
    personaldetails: ['name', 'email', 'phone', 'streetaddress', 'zip', 'city'],
    questions: [{
      id: 1,
      title: 'Montako kertaa viikossa haluaisitte vuoron?',
      type: 'number',
      required: true
    },
      {
        id: 2,
        title: 'Miten pitkän vuoron haluaisitte?',
        type: 'select',
        options: ['1h', '1,5h', '2h', '2,5h', '3h'],
        required: true
      },
      {
        id: 4,
        title: 'Mitä lajia haluatte pelata?',
        type: 'text',
        required: true
      },
      {
        id: 5,
        title: 'Mitkä päivät ja ajat sopivat teille?',
        type: 'daytimeselector',
        required: true
      },
      {
        id: 6,
        title: 'Viesti / lisätietoja',
        type: 'textarea',
        required: false
      }
    ],
  },
]

export const applications = _applications
