import React, { useEffect } from "react";
import { useFirebase } from 'react-redux-firebase'

function PaymentRedirect({ selectedGw,  showConfirmation, confirmationtype }) {
  const firebase = useFirebase()
  const isTest = document.location.hostname === 'utest.cintoia.com' || document.location.hostname === 'localhost'
  
  // const url = (isTest ? 'https://europe-west1-falcon-328a1.cloudfunctions.net/testpaymentv3-init': 'https://us-central1-falcon-328a1.cloudfunctions.net/paymentv3-paymentInit/') + '?p=web&pm='
  const url =
    'https://europe-west1-falcon-328a1.cloudfunctions.net/' +
    (isTest ? 'testpaymentv3' : 'paymentv3') +
    '-init/?p=web&pm='
  useEffect(() => {
    async function refreshToken() {
      const tokenResult = await firebase.auth().currentUser.getIdTokenResult()
      window.location.href =
      url +
      selectedGw +
      '&auth=' +
      tokenResult.token
    return tokenResult.token
    }
    refreshToken()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="centeredbox">
      <p>Siirrytään maksupalveluun</p>
    </div>
  );
}

export default PaymentRedirect;