import React, { useState, } from 'react'
import { Avatar, Typography } from '@material-ui/core'
import { Col, FormGroup, Input, Row } from 'reactstrap'

function ExtraProduct({ product, onChange }) {
  const [amount, setAmount] = useState(0)

  const updateAmount = (value) => {
    setAmount(value)
    onChange(product.id, value)
  }

  return (
    <Row key={product.id || Math.random().toString()}>
      
      <Col xs={2} sm={1}>
        {product.image && product.image.length > 0 ? (
          <Avatar variant='square' className='item-image' src={product.image} alt='' />) : (
          null
        )}
      </Col>
      <Col xs={6} sm={9}>
        <Typography variant='body2' className='item-name'>
          {product.displayName} {(product.price / 100).toFixed(2).replace('.', ',') + ' € / kpl'}
        </Typography>
        <Typography variant='caption' className='item-description'>
          {product.desc}
        </Typography>
      </Col>
      <Col xs={4} sm={2}>
        <FormGroup className='loose-form'>
          <Input
            type='select'
            name='select'
            id='itemSelect'
            value={amount || 0}
            onChange={(e) => updateAmount(e.target.value)}
          >
            <option value={0}></option>
            {product.amounts.map((a, i) => (
              <option value={a} key={'ep-' + i}>{a}</option>
            ))}
          </Input>
        </FormGroup>
      </Col>
    </Row>
  )
}

export default ExtraProduct
