import React from 'react'
// import { useTranslation } from 'react-i18next';
import { useParams /*, useHistory */ } from 'react-router-dom'
import TopNavigation from '../../TopNavigation'
import { Container } from 'reactstrap'
import { useSelector } from 'react-redux'
import { Button, Chip, Divider, Grid, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import withAppconfig from '../../AppContext'
import { getLastvalidDate } from './MembershipUtil'
import { connect } from 'react-redux'
import ProfileInput from '../../components/ProfileInput'
import Poster from '../../components/Poster'

const enhance = connect(({ globaltime: { gtime } }) => ({
  gtime
}))

function MembershipScreen({ appconfig, gtime }) {
  const params = useParams()
  const dispatch = useDispatch()
  const history = useHistory()
  const { t } = useTranslation()
  const [selectedVariant, setSelectedVariant] = React.useState(null)
  const [profileIsValid, setProfileIsValid] = React.useState(false)
  const membership = useSelector((state) => {
    return state.firebase.data.shopmemberships && state.firebase.data.shopmemberships[params.id]
  })

  if (membership === undefined) return history.push(appconfig.pathPrefix + '/shop/memberships')
  const { poster } = membership
  let variationsSelector = null
  if (membership.variations) {
    if (membership.variations.length > 1) {
      variationsSelector = membership.variations.map((v) => {
        return (
          <Chip
            clickable
            label={v.name}
            key={'varid-' + v.varid}
            color={selectedVariant?.varid === v.varid ? 'primary' : 'default'}
            variant={selectedVariant?.varid === v.varid ? 'outlined' : 'default'}
            onClick={() => setSelectedVariant(v)}
            style={{ margin: 5 }}
          />
        )
      })
    } else {
      variationsSelector = <Typography variant='h5'>{membership.variations[0].name}</Typography>
      if (selectedVariant === null) {
        setSelectedVariant(membership.variations[0])
      }
    }
  }

  const goToPay = (membership) => {
      dispatch({
        type: 'UPDATE_PAYABLE_MEMBERSHIP',
        payload: membership,
      })
      history.push(appconfig.pathPrefix + '/shop/checkout')
  }

  let lastvalidDate = getLastvalidDate(membership.validity, gtime)
  if (lastvalidDate === 'permanent') {
    lastvalidDate = t('permanent')
  } else {
    lastvalidDate = t('valid') + ' ' + lastvalidDate.format('D.M.YYYY')
  }

  return (
    <div className='full-page-background'>
      <TopNavigation />
      <Container className='front' style={{ paddingTop: 10 }}>
        <Grid container spacing={3} alignItems='flex-start' >
          <Grid item xs={12} md={6} style={{ textAlign: 'center', paddingTop: 40 }}>
            <Poster data={poster} preferredSize={300} alt={'Product image'} />
          </Grid>
          <Grid item xs={12} md={6} style={{ paddingTop: '5em', paddingRight: 20 }}>
            <ProfileInput onValid={(isValid) => setProfileIsValid(isValid)} label={'Jotta voit ostaa jäsenyyden täydennä seuraavat tiedot'} />
            <Typography variant='h4'>{membership.displayName.toUpperCase()}</Typography>
            <Typography variant='button'>{lastvalidDate}</Typography>
            <Divider />
            {variationsSelector}
            <div>
              <Typography variant='h6'>
                {selectedVariant ? (selectedVariant.price / 100).toFixed(2).replace('.', ',') + ' €' : null}
              </Typography>
              <Typography variant='caption'>
                {selectedVariant ? t('vat') + ' ' + membership.vat + ' %' : null}
              </Typography>
            </div>

            <Button
              variant='contained'
              color='primary'
              disabled={!selectedVariant || !profileIsValid}
              style={{ marginTop: 10, marginBottom: 10 }}
              onClick={() => goToPay({ ...membership, selectedVariant })}
            >
              {t('buy_membership')}
            </Button>
            <Divider />
            <Typography variant='body1'>
              <span dangerouslySetInnerHTML={{ __html: membership.description }} />
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default withAppconfig(enhance(MembershipScreen))
