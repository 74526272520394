import React, { useState, useEffect } from 'react'
import { FormGroup, Input, Label, Button, Spinner } from 'reactstrap'
import Collapse from '@material-ui/core/Collapse'
import { useFirebase } from 'react-redux-firebase'
import withAppconfig from '../AppContext'
import { useTranslation } from 'react-i18next'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'

function Giftcode({ appconfig, producttype, membershipprices, selected, pricesCheckState, onValidGiftcode }) {
  const firebase = useFirebase()
  const { t } = useTranslation()
  const [giftcode, setGiftcode] = useState(null)
  // const [giftcoderedeem, setGiftcoderedeem] = useState('')
  const [pricescheck, setPricesCheck] = useState(false)
  const [giftcodeOpen, setGiftcodeOpen] = useState(false)
  const [mprices, setMprices] = useState([])

  const onPricesCheck = (state) => {
    if (pricesCheckState) {
      pricesCheckState(state)
    }
    setPricesCheck(state)
  }

  useEffect(() => {
    if (membershipprices) {
      setMprices(membershipprices)
    }
  }, [membershipprices])



  const redeemGiftcode = async () => {
    if (giftcode) {
      const isAlready = mprices.find((x) => x.code === giftcode)
      if (isAlready) return { error: true, message: 'not_valid_code' }
      onPricesCheck(true)
      firebase.functions().region = 'europe-west1'
      const fn = appconfig.istest ? 'testmemberships-checkgiftcode' : 'memberships-checkgiftcode'
      let checkGiftcode = firebase.functions().httpsCallable(fn)
      try {
        let giftcodeObject = {
          customerid: appconfig.accountkey,
          code: giftcode,
          type: producttype,
        }
        if (producttype === 'courtreservation') {
          giftcodeObject = {
            ...giftcodeObject,
            duration: selected.duration,
            resourceId: selected.resourceId,
            start: selected.start,
            end: selected.end,
          }
        } else if (producttype === 'activity') {
          giftcodeObject = {
            ...giftcodeObject,
            sport: selected.sport,
            activitytype: selected.type,
            producertype: selected.producertype,
            start: selected.start,
            id: selected.id,
          }
        }
        const response = await checkGiftcode(giftcodeObject)
        // alert(JSON.stringify(response.data, null, 2))
        // eslint-disable-next-line no-unused-vars
        const { type, value, checksum, reason } = response.data
        // if (type === 'reduction') {
        //   const _p = (selected.price * value).toFixed(2)
        //   membershipprices.push({ pricing: 'Etukoodi', price: _p, code: giftcode })
        //   setGiftcoderedeem(giftcode)
        //   updateItemPricing(membershipprices)
        //   setPaymentSummary(false, true, false, false, false)
        // } else
        if (type === 'price') {
          mprices.push({ pricing: 'Etukoodi', price: value, code: giftcode })
          onValidGiftcode(mprices, giftcode)
        } else if (reason) {
          setGiftcode('')
          alert(reason)
        }
      } catch (error) {
        // console.log(error)
        setGiftcode('')
        alert(error.message)
      }
    }
    onPricesCheck(false)
  }

  const toggleGiftcode = () => {
    if (giftcodeOpen) setGiftcode(null)
    setGiftcodeOpen(!giftcodeOpen)
  }

  return (
    <React.Fragment>
      <div onClick={() => toggleGiftcode()}>
        {giftcodeOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        <Label for='giftcode'>{t('giftcode')}</Label>
      </div>
      <Collapse in={giftcodeOpen}>
        <FormGroup className='loose-form'>
          <Input
            bsSize={'sm'}
            type='text'
            name='giftcode'
            id='giftcode'
            value={giftcode || ''}
            placeholder=''
            onChange={(event) => setGiftcode(event.target.value.toUpperCase())}
          />
        </FormGroup>
        {giftcode ? (
          <Button onClick={() => redeemGiftcode()} color={'info'} disabled={giftcode.length < 3 || pricescheck}>
            {pricescheck ? <Spinner size={'sm'} /> : t('redeem_giftcode')}
          </Button>
        ) : null}
      </Collapse>
    </React.Fragment>
  )
}

export default withAppconfig(Giftcode)
