import React, { useEffect, useState } from 'react'
import TopNavigation from '../TopNavigation'
import { Container, Row, Col } from 'reactstrap'
import { Divider } from '@material-ui/core'
import withAppconfig from '../AppContext'
import AddEdenredAsset from '../components/AddEdenredAsset'
import AddEpassiAsset from '../components/AddEpassiAsset'
import AddEasybreikAsset from '../components/AddEasybreikAsset'
import AddSmartumAsset from '../components/AddSmartumAsset'

const PROVIDERS = [
  { name: 'edenred', component: AddEdenredAsset, id: '1' },
  { id: '2', name: 'epassi', component: AddEpassiAsset },
  { id: '3', name: 'eazybreak', component: AddEasybreikAsset },
  { id: '4', name: 'smartum', component: AddSmartumAsset },
]

function AddAssetsScreen({ appconfig }) {
  const [activeTab, setActiveTab] = useState('0')

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  useEffect(() => {
    if (appconfig.sportmoney && appconfig.sportmoney.length > 0) {
      const provider = PROVIDERS.find((p) => p.name === appconfig.sportmoney[0])
      if (provider) {
        setActiveTab(provider.id)
      }
    }
  }, [appconfig.sportmoney])

  return (
    <div className='full-page-background'>
      <TopNavigation title='Add Asset' />
      <Container className='front'>
        <Row className='text-center' style={{ paddingTop: 20, marginBottom: 30 }}>
          {appconfig.sportmoney
            ? appconfig.sportmoney.map((sm) => {
                const provider = PROVIDERS.find((p) => p.name === sm)
                if (provider) {
                  const _s = { height: 20, marginLeft: 5, filter: 'opacity(30%)' }
                  if (provider.id === activeTab) {
                    _s.filter = 'opacity(100%)'
                  }
                  return (
                    <Col key={provider.id}>
                      <img
                        src={process.env.PUBLIC_URL + `/imgs/${provider.name}_logo.svg`}
                        alt={provider.name}
                        style={_s}
                        onClick={() => {
                          toggle(provider.id)
                        }}
                      />
                      {activeTab === provider.id ? <Divider style={{ margin: 10 }} /> : null}
                    </Col>
                  )
                } else {
                  return null
                }
              }).filter((x) => x)
            : null}
          {/* <Col>
            <img
              src={process.env.PUBLIC_URL + '/imgs/edenred_logo.svg'}
              alt='edenred'
              style={{ height: 20, marginLeft: 5 }}
              onClick={() => {
                toggle('1')
              }}
            />
            {activeTab === '1' ? <Divider style={{ margin: 10 }} /> : null}
          </Col>
          <Col>
            <img
              src={process.env.PUBLIC_URL + '/imgs/epassi_logo.png'}
              alt='epassi'
              style={{ height: 20, marginLeft: 5 }}
              onClick={() => {
                toggle('2')
              }}
            />
            {activeTab === '2' ? <Divider style={{ margin: 10 }} /> : null}
          </Col>
          <Col>
            <img
              src={process.env.PUBLIC_URL + '/imgs/eazybreak_logo.png'}
              alt='eazybreak'
              style={{ height: 20, marginLeft: 5 }}
              onClick={() => {
                toggle('3')
              }}
            />
            {activeTab === '3' ? <Divider style={{ margin: 10 }} /> : null}
          </Col>
          <Col>
            <img
              src={process.env.PUBLIC_URL + '/imgs/smartum_logo.svg'}
              alt='smartum'
              style={{ height: 20, marginLeft: 5 }}
              onClick={() => {
                toggle('4')
              }}
            />
            {activeTab === '4' ? <Divider style={{ margin: 10 }} /> : null}
          </Col> */}
        </Row>
        {(() => {
          switch (activeTab) {
            case '1':
              return <AddEdenredAsset />
            case '2':
              return <AddEpassiAsset />
            case '3':
              return <AddEasybreikAsset />
            case '4':
              return <AddSmartumAsset />
            default:
              break
          }
        })()}
      </Container>
    </div>
  )
}

export default withAppconfig(AddAssetsScreen)
