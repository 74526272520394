import React, { useState } from 'react'
import withAppconfig from '../AppContext'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import moment from 'moment'
import { hasResource, getStatus } from '../components/ReservationUtil'
import { Container } from 'reactstrap'
import TopNavigation from '../TopNavigation'
// import { useEffect } from 'react'
// import { useFirebase } from 'react-redux-firebase'
import HorizontalView from '../components/HorizontalView'

const mapStateToProps = (state, props) => {
  const {
    firestore,
  } = state
  const { appconfig } = props
  if (firestore.data['ownReservations']) {
    // Object.keys(firestore.data['ownReservations']).forEach((key) => {
    //   console.log(firestore.data['ownReservations'][key])
    // })

    let _m = Object.keys(firestore.data['ownReservations'])
      .map((key) => {
        return {
          ...firestore.data['ownReservations'][key],
          id: key,
          selectkey: key,
        }
      })
      .filter((r) => hasResource(r) && r.locationid === appconfig.accountkey && r.state !== 'user_cancelled')
      .map((r) => {
        if (r.isrecurring) {
          return r.recurrencyDates
            .map((rec) => {
              if (rec.state === 'moved') return null
              let _r = JSON.parse(JSON.stringify(r))
              let _sKey = _r.selectkey + '-'
              if (typeof rec === 'object') {
                _r.occurency = {
                  date: rec.date,
                  state: rec.state,
                  isstored: rec.isstored,
                  refundinvoicing: rec.refundinvoicing,
                  salestatus: rec.salestatus,
                }
                _sKey += rec.date
              } else {
                _r.occurency = { date: rec }
                _sKey += rec
              }
              _r.selectkey = _sKey // _r.selectkey + '-' + rec
              const timeOfDayHelper = moment.utc(_r.resourcegroups[0].end)
              const ordering = moment.utc(_r.occurency.date)
              ordering.hour(timeOfDayHelper.hour()).minute(timeOfDayHelper.minute())
              if (moment().utcOffset() > 0) {
                ordering.subtract(moment().utcOffset(), 'minute')
              } else {
                ordering.add(moment().utcOffset(), 'minute')
              }
              _r.ordering = ordering.unix()
              return _r
            })
            .filter((x) => x)
        } else {
          const ordering = moment.utc(r.resourcegroups[0].end)
          if (moment().utcOffset() > 0) {
            ordering.subtract(moment().utcOffset(), 'minute')
          } else {
            ordering.add(moment().utcOffset(), 'minute')
          }
          r.ordering = ordering.unix()
          r.occurency = { date: moment.utc(r.resourcegroups[0].start).format('YYYY-MM-DD') }
          return r
        }
      })
      .flat(3)
      .filter((r) => {
        if (appconfig.nextseasonhide) {
          if (moment(r.occurency.date).isAfter(appconfig.nextseasonhide)) {
            return false
          } else {
            return true
          }
        }
        return true
      })
      .map((r) => {
        r.vs = getStatus(r)
        return r
      })

    _m.sort(function (a, b) {
      if (a.ordering < b.ordering) {
        return -1
      } else if (a.ordering > b.ordering) {
        return 1
      } else {
        return 0
      }
    })

    let _onsale = _m.filter((x) => x.vs.isOnSale && !x.vs.isCancelled)
    let _upcoming = _m.filter((x) => x.vs.isUpcoming && !x.vs.isCancelled)
    let _past = _m.filter((x) => x.vs.isPast && !x.vs.isCancelled)
    let _cancelled = _m.filter((x) => x.vs.isCancelled)
    const _unpaid = _m.filter((x) => {
      if (x.vs.saleState && x.vs.saleState.p === 1) return false
      if (!x.vs.isPaid) {
        if (x.vs.isStored) {
          return true
        }
        if (x.vs.isCancelled) {
          return false
        }
        return true
      }
      return false
    })

    return {
      reservations: _upcoming,
      pastreservations: _past,
      onsale: _onsale,
      cancelled: _cancelled,
      unpaid: _unpaid,
      resources: appconfig.resources,
      // owns: firestore.data['ownReservations'],
    }
  } else {
    return { reservations: undefined }
  }
}

const withReservations = compose(
  connect((state) => ({ auth: state.firebase.auth })),
  firestoreConnect((props) => [
    {
      collection: 'users',
      doc: `${props.auth.uid}`,
      subcollections: [{ collection: 'reservations' }],
      storeAs: 'ownReservations',
    },
    {
      collection: 'users',
      doc: `${props.auth.uid}`,
      subcollections: [
        { collection: 'paymentinfo', doc: 'saldo', subcollections: [{ collection: `${props.appconfig.accountkey}` }] },
      ],
      storeAs: 'wallets',
    },
  ]),
  connect(mapStateToProps)
)

const aggregatePerDay = (reservations) => {
  if(!reservations) return {}
  let _res = {}
  reservations.forEach((r) => {
    if (!_res[r.occurency.date]) {
      _res[r.occurency.date] = []
    }
    _res[r.occurency.date].push(r)
  })
  return _res
}

const aggregatePerStarttime = (reservations) => {
  if(!reservations) return {}
  let _res = {}
  reservations.forEach((r) => {
    if (!_res[r.resourcegroups[0].start]) {
      _res[r.resourcegroups[0].start] = []
    }
    _res[r.resourcegroups[0].start].push(r)
  })
  return _res
}

const formatStarttimeAggregate = (reservations, resources) => {
  if(!reservations) return {}
  return Object.keys(reservations).map((k, i) => {
    const id = moment.utc(k).format('YYYYMMDD')
   return { courts: getFormattedCourts(reservations[k], resources), start: k, durations: getDurations(reservations[k]), id, key: id + '.' + i }
  })
}

const getFormattedCourts = (reservations, resources) => {
  if (!reservations) return []
  const array = reservations.map((k) => {
    const resource = resources[k.resourcegroups[0].resources[0].id]
    if (!resource) return {}
    return { ...resource, id: k.resourcegroups[0].resources[0].id, start: moment.utc(k.resourcegroups[0].start).format('YYYY-MM-DDTHH:mm:00'), startmoment: moment.utc(k.resourcegroups[0].start) }
  })
  return [...new Map(array.map(item =>
    [item['id'], item])).values()]
}

const getDurations = (reservations) => {
  if (!reservations) return []
  return reservations.map((k) => {
    return moment.utc(k.resourcegroups[0].end).diff(moment.utc(k.resourcegroups[0].start), 'minutes')
  }).filter((value, index, self) => self.indexOf(value) === index)
}

function Mass({ auth, dispatch, reservations, pastreservations, onsale, cancelled, unpaid, accesscode, appconfig, resources }) {
  let category = appconfig?.categories?.[0]?.category || 'tennis'
  // eslint-disable-next-line no-unused-vars
  const [selectedCategory, setSelectedCategory] = useState(category)

  const ownreservations = reservations ? reservations.map((r) => {
    if (r.vs.isRecurring) {
      const occurrencydate = moment.utc(r.occurency.date)
      const start = moment.utc(r.resourcegroups[0].start).year(occurrencydate.year()).month(occurrencydate.month()).date(occurrencydate.date())
      const end = moment.utc(r.resourcegroups[0].end).year(occurrencydate.year()).month(occurrencydate.month()).date(occurrencydate.date())
      return { start: start.format(), end: end.format(), resourceId: r.resourcegroups[0].resources[0].id, id: r.id }
    } else {
      return { start: r.resourcegroups[0].start, end: r.resourcegroups[0].end, resourceId: r.resourcegroups[0].resources[0].id, id: r.id }
    }
  }) : []

  const dailyReservations = aggregatePerDay(pastreservations)
  const perDayDebug = Object.keys(dailyReservations).map((k) => {
    return { date: k, reservations: dailyReservations[k].length, reservationsDebug: formatStarttimeAggregate(aggregatePerStarttime(dailyReservations[k]), resources || []) }
  })
  const dailyFreeTimes = perDayDebug?.[0]?.reservationsDebug
  console.log('dailyFreeTimes', dailyFreeTimes)
  return (
    <div className='full-page-background'>
      <TopNavigation />
      <Container className='front'>
        {/* <pre>
          {JSON.stringify(perDayDebug, null, 2)}
        </pre>
        <pre>
          {JSON.stringify(dailyFreeTimes, null, 2)}
        </pre> */}
        <HorizontalView dailyFreeTimes={[]} servertime={moment().format('HH:00')} selectedvenue={selectedCategory} ownreservations={ownreservations} />
        <pre>
          {JSON.stringify(ownreservations, null, 2)}
        </pre>
        <pre>{selectedCategory}</pre>
      </Container>
    </div>
  )
}

export default withAppconfig(withReservations(Mass))
