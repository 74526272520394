import { combineReducers } from 'redux'
import { firebaseReducer } from 'react-redux-firebase'
import { firestoreReducer } from 'redux-firestore'

function _addCollectionToSelected(selected, collection) {
  if (Array.isArray(selected)) {
    selected.forEach((item) => {
      if(!item['collection']) {
        item['collection'] = collection
      }
    })
  } else {
    if(!selected['collection']) {
      selected['collection'] = collection
    }
  }
  return selected
}


function reservationReducer(state = [], action) {
  switch (action.type) {
    case 'UPDATE_RESERVATION': {
      const selected = _addCollectionToSelected(action.payload, 'reservations')
      return {
        ...state,
        selected,
      }
    }
    case 'CLEAR_RESERVATION': {
      return {
        ...state,
        selected: null,
      }
    }
    case 'USER_LOGOUT': {
      return {
        ...state,
        selected: null,
      }
    }
    default:
      return state
  }
}

function paymentReducer(state = [], action) {
  switch (action.type) {
    case 'UPDATE_PAYABLE_RESERVATION': {
      const selected = _addCollectionToSelected(action.payload, 'reservations')
      return {
        ...state,
        selected,
      }
    }
    case 'CLEAR_PAYABLE_RESERVATION': {
      return {
        ...state,
        selected: null,
      }
    }
    case 'UPDATE_PAYABLE_ACTIVITY': {
      const selected = _addCollectionToSelected(action.payload, 'activities')
      return {
        ...state,
        selected,
      }
    }
    case 'CLEAR_PAYABLE_ACTIVITY': {
      return {
        ...state,
        selected: null,
      }
    }
    case 'UPDATE_PAYABLE_MEMBERSHIP': {
      const selected = _addCollectionToSelected(action.payload, 'memberships')
      return {
        ...state,
        selected,
      }
    }
    case 'CLEAR_PAYABLE_MEMBERSHIP': {
      return {
        ...state,
        selected: null,
      }
    }
    case 'UPDATE_PAYABLE_BENEFITCARD': {
      const selected = _addCollectionToSelected(action.payload, 'benefitcards')
      return {
        ...state,
        selected,
      }
    }
    case 'CLEAR_PAYABLE_BENEFITCARD': {
      return {
        ...state,
        selected: null,
      }
    }
    case 'UPDATE_PAYABLE_INVOICE': {
      const selected = _addCollectionToSelected(action.payload, 'invoices')
      return {
        ...state,
        selected,
      }
    }
    case 'CLEAR_PAYABLE_INVOICE': {
      return {
        ...state,
        selected: null,
      }
    }
    case 'UPDATE_PAYABLE_RECURRENCY': {
      const selected = _addCollectionToSelected(action.payload, 'recurrencies')
      return {
        ...state,
        selected,
      }
    }
    case 'CLEAR_PAYABLE_RECURRENCY': {
      return {
        ...state,
        selected: null,
      }
    }
    case 'USER_LOGOUT': {
      return {
        ...state,
        selected: null,
      }
    }
    default:
      return state
  }
}

function splittableReducer(state = [], action) {
  switch (action.type) {
    case 'UPDATE_SPLITTABLE_RESERVATION': {
      const selected = action.payload
      return {
        ...state,
        selected,
      }
    }
    case 'CLEAR_SPLITTABLE_RESERVATION': {
      return {
        ...state,
        selected: null,
      }
    }
    case 'USER_LOGOUT': {
      return {
        ...state,
        selected: null,
      }
    }
    default:
      return state
  }
}

function dateReducer(state = '', action) {
  switch (action.type) {
    case 'UPDATE_DATE': {
      const selected = action.payload
      return {
        ...state,
        selected,
      }
    }
    case 'CLEAR_DATE': {
      return {
        ...state,
        selected: null,
      }
    }
    default:
      return state
  }
}

function vacanciesReducer(state = [], action) {
  switch (action.type) {
    case 'UPDATE_VACANCIES': {
      const selected = action.payload
      return {
        ...state,
        selected,
      }
    }
    case 'CLEAR_VACANCIES': {
      return {
        ...state,
        selected: null,
      }
    }
    case 'USER_LOGOUT': {
      return {
        ...state,
        selected: null,
      }
    }
    default:
      return state
  }
}

function resourcesReducer(state = null, action) {
  switch (action.type) {
    case 'UPDATE_RESOURCES': {
      const resources = action.payload
      return {
        ...state,
        resources,
      }
    }
    case 'CLEAR_RESOURCES': {
      return {
        ...state,
        resources: null,
      }
    }
    default:
      return state
  }
}

function globaltimeReducer(state = [], action) {
  switch (action.type) {
    case 'UPDATE_GLOBALTIME': {
      const gtime = action.payload
      return {
        ...state,
        gtime,
      }
    }
    case 'CLEAR_GLOBALTIME': {
      return {
        ...state,
        gtime: null,
      }
    }
    default:
      return state
  }
}

const appReducer = combineReducers({
  firestore: firestoreReducer,
  firebase: firebaseReducer,
  reservation: reservationReducer,
  payable: paymentReducer,
  splittable: splittableReducer,
  handledate: dateReducer,
  vacancies: vacanciesReducer,
  resources: resourcesReducer,
  globaltime: globaltimeReducer,
})

const rootReducer = (state, action) => {
  // if (action.type === 'USER_LOGOUT') {
  //   return appReducer(undefined, action)
  // }
  return appReducer(state, action)
}

export default rootReducer
