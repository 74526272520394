import React from 'react'
import { useParams } from 'react-router-dom'
import TopNavigation from '../TopNavigation'
import { Container, Row, Col } from 'reactstrap'
import LottieAnim from './LottieAnim'
import { useHistory } from 'react-router-dom'

// const LottieAnim = lazy(() => import('./LottieAnim'))

function AddCardResult() {
  const params = useParams()
  const history = useHistory()

  const onComplete = () => {
    history.push('../profile')
  }

  return (
    <div className='full-page-background'>
      <TopNavigation title='Card Result' />
      <Container className='front'>
        <Row className='text-center' style={{ paddingTop: 20, marginBottom: 30 }}>
          {/* <Suspense fallback={<div>Loading...</div>}> */}
          <Col className={'mx-auto'} xs={3}><LottieAnim status={params.status} onComplete={onComplete} /> {params.status === 'success' ? 'Card added' : 'Card not added'}</Col>
          {/* </Suspense> */}
        </Row>
      </Container>
    </div>
  )
}

export default AddCardResult
