import React, { useState } from 'react'
import moment from 'moment'
import 'moment/locale/en-gb'
import 'moment/locale/et'
import 'moment/locale/sv'
import 'moment/locale/fi'
import { Row, Col, Button, Badge } from 'reactstrap'
import Divider from '@material-ui/core/Divider'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Collapse from '@material-ui/core/Collapse'
// import Weather from './Weather'
import { useHistory } from 'react-router-dom'
import withAppconfig from '../AppContext'
import { connect } from 'react-redux'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Avatar } from '@material-ui/core'
import { t } from 'i18next'
import { useEffect } from 'react'
import { getResources } from './ViewUtils'
import useWindowDimensions from '../useWindowsDimensions'
// const APPINITLANG = 'fi'
const ICONS = false

const enhance = connect(
  // Map redux state to component props
  ({ firebase: { auth, profile } }) => ({
    auth,
    profile,
  })
)

function SimpleView({ dailyFreeTimes, selectedvenue, appconfig, weather, dispatch, viewEmptyTimes, recurrentsales, onSelectSlot }) {
  const [open, setOpen] = useState({})
  const history = useHistory()
  const [resources, setResources] = useState([])
  const { width } = useWindowDimensions()

  useEffect(() => {
    // console.log('dailyFreeTimes', dailyFreeTimes)
    // console.log('selectedvenue', selectedvenue)
    const resources = getResources(dailyFreeTimes, selectedvenue, appconfig).sort((a, b) => {
      if (a.order) {
        return a.order - b.order
      } else {
        return a.courtname.localeCompare(b.courtname, undefined, { numeric: true, sensitivity: 'base' })
      }
    })
    // console.log('resources', resources)
    setResources(resources)
  }, [dailyFreeTimes, selectedvenue, appconfig])

  const handleClick = (timeIndex) => {
    let v = true
    if (open[timeIndex]) v = false
    setOpen({ ...open, [timeIndex]: v })
  }

  const selectReservation = (slot, d) => {
    // console.log(JSON.stringify(slot))
    slot.view = 'simple'
    slot.selectedduration = d
    dispatch({
      type: 'UPDATE_RESERVATION',
      payload: slot,
    })
    history.push(appconfig.pathPrefix + '/reservation')
  }

  const selectRecurrent = (slot, d) => {
    // console.log(JSON.stringify(slot))
    slot.view = 'simple'
    slot.selectedduration = d
    dispatch({
      type: 'UPDATE_PAYABLE_RECURRENCY',
      payload: slot,
    })
    history.push(appconfig.pathPrefix + '/recs/checkout')
  }

  const selectSlot = (slot, d) => {
    if (onSelectSlot) {
      onSelectSlot(slot, d)
    } else if (recurrentsales) {
      selectRecurrent(slot, d)
    } else {
      selectReservation(slot, d)
    }
  }

  let isEmptyCategory = true

  const response = dailyFreeTimes.map((slot) => {
  // return dailyFreeTimes.map((slot) => {
    const courtTitles = []
    let index = 0
    let expandedslots = slot.courts
      .filter((x) => {
        if (appconfig.categories.length > 0 && selectedvenue !== 'all') {
          return x.category === selectedvenue
        } else {
          return true
        }
      })
      .map((court) => {
        return court.times.map((time) => {
          let _t = { ...time, ...court }
          delete _t.times
          _t.courtname = court.name || court.displayName
          _t.id = court.id
          _t.key = court.id + index + Math.random().toString()
          _t.start = slot.start
          courtTitles.push(_t.courtname)
          index++
          return _t
        })
      })
      .flat()
    if (expandedslots.length > 0 && isEmptyCategory) {
      isEmptyCategory = false
    }

    let uniqueCourtTitles = [...new Set(courtTitles)]
    uniqueCourtTitles.sort(function (as, bs) {
      var a,
        b,
        a1,
        b1,
        rx = /(\d+)|(\D+)/g,
        rd = /\d+/
      a = String(as).toLowerCase().match(rx)
      b = String(bs).toLowerCase().match(rx)
      while (a.length && b.length) {
        a1 = a.shift()
        b1 = b.shift()
        if (rd.test(a1) || rd.test(b1)) {
          if (!rd.test(a1)) return 1
          if (!rd.test(b1)) return -1
          // eslint-disable-next-line eqeqeq
          if (a1 != b1) return a1 - b1
        }
        // eslint-disable-next-line eqeqeq
        else if (a1 != b1) return a1 > b1 ? 1 : -1
      }
      return a.length - b.length
    })

    expandedslots.sort(function (ax, bx) {
      const as = ax.displayName
      const bs = bx.displayName
      var a,
        b,
        a1,
        b1,
        rx = /(\d+)|(\D+)/g,
        rd = /\d+/
      a = String(as).toLowerCase().match(rx)
      b = String(bs).toLowerCase().match(rx)
      while (a.length && b.length) {
        a1 = a.shift()
        b1 = b.shift()
        if (rd.test(a1) || rd.test(b1)) {
          if (!rd.test(a1)) return 1
          if (!rd.test(b1)) return -1
          // eslint-disable-next-line eqeqeq
          if (a1 != b1) return a1 - b1
        }
        // eslint-disable-next-line eqeqeq
        else if (a1 != b1) return a1 > b1 ? 1 : -1
      }
      return a.length - b.length
    })

    const grouped = expandedslots.reduce((acc, slot) => {
      if (!acc[slot.displayName]) acc[slot.displayName] = []
      const h = Math.floor(slot.duration / 60)
      const m = slot.duration % 60
      let timeTitle = (h > 0 ? h + ' h ' : '') + (m > 0 ? m + ' m' : '')
      
      let bg = slot.duration < 60 ? { margin: 3, fontSize: '0.75rem', background: 'repeating-linear-gradient(45deg,#007bff,#007bff 3px,#465298 3px,#465298 6px)' } : { margin: 3, fontSize: '0.75rem' }

      acc[slot.displayName].push(
        <React.Fragment key={slot.key + '-fragment-' + Math.random().toString()}>
          <Button
            block
            size={'sm'}
            style={bg}
            color='primary'
            onClick={(e) => selectSlot(slot)}
            key={slot.key + '-bttn-' + Math.random().toString()}
          >
            <div>
              <div style={{ flex: 1, textAlign: 'center' }}>{timeTitle}</div>
              <div style={{ flex: 1, textAlign: 'center' }}>
              {!recurrentsales ? (<Badge color='success' pill>{slot.price + ' €'}</Badge>) : null}
              </div>
            </div>
          </Button>{' '}
        </React.Fragment>
      )
      return acc
    }, {})

    let timeIndex = moment.utc(slot.start).format('HH:mm')
    // _o[timeIndex] = false
    let _courtNames = slot.courts.map((c) => c.displayName)
    _courtNames.sort()
    const courtNames = <span style={{ marginLeft: 10 }}>{uniqueCourtTitles.join(', ')}</span>
    let iconSize = 14
    let marginTop = 3
    if (resources.length > 16 && width < 600) {
      iconSize = 8
      marginTop = 6
    }
    const courtIcons = resources.map((c) => {
      let courtcolor = '#bdbdbd'
      if (uniqueCourtTitles.includes(c.courtname)) {
        courtcolor = '#007bff'//'#16ba64'
      }
      return (<Avatar key={c.id} variant="rounded" style={{ width: iconSize, height: iconSize, fontSize: 'x-small', backgroundColor: courtcolor, marginLeft: 3, marginTop }}> </Avatar>)

    })
    let colWidth = 1 // 12 / chunk
    let colWidthSm = 4 // 24 / chunk
    if (uniqueCourtTitles.length > 8) {
      colWidth = 1 // 12 / chunk
      colWidthSm = 4 // 24 / chunk
    }
    const courtKeys = Object.keys(grouped)
    let altRowByCourts = []
    for (let j = 0; j < courtKeys.length; j++) {
      altRowByCourts.push(
        <Col
          key={'altRowBy' + j + Math.random().toString()}
          style={{ marginTop: 10, textAlign: 'center', paddingLeft: 1, paddingRight: 1 }}
          xs={colWidthSm}
          sm={colWidthSm}
          md={colWidth}
          lg={colWidth}
          xl={colWidth}
        >
          <span style={{ fontSize: 'small' }}>{courtKeys[j]}</span>
          {/* <br /><span style={{ fontSize: 'small' }}>SPONSORI</span> */}
          {grouped[courtKeys[j]]}
        </Col>
      )
    }
    const paddings = 4
    let listitemStyle = { paddingTop: paddings, paddingBottom: paddings, overflow: 'hidden' }
    if (width < 600) {
      listitemStyle = { paddingTop: paddings, paddingBottom: paddings, paddingLeft: 0, overflow: 'hidden' }
    }
    if (uniqueCourtTitles.length > 0) {
      return (
        <React.Fragment key={slot.key + '-list'}>
          <ListItem
            button
            dense={width < 600 ? true : false}
            key={slot.key + '-lstbttn' + Math.random().toString()}
            onClick={() => handleClick(timeIndex)}
            style={listitemStyle}
          >
            <ListItemText
              primary={
                open[timeIndex] ? (
                  <React.Fragment>
                    <ExpandLessIcon /> <b>{timeIndex}</b>{' '}
                  </React.Fragment>
                ) : (
                    <div style={{ display: 'flex' }}> <ExpandMoreIcon />  <b>{timeIndex}</b> {ICONS ? courtIcons : courtNames}</div>
                )
              }
            />
          </ListItem>
          <Collapse
            in={open[timeIndex]}
            timeout='auto'
            unmountOnExit
            key={slot.key + '-lstcoll' + Math.random().toString()}
          >
            <Row key={slot.key + '-col-'} style={{ marginLeft: 5, marginRight: 5 }}>{altRowByCourts}</Row>

          </Collapse>
          <Divider component='li' key={slot.key + '-lstdiv' + Math.random().toString()} />
        </React.Fragment>
      )
    } else {
      if (viewEmptyTimes) {
        listitemStyle.color = 'grey'
        return (
          <React.Fragment key={slot.key + '-list'}>
            <ListItem
              button
              dense={width < 600 ? true : false}
              key={slot.key + '-lstbttn' + Math.random().toString()}
              style={listitemStyle}
            >
              <ListItemText
                primary={
                  open[timeIndex] ? (
                    <React.Fragment>
                      <ExpandLessIcon /> <b>{timeIndex}</b>{' '}
                    </React.Fragment>
                  ) : (
                    <div style={{ display: 'flex' }}> <ExpandMoreIcon />  <b>{timeIndex}</b> {courtIcons}</div>
                  )
                }
              />
            </ListItem>
            <Divider component='li' key={slot.key + '-lstdiv' + Math.random().toString()} />
          </React.Fragment>
        )
      } else {
        return null
      }
    }
  })

  if(isEmptyCategory) {
    return <div style={{ textAlign: 'center', marginTop: 20 }}>{t('no_vacancy')}</div>
  } else {
    return response
  }
}

export default withAppconfig(enhance(SimpleView))
