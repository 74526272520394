import { SvgIcon } from "@material-ui/core";

import { ReactComponent as TennisIcon } from '../assets/icons/Tennis.svg';
import { ReactComponent as BadmintonIcon } from '../assets/icons/Badminton.svg';
import { ReactComponent as TableTennisIcon } from '../assets/icons/TableTennis.svg';
import { ReactComponent as SquashIcon } from '../assets/icons/Squash.svg';
import { ReactComponent as PadelIcon } from '../assets/icons/Padel.svg';
import { ReactComponent as PickleballIcon } from '../assets/icons/Pickleball.svg';
import { ReactComponent as GolfIcon } from '../assets/icons/Golf.svg';
import { ReactComponent as BasketballIcon } from '../assets/icons/Basketball.svg';
import { ReactComponent as VolleyballIcon } from '../assets/icons/Volleyball.svg';
import { ReactComponent as FootballIcon } from '../assets/icons/Football.svg';
import { ReactComponent as FloorballIcon } from '../assets/icons/Floorball.svg';
import { ReactComponent as IceHockeyIcon } from '../assets/icons/Icehockey.svg';
import { ReactComponent as BoxingIcon } from '../assets/icons/Boxing.svg';
import { ReactComponent as GymIcon } from '../assets/icons/Gymnastics.svg';
import { ReactComponent as BilliardIcon } from '../assets/icons/Billiard.svg';
import { ReactComponent as BeachVolleyIcon } from '../assets/icons/BeachVolley.svg';

const SPECIALS = {
  'tali ulkokentät': 'tennis',
  'tali sisäkentät': 'tennis',
}

const TRANSLATIONS = {
  pöytätennis: 'tabletennis',
  sulis: 'badminton',
  sulkapallo: 'badminton',
  ulkotennis: 'tennis',
  sisätennis: 'tennis',
  minitennis: 'tennis',
  tennis: 'tennis',
  squash: 'squash',
  ulkopadel: 'padel',
  sisäpadel: 'padel',
  padel: 'padel',
  pickleball: 'pickleball',
  pingis: 'tabletennis',
  jäähalli: 'icehockey',
  futsal: 'football',
  biljardi: 'billiard',
  salibandy: 'floorball',
  säbä: 'floorball',
  futis: 'football',
  tekonurmi: 'football',
  'beach volley': 'volleyball',
  
}

const SPORTICONS = {
  badminton: BadmintonIcon,
  tennis: TennisIcon,
  tabletennis: TableTennisIcon,
  squash: SquashIcon,
  padel: PadelIcon,
  pickleball: PickleballIcon,
  golf: GolfIcon,
  basketball: BasketballIcon,
  volleyball: VolleyballIcon,
  football: FootballIcon,
  floorball: FloorballIcon,
  icehockey: IceHockeyIcon,
  boxing: BoxingIcon,
  gym: GymIcon,
  billiard: BilliardIcon,
  beachvolley: BeachVolleyIcon,
}

const getIcon = (sport) => {
  const icon = SPORTICONS[sport];
  if (!icon) {
    const key = TRANSLATIONS[sport];
    if (!key) {
      const translationKeys = Object.keys(TRANSLATIONS);
      for (let i = 0; i < translationKeys.length; i++) {
        const translationKey = translationKeys[i];
        if (sport.includes(translationKey)) {
          return SPORTICONS[TRANSLATIONS[translationKey]];
        }
      }
      const specialKey = SPECIALS[sport];
      if (specialKey) {
        return SPORTICONS[specialKey];
      }
    } else {
      return SPORTICONS[key];
    }
  } else {
    return icon;
  }
}

export const SportIcon = (props) => {
  const { sport, color, ...other } = props;
  const icon = getIcon(sport);
  if (!icon) {
    return null;
  }
  return <SvgIcon component={icon} {...other} viewBox="0 0 1024 1024" />
};

export default SportIcon;
