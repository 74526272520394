import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardMedia from '@material-ui/core/CardMedia'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Collapse from '@material-ui/core/Collapse'
import Avatar from '@material-ui/core/Avatar'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Chip from '@material-ui/core/Chip'
import { blue } from '@material-ui/core/colors'
import ShareIcon from '@material-ui/icons/Share'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
// import MoreVertIcon from '@material-ui/icons/MoreVert'
import { yellow } from '@material-ui/core/colors'
import { useHistory } from 'react-router'
import moment from 'moment'
import 'moment/locale/fi'
import 'moment/locale/sv'
import 'moment/locale/en-gb'
import 'moment/locale/et'

import { Row, Col } from 'reactstrap'
import withAppconfig from '../AppContext'

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 380,
    // maxHeight: 400,
    backgroundColor: 'rgba(253,253,253,0.8)',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
    boxShadow: 'inset -2px -20px 35px 12px rgba(255,255,255,1)',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: blue[500],
  },
  horizontalroot: {
    maxWidth: '100%',
    marginBottom: 30,
    boxShadow: 'inset 200px 180px 135px 0px rgba(255,255,255,1)',
  },
  horizontalroote: {
    maxWidth: '100%',
    marginBottom: 30,
    boxShadow: 'inset 200px 330px 135px 0px rgba(255,255,255,1)',
  },
  horizontalmedia: {
    height: '100%',
    // paddingLeft: '56.25%', // 16:9
    // boxShadow: 'inset -2px -20px 35px 12px rgba(255,255,255,1)',
    width: '100%',
    maxHeight: 150,
    objectFit: 'cover',
  },
}))

function LeagueCard({ activity, horizontal, paymentAction, cancelAction, uid, shareClick, gtime, defaultExpanded = false, appconfig }) {
  let { name, poster, description, price, type, start, end, ingress, maxp, participants, logo, queue, hasqueue, sport, id } =
    activity
  const classes = useStyles()
  const [expanded, setExpanded] = React.useState(defaultExpanded)
  const history = useHistory()

  if (!participants) participants = []
  if (!queue) queue = []

  const hasEntry = participants.find((x) => x.id === uid)
  const isInQueue = queue.find((x) => x.id === uid)
  const isFull = participants.length >= maxp
  const hasLittleRoom = participants.length > Math.floor(maxp * 0.75) && participants.length < maxp
  // const canSignUp = !hasEntry && !isInQueue && !isFull
  // const canQueue = !hasEntry && !isInQueue && participants.length >= maxp
  const signUpOpen = moment.utc(activity.entryend, 'YYYY-MM-DDTHH:mm').isAfter(gtime)

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  const buttonSignUpText = price > 0 ? 'Ilmoittaudu ' + price + ' €' : 'Ilmoittaudu'
  const buttonCancelText = 'Peruuta'

  const actionButton = () => {
    if (signUpOpen) {
      if (hasEntry) {
        return (
          <Button variant='contained' color='secondary' onClick={() => cancelAction(activity)}>
            {buttonCancelText}
          </Button>
        )
      } else if (isInQueue) {
        return (
          <Button variant='contained' color='secondary' onClick={() => cancelAction(activity)}>
            {buttonCancelText}
          </Button>
        )
      } else if (!isFull) {
        return (
          <Button
            variant='contained'
            color={'primary'}
            style={hasLittleRoom ? { backgroundColor: yellow[400], color: 'black' } : {}}
            onClick={() => paymentAction(activity)}
          >
            {buttonSignUpText}
          </Button>
        )
      } else {
        if (hasqueue) {
          return (
            <Button
              variant='contained'
              color={'primary'}
              onClick={() => paymentAction(activity)}
              style={{ backgroundColor: '#a643c1', color: 'white' }}
            >
              Jonoon
            </Button>
          ) 
        } else {
          return (
            <Button variant='contained' color='primary' style={{ backgroundColor: yellow[400], color: 'black' }} disabled>
              Tapahtuma täynnä
            </Button>
          )
        }
      }
    } else {
      // signup not open any more
      return (
        <Button variant='contained' color='secondary' disabled>
          Ilmoittautuminen päättynyt
        </Button>
      )
    }
  }

  // const subheader = <div>HALOO{moment.utc(start).format('dddd D.M. H:mm') + ' - ' + moment.utc(end).format('H:mm')}</div>

  if (horizontal === 'horizontal') {
    return (
      <Col xs={12}>
        <Card
          className={clsx(classes.horizontalroot, {
            [classes.horizontalroote]: expanded,
          })}
          style={{
            backgroundImage: `url(${poster})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPositionX: 'center',
            backgroundPositionY: 'center',
          }}
        >
          <CardContent>
            <Row style={{ alignItems: 'flex-start' }}>
              <Col xs={'1'}>
                {' '}
                <Avatar variant='rounded' className={classes.avatar} src={logo}>
                  {type.substring(0, 1).toUpperCase()}
                </Avatar>
              </Col>
              <Col md={'3'}>
                <img className={classes.horizontalmedia} src={poster} alt='' />
              </Col>
              <Col>
                <Typography variant='h6' color='textPrimary'>
                  {name.fi}
                </Typography>
                <Typography variant='subtitle1' color='textPrimary' paragraph={true}>
                  {moment.utc(start).format('dddd D.M. H:mm')} - {moment.utc(end).format('H:mm')}
                </Typography>
                <Typography variant='body2' color='textPrimary' component='p'>
                  {ingress.fi}
                </Typography>
                {!activity.isexternal ? (
                  <Typography variant='button' color='textPrimary' component='p'>
                    {participants.length + '/' + maxp} osallistujaa
                  </Typography>
                ) : null}
                <Typography variant='body' color='textPrimary' component='p'>
                  {hasEntry || isInQueue ? (
                    <Chip
                      color={hasEntry ? 'primary' : 'seconday'}
                      label={hasEntry ? 'Olet ilmoittautunut' : 'Olet jonossa'}
                    />
                  ) : null}
                </Typography>
              </Col>
            </Row>
          </CardContent>
          <CardActions disableSpacing>
            {actionButton()}
            <IconButton aria-label='share' onClick={() => shareClick(activity)}>
              <ShareIcon />
            </IconButton>
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: expanded,
              })}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label='show more'
            >
              <ExpandMoreIcon />
            </IconButton>
          </CardActions>
          <Collapse in={expanded} timeout='auto' unmountOnExit>
            <CardContent>
              <Typography paragraph>{description.fi}</Typography>
              <Typography></Typography>
            </CardContent>
          </Collapse>
        </Card>
      </Col>
    )
  } else {
    return (
      <Col style={{ marginBottom: 30, paddingLeft: 8, paddingRight: 8 }}>
        <Card className={classes.root}>
          <CardHeader
            style={{ paddingBottom: 4 }}
            avatar={
              <Avatar variant='rounded' className={classes.avatar} src={logo}>
                {type.substring(0, 1).toUpperCase()}
              </Avatar>
            }
            // action={
            //   <IconButton aria-label='settings'>
            //     <MoreVertIcon />
            //   </IconButton>
            // }
            title={name}
            subheader={sport ? sport : ''}
          />
          <CardMedia className={classes.media} image={poster} title='' onClick={() => history.push(appconfig.pathPrefix + '/league/' + id)} />
          <CardContent onClick={() => history.push(appconfig.pathPrefix + '/league/' + id)}>
            <Typography variant='body2' color='textPrimary' component='p' style={{ marginTop: '-25px' }}>
              {ingress.fi}
            </Typography>
            {!activity.isexternal ? (
              <Typography variant='body2' color='textPrimary' component='p'>
                {hasEntry || isInQueue ? (
                  <Chip
                    color={hasEntry ? 'primary' : 'seconday'}
                    label={hasEntry ? 'Olet ilmoittautunut' : 'Olet jonossa'}
                  />
                ) : null}
              </Typography>
            ) : null}
          </CardContent>
          <CardActions disableSpacing>
            <IconButton aria-label='share' onClick={() => shareClick(activity)}>
              <ShareIcon />
            </IconButton>
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: expanded,
              })}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label='show more'
            >
              <ExpandMoreIcon />
            </IconButton>
          </CardActions>
          <Collapse in={expanded} timeout='auto' unmountOnExit>
            <CardContent>
              <Typography paragraph>{description.fi}</Typography>
            </CardContent>
          </Collapse>
        </Card>
      </Col>
    )
  }
}

export default withAppconfig(LeagueCard)
