import React from 'react'
import { useTranslation } from 'react-i18next'

import { CardBody, CardTitle, CardText, Row, Col } from 'reactstrap'
import { Divider } from '@material-ui/core'

function CheckoutItem({ row }) {
  const { t } = useTranslation()


  return (
    <CardBody key={row.id}>
    <CardTitle tag='h5'>{row.summary.displayName}</CardTitle>
    <Row className={'loose'}>
      <Col>{t('day')}</Col>
      <Col style={{ textTransform: 'capitalize' }} className={'text-right'}>
        {row.summary.start.format('dddd D.M.YYYY')}
      </Col>
    </Row>
    <Divider />
    <Row className={'loose'}>
      <Col>{t('time')}</Col>
      <Col className={'text-right'}>
        {row.summary.start.format('H:mm')} - {row.summary.end.format('H:mm')}
      </Col>
    </Row>
    <Divider />
    <Row className={'loose'}>
      <Col>{t('price')}</Col>
      <Col className={'text-right'}>{row.summary.price} €</Col>
    </Row>
    <CardText></CardText>
    </CardBody>
  )

}

export default CheckoutItem;