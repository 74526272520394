import React from 'react'
import {
  Container,
  Row,
  Col,
  Alert,
} from 'reactstrap'
import TopNavigation from '../../TopNavigation'
import withAppconfig from '../../AppContext'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firebaseConnect } from 'react-redux-firebase'
import ProductCard from '../../components/Shop/ProductCard'
import { Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const mapStateToProps = (state, props) => {
  const {
    firebase
  } = state
  if(!firebase.ordered.shopmemberships) return { memberships: null }
  const memberships = firebase.ordered.shopmemberships.map((m) => {
    return { id: m.key, ...m.value }
  })
  return {
    memberships,
  }
}

const withProducts = compose(
  connect((state) => ({ auth: state.firebase.auth })),
  firebaseConnect((props) => [
    {
      path: 'shop/' + props.appconfig.accountkey + '/memberships',
      queryParams: ['orderByChild=displayName'],
      storeAs: 'shopmemberships',
    },
  ]),
  connect(mapStateToProps)
)

function MembershipsScreen({ appconfig, memberships, auth }) {
  const { t } = useTranslation()
  const isAuth = auth.isLoaded && !auth.isEmpty
  return (
    <div className='full-page-background'>
      <TopNavigation />
      <Container className='front' style={{ paddingTop: 10 }}>
        {!isAuth ? (<Alert color='warning'>{t('login_to_buy_membership')}</Alert>) : null}
        <Typography variant='h6' style={{ paddingBottom: 20 }}>
          Jäsenyydet
        </Typography>
        {memberships && memberships.length > 0 ? (
          <Row>
            {memberships.map((m) => (
              <Col key={m.id} xs={12} sm={4}>
                <ProductCard product={m} collection={'membership'} isAuthRequired={true} isAuth={isAuth} />
              </Col>
            ))}
          </Row>
        ) : (
          null
        )}
      </Container>
    </div>
  )
}

export default withAppconfig(withProducts(MembershipsScreen))
