import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import {
  firebaseConnect,
  isEmpty,
  isLoaded,
  useFirebase,
} from 'react-redux-firebase'
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  ButtonGroup,
  Spinner,
} from 'reactstrap'
import { Chip, Typography } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import moment from 'moment'
import 'moment/locale/en-gb'
import 'moment/locale/sv'
import 'moment/locale/fi'
import ViewColumnIcon from '@material-ui/icons/ViewColumn'
import ViewListIcon from '@material-ui/icons/ViewList'
import CalendarViewDayIcon from '@material-ui/icons/CalendarViewDay'
import List from '@material-ui/core/List'

import Divider from '@material-ui/core/Divider'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import TopNavigation from '../TopNavigation'
import { useCookies } from 'react-cookie'
import IconButton from '@material-ui/core/IconButton'
import { useTranslation } from 'react-i18next'
import withAppconfig from '../AppContext'
import SimpleView from '../components/SimpleView'
import VerticalView from '../components/VerticalView2'
import HorizontalView from '../components/HorizontalView'
import SelectDefaultViewDialog from '../components/SelectDefaultViewDialog'
import TextBannerPlacement from '../components/TextBannerCarouselPlacement'
import BannerPlacement from '../components/BannerCarouselPlacement'
import NextReservations from '../components/NextReservations'
import { useUiGet } from '../utils/useUiGet'
//import BottomNav from '../BottomNav'
import { useHistory } from 'react-router'
import TabNavigation from '../TabNavigation'
import SportIcon from '../components/SportIcon'

const APPINITLANG = 'fi'
const TODAY_INDEX = moment().format('YYYYMMDD')
const isTest =
  document.location.hostname === 'utest.cintoia.com' ||
  document.location.hostname === 'localhost'

const datenavidation = { cursor: 'pointer', margin: 10 }

// const textBannerStyle = { backgroundColor: '#cff4fc', borderRadius: 5, padding: 8, borderColor: '#b6effb', borderStyle: 'solid', borderWidth: 1 }

const getOffset = (timeZone = 'UTC', date = new Date()) => {
  const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }))
  const tzDate = new Date(date.toLocaleString('en-US', { timeZone }))
  return (tzDate.getTime() - utcDate.getTime()) / 6e4
}

const TIMEZONEOFFSET = getOffset('Europe/Helsinki')

const ZennizButton = ({ onClick, children, selected, ...props }) => {
  const selectedColor = {
    backgroundColor: '#007bff',
    color: 'white',
    marginRight: 5,
    marginLeft: 5,
  }
  return (
    <Chip
      style={selected ? selectedColor : { marginLeft: 5, marginRight: 5 }}
      icon={
        selected ? (
          <img
            src={process.env.PUBLIC_URL + '/imgs/zenniz-20-white.svg'}
            alt='zenniz'
          />
        ) : (
          <img
            src={process.env.PUBLIC_URL + '/imgs/zenniz-20-black.svg'}
            alt='zenniz'
          />
        )
      }
      variant={selected ? 'default' : 'outlined'}
      label='Zenniz kentät'
      size='small'
      onClick={onClick}
      {...props}
    />
  )
}
/*
const EmtpyslotsButton = ({ onClick, selected, ...props }) => {
  const selectedColor = {
    backgroundColor: '#007bff',
    color: 'white',
  }
  return (
    <Chip
      style={selected ? selectedColor : {}}
      // icon={
      //   selected ? (
      //     <img src={process.env.PUBLIC_URL + '/imgs/zenniz-20-white.svg'} alt='zenniz' />
      //   ) : (
      //     <img src={process.env.PUBLIC_URL + '/imgs/zenniz-20-black.svg'} alt='zenniz' />
      //   )
      // }
      variant={selected ? 'default' : 'outlined'}
      label={selected ? 'Näytä vain vapaat ajat' : 'Näytä myös varatut ajat'}
      size='small'
      onClick={onClick}
      {...props}
    />
  )
}
*/
const filterVacancies = (vacancies, filters) => {
  // console.log('filtering zenniz')
  // console.log('vacancies', vacancies)
  if (filters.zenniz) {
    vacancies
      .map((v) => {
        // console.log(v)
        // console.log(v.courts)
        v.courts = v.courts.filter((v) => v.zenniz)
        // console.log(v.courts)
        if (v.courts.length > 0) {
          return v
        } else {
          return null
        }
      })
      .filter((v) => v)
    // console.log('filtering zenniz')
    // console.log(_v)
    // vacancies.courts = vacancies.courts.filter(v => v.zenniz)
  }
  return vacancies
}

const getBanners = (data, bannertype, accountkey) => {
  if (data && data[bannertype] && data[bannertype][accountkey]) {
    return Object.keys(data[bannertype][accountkey]).map((k) => {
      return { id: k, ...data[bannertype][accountkey][k] }
    })
  } else {
    return null
  }
}

const mapStateToProps = (state, props) => {
  const {
    globaltime: { gtime },
  } = state

  console.log('state', state)

  let _handledate = state.handledate
  if (!_handledate) {
    _handledate = TODAY_INDEX
  }
  const { freeindex } = state.firebase.data

  // console.log('freeindex', freeindex)

  if (
    state.firebase.data.freetimes &&
    state.firebase.data.freetimes[props.appconfig.accountkey]
  ) {
    let _freetimes = JSON.parse(
      JSON.stringify(state.firebase.data.freetimes[props.appconfig.accountkey])
    )
    let _keys = Object.keys(_freetimes)
    let groupedByDay = {}
    let _justNow = parseInt(
      moment
        .utc()
        .add(Math.abs(TIMEZONEOFFSET) - 15, 'minutes')
        .format('X')
    )
    // eslint-disable-next-line array-callback-return
    _keys.map((curr, index) => {
      // console.log(curr)
      let _d = moment.utc(curr, 'X').format('YYYYMMDD')
      // console.log('curr', moment.utc(curr, 'X').format('YYYYMMDD HH:mm'), '_justNow', moment.utc(_justNow, 'X').format('YYYYMMDD HH:mm'))
      if (_justNow < parseInt(curr)) {
        let _ks = Object.keys(_freetimes[curr]).filter((k) => k !== 'start')
        // console.log(JSON.stringify(_ks))
        let _durs = {}
        let courts = _ks
          .map((k) => {
            if (
              props.appconfig.categories.length > 0 &&
              props.appconfig.categories
                .map((cat) => cat.category)
                .includes(_freetimes[curr][k].category)
            ) {
              let _times = _freetimes[curr][k].times
                .filter((x) => x.duration < 200)
                .map((t) => {
                  _durs[t.duration] = 1
                  return t
                })
              _freetimes[curr][k].times = _times
              return _freetimes[curr][k]
            } else if (props.appconfig.categories.length === 0) {
              let _times = _freetimes[curr][k].times
                .filter((x) => x.duration < 200)
                .map((t) => {
                  _durs[t.duration] = 1
                  return t
                })
              _freetimes[curr][k].times = _times
              return _freetimes[curr][k]
            } else {
              return null
            }
          })
          .filter((x) => x)
        // eslint-disable-next-line array-callback-return
        _ks.map((k) => {
          delete _freetimes[curr][k]
        })

        if (!groupedByDay[_d]) groupedByDay[_d] = []
        _freetimes[curr].courts = courts
        _freetimes[curr].durations = Object.keys(_durs)
        _freetimes[curr].pricerange = [0, 1]
        _freetimes[curr].id = _d
        _freetimes[curr].key = _d + '.' + index
        if (_freetimes[curr].courts && _freetimes[curr].courts.length > 0) {
          groupedByDay[_d].push(_freetimes[curr])
        }
      }
    })
    // let weather = null
    // if (state.firebase.data.weatherforecasts && state.firebase.data.weatherforecasts[props.appconfig.accountkey]) {
    //   weather = state.firebase.data.weatherforecasts[props.appconfig.accountkey]
    // }
    const infobanners = getBanners(
      state.firebase.data,
      'infobanners',
      props.appconfig.accountkey
    )
    // if (state.firebase.data.infobanners && state.firebase.data.infobanners[props.appconfig.accountkey]) {
    //   infobanners = Object.keys(state.firebase.data.infobanners[props.appconfig.accountkey]).map((k) => {
    //     return { id: k, ...state.firebase.data.infobanners[props.appconfig.accountkey][k] }
    //   })
    // }
    const textbanners = getBanners(
      state.firebase.data,
      'textbanners',
      props.appconfig.accountkey
    )
    // if (state.firebase.data.textbanners && state.firebase.data.textbanners[props.appconfig.accountkey]) {
    //   textbanners = Object.keys(state.firebase.data.textbanners[props.appconfig.accountkey]).map((k) => {
    //     return { id: k, ...state.firebase.data.textbanners[props.appconfig.accountkey][k] }
    //   })
    // }
    return {
      // freetimes: groupedByDay,
      // raw: state.firebase.data.freetimes,
      reservation: state.reservation,
      // weather,
      infobanners,
      textbanners,
      handledate: _handledate,
      freeindex,
      servertime: gtime,
    }
  } else {
    const infobanners = getBanners(
      state.firebase.data,
      'infobanners',
      props.appconfig.accountkey
    )
    // if (state.firebase.data.infobanners && state.firebase.data.infobanners[props.appconfig.accountkey]) {
    //   infobanners = Object.keys(state.firebase.data.infobanners[props.appconfig.accountkey]).map((k) => {
    //     return { id: k, ...state.firebase.data.infobanners[props.appconfig.accountkey][k] }
    //   })
    // }
    const textbanners = getBanners(
      state.firebase.data,
      'textbanners',
      props.appconfig.accountkey
    )
    // if (state.firebase.data.textbanners && state.firebase.data.textbanners[props.appconfig.accountkey]) {
    //   textbanners = Object.keys(state.firebase.data.textbanners[props.appconfig.accountkey]).map((k) => {
    //     return { id: k, ...state.firebase.data.textbanners[props.appconfig.accountkey][k] }
    //   })
    // }
    return {
      reservation: state.reservation,
      infobanners,
      textbanners,
      handledate: _handledate,
      freeindex,
      servertime: gtime,
    }
  }
}

const mapCug = (state, props) => {
  const { appconfig } = props
  if (props.cug) {
    appconfig.memberindex = props.cug.cugid
    appconfig.daysahead = props.cug.days
    return { appconfig }
  }
  return { appconfig }
}

const withFreeTimes = compose(
  connect(mapCug),
  firebaseConnect((props) => [
    {
      path:
        'freeindex/' +
        props.appconfig.accountkey +
        '/' +
        (props.appconfig.memberindex || 'public'),
      storeAs: 'freeindex',
    },
    {
      path: 'infobanners/' + props.appconfig.accountkey,
      queryParams: ['orderByChild=state', 'equalTo=active'],
    },
    {
      path: 'textbanners/' + props.appconfig.accountkey,
      queryParams: ['orderByChild=state', 'equalTo=active'],
    },
  ]),
  connect(mapStateToProps)
)

const enhance = connect(
  // Map redux state to component props
  ({ firebase: { auth, profile } }) => ({
    auth,
    profile,
  })
)

// const findInfoBanner = (infobanners, date) => {
//   if (infobanners) {
//     let _b = infobanners.find((b) =>
//       moment.utc(date).isBetween(moment.utc(b.start), moment.utc(b.end), undefined, '[]')
//     )
//     return _b
//   } else {
//     return null
//   }
// }

// const findInfoBanners = (infobanners, date) => {
//   if (infobanners) {
//     let _b = infobanners.filter((b) =>
//       moment.utc(date).isBetween(moment.utc(b.start), moment.utc(b.end), undefined, '[]')
//     )
//     return _b
//   } else {
//     return null
//   }
// }

// const findTextBanners = (textbanners, date) => {
//   if (textbanners) {
//     let _b = textbanners.filter((b) =>
//       moment.utc(date).isBetween(moment.utc(b.start), moment.utc(b.end), undefined, '[]')
//     )
//     if (_b) {
//       _b.hascats = false
//       if (_b.categories && _b.categories.length > 0) {
//         _b.cats = _b.categories
//           .toLowerCase()
//           .split(',')
//           .map((c) => c.trim())
//         _b.hascats = true
//       }
//     }
//     return _b
//   } else {
//     return []
//   }
// }

const formatVacancies = (vacancies, dateindex, resources) => {
  // console.log('formatVacancies', dateindex, resources, vacancies)
  const byStartTime = {}
  for (let resourceid in resources) {
    let resource = JSON.parse(JSON.stringify(resources[resourceid]))
    resource.id = resourceid
    if (vacancies[resource.id]) {
      vacancies[resource.id].forEach((v, i) => {
        if (!byStartTime[v.s])
          byStartTime[v.s] = {
            courts: [],
            start: moment.utc(dateindex + 'T' + v.s, 'YYYYMMDDTHHmm').format(),
            durations: [],
          }
        const court = {
          ...resource,
          id: resource.id,
          start: moment.utc(dateindex + 'T' + v.s, 'YYYYMMDDTHHmm').format(),
        }
        let times = []
        if (v.ex) {
          times = v.ex.map((x) => {
            byStartTime[v.s].durations.push(x.du)
            return { duration: x.du, price: x.p / 100, resourceId: resource.id }
          })
          court.times = times
        }
        if (v.e === '2359') {
          const ovdu = moment(v.e, 'HHmm').diff(moment(v.s, 'HHmm'), 'minutes')
          const vdu = Math.ceil(
            moment(v.e, 'HHmm')
              .endOf('day')
              .diff(moment(v.s, 'HHmm'), 'seconds') / 60
          )
          byStartTime[v.s].durations.push(vdu)
          times.push({
            duration: vdu,
            od: ovdu,
            price: v.p / 100,
            resourceId: resource.id,
          })
        } else {
          const vdu = moment(v.e, 'HHmm').diff(moment(v.s, 'HHmm'), 'minutes')
          byStartTime[v.s].durations.push(vdu)
          times.push({
            duration: vdu,
            price: v.p / 100,
            resourceId: resource.id,
          })
        }
        court.times = times.sort((a, b) => a.duration - b.duration)

        const uniqueDurations = [...new Set(byStartTime[v.s].durations)].sort(
          (a, b) => a - b
        )
        byStartTime[v.s].durations = uniqueDurations
        byStartTime[v.s].courts.push(court)
      })
    } else {
      const court = { ...resource, id: resource.id, times: [] }
      if (!byStartTime['0000'])
        byStartTime['0000'] = {
          courts: [],
          start: moment.utc(dateindex + 'T0000').format(),
          durations: [],
        }
      byStartTime['0000'].courts.push(court)
    }
  }
  const byStartTimeArray = Object.values(byStartTime).filter((x) =>
    moment.utc(x.start)
  )
  byStartTimeArray.sort(
    (a, b) => moment.utc(a.start).unix() - moment.utc(b.start).unix()
  )
  return byStartTimeArray
    .filter((x) =>
      moment
        .utc(x.start)
        .isAfter(moment.utc().add(TIMEZONEOFFSET, 'minutes'), 'minutes')
    )
    .map((x, i) => {
      return { ...x, id: dateindex, key: dateindex + '.' + i }
    })
}

function HomeScreenCalendar({
  auth,
  profile,
  dispatch,
  infobanners,
  textbanners,
  appconfig,
  handledate,
  freeindex,
  isMember,
  servertime,
}) {
  // const [open, setOpen] = useState({})
  const firebase = useFirebase()
  const uiGet = useUiGet({ appconfig })
  const history = useHistory()
  const [viewStyle, setViewStyle] = useState(appconfig.forceview || 'simple')
  const [openDefaultViewDialog, setOpenDefaultViewDialog] = useState(false)
  // const [servertime, setServertime] = useState(null)
  const { t, i18n } = useTranslation()
  const now = moment.utc().add(Math.abs(TIMEZONEOFFSET), 'minutes')
  let today = appconfig.openingdate
    ? moment.utc(appconfig.openingdate).isAfter(now, 'd')
      ? moment
          .utc(appconfig.openingdate)
          .add(Math.abs(TIMEZONEOFFSET), 'minutes')
          .format('YYYY-MM-DD')
      : now.format('YYYY-MM-DD')
    : now.format('YYYY-MM-DD')
  const minDate = moment
    .utc()
    .add(Math.abs(TIMEZONEOFFSET), 'minutes')
    .format('YYYY-MM-DD')
  const maxDate = moment
    .utc()
    .add(Math.abs(TIMEZONEOFFSET), 'minutes')
    .add((appconfig && appconfig.daysahead) || 13, 'days')
    .format('YYYY-MM-DD')
  const [cookies, setCookie] = useCookies([
    'selectedDay',
    'selectedVenue',
    'selectedLanguage',
    'selectedView',
    'ignoreDefault',
    'defaultViewWizardShown',
  ])
  const [vacancies, setVacancies] = useState({})
  const [handleDate, setHandleDate] = useState(handledate)
  const [resources, setResources] = useState(appconfig.resources)
  const [cachedDates, setCachedDates] = useState([])
  const [cacheLoading, setCacheLoading] = useState(false)
  const [loading, setLoading] = useState(true)
  const [loadStatus, setLoadStatus] = useState({
    resources: false,
    vacancies: false,
    freeindex: false,
    infobanners: false,
    textbanners: false,
  })
  const [filters, setFilters] = useState({})
  // const { width } = useWindowDimensions()

  const setLoad = (key, value) => {
    const status = { ...loadStatus, [key]: value }
    if (Object.values(status).every((x) => x === true)) {
      setLoading(false)
    }
    setLoadStatus({ ...loadStatus, [key]: value })
  }

  const isLoad = (key) => {
    return loadStatus[key]
  }

  if (isLoaded(infobanners) && !isLoad('infobanners')) {
    setLoad('infobanners', true)
  }
  if (isLoaded(textbanners) && !isLoad('textbanners')) {
    setLoad('textbanners', true)
  }
  if (isLoaded(freeindex) && !isLoad('freeindex')) {
    setLoad('freeindex', true)
  }
  if (resources && !isLoad('resources')) {
    setLoad('resources', true)
  }

  useEffect(() => {
    if (!appconfig.resources && !resources) {
      uiGet({ q: 'getResources', customerid: appconfig.accountkey }).then(
        (response) => {
          if (response.data.error) {
            // console.log(response.data)
          } else {
            setResources(response.data)
            dispatch({
              type: 'UPDATE_RESOURCES',
              payload: response.data,
            })
          }
        }
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (selectedday && selectedday !== handleDate) {
      setHandleDate(moment.utc(selectedday, 'YYYY-MM-DD').format('YYYYMMDD'))
    }
    if (
      freeindex &&
      freeindex[handleDate] &&
      (appconfig.resources || resources)
    ) {
      const { key } = freeindex[handleDate]
      if (cachedDates.includes(key)) return
      setCacheLoading(true)
      // console.log('fetching vacancies for ', handleDate, selectedday, key)
      fetch(key)
        .then((res) => res.json())
        .then((result) => {
          if (result && Object.keys(result).length > 0) {
            // console.log('result was ok')
            const _dateIndex = formatVacancies(
              result,
              handleDate,
              appconfig.resources || resources
            )
            // console.log('_dateIndex', _dateIndex)
            // console.log('updating vacancies for ', handleDate, selectedday)
            setVacancies({ ...vacancies, [handleDate]: _dateIndex })
            setCachedDates([...cachedDates, key])
          }
          setSelectedday(
            moment.utc(handleDate, 'YYYYMMDD').format('YYYY-MM-DD')
          )
          setCacheLoading(false)
          setLoad('vacancies', true)
          // console.log('vacancies', vacancies)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleDate, freeindex, appconfig, resources])

  if (cookies.selectedDay) {
    today = cookies.selectedDay
  }
  // if (cookies.selectedView && cookies.selectedView !== viewStyle && ignoreDefault) {
  //   setViewStyle(cookies.selectedView)
  // }

  // console.log('loadStatus', loadStatus)

  let defaultLanguage = 'FI'
  if (cookies.selectedLanguage) {
    defaultLanguage = cookies.selectedLanguage
  }
  // const [selectedInfoBanner, setSelectedInfoBanner] = useState()
  // // eslint-disable-next-line no-unused-vars
  // const [selectedTextBanner, setSelectedTextBanner] = useState()
  const [selectedday, setSelectedday] = useState(today)
  let category = appconfig?.categories?.[0]?.category || 'nocategory'
  if (cookies.selectedVenue) {
    category = cookies.selectedVenue
  }
  const [selectedCategory, setSelectedCategory] = useState(category)
  // const history = useHistory()

  useEffect(() => {
    if (defaultLanguage.toLowerCase() === 'se') {
      moment.locale('sv', { week: { dow: 3 } })
    } else if (defaultLanguage.toLowerCase() === 'ee') {
      moment.locale('et', { week: { dow: 3 } })
    } else {
      moment.locale(defaultLanguage.toLowerCase(), { week: { dow: 3 } })
    }
    // setSelectedInfoBanner(findInfoBanner(infobanners, today))
    // setSelectedTextBanner(findTextBanners(textbanners, today))
  }, [defaultLanguage, infobanners, textbanners, today])

  i18n.on('languageChanged', function (lng) {
    if (lng === 'se') {
      lng = 'sv'
    }
    if (lng === 'ee') {
      lng = 'et'
    }
    moment.locale(lng, { week: { dow: 3 } })
  })

  // const handleClick = (timeIndex) => {
  //   let v = true
  //   if (open[timeIndex]) v = false
  //   setOpen({ ...open, [timeIndex]: v })
  // }

  // const handleInfoBannerClick = (url, id) => {
  //   window.location = url
  // }

  const setView = (view, byuser) => {
    // setIgnoreDefault(true)
    setViewStyle(view)
    setCookie('selectedView', view, { path: '/', maxAge: 86400, secure: true })
    if (byuser && !cookies.ignoreDefault)
      setCookie('ignoreDefault', true, { path: '/', maxAge: 60, secure: true })
  }

  const setDayVenue = (day, venue) => {
    if (day) {
      setSelectedday(day)
      setHandleDate(moment.utc(day).format('YYYYMMDD'))
      setCookie('selectedDay', day, { path: '/', maxAge: 600, secure: true })
    }
    if (venue) {
      setSelectedCategory(venue)
      setCookie('selectedVenue', venue, {
        path: '/',
        maxAge: 600,
        secure: true,
      })
    }
  }

  const cancelDialog = () => {
    setOpenDefaultViewDialog(false)
    setCookie('defaultViewWizardShown', true, {
      path: '/',
      maxAge: 60,
      secure: true,
    })
  }

  const updateDefaultView = (view) => {
    firebase.updateProfile({ defaultview: view })
  }

  if (history.location.search.indexOf('?_c=') > -1) {
    const predefinedCategory = new URLSearchParams(history.location.search).get(
      '_c'
    )
    if (
      (selectedCategory === 'nocategory' && predefinedCategory) ||
      (!cookies.selectedVenue && predefinedCategory)
    ) {
      setSelectedCategory(predefinedCategory)
      setCookie('selectedVenue', predefinedCategory, {
        path: '/',
        maxAge: 600,
        secure: true,
      })
      history.push(history.location.pathname)
    }
  }

  // const selectReservation = (slot, d) => {
  //   slot.selectedduration = d
  //   dispatch({
  //     type: 'UPDATE_RESERVATION',
  //     payload: slot,
  //   })
  //   history.push(appconfig.pathPrefix + '/reservation')
  // }

  // let todayTitle = moment.utc(selectedday).format('D.M.')
  let _todayWeekday = moment.utc(selectedday).format('dddd')
  const todayWeekday =
    _todayWeekday.charAt(0).toUpperCase() + _todayWeekday.slice(1)
  // todayTitle += ' ' + todayWeekday

  const moveDate = (days) => {
    let date
    if (days > 0) {
      date = moment.utc(selectedday).add(days, 'days')
    } else if (days < 0) {
      date = moment.utc(selectedday).subtract(-days, 'days')
    } else {
      date = moment.utc()
    }
    if (
      date.isValid() &&
      date.diff(moment.utc(), 'days') <
        ((appconfig && appconfig.daysahead) || 13) &&
      date.diff(moment.utc(), 'days') > -1
    ) {
      setSelectedday(date.format('YYYY-MM-DD'))
      setHandleDate(date.format('YYYYMMDD'))
      // setSelectedInfoBanner(findInfoBanner(infobanners, date.format('YYYY-MM-DD')))
      // setSelectedTextBanner(findTextBanners(textbanners, date.format('YYYY-MM-DD')))
      setCookie('selectedDay', date.format('YYYY-MM-DD'), {
        path: '/',
        maxAge: 600,
        secure: true,
      })
    }
  }

  let selectedFreeTimes = (
    <Card key={'notime'} className='text-center border-0'>
      <CardBody>{cacheLoading ? <Spinner /> : t('no_vacancy')}</CardBody>
    </Card>
  )

  if (!loading) {
    // console.log('loading', loading)
    // let _o = {}
    // let dailyTimes = freetimes[moment.utc(selectedday).format('YYYYMMDD')]
    let dailyTimes = vacancies[moment.utc(selectedday).format('YYYYMMDD')]
    let currentTime = null
    if (selectedday && servertime) {
      if (servertime.format('YYYY-MM-DD') === selectedday) {
        currentTime = servertime.format('HH:00')
      }
    }
    if (dailyTimes && dailyTimes.length > 0) {
      let dailyFreeTimes = vacancies[
        moment.utc(selectedday).format('YYYYMMDD')
      ].map((a) => {
        return { ...a }
      }) //freetimes[moment.utc(selectedday).format('YYYYMMDD')]
      if (filters && Object.keys(filters).length > 0) {
        // console.log('filters', filters)
        // console.log('dailyFreeTimes', dailyFreeTimes)
        dailyFreeTimes = filterVacancies(dailyFreeTimes, filters)
      }
      // console.log('dailyFreeTimes', dailyFreeTimes)
      // console.log('vacancies', vacancies)
      // console.log('dailyNewTimes', freetimes[moment.utc(selectedday).format('YYYYMMDD')])
      if (viewStyle === 'vertical') {
        selectedFreeTimes = (
          <VerticalView
            dailyFreeTimes={dailyFreeTimes}
            servertime={currentTime}
            selectedvenue={selectedCategory}
          />
        )
      } else if (viewStyle === 'horizontal') {
        selectedFreeTimes = (
          <HorizontalView
            dailyFreeTimes={dailyFreeTimes}
            servertime={currentTime}
            selectedvenue={selectedCategory}
          />
        )
      } else {
        selectedFreeTimes = (
          <SimpleView
            dailyFreeTimes={dailyFreeTimes}
            selectedvenue={selectedCategory}
            weather={null}
            viewEmptyTimes={false}
            showWeek={false}
          />
        )
      }
    }
  }
  const widebuttons = false //selectedInfoBanner ? false : true

  // Wizards
  if (isLoaded(auth) && !isEmpty(auth) && isLoaded(profile)) {
    // console.log('Authenticated and profile loaded')
    if (!profile.defaultview) {
      // console.log('Ei default viewtä määritelty')
      if (!cookies.defaultViewWizardShown && !openDefaultViewDialog) {
        // console.log('Ei defaultViewWizardShown keksiä')
        setOpenDefaultViewDialog(true)
      }
    } else if (viewStyle !== profile.defaultview) {
      // console.log('default view määritelty, mutta ollaan eri näkymässä')
      // exept if selected by hand
      if (!cookies.ignoreDefault) {
        // console.log('Ei ole vaihdettu itse näkymää joten hypätään defaulttiin')
        setView(profile.defaultview)
      }
    }
  } else if (isLoaded(auth) && isEmpty(auth)) {
    // console.log('Ei ole autentikoiduttu')
    if (!cookies.defaultViewWizardShown && !openDefaultViewDialog) {
      // console.log('Ei ole näytetty infowizardia')
      // setOpenDefaultViewDialog(true)
    }
  }

  if (appconfig.closed && !isTest) {
    return (
      <div className='full-page-background'>
        <TopNavigation />
        <Container className='front' fluid={false}>
          <Row className='home-header'>
            <Col xs={'12'} className='py-2 text-center mt-3'>
              <Typography variant='body1'>{appconfig.closedmessage}</Typography>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }

  // let showTextBanner = false
  // if (selectedTextBanner) {
  //   if (!selectedTextBanner.hascats) {
  //     showTextBanner = true
  //   }
  //   if (selectedCategory && selectedTextBanner.hascats && selectedTextBanner.cats.includes(selectedCategory)) {
  //     showTextBanner = true
  //   }
  //   if (!selectedCategory) {
  //     showTextBanner = true
  //   }
  //   if (!selectedTextBanner.content) {
  //     showTextBanner = false
  //   } else if(selectedTextBanner.content.trim().length === 0) {
  //     showTextBanner = false
  //   }
  // }

  if (appconfig.isOnlyMembers && !isMember) {
    return (
      <div className='full-page-background'>
        <TopNavigation />
        <Container className='front' fluid={false}>
          <Row className='home-header'>
            <Col xs={'12'} className='py-2 text-center mt-3'>
              <Typography variant='body1'>{t('only_members')}</Typography>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }

  // console.log('widebuttons', widebuttons, 'selectedInfoBanner', selectedInfoBanner)
  return (
    <div className='full-page-background'>
      <TopNavigation />
      <Container className='front' fluid={false}>
        <TabNavigation />
        <Row className='home-header'>
          <Col xs={'12'} sm={'12'} md={'6'} lg={'4'} xl={'4'} className='py-2'>
            <TextField
              id='date'
              label={todayWeekday}
              type='date'
              value={selectedday}
              className=''
              style={{ marginRight: 10 }}
              onChange={(e) => setDayVenue(e.target.value, null)}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{ inputProps: { min: minDate, max: maxDate } }}
            />
            <IconButton
              aria-label='back'
              onClick={() => moveDate(-1)}
              size='small'
            >
              <KeyboardArrowLeftIcon style={datenavidation} />
            </IconButton>
            <IconButton
              aria-label='next'
              onClick={() => moveDate(1)}
              size='small'
            >
              <KeyboardArrowRightIcon style={datenavidation} />
            </IconButton>
          </Col>
          {appconfig.categories.length > 0 ? (
            <Col
              xs={'12'}
              sm={'12'}
              md={'6'}
              lg={widebuttons ? '8' : '4'}
              xl={widebuttons ? '8' : '4'}
              className='py-2'
            >
              <ButtonGroup size={'sm'} className='flex-wrap'>
                {appconfig.categories.map((c) => {
                  // if (selectedvenue === c.category) {
                  const iconStroke =
                    selectedCategory === c.category ? '#fff' : '#007bff'
                  return (
                    <Button
                      size={'sm'}
                      key={c.category}
                      color='primary'
                      onClick={() => setDayVenue(null, c.category)}
                      active={selectedCategory === c.category}
                      outline
                      onMouseOver={(e) => {
                        e.currentTarget.style.stroke = '#efefef'
                      }}
                      onMouseOut={(e) => {
                        e.currentTarget.style.stroke = iconStroke
                      }}
                    style={{
                        stroke: iconStroke
                      }}
                    >
                      <SportIcon
                        style={{ marginRight: 5, marginBottom: 2 }}
                        sport={c.label[APPINITLANG].toLowerCase()}
                        fontSize={'small'}
                        // onMouseOver={(e) => {
                        //   e.currentTarget.style.stroke = '#efefef'
                        // }}
                        // onMouseOut={(e) => {
                        //   e.currentTarget.style.stroke = iconStroke
                        // }}
                      />
                      {c.label[APPINITLANG]}
                    </Button>
                  )
                })}
              </ButtonGroup>
            </Col>
          ) : null}
          {/* {selectedInfoBanner ? (
            <Col xs={'12'} sm={'12'} md={'4'} lg={'4'} xl={'4'} className='py-2' style={{ cursor: 'pointer' }}>
              <img
                src={selectedInfoBanner.creativeurl}
                alt={selectedInfoBanner.title}
                onClick={() => handleInfoBannerClick(selectedInfoBanner.clickurl, selectedInfoBanner.id)}
                width='80%'
                className='mx-auto d-block'
                style={{ maxWidth: '370px', margin: 'auto' }}
              />
            </Col>
          ) : null} */}
          <Col
            xs={'12'}
            sm={'12'}
            md={'4'}
            lg={'4'}
            xl={'4'}
            className='py-2'
            style={{ cursor: 'pointer' }}
          >
            <BannerPlacement
              banners={infobanners}
              date={selectedday}
              selectedCategory={selectedCategory}
            />
          </Col>

          <Col
            xs={'12'}
            sm={'12'}
            md={'6'}
            lg={'6'}
            xl={'6'}
            className='py-2 text-center  mr-auto  ml-auto'
          >
            <TextBannerPlacement
              banners={textbanners}
              date={selectedday}
              selectedCategory={selectedCategory}
            />
          </Col>
        </Row>
        <Row>
          <Col
            xs={'12'}
            sm={'12'}
            md={'12'}
            lg={'12'}
            xl={'12'}
            className='py-2 text-center'
          >
            <NextReservations />
          </Col>
        </Row>
        <Row style={{ marginTop: 20, marginBottom: 3 }}>
          <Col className='text-center'>
            <Chip
              style={
                viewStyle === 'simple'
                  ? { backgroundColor: '#007bff', color: 'white' }
                  : {}
              }
              icon={
                <CalendarViewDayIcon
                  style={
                    viewStyle === 'simple'
                      ? { color: 'rgba(255,255,255,1)' }
                      : { color: 'rgba(0,0,0,0.54)' }
                  }
                />
              }
              variant={viewStyle === 'simple' ? 'default' : 'outlined'}
              label={t('quickselection')}
              size='small'
              onClick={() => setView('simple', true)}
            />{' '}
            <Chip
              style={
                viewStyle === 'horizontal'
                  ? { backgroundColor: '#007bff', color: 'white' }
                  : {}
              }
              icon={
                <ViewListIcon
                  style={
                    viewStyle === 'horizontal'
                      ? { color: 'rgba(255,255,255,1)' }
                      : { color: 'rgba(0,0,0,0.54)' }
                  }
                />
              }
              variant={viewStyle === 'horizontal' ? 'default' : 'outlined'}
              label={t('horizontalcalendar')}
              size='small'
              onClick={() => setView('horizontal', true)}
            />{' '}
            <Chip
              style={
                viewStyle === 'vertical'
                  ? { backgroundColor: '#007bff', color: 'white' }
                  : {}
              }
              icon={
                <ViewColumnIcon
                  style={
                    viewStyle === 'vertical'
                      ? { color: 'rgba(255,255,255,1)' }
                      : { color: 'rgba(0,0,0,0.54)' }
                  }
                />
              }
              variant={viewStyle === 'vertical' ? 'default' : 'outlined'}
              label={t('verticalcalendar')}
              size='small'
              onClick={() => setView('vertical', true)}
            />
            {/* {t('views')}{' '}
            <CalendarViewDayIcon
              style={viewStyle === 'simple' ? { color: 'rgba(0,0,0,1)' } : { color : 'rgba(0,0,0,0.54)'}}
              onClick={() => setView('simple', true)}
            />{' '}
            <ViewListIcon
              style={viewStyle === 'horizontal' ? { color: 'rgba(0,0,0,1)' } : { color : 'rgba(0,0,0,0.54)'}}
              onClick={() => setView('horizontal', true)}
            />{' '}
            <ViewColumnIcon
              style={viewStyle === 'vertical' ? { color: 'rgba(0,0,0,1)' } : { color : 'rgba(0,0,0,0.54)'}}
              onClick={() => setView('vertical', true)}
            /> */}
          </Col>
        </Row>
        <Row style={{ marginTop: 0, marginBottom: 3 }}>
          <Col xs={12} sm={12} className={'mb-1'}>
            <Typography variant='body2'>
              {appconfig.categories.find((c) => selectedCategory === c.category)
                ? appconfig.categories.find(
                    (c) => selectedCategory === c.category
                  ).label[APPINITLANG]
                : null}
            </Typography>
            {/* {viewStyle === 'simple' ? (
              <EmtpyslotsButton selected={filters.emptyslots} onClick={() => setFilters({ ...filters, emptyslots: !filters.emptyslots })} />
            ) : null} */}
            {appconfig.zenniz ? (
              <ZennizButton
                selected={filters.zenniz}
                onClick={() =>
                  setFilters({ ...filters, zenniz: !filters.zenniz })
                }
              />
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col md='12' className='mx-auto'>
            <Divider key={'divider' + Math.random().toString()} />
            {!loading && viewStyle === 'simple' ? (
              <List
                component='nav'
                aria-labelledby='nested-list-subheader'
                style={{ marginBottom: 50 }}
              >
                {selectedFreeTimes}
              </List>
            ) : !loading ? (
              // <div style={{ overflowX: 'auto' }}>{selectedFreeTimes}</div>
              <>{selectedFreeTimes}</>
            ) : (
              <Card>
                <CardBody className='text-center border-0'>
                  <Spinner /> Loading ...
                </CardBody>
              </Card>
            )}
          </Col>
        </Row>
      </Container>
      <SelectDefaultViewDialog
        setOpenDialog={setOpenDefaultViewDialog}
        cancelDialog={cancelDialog}
        openDefaultViewDialog={openDefaultViewDialog}
        updateDefaultView={updateDefaultView}
      />
    </div>
  )
}

export default withAppconfig(withFreeTimes(enhance(HomeScreenCalendar)))
