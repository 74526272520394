import React, { useEffect, useState, Suspense, lazy } from 'react'
import { useTranslation } from 'react-i18next'
import { useFirebase } from 'react-redux-firebase'
import { /*useHistory,*/ useParams } from 'react-router-dom'
import withAppconfig from '../../AppContext'
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  // CardTitle,
  CardHeader,
  CardText,
  Spinner,
  // Modal,
  // ModalBody,
  Button,
  // Label,
} from 'reactstrap'
import {
  MenuItem,
  Select,
  TextField,
  InputLabel,
  // Dialog,
  // CardMedia,
} from '@material-ui/core'
import TopNavigation from '../../TopNavigation'
import LottieLoading from '../../components/LottieLoading'
// import CustomWebcam from './CustomWebcam'
// import ImagePond from './ImagePond'
const ImageLoader = lazy(() => import('./ImageLoader'))

function ThankYouNotice() {
  const { t } = useTranslation()
  return (
    <div>
      <h2>{t('thank_you')}</h2>
      <p>{t('thank_you_text')}</p>
    </div>
  )
}

function ReportMaintenance({ isLoggedIn, appconfig, auth }) {
  const { t } = useTranslation()
  const firebase = useFirebase()
  firebase.functions().region = 'europe-west1'
  // const history = useHistory()
  const { targetid } = useParams()
  const [message, setMessage] = useState('')
  const [targets, setTargets] = useState(null)
  const [target, setTarget] = useState('')
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(true)
  const [image, setImage] = useState(null)
  const [thankyou, setThankyou] = useState(false)
  const [saving, setSaving] = useState(false)
  // const [cameraOpen, setCameraOpen] = useState(false)
  // const [uploadOpen, setUploadOpen] = useState(false)

  useEffect(() => {
    const fn = appconfig.istest ? 'testui-get' : 'ui-get'
    const _getTargets = firebase.functions().httpsCallable(fn)
    firebase.functions().httpsCallable(fn)
    _getTargets({
      q: 'getReportTargets',
      customerid: appconfig.accountkey,
    })
      .then((response) => {
        if (response.data.error) {
          setLoading(false)
          console.error(response.data.message)
        } else {
          console.log(response.data)
          if (response.data) {
            setTargets(
              response.data
                .map((t) => (t.visibility ? t : null))
                .filter((t) => t)
            )
          }
          if (targetid !== '1') {
            const _t = response.data.find(
              (t) => t.name === targetid && t.visibility
            )
            setTarget(_t.name)
          }
          setLoading(false)
        }
      })
      .catch((error) => {
        console.error(error)
        setLoading(false)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetid])

  const sendReport = async () => {
    setSaving(true)
    const report = {
      target: target,
      message: message,
      email: email,
      image: image,
    }
    console.log(report)
    const fn = appconfig.istest ? 'testui-create' : 'ui-create'
    const _sendReport = firebase.functions().httpsCallable(fn)
    const data = {
      q: 'reportmaintenance',
      report,
      customerid: appconfig.accountkey,
    }
    const response = await _sendReport(data)
    if (response.data.error) {
      alert(response.data.message)
    } else {
      setSaving(false)
      setThankyou(true)
    }
  }

  if (isLoggedIn && auth && !email) {
    setEmail(auth.email)
  }

  if (thankyou) {
    return (
      <div className='full-page-background'>
        <TopNavigation />
        <Container className='login-dialog'>
          <ThankYouNotice />
        </Container>
      </div>
    )
  }

  return (
    <div className='full-page-background'>
      <TopNavigation />
      <Container className='login-dialog'>
        <Row>
          <Col md='8' className='mx-auto'>
            <Card>
              <CardHeader tag='h4'>{t('report_problem')}</CardHeader>
              <CardBody>
                {loading ? (
                  <Row>
                    <Col className='d-flex justify-content-center' xs={12}>
                      <LottieLoading width={150} height={150} />
                    </Col>
                  </Row>
                ) : (
                  <React.Fragment>
                    <CardText>
                      <InputLabel id='demo-simple-select-label'>
                        {t('report_problem_target')}
                      </InputLabel>
                      {targets ? (
                        <Select
                          labelId='demo-simple-select-label'
                          value={target ? target : ''}
                          onChange={(e) => setTarget(e.target.value)}
                          fullWidth
                        >
                          {/* <MenuItem key={'0'} value={t('select_target')}>
                            {t('report_problem_target')}
                          </MenuItem> */}
                          {targets.map((t) => (
                            <MenuItem key={t.name} value={t.name}>
                              {t.name}
                            </MenuItem>
                          ))}
                        </Select>
                      ) : null}
                    </CardText>
                    <CardText>
                      <TextField
                        id='outlined-basic'
                        label={t('report_problem_text')}
                        variant='standard'
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        fullWidth
                        multiline
                        minRows={10}
                      />
                    </CardText>
                    <CardText>
                      <TextField
                        disabled={isLoggedIn && auth}
                        id='outlined-basic'
                        label={t('email')}
                        variant='standard'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        fullWidth
                      />
                    </CardText>
                    <CardText>
                      <Suspense fallback={<Spinner />}>
                        <ImageLoader setImage={setImage} />
                      </Suspense>
                      {/* {!cameraOpen && !uploadOpen ? (
                    <>
                      <Button onClick={() => setCameraOpen(true)}>
                        {t('take_picture')}
                      </Button>{' '}
                      <Button onClick={() => setUploadOpen(true)}>
                        {t('upload_image')}
                      </Button>
                    </>
                  ) : null}
                  {uploadOpen ? <ImagePond onUpload={setImage} onReady={() => setUploadOpen(false)}/> : null} */}
                    </CardText>
                    <CardText>
                      {image ? (
                        <img
                          src={image}
                          alt='webcam'
                          style={{
                            margin: 20,
                            height: 400,
                            width: '100%',
                            objectFit: 'contain',
                          }}
                        />
                      ) : null}
                    </CardText>
                    <CardText>
                      <Button
                        disabled={
                          loading ||
                          !message ||
                          !target ||
                          (!isLoggedIn && !email)
                        }
                        onClick={sendReport}
                      >{saving ? <Spinner size={'sm'} /> : t('send_report')}</Button>
                    </CardText>
                  </React.Fragment>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
        {/* <Dialog open={cameraOpen} fullScreen>
          <CustomWebcam onCapture={setImage} />
          <Button onClick={() => setCameraOpen(false)}>{t('close')}</Button>
        </Dialog> */}
      </Container>
    </div>
  )
}

export default withAppconfig(ReportMaintenance)
