import React from 'react'
import { formatSlot } from './ViewUtils'
import { Modal, ModalHeader, ModalBody, Button, Badge, ModalFooter } from 'reactstrap'
import moment from 'moment'
import 'moment/locale/en-gb'
import 'moment/locale/et'
import 'moment/locale/sv'
import 'moment/locale/fi'

export default function DurationPopover({ isOpen, toggle, slot, select }) {
  const handleSelect = (duration) => {
    const _slot = formatSlot(slot, duration)
    select(_slot, duration)
  }

  return (
    <Modal size='sm' isOpen={isOpen} toggle={toggle} backdrop={true}>
      <ModalHeader toggle={toggle}>Valitse kesto</ModalHeader>
      <ModalBody style={{ textAlign: 'center' }}>
        {slot ? (
          <p className='h6'>
            {slot.displayName} - {moment.utc(slot.start).format('dddd D.M. H:mm')}
          </p>
        ) : null}
        {slot
          ? slot.times.map((d) => {
              const h = Math.floor(d.duration / 60)
              const m = d.duration % 60
              let timeTitle = (h > 0 ? h + ' h ' : '') + (m > 0 ? m + ' min ' : '')
              return (
                <Button
                  block
                  style={{ marginBottom: 5 }}
                  color='primary'
                  onClick={(e) => handleSelect(d.duration)}
                  key={d.key + '-bttn-' + Math.random().toString()}
                >
                  <div style={{ display: 'flex' }}>
                    <div style={{ flex: 1, textAlign: 'left' }}>{timeTitle}</div>
                    <div style={{ flex: 1, textAlign: 'right' }}>
                      <Badge color='success' pill>
                        {d.price + ' €'}
                      </Badge>
                    </div>
                  </div>
                </Button>
              )
            })
          : null}
      </ModalBody>
      {slot && slot.sponsor ? (
      <ModalFooter style={{ justifyContent: 'center'}}>
        <img src={slot.sponsor} alt='courtsponsor' style={{ maxWidth: 290 }} />
        </ModalFooter>
      ) : null}
    </Modal>
  )
}
