import React, { useState } from 'react'
import WhatsAppIcon from '@material-ui/icons/WhatsApp'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import { withStyles } from '@material-ui/core/styles'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import TextsmsIcon from '@material-ui/icons/Textsms'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import { green } from '@material-ui/core/colors'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import Chip from '@material-ui/core/Chip'
// import { TextField } from '@material-ui/core'
import InputAdornment from '@material-ui/core/InputAdornment'
import Input from '@material-ui/core/Input'
// import InputLabel from '@mui/material/InputLabel'
import FormControl from '@material-ui/core//FormControl'
import Fade from '@material-ui/core//Fade'
import { isIOS, isAndroid } from 'react-device-detect'
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
})

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h6'>{children}</Typography>
      {onClose ? (
        <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

function ShareDialog({ open, link, name, onClose }) {
  const [copied, setCopied] = useState(false)

  const copyClicked = () => {
    setCopied(true)
    setTimeout(() => {
      setCopied(false)
    }, '6000')
  }

  const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
  }

  const smsPrefix = 'sms:///' + (isAndroid ? '?' : '&') + 'body='

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby='customized-dialog-title'
      aria-describedby='alert-dialog-description'
      maxWidth={'xs'}
      fullWidth={true}
    >
      <DialogTitle id='customized-dialog-title' onClose={onClose}>
        {'Jaa'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          <IconButton
            variant='contained'
            style={{ backgroundColor: green[500], margin: 3 }}
            rounded
            onClick={() => openInNewTab('https://wa.me/?text=' + encodeURIComponent(link))}
          >
            <WhatsAppIcon style={{ color: '#FFFFFF' }} fontSize={'large'} />
          </IconButton>
          <IconButton variant='contained' style={{ backgroundColor: '#0000FF', margin: 3 }} rounded onClick={() => openInNewTab('mailto:?subject=' + encodeURIComponent(name) + '&body=' + encodeURIComponent(link))}>
            <MailOutlineIcon style={{ color: '#FFFFFF' }} fontSize={'large'} />
          </IconButton>
          {isAndroid || isIOS ?
            <IconButton variant='contained' style={{ backgroundColor: 'yellow', margin: 3 }} rounded onClick={() => openInNewTab(smsPrefix + encodeURIComponent(name) + ' ' + encodeURIComponent(link))}>
              <TextsmsIcon style={{ color: '#000000' }} fontSize={'large'} />
            </IconButton>
            : null}
        </DialogContentText>
        <DialogContentText id='alert-dialog-description-link'>
          <FormControl fullWidth variant='standard'>
            <Input
              id='standard-adornment'
              type={'text'}
              value={link}
              disabled
              endAdornment={
                <InputAdornment position='end'>
                  <CopyToClipboard text={link ? link : ''} onCopy={copyClicked}>
                    <Button>{'Kopioi'}</Button>
                  </CopyToClipboard>
                </InputAdornment>
              }
            />
          </FormControl>
          <br />
          <br />
          {copied ? (
            <Fade in={copied} timeout={1500}>
              <Chip color={'disabled'} label={'Kopioitu'} />
            </Fade>
          ) : null}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  )
}

export default ShareDialog
