import moment from 'moment'

export const getLastvalidDate = (validuntil, now) => {
  console.log('getLastvalidDate', validuntil, now)
  if (!validuntil) return moment.utc(now).add(1, 'days').endOf('day')
  let lastvalidmoment = null
  switch (validuntil.type) {
    case 'next':
      const { endof, daysahead } = validuntil
      lastvalidmoment = moment.utc(now).add(daysahead, 'days').endOf(endof)
      break
    case 'fixed':
      const { lastvalid } = validuntil
      lastvalidmoment = moment.utc(lastvalid, 'YYYY-MM-DD')
      break
    case 'rolling':
      const { unit, amount } = validuntil
      lastvalidmoment = moment.utc(now).add(amount, unit)
      break
    case 'permanent':
      lastvalidmoment = 'permanent'
      break
    default:
      break
  }
  console.log('lastvalidmoment', lastvalidmoment)
  return lastvalidmoment
}
