import React from 'react'
import { connect } from 'react-redux'
import withAppconfig from '../AppContext.js'
import {
  Container,
  Typography,
  CircularProgress,
  Grid,
  Link,
  Box,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'
import moment from 'moment'
import 'moment/locale/en-gb'
import 'moment/locale/sv'
import 'moment/locale/fi'
import 'moment/locale/et'
// import { t } from 'i18next'
import { useHistory } from 'react-router-dom'
import { hasResource, getStatus } from './ReservationUtil'
import ReservationSummary from './ReservationSummary.js'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const mapStateToProps = (state, props) => {
  const {
    firestore,
    firebase: { profile, auth },
  } = state

  if (!auth.uid) return { reservations: undefined }
  const { appconfig } = props
  if (firestore.data['ownReservations']) {
    let accesscode = profile.accesscode
      ? profile.accesscode[appconfig.accountkey]
      : null
    if (accesscode && appconfig.accesscodeextra)
      accesscode += appconfig.accesscodeextra

    let _m = Object.keys(firestore.data['ownReservations'])
      .map((key) => {
        return {
          ...firestore.data['ownReservations'][key],
          id: key,
          selectkey: key,
        }
      })
      .filter(
        (r) =>
          hasResource(r) &&
          r.locationid === appconfig.accountkey &&
          r.state !== 'user_cancelled'
      )
      .map((r) => {
        if (r.isrecurring) {
          return r.recurrencyDates
            .map((rec) => {
              if (rec.state === 'moved') return null
              let _r = JSON.parse(JSON.stringify(r))
              let _sKey = _r.selectkey + '-'
              if (typeof rec === 'object') {
                _r.occurency = {
                  date: rec.date,
                  state: rec.state,
                  isstored: rec.isstored,
                  refundinvoicing: rec.refundinvoicing,
                  salestatus: rec.salestatus,
                }
                _sKey += rec.date
              } else {
                _r.occurency = { date: rec }
                _sKey += rec
              }
              _r.selectkey = _sKey // _r.selectkey + '-' + rec
              const timeOfDayHelper = moment.utc(_r.resourcegroups[0].end)
              const ordering = moment.utc(_r.occurency.date)
              ordering
                .hour(timeOfDayHelper.hour())
                .minute(timeOfDayHelper.minute())
              if (moment().utcOffset() > 0) {
                ordering.subtract(moment().utcOffset(), 'minute')
              } else {
                ordering.add(moment().utcOffset(), 'minute')
              }
              _r.ordering = ordering.unix()
              return _r
            })
            .filter((x) => x)
        } else {
          const ordering = moment.utc(r.resourcegroups[0].end)
          if (moment().utcOffset() > 0) {
            ordering.subtract(moment().utcOffset(), 'minute')
          } else {
            ordering.add(moment().utcOffset(), 'minute')
          }
          r.ordering = ordering.unix()
          r.occurency = {
            date: moment.utc(r.resourcegroups[0].start).format('YYYY-MM-DD'),
          }
          return r
        }
      })
      .flat(3)
      .filter((r) => {
        if (appconfig.nextseasonhide) {
          if (moment(r.occurency.date).isAfter(appconfig.nextseasonhide)) {
            return false
          } else {
            return true
          }
        }
        return true
      })
      .map((r) => {
        r.vs = getStatus(r)
        return r
      })

    _m.sort(function (a, b) {
      if (a.ordering < b.ordering) {
        return -1
      } else if (a.ordering > b.ordering) {
        return 1
      } else {
        return 0
      }
    })

    // let _onsale = _m.filter((x) => x.vs.isOnSale && !x.vs.isCancelled)
    let _upcoming = _m.filter((x) => x.vs.isUpcoming && !x.vs.isCancelled)

    return {
      reservations: _upcoming.slice(0, 2),
      reservationslength: _upcoming.length,
      // pastreservations: _past,
      // onsale: _onsale,
      accesscode,
    }
  } else {
    return { reservations: undefined, reservationslength: 0 }
  }
}

const withReservations = compose(
  connect((state) => ({ auth: state.firebase.auth })),
  firestoreConnect((props) => [
    {
      collection: 'users',
      doc: `${props.auth.uid}`,
      subcollections: [{ collection: 'reservations' }],
      where: [['validtime', '>', moment.utc().unix()]],
      storeAs: 'ownReservations',
    },
  ]),
  connect(mapStateToProps)
)

function NextReservations({ reservations, accesscode, appconfig, reservationslength, dispatch }) {
  const { t } = useTranslation()
  const [loading, setLoading] = React.useState(true)
  const [reservationsList, setReservationsList] = React.useState([])
  const history = useHistory()
  const isSm = useMediaQuery('(max-width:600px)')

  React.useEffect(() => {
    if (reservations) {
      setReservationsList(reservations)
      setLoading(false)
    }
  }, [reservations])

  const splitPayment = (reservation) => {
    dispatch({
      type: 'UPDATE_SPLITTABLE_RESERVATION',
      payload: reservation,
    })
    history.push(appconfig.pathPrefix + '/splitpayment')
  }

  if (!reservations || reservations.length === 0) return null
  return (
    <Container>
      <Typography variant='h6'>{t('next_reservations')}</Typography>
      {loading ? (
        <CircularProgress />
      ) : (
        <Grid container spacing={isSm ? 0 : 1}>
          {reservationsList.map((r, i) => (
            <Grid
              item
              xs={12}
              md={reservations.length === 1 ? 12 : 6}
              key={r.id + '---' + i}
            >
              <ReservationSummary key={r.id} r={r} accesscode={accesscode} onSplitpayment={splitPayment} />
            </Grid>
          ))}
          {reservationslength > 2 && (
            <Grid item xs={12}>
              <Box display='flex' justifyContent='flex-end'>
                <Link
                  component='button'
                  variant='body2'
                  onClick={() =>
                    history.push(appconfig.pathPrefix + '/reservations')
                  }
                >
                  {t('view_all')}
                </Link>
              </Box>
            </Grid>
          )}
        </Grid>
      )}
    </Container>
  )
}

export default withAppconfig(withReservations(NextReservations))
