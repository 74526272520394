import React, { useState } from 'react'
import { useFirebase } from 'react-redux-firebase'
import { Row, Col, Button, Spinner } from 'reactstrap'
import { Input, InputAdornment, InputLabel } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import withAppconfig from '../AppContext'
import BalanceSelector from './BalanceSelector'

const quickselections = [25, 50, 100, 200]

function AddSmartumAsset({ appconfig, wallets }) {
  const firebase = useFirebase()
   const { t } = useTranslation()
  const [assetPrice, setAssetPrice] = useState(0)
  const [creatingCode, setCreatingCode] = useState(false)
  const [balance, setBalance] = useState(null)
  const [validated, setValidated] = useState(appconfig.balances && appconfig.balances.length > 1 ? false : true)

  const setVenueBalance = (venue) => {
    setBalance(venue)
    setValidated(true)
  }

  const createAssetAdd = () => {
    const paidsum = Math.floor(parseFloat(assetPrice) * 100)
    const venue = balance ? balance : 'default'
    const asset = {
      paidsum,
      cid: appconfig.accountkey,
      venue,
      provider: 'smartum',
    }
    setCreatingCode(true)
    firebase.functions().region = 'europe-west1'
    const fn = appconfig.istest ? 'testassetpayment-payment' : 'assetpayment-payment'
    let createPayment = firebase.functions().httpsCallable(fn)
    createPayment(asset).then((response) => {
      if (response.data.error) {
        alert(response.data.message)
      } else {
        window.location.href = response.data.data.url
      }
    })
  }

  const setAmount = (e) => {
    setAssetPrice(e.target.value.replace(/^0+/, ''))
  }

  return (
    <React.Fragment>
            <Row className='text-center mb-4'>
        <Col>{t('deposit')}</Col>
      </Row>
      <Row className='text-center mb-4'>
        <Col>{t('quickselections')}</Col>
      </Row>
      <Row className='text-center mb-4'>
        <Col>
          {quickselections.map((q) => (
            <Button key={q} outlined color='primary' onClick={() => setAssetPrice(q)} style={{ marginRight: 15 }}>
              {q} €
            </Button>
          ))}
        </Col>
      </Row>
      <Row className='text-center'>
        <Col lg={12} sm={12} style={{ marginBottom: 20 }}>
          <InputLabel htmlFor='asset-amount'>{t('set_amount')}</InputLabel>
          <Input
            id='asset-amount'
            label={t('set_amount')}
            value={assetPrice}
            onChange={setAmount}
            type='number'
            endAdornment={<InputAdornment position='end'>€</InputAdornment>}
          />
        </Col>
        {appconfig.balances && appconfig.balances.length > 1 ? (
          <Col lg={12} sm={12} style={{ marginBottom: 20 }}>
            <BalanceSelector balances={appconfig.balances} setVenue={setVenueBalance} balance={balance} />
          </Col>
        ) : null}
        <Col lg={12} sm={12}>
          <Button
            style={{ color: 'black', backgroundColor: 'white', borderColor: 'black' }}
            // color={'success'}
            disabled={!assetPrice || !validated}
            onClick={createAssetAdd}
          >
            {creatingCode ? <Spinner /> : t('pay') + ' '} {assetPrice} €{' '}
            <img src={process.env.PUBLIC_URL + '/imgs/smartum_logo.svg'} alt='smartum' style={{ height: 20 }} />
          </Button>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default withAppconfig(AddSmartumAsset)
