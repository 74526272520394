import {
  Grid,
  Box,
  Typography,
  Divider,
  Table,
  TableRow,
  TableContainer,
  TableHead,
  TableBody,
  Button,
  Chip,
} from '@material-ui/core'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'
import React from 'react'
import MuiTableCell from '@material-ui/core/TableCell'
import { withStyles } from '@material-ui/core/styles'
import DoneIcon from '@material-ui/icons/Done'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import { useTranslation } from 'react-i18next'

const TableCell = withStyles({
  root: {
    // borderBottom: "none",
    fontSize: '0.75rem',
    paddingBottom: 3,
    paddingTop: 3,
  },
})(MuiTableCell)

const BlueButton = withStyles({
  root: {
    color: '#fff',
    backgroundColor: '#007bff',
    '&:hover': {
      backgroundColor: '#007bff',
    },
  },
})(Button)

function toCurrency(num) {
  return (num / 100).toFixed(2).replace('.', ',') + ' €'
}

function formatDate(dateString) {
  if (!dateString) return ''
  const date = new Date(dateString)
  return date.toLocaleDateString('fi-FI', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  })
}

// function ContactDetails(props) {
//   const {
//     name,
//     email,
//     phone,
//     address,
//     businessId,
//     contacts,
//     firstname,
//     lastname,
//     billingemail,
//     billingOvt,
//     billingOperator,
//     city,
//     zipcode,
//   } = props.contact
//   let _name = name ? name : firstname + ' ' + lastname

//   // billingemail, infoemail, contactemail
//   let _email = email
//   let _contactname = null
//   if (businessId) {
//     if (billingemail) {
//       _email = billingemail
//     } else if (contacts && contacts.length > 0) {
//       _email = contacts[0].email
//     }
//     if (contacts && contacts.length > 0) {
//       _contactname = contacts[0].firstname + ' ' + contacts[0].lastname
//     }
//   }
//   return (
//     <React.Fragment>
//       <Grid container spacing={0}>
//         <Grid item xs={12}>
//           <Typography variant='body1'>{_name}</Typography>
//           {businessId && <Typography variant='caption'>Y-tunnus: {businessId}</Typography>}
//           <Divider />
//         </Grid>
//         <Grid item xs={6}>
//           <Typography variant='caption' gutterBottom component={'span'}>
//             Laskutusosoite
//           </Typography>
//         </Grid>
//         <Grid item xs={6}>
//           <Typography variant='caption' gutterBottom component={'span'}>
//             {address}
//           </Typography>
//         </Grid>
//         <Grid item xs={6}>
//           <Typography variant='caption'>Postinumero ja toimipaikka</Typography>
//         </Grid>
//         <Grid item xs={6}>
//           <Typography variant='caption'>
//             {zipcode} {city}
//           </Typography>
//         </Grid>
//         <Grid item xs={6}>
//           <Typography variant='caption' gutterBottom component={'span'}>
//             Sähköposti
//           </Typography>
//         </Grid>
//         <Grid item xs={6}>
//           <Typography variant='caption' gutterBottom component={'span'}>
//             {_email}
//           </Typography>
//         </Grid>
//         <Grid item xs={6}>
//           <Typography variant='caption' gutterBottom component={'span'}>
//             Yhteyshenkilö
//           </Typography>
//         </Grid>
//         <Grid item xs={6}>
//           <Typography variant='caption' gutterBottom component={'span'}>
//             {_contactname}
//           </Typography>
//         </Grid>
//         <Grid item xs={6}>
//           <Typography variant='caption' gutterBottom component={'span'}>
//             Puhelin
//           </Typography>
//         </Grid>
//         <Grid item xs={6}>
//           <Typography variant='caption' gutterBottom component={'span'}>
//             {phone}
//           </Typography>
//         </Grid>
//         <Grid item xs={6}>
//           <Typography variant='caption' gutterBottom component={'span'}>
//             Verkkolaskuosoite
//           </Typography>
//         </Grid>
//         <Grid item xs={6}>
//           <Typography variant='caption' gutterBottom component={'span'}>
//             {billingOvt}
//           </Typography>
//         </Grid>
//         <Grid item xs={6}>
//           <Typography variant='caption' gutterBottom component={'span'}>
//             Verkkolaskuoperaattori
//           </Typography>
//         </Grid>
//         <Grid item xs={6}>
//           <Typography variant='caption' gutterBottom component={'span'}>
//             {billingOperator}
//           </Typography>
//         </Grid>
//       </Grid>
//     </React.Fragment>
//   )
// }

function PaymentAction(props) {
  const { sum, id, fullypaid } = props
  return (
    <React.Fragment>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <img
            src={process.env.PUBLIC_URL + '/imgs/receipt.svg'}
            alt='receipt'
            style={{ height: 40, marginBottom: 20, marginLeft: 20, marginRight: 20, marginTop: 10 }}
          />
          <Typography variant='h4' component={'span'} align='center' gutterBottom>
            {toCurrency(sum)}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {fullypaid ? (
            <Chip label='Lasku maksettu' icon={<DoneIcon />} />
          ) : (
            <BlueButton variant='contained' color='primary' onClick={() => props.onPay(id)} fullWidth>
              Maksa
            </BlueButton>
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

function InvoiceInfo(props) {
  const { invoice } = props
  const { invoiceNumber, sent, invoiceReference, duedate } = invoice
  // const { billingReference } = invoice.contact

  return (
    <React.Fragment>
      <Grid container spacing={0}>
        <Grid item xs={6}>
          <Typography variant='caption' gutterBottom component={'span'}>
            Laskunumero
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant='caption' gutterBottom component={'span'}>
            {invoiceNumber}
          </Typography>
        </Grid>
        {/* <Grid item xs={6}>
          <Typography variant='caption' gutterBottom component={'span'}>
            Viiteenne
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant='caption' gutterBottom component={'span'}>
            {billingReference}
          </Typography>
        </Grid> 
        <Grid item xs={6}>
          <Typography variant='caption' gutterBottom component={'span'}>
            Laskun päivämäärä
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant='caption' gutterBottom component={'span'}>
            {formatDate(billingdate)}
          </Typography>
        </Grid>*/}
        <Grid item xs={6}>
          <Typography variant='caption' gutterBottom component={'span'}>
            Eräpäivä
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant='caption' gutterBottom component={'span'}>
            {formatDate(duedate)}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant='caption' gutterBottom component={'span'}>
            Laskun viite
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant='caption' gutterBottom component={'span'}>
            {invoiceReference}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant='caption' gutterBottom component={'span'}>
            Lähetyspäivä
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant='caption' gutterBottom component={'span'}>
            {formatDate(sent)}
          </Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

const paymenttypes = {
  reference: 'Viitemaksu',
  banktransfer: 'Tilisiirto',
  cash: 'Kassamaksu',
  manualpayment: 'Muu maksu',
  creditmemorandum: 'Hyvityslasku',
  cashwallet: 'Saldomaksu',
  online: 'Verkkomaksu',
}

function PaymentInfo(props) {
  const { payments, sum } = props
  let paid = 0
  if (payments && payments.length > 0) {
    payments.forEach((payment) => {
      paid += payment.amount
    })
  }
  const unpaid = sum - paid
  return (
    <React.Fragment>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Typography variant='subtitle2'>Maksusuoritukset</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant='caption' gutterBottom component={'span'}>
            Kokonaissumma
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant='caption' gutterBottom component={'span'}>
            {toCurrency(sum)}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant='caption' gutterBottom component={'span'}>
            Avoinna
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant='caption' gutterBottom component={'span'}>
            {toCurrency(unpaid)}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='caption' gutterBottom component={'span'}>
            Suoritukset ({payments ? payments.length : 0})
          </Typography>
        </Grid>
        {payments.map((payment, index) => {
          return (
            <React.Fragment>
              <Grid item xs={6}>
                <Typography variant='caption' gutterBottom component={'span'}>
                  {formatDate(payment.timestamp) + ' ' + paymenttypes[payment.type]}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant='caption' gutterBottom component={'span'}>
                  {toCurrency(payment.amount)}
                </Typography>
              </Grid>
            </React.Fragment>
          )
        })}
      </Grid>
    </React.Fragment>
  )
}

function Invoicerows(props) {
  const { rows } = props
  return (
    <TableContainer>
      <Table size='small'>
        <TableHead>
          <TableRow>
            <TableCell>Tuote</TableCell>
            <TableCell>Rivi</TableCell>
            <TableCell>ALV</TableCell>
            <TableCell>Laji</TableCell>
            <TableCell>ALV 0%</TableCell>
            <TableCell>ALV</TableCell>
            <TableCell>Yhteensä</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => {
            // get vat 0 price from amount
            const vat = (row.amount * row.vat) / (100 + row.vat)
            const vat0 = row.amount - vat
            return (
              <TableRow key={index}>
                <TableCell>{row.product}</TableCell>
                <TableCell>{row.row}</TableCell>
                <TableCell>{row.vat}</TableCell>
                <TableCell>{row.sport}</TableCell>
                <TableCell>{toCurrency(vat0)}</TableCell>
                <TableCell>{toCurrency(vat)}</TableCell>
                <TableCell>{toCurrency(row.amount)}</TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const labels = { invoice: 'Lasku', reminder: 'Maksumuistutus', creditmemorandum: 'Hyvityslasku' }

function Attachments(props) {
  const { invoice } = props
  const attachmenturls = Object.keys(invoice)
    .filter((key) => key.indexOf('pdfurl') > -1)
    .map((key) => {
      return { url: invoice[key], name: key.replace('pdfurl', '').replace(/[0-9]/g, '') }
    })
    .sort((a, b) => a.name.localeCompare(b.name))
  return (
    <React.Fragment>
      <Grid container spacing={0}>
        <Grid item xs={12} style={{ textAlign: 'right' }}>
          {attachmenturls.map((object, index) => {
            return (
              <Typography variant='caption' gutterBottom component={'span'}>
                <a href={object.url} target='_blank' rel='noreferrer'>
                  <PictureAsPdfIcon fontSize='small' /> {labels[object.name]}
                </a>{' '}
              </Typography>
            )
          })}
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

function InvoiceDetails(props) {
  const { t } = useTranslation()
  const { invoice, onClose } = props
  if (!invoice) return null
  const { payments, rows, sum } = invoice //.details
  let paid = 0
  if (payments && payments.length > 0) {
    payments.forEach((payment) => {
      paid += payment.amount
    })
  }
  let fullypaid = false
  const unpaid = sum - paid
  if (unpaid <= 0) fullypaid = true
  
  const onBack = () => {
    if (onClose) onClose()
  }

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Button size='small' color='link' className='text-muted' onClick={onBack}>
            <ChevronLeftIcon /> {t('back')}
          </Button>
        </Grid>
        <Grid item sm={4} xs={12}>
          <PaymentAction sum={unpaid} id={invoice.id} onPay={props.onPay} fullypaid={fullypaid} />
        </Grid>
        <Grid item sm={4} xs={12}>
          {/* invoice info */}
          <InvoiceInfo invoice={invoice} />
        </Grid>
        <Grid item sm={4} xs={12}>
          {/* payment info */}
          <PaymentInfo payments={payments || []} sum={sum} />
        </Grid>
        <Grid item xs={12}>
          <Attachments invoice={invoice} />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          {/* line items */}
          <Invoicerows rows={rows} />
        </Grid>
      </Grid>
    </Box>
  )
}

export default InvoiceDetails
