import React, { useState, useEffect } from 'react'
import { Col, FormGroup, Input, Row } from 'reactstrap'
import { useTranslation } from 'react-i18next'


function OrganisationSelect({ organisations, onChange }) {
  const { t } = useTranslation()
  const [selected, setSelected] = useState('personal')
  const [values, setValues] = useState([])

  const updateSelected = (value) => {
    setSelected(value)
    console.log('selected organisation', value)
    console.log('organisations', organisations)
    const org = organisations.find((o) => o.id === value)
    if (onChange) onChange(org)
  }

  useEffect(() => {
    const PERSONAL = { id: 'personal', name: t('personal') }
    if (organisations.length === 0) {
      setValues([PERSONAL])
    } else {
      organisations.unshift(PERSONAL)
      setValues(organisations)
    }
    setValues(organisations)
  }, [organisations, t])

  return (
    <Row key={'payer'}>
      <Col>{t('payer')}</Col>
      <Col>
        <FormGroup className='loose-form'>
          <Input
            type='select'
            name='organisationSelect'
            id='organisationSelect'
            value={selected}
            onChange={(e) => updateSelected(e.target.value)}
          >
            {values.map((a) => (
              <option value={a.id}>{a.name}</option>
            ))}
          </Input>
        </FormGroup>
      </Col>
    </Row>
  )
}

export default OrganisationSelect
