import React, { useState, useEffect } from 'react'
// import { useHistory } from 'react-router-dom' // if you use react-router
import { useFirebase, firestoreConnect } from 'react-redux-firebase'
import { connect } from 'react-redux'
import { compose } from 'redux'
import {  Row, Col, Button, Spinner } from 'reactstrap'
import { Input, InputAdornment, InputLabel } from '@material-ui/core'
import withAppconfig from '../AppContext'

const withWallet = compose(
  connect((state) => ({ auth: state.firebase.auth })),
  firestoreConnect((props) => [
    {
      collection: 'users',
      doc: `${props.auth.uid}`,
      subcollections: [
        { collection: 'paymentinfo', doc: 'saldo', subcollections: [{ collection: `${props.appconfig.accountkey}` }] },
      ],
      storeAs: 'wallets',
    },
  ]),
  connect((state) => ({ wallets: state.firestore.ordered.wallets }))
)

function AddEazybreakAsset({ appconfig, wallets }) {
  const firebase = useFirebase()
  // const history = useHistory()
  const [assetPrice, setAssetPrice] = useState()
  // eslint-disable-next-line no-unused-vars
  const [paymentCode, setPaymentCode] = useState(null)
  const [creatingCode, setCreatingCode] = useState(false)
  const [confirmed, setConfirmed] = useState(false)
  const [formML, setFormML] = useState(null)


  if (paymentCode && !confirmed) {
    for (let w of wallets) {
      if (w.cash) {
        const t = w.cash.find((c) => c.purchase && c.purchase.paymentcode === paymentCode)
        if (t) {
          setConfirmed(true)
        }
      }
    }
  }

  useEffect(() => {
    if (formML && document.getElementById('ep')) {
      document.getElementById('ep').submit()
    }
  }, [formML])

  const createAssetAdd = () => {
    const paidsum = Math.floor(parseFloat(assetPrice) * 100)
    const asset = {
      paidsum,
      cid: appconfig.accountkey,
      venue: 'default',
      provider: 'eazybreak',
    }
    setCreatingCode(true)
    firebase.functions().region = 'europe-west1'
    const fn = appconfig.istest ? 'testassetpayment-payment' : 'assetpayment-payment'
    let createPayment = firebase.functions().httpsCallable(fn)
    createPayment(asset).then((response) => {
      if (response.data.error) {
        // console.log(response.data)
        alert(response.data)
      } else {
        // console.log(response.data)
        setFormML(response.data)
      }
      setCreatingCode(false)
    })
  }

  const setAmount = (e) => {
    setAssetPrice(e.target.value.replace(/^0+/, ''))
  }

  return (
    <React.Fragment>
      <Row className='text-center'>
        <Col lg={12} sm={12} style={{ marginBottom: 20 }}>
          <InputLabel htmlFor='asset-amount'>Tallennettava summa</InputLabel>
          <Input
            id='asset-amount'
            label='Tallennettava summa'
            disabled={paymentCode}
            value={assetPrice}
            onChange={setAmount}
            type='number'
            endAdornment={<InputAdornment position='end'>€</InputAdornment>}
          />
        </Col>
        <Col lg={12} sm={12}>
          <Button color={'success'} disabled={!assetPrice} onClick={createAssetAdd}>
            {creatingCode ? <Spinner /> : 'Maksa'} <img src={process.env.PUBLIC_URL + '/imgs/eazybreak_logo.png'} alt="eazybreak" style={{ height: 20 }} />
          </Button>
        </Col>
      </Row>
      {/*     <Row style={{ marginTop: 30 }} className='text-center'>
            {!confirmed ? (
              <Col lg={12} sm={12}>
                Odotetaan maksun vahvistusta...
                <Progress animated color='success' value='100' />
              </Col>
            ) : (
              <Col lg={12} sm={12}>
                Maksu vahvistettu!
                <br />
                <br />
                <Button color='success' onClick={() => history.push(appconfig.pathPrefix + '/profile')}>
                  Jatka
                </Button>
              </Col>
            )}
      </Row> */}
      {formML ? (<div style={{ display: 'none'}}  dangerouslySetInnerHTML={{ __html: formML }} />) : null}
    </React.Fragment>
  )
}

export default withAppconfig(withWallet(AddEazybreakAsset))
