import React from 'react'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import { useTranslation } from 'react-i18next'

export default function BalanceSelector({ balances, setVenue, balance }) {
  const [value, setValue] = React.useState(balance ? balance : null)
  const { t } = useTranslation()

  const handleChange = (event) => {
    setValue(event.target.value)
    setVenue(event.target.value)
  }

  return (
    <FormControl component='fieldset'>
      <FormLabel component='legend' color='disabled'>{t('set_balance_to_add')}</FormLabel>
      <RadioGroup aria-label='venue' name='venue' value={value} onChange={handleChange}>
        {balances.map((b) => (
          <FormControlLabel value={b.venue} control={<Radio color='primary' />} label={b.label.fi} />
        ))}
      </RadioGroup>
    </FormControl>
  )
}
