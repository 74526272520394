import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom' // if you use react-router
import TopNavigation from '../../TopNavigation'
import { firestoreConnect } from 'react-redux-firebase'
import { useCookies } from 'react-cookie'
import { compose } from 'redux'
import { Container, Row, Col } from 'reactstrap'
import { Chip, IconButton, Typography } from '@material-ui/core'
import EuroIcon from '@material-ui/icons/Euro'
import useWindowDimensions from '../../useWindowsDimensions'
import withAppconfig from '../../AppContext'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import 'moment/locale/fi'
import 'moment/locale/sv'
import 'moment/locale/en-gb'
import 'moment/locale/et'
import { ChevronRight } from '@material-ui/icons'
import InvoiceDetails from '../../components/InvoiceDetails'

function Alert(props) {
  return <div style={{
    borderRadius: 4,
    padding: 10,
    marginBottom: 10,
    backgroundColor: '#e5f6fd',
    color: '#000',
    alignContent: 'center',
    alignItems: 'center',
  }}>{props.children}</div>
}


const mapStateToProps = (state, props) => {
  const {
    globaltime: { gtime },
  } = state
  const { ownInvoices } = state.firestore.data
  console.log('ownInvoices', ownInvoices)
  if (ownInvoices) {
    const _invoices = Object.keys(ownInvoices)
      .map((k) => {
        return { ...ownInvoices[k], id: k }
      })
      .filter((invoice) => invoice.locationid === props.appconfig.accountkey)
      .map((invoice) => {
        let paid = 0
        if (invoice.payments) {
          paid = invoice.payments.reduce((a, c) => (a += c.amount), 0)
        }
        const sum = invoice.rows.reduce((acc, cur) => {
          return acc + cur.amount
        }, 0)
        invoice.sum = sum
        invoice.unpaid = sum - paid
        const isSent = invoice.sent ? true : false
        const isPaid = paid >= sum ? true : false
        const isOverdue = invoice.duedate && moment(invoice.duedate).isBefore(moment(), 'D') ? true : false
        const isReminded = invoice.reminds ? true : false
        const isDebtCollection = invoice.reminds && invoice.reminds.length > 2 ? true : false
        invoice.statuses = {
          isSent,
          isPaid,
          isOverdue,
          isReminded,
          isDebtCollection,
        }
        return invoice
      })
      .reduce(
        (acc, cur) => {
          if (cur.statuses.isPaid) {
            acc.paid.push(cur)
          } else {
            acc.open.push(cur)
          }
          return acc
        },
        { open: [], paid: [] }
      )
    // const _invoices = JSON.parse(JSON.stringify(ownInvoices))
    // const leaguesList = []
    // const types = [] //[{ value: 'all', label: 'Kaikki' }]
    // const sports = []
    // for (let a in _leagues) {
    //   leaguesList.push({ id: a, ..._leagues[a] })
    //   const isAlreadyIn = types.find((t) => t.value === _leagues[a].type.toLowerCase())
    //   const isAlreadySport = sports.find((t) => _leagues[a].sport && t.value === _leagues[a].sport.toLowerCase())
    //   if (!isAlreadyIn) {
    //     types.push({ value: _leagues[a].type.toLowerCase(), label: _leagues[a].type })
    //   }
    //   if (!isAlreadySport) {
    //     if (_leagues[a].sport) {
    //       const capFirst = _leagues[a].sport.toLowerCase().charAt(0).toUpperCase() + _leagues[a].sport.toLowerCase().slice(1)
    //       sports.push({ value: _leagues[a].sport.toLowerCase(), label: capFirst })
    //     }
    //   }
    // }

    return {
      invoices: _invoices,
      gtime,
    }
  } else {
    return {
      invoices: { open: [], paid: [] },
      gtime,
    }
  }
}

const withInvoices = compose(
  connect((state) => ({ auth: state.firebase.auth })),
  firestoreConnect((props) => [
    {
      collection: 'users',
      doc: `${props.auth.uid}`,
      subcollections: [{ collection: 'invoices' }],
      where: [['locationid', '==', `${props.appconfig.accountkey}`]],
      orderBy: ['sent', 'asc'],
      // where: [
      //   ['userstate', '==', 'participating']
      // ],
      storeAs: 'ownInvoices',
    },
  ]),
  connect(mapStateToProps)
)

function InvoiceRow(props) {
  const { t } = useTranslation()
  const { width } = useWindowDimensions()
  console.log('InvoiceRow', props)
  const { invoice, onAddPayment, onShowDetails } = props

  // let email = invoice.contact.email
  // if (invoice.billingEmail) {
  //   email = invoice.billingEmail
  // } else if (invoice.contacts && invoice.contacts.length > 0) {
  //   email = invoice.contacts[0].email
  // }

  const state = invoice.statuses.isPaid
    ? 'paid'
    : invoice.statuses.isOverdue
    ? 'overdue'
    : invoice.statuses.isReminded
    ? 'reminded'
    : invoice.statuses.isDebtCollection
    ? 'debt collection'
    : 'open'

  const rowPadding = width > 768 ? 20 : 0
  const colPadding = width > 768 ? 0 : 10

  return (
    <Row style={{ padding: rowPadding }}>
      <Col xs={5} style={{ padding: colPadding }}>
        {t('invoice')} {invoice.invoiceNumber}{' '}
        {state !== 'paid' && (
          <Chip
            variant='outlined'
            size='small'
            icon={<EuroIcon />}
            label={t('pay')}
            clickable
            onClick={() => onAddPayment(invoice.id)}
            style={{ marginLeft: 20 }}
          />
        )}{' '}
      </Col>
      <Col xs={3} style={{ padding: colPadding }}>
        {moment.utc(invoice.duedate).format('D.M.YYYY')}
      </Col>
      <Col xs={3} style={{ padding: colPadding }}>
        {invoice.sum / 100} €
      </Col>
      <Col xs={1} style={{ padding: colPadding }}>
        <IconButton size='small' onClick={() => onShowDetails(invoice)}>
          <ChevronRight fontSize='inherit' />
        </IconButton>
      </Col>
    </Row>
  )
}

function Invoices(props) {
  const { t } = useTranslation()
  const { invoices, title, onShowDetails, onAddPayment } = props
  if (!invoices || invoices.length === 0) return (
    <div className='p-3 my-2 rounded'>
      <Row>
        <Col>
          <h6>{t(title)}</h6>
        </Col>
      </Row>
      <Row style={{ borderTop: '1px solid #bdbdbd' }}>
        <Col xs={12}>{t('no_invoices')}</Col>
      </Row>
    </div>
  )
  return (
    <div className='p-3 my-2 rounded'>
      <Row>
        <Col>
          <h6>{t(title)}</h6>
        </Col>
      </Row>
      <Row style={{ borderBottom: '1px solid #bdbdbd' }}>
        <Col xs={5}></Col>
        <Col xs={3}>
          <b>{t('duedate')}</b>
        </Col>
        <Col xs={3}>
          <b>{t('invoicesum')}</b>
        </Col>
        <Col xs={1}></Col>
      </Row>
      {invoices.map((invoice) => {
        return <InvoiceRow invoice={invoice} onAddPayment={onAddPayment} onShowDetails={onShowDetails} />
      })}
    </div>
  )
}

function InvoicesScreen({ auth, invoices, dispatch, appconfig, gtime }) {
  let defaultLanguage = 'FI'
  const history = useHistory()
  const [detailsInvoice, setDetailsInvoice] = useState(null)
  const [cookies] = useCookies(['selectedLanguage'])
  if (cookies.selectedLanguage) {
    defaultLanguage = cookies.selectedLanguage
  }

  useEffect(() => {
    if (defaultLanguage.toLowerCase() === 'se') {
      moment.locale('sv', { week: { dow: 3 } })
    } else if (defaultLanguage.toLowerCase() === 'ee') {
      moment.locale('et', { week: { dow: 3 } })
    } else {
      moment.locale(defaultLanguage.toLowerCase(), { week: { dow: 3 } })
    }
  }, [defaultLanguage])

  const handleShowDetails = (invoice) => {
    setDetailsInvoice(invoice)
  }

  const goToPayInvoice = (invoice) => {
    dispatch({
      type: 'UPDATE_PAYABLE_INVOICE',
      payload: invoice,
    })
    history.push(appconfig.pathPrefix + '/invoices/checkout')
  }

  const handleShowList = () => {
    setDetailsInvoice(null)
  }

  const handleAddPayment = (invoiceId) => {
    const invoice = invoices.open.find((i) => i.id === invoiceId)
    goToPayInvoice(invoice)
  }

  return (
    <div className='full-page-background'>
      <TopNavigation />
      <Container className='front' style={{ paddingTop: 10 }}>
        {detailsInvoice ? (
          <InvoiceDetails invoice={detailsInvoice} onClose={handleShowList} onPay={handleAddPayment} />
        ) : (
            <React.Fragment>
              <Alert icon={false} severity="success">
              <Typography variant='caption' gutterBottom>Jos olet maksanut laskun pankissa viitemaksuna, niin maksun kirjautuminen saattaa kestää 4 arkipäivää.</Typography>
              </Alert>
            <Invoices
              invoices={invoices.open}
              title={'open_invoices'}
              onAddPayment={handleAddPayment}
              onShowDetails={handleShowDetails}
            />
            <Invoices invoices={invoices.paid} title={'paid_invoices'} onShowDetails={handleShowDetails} />
          </React.Fragment>
        )}
      </Container>
    </div>
  )
}

export default withAppconfig(withInvoices(InvoicesScreen))
