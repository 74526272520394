import React from 'react'
import fail from '../assets/fail-anim.json'
import success from '../assets/success-anim.json'
import Lottie from 'react-lottie-player'
import { useEffect } from 'react'

function LottieAnim({ status, onComplete }) {
  const ref = React.useRef()

  useEffect(() => {
    if (ref.current) {
      ref.current.play()
    }
  }, [])

  console.log('status', status)
  const animation = status === 'success' ? success : fail
  return <Lottie ref={ref} animationData={animation} loop={false} autoPlay={false} onComplete={onComplete} />
}

export default LottieAnim

