import React from 'react'
// import fail from '../assets/fail-anim.json'
import loadAnim from '../assets/loading1-anim.json'
import Lottie from 'react-lottie-player'
import { useEffect } from 'react'

function LottieLoading({ status, onComplete, width, height }) {
  const ref = React.useRef()

  useEffect(() => {
    if (ref.current) {
      ref.current.play()
    }
  }, [])

  return <Lottie ref={ref} animationData={loadAnim} loop={true} autoPlay={true} onComplete={onComplete} style={{ width, height }}/>
}

export default LottieLoading

