import moment from "moment"


export default function mapWalletsStateToProps (state, props) {
  const {
    firestore,
    firebase: { auth, profile, push },
    globaltime: { gtime },
  } = state
  const { appconfig } = props
  let _balances = JSON.parse(JSON.stringify(appconfig.balances))
  const balances = _balances.map((balance) => {
    const isCategoryBalance = firestore.data['wallets'] && firestore.data['wallets'][balance.venue] && firestore.data['wallets'][balance.venue][balance.category]
    
    balance.sum = isCategoryBalance
        ? firestore.data['wallets'][balance.venue][balance.category].reduce((acc, curr) => {
            acc = acc + curr.amount
            return acc
          }, 0)
        : 0
    if (balance.expiring && isCategoryBalance) {
      const _linedate = moment(gtime).subtract(balance.expiring, 'days')
      const _ed = firestore.data['wallets'][balance.venue][balance.category].filter((ta) => moment.utc(ta.timestamp, 'YYYY.MM.DD HH:mm').isAfter(_linedate) && ta.amount > 0).map((ta) => moment.utc(ta.timestamp, 'YYYY.MM.DD HH:mm')).sort((a, b) => a.unix() - b.unix())
      for (let i = 0; i < _ed.length; i++) {
        const linedate = _ed[i]
        // stored fund before linedate
        const _s = firestore.data['wallets'][balance.venue][balance.category].reduce((acc, ta) => {
          if (moment.utc(ta.timestamp, 'YYYY.MM.DD HH:mm').isSameOrBefore(linedate) && ta.amount > 0) {
            return acc + ta.amount
          } else {
            return acc
          }
        }, 0)
  
        // purchases after linedate
        const _p = firestore.data['wallets'][balance.venue][balance.category].reduce((acc, ta) => {
          if (moment.utc(ta.timestamp, 'YYYY.MM.DD HH:mm').isSameOrAfter(linedate) && ta.type === 'purchase') {
            return acc + ta.amount
          } else {
            return acc
          }
        }, 0)
        if (_p + _s > 0) {
          balance.nextexpiring =  moment(linedate).add(balance.expiring, 'days').format('D.M.YYYY')
          balance.nextexpiringamount = (_p + _s) / 100
          break
        }
      }
    }
    return balance
  })
  let storages = null
  if (appconfig.storages && (appconfig.storage || appconfig.summerstorage)) {
    let _storages = JSON.parse(JSON.stringify(appconfig.storages))
    storages = _storages.map((storage) => {
      if (storage.separatesports) {
        const hasThisStorageTransactions = firestore.data['storages'] && firestore.data['storages'][storage.venue] && firestore.data['storages'][storage.venue][storage.category]
        if (hasThisStorageTransactions) {
          const sums = firestore.data['storages'][storage.venue][storage.category].reduce((acc, curr) => {
            const sport = curr.sport || 'default'
            acc[sport] = acc[sport] || 0
            acc[sport] = acc[sport] + curr.amount
            acc.sum = acc.sum + curr.amount
            return acc
          }, { sum: 0 })
          storage.sum = sums.sum
          storage.sums = sums
        } else {
          storage.sum = 0
          storage.sums = {}
        }
        return storage
      } else {
        storage.sum =
          firestore.data['storages'] &&
            firestore.data['storages'][storage.venue] &&
            firestore.data['storages'][storage.venue][storage.category]
            ? firestore.data['storages'][storage.venue][storage.category].reduce((acc, curr) => {
              acc = acc + curr.amount
              return acc
            }, 0)
            : 0
        return storage
      }
    })
  }
  // console.log('calculated storages', storages)
  // console.log('firestore', firestore)


  let accesscode = profile.accesscode ? profile.accesscode[appconfig.accountkey] : null
  if (accesscode && appconfig.accesscodeextra) accesscode += appconfig.accesscodeextra

  let benefitcards = null
  if (firestore.data.benefitcards) {
    benefitcards = Object.keys(firestore.data.benefitcards || {})
      .filter((x) => x !== 'undefined' && x !== 'null')
      .map((k) => {
        return { ...firestore.data.benefitcards[k], id: k }
      })
      .filter((v) => gtime.isSameOrBefore(moment.utc(v.validuntil, 'YYYY-MM-DD'), 'day'))
      .filter(
        (v) =>
          (v.type === 'count' && v.remaining > 0) ||
          (v.type === 'time' && moment.utc(v.end, 'YYYY.MM.DD').isSameOrAfter(gtime, 'day'))
      )
      .map((v) => {
        if (v.type === 'time') {
          if (moment.utc(v.end, 'YYYY.MM.DD').isBefore(moment.utc(v.validuntil, 'YYYY-MM-DD'), 'day')) {
            v.lastday = moment.utc(v.end, 'YYYY.MM.DD')
          } else {
            v.lastday = moment.utc(v.validuntil, 'YYYY-MM-DD')
          }
        }
        return v
      })
      // console.log('benefitcards', JSON.stringify(benefitcards))
  // } else {
  //   console.log('no benefitcards')
  }

  const _membrss = Object.keys(firestore.data.memberships || {})
    .filter((x) => x !== 'undefined' && x !== 'null')
    .map((k) => firestore.data.memberships[k])
    .filter((v) => v.permanent || moment.utc(v.end, 'YYYY.MM.DD').isAfter(gtime))
  
  console.log('wallets', firestore.data['wallets'])
  
  return {
    auth,
    profile,
    saldotransactions: firestore.data['wallets'] || [],
    push,
    memberships: _membrss,
    mems: firestore.data.memberships,
    balances,
    accesscode,
    storages,
    storagetransactions: firestore.data['storages'] || [],
    benefitcards: benefitcards || [],
    gtime,
    cards: firestore.ordered.cards || [],
  }
}

