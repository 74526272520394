import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory /*, useParams*/ } from 'react-router-dom' // if you use react-router
import TopNavigation from '../TopNavigation'
import { useFirebase, firebaseConnect } from 'react-redux-firebase'
import { useCookies } from 'react-cookie'
import { compose } from 'redux'
import {
  Container,
  Row,
  Col,
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  Spinner,
} from 'reactstrap'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import LeagueCard from '../components/LeagueCard'
// import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
// import ViewListIcon from '@material-ui/icons/ViewList'
// import AppsIcon from '@material-ui/icons/Apps'
import useWindowDimensions from '../useWindowsDimensions'
import withAppconfig from '../AppContext'
import { useTranslation } from 'react-i18next'
import ShareDialog from '../components/ShareDialog'
import moment from 'moment'
import 'moment/locale/fi'
import 'moment/locale/sv'
import 'moment/locale/en-gb'
import 'moment/locale/et'
import { Select } from '@material-ui/core'

const mapStateToProps = (state, props) => {
  const {
    globaltime: { gtime },
  } = state
  const { leagues } = state.firebase.data
  if (leagues && leagues[props.appconfig.accountkey]) {
    const _leagues = JSON.parse(JSON.stringify(leagues[props.appconfig.accountkey]))
    const leaguesList = []
    const types = [] //[{ value: 'all', label: 'Kaikki' }]
    const sports = []
    for (let a in _leagues) {
      leaguesList.push({ id: a, ..._leagues[a] })
      const isAlreadyIn = types.find((t) => t.value === _leagues[a].type.toLowerCase())
      const isAlreadySport = sports.find((t) => _leagues[a].sport && t.value === _leagues[a].sport.toLowerCase())
      if (!isAlreadyIn) {
        types.push({ value: _leagues[a].type.toLowerCase(), label: _leagues[a].type })
      }
      if (!isAlreadySport) {
        if (_leagues[a].sport) {
          const capFirst = _leagues[a].sport.toLowerCase().charAt(0).toUpperCase() + _leagues[a].sport.toLowerCase().slice(1)
          sports.push({ value: _leagues[a].sport.toLowerCase(), label: capFirst })
        }
      }
    }
    if (types.length > 1) {
      types.unshift({ value: 'all', label: 'Kaikki', t: 'all' })
    }
    if (sports.length > 1) {
      sports.unshift({ value: 'all', label: 'Kaikki', t: 'all' })
    }

    const activeLeagues = leaguesList
      .filter((x) => x.state === 'published')
      .filter((x) => moment.utc(x.end).isAfter(gtime))
      .sort((a, b) => {
        if (a.start < b.start) {
          return -1
        }
        if (a.start > b.start) {
          return 1
        }
        return 0
      })

    return {
      leagues: activeLeagues,
      types,
      sports,
      gtime
    }
  } else {
    return {
      leagues: [],
      types: [],
      sports: [],
      gtime
    }
  }
}

const withLeagues = compose(
  connect((state) => ({ auth: state.firebase.auth })),
  firebaseConnect((props) => [
    {
      path: 'leagues/' + props.appconfig.accountkey,
      queryParams: ['orderByChild=displayName'],
    },
  ]),
  connect(mapStateToProps)
)

function FilterGroup({ options, value, onChange, type, selectionLabel, classNames = [], style = {} }) {
  const { t } = useTranslation()

  if (options.length < 2) return null
  if (type === 'buttons') {
    return (
      <ButtonGroup size={'sm'} className='flex-wrap' style={style}>
        {options.map((option) => {
          return (
            <Button
              size={'sm'}
              key={option.value}
              color='primary'
              onClick={() => onChange({ target: { value: option.value } })}
              active={value === option.value}
              outline
            >
              {option.t ? t([option.t, option.label]) : option.label}
            </Button>
          )
        })}
      </ButtonGroup>
    )
  } else if (type === 'dropdown') {
    return (
      <FormControl className={classNames.join(' ')} style={style}>
        <InputLabel id={(selectionLabel || 'dropdown') + '-select-label'}>{t(selectionLabel)}</InputLabel>
        <Select
          labelId={(selectionLabel || 'dropdown') + '-select-label'}
          id={(selectionLabel || 'dropdown') + '-select'}
          value={value}
          onChange={onChange}
          style={{ width: '100%' }}
        >
          {options.map((option) => {
            return (
              <MenuItem key={option.value} value={option.value}>
                {option.t ? t([option.t, option.label]) : option.label}
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
    )
  }
}

function LeaguesScreen({ auth, leagues, dispatch, appconfig, types, gtime, sports }) {
  // const params = useParams()
  const firebase = useFirebase()
  // const [types, setTypes] = useState([
  //   { value: 'all', label: 'Kaikki' },
  //   { value: 'valmennus', label: 'Valmennus' },
  // ])
  const [selectedactivity, setSelectedActivity] = useState(null)
  const [type, setType] = useState(types && types.length > 0 ? types[0].value : 'all')
  const [sport, setSport] = useState(sports && sports.length > 0 ? sports[0].value : 'all')
  const [view, setView] = useState('grid')
  const [sharelink, setShareLink] = useState('')
  const [confirmationOpen, setConfirmationOpen] = useState(false)
  const [socialOpen, setSocialOpen] = useState(false)
  let defaultLanguage = 'FI'
  const { width } = useWindowDimensions()
  const history = useHistory()
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [cookies] = useCookies(['selectedLanguage'])
  if (cookies.selectedLanguage) {
    defaultLanguage = cookies.selectedLanguage
  }
  
  useEffect(() => {
    if (defaultLanguage.toLowerCase() === 'se') {
      moment.locale('sv', { week: { dow: 3 } })
    } else if (defaultLanguage.toLowerCase() === 'ee') {
      moment.locale('et', { week: { dow: 3 } })
    } else {
      moment.locale(defaultLanguage.toLowerCase(), { week: { dow: 3 } })
    }
  }, [defaultLanguage])

  useEffect(() => {
    if (types && types.length > 0 && type === 'all') {
      setType(types[0].value)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [types])

  useEffect(() => {
    if (sports && sports.length > 0 && sport === 'all') {
      setSport(sports[0].value)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [sports])

  const handleTypeChange = (event) => {
    setType(event.target.value)
  }

  const handleSportChange = (event) => {
    setSport(event.target.value)
  }

  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer')
  }

  const goToPayActivity = (activity) => {
    if (activity.isexternal) {
      openInNewTab(activity.externalurl)
    } else {
      dispatch({
        type: 'UPDATE_PAYABLE_ACTIVITY',
        payload: activity,
      })
      history.push(appconfig.pathPrefix + '/bookactivity')
    }
  }

  const closeSocial = () => {
    setSocialOpen(false)
  }

  const cancelModal = () => {
    setSelectedActivity(null)
    setConfirmationOpen(false)
  }

  const shareClick = (o) => {
    const link =
      'https://' +
      (appconfig.istest ? 'utest.cintoia.com' + appconfig.pathPrefix : appconfig.hosts[0]) +
      '/league/' +
      o.id
    setShareLink(link)
    setSocialOpen(true)
  }

  const confirmCancel = (activity) => {
    setSelectedActivity(activity)
    setConfirmationOpen(true)
  }

  const cancelActivity = async () => {
    setConfirmationOpen(false)
    setLoading(true)
    const activityObject = {
      id: selectedactivity.id,
      customerid: appconfig.accountkey,
    }
    firebase.functions().region = 'europe-west1'
    const fn = appconfig.istest ? 'testactivities-cancel' : 'activities-cancel'
    let cancel = firebase.functions().httpsCallable(fn)
    try {
      const response = await cancel(activityObject)
      setLoading(false)
      if (response.data.error) {
        alert(response.data.message)
      }
    } catch (error) {
      setLoading(false)
      alert(error.message)
    }
  }

  if (width < 770 && view === 'horizontal') setView('grid')

  const filtered = leagues
    .filter((x) => !type || (type && (type === x.type.toLowerCase() || type === 'all')))
    .filter((x) => !sport || (sport && (sport === x.sport.toLowerCase() || sport === 'all')))

  return (
    <div className='full-page-background'>
      <TopNavigation />
      <Container className='front' style={{ paddingTop: 10 }}>
        <Row>
          <Col className='text-left mb-2'>
            <FilterGroup options={types} value={type} onChange={handleTypeChange} type={'dropdown'} selectionLabel={'Tapahtuma'} />
            <FilterGroup options={sports} value={sport} onChange={handleSportChange} type={'dropdown'} selectionLabel={'Laji'} style={{ marginLeft: 20 }}/>
          </Col>
        </Row>
        <Row>
          {filtered
            .map((a) => (
              <LeagueCard
                activity={a}
                horizontal={view}
                paymentAction={goToPayActivity}
                cancelAction={confirmCancel}
                uid={auth.uid}
                shareClick={shareClick}
                gtime={gtime}
              />
            ))}
        </Row>
        <h6>DEBUG</h6>
        <pre><code>{JSON.stringify(leagues)}</code></pre>
      </Container>
      <Modal
        size='sm'
        centered
        isOpen={loading}
        backdrop={true}
        wrapClassName={'al-wrap'}
        modalClassName={'al-modal'}
        contentClassName={'loading-spinner'}
      >
        <ModalBody className='text-center'>
          <Spinner color='light' /> <p style={{ color: 'white' }}>{t('cancelling')}</p>
        </ModalBody>
      </Modal>
      <Dialog
        open={confirmationOpen}
        onClose={() => cancelModal()}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{'Haluatko varmasti perua osallistumisen?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'></DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => cancelModal()} color='secondary'>
            {t('back')}
          </Button>
          <Button onClick={() => cancelActivity()} color='danger' autoFocus>
            {t('yes')}
          </Button>
        </DialogActions>
      </Dialog>
      <ShareDialog open={socialOpen} onClose={closeSocial} link={sharelink} />
    </div>
  )
}

export default withAppconfig(withLeagues(LeaguesScreen))
