import { useFirebase } from 'react-redux-firebase'

export function useUiGet({ appconfig }) {
  const firebase = useFirebase()
  if (appconfig) {
    const fn = appconfig.istest ? 'testui-get' : 'ui-get'
    firebase.functions().region = 'europe-west1'
    return firebase.functions().httpsCallable(fn)
  }
}

