import React, { useState } from 'react'
import { isEmpty } from 'react-redux-firebase'
import { connect } from 'react-redux'
import { useFirebase } from 'react-redux-firebase'
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Spinner,
} from 'reactstrap'
import { useHistory } from 'react-router-dom'
import TopNavigation from '../TopNavigation'
import { useTranslation } from 'react-i18next'
// import { useCookies } from 'react-cookie'
import useWindowDimensions from '../useWindowsDimensions'
import withAppconfig from '../AppContext'
import { ChevronLeft as ChevronLeftIcon, Delete as DeleteIcon /*, Folder as FolderIcon*/ } from '@material-ui/icons'
import {
  Divider,
  Typography,
  MenuItem,
  Select,
  ListItemAvatar,
  ListItem,
  List,
  Avatar,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
} from '@material-ui/core'
// import AddCircleIcon from '@material-ui/icons/AddCircle'
import PersonIcon from '@material-ui/icons/Person'

const enhance = connect(
  // Map redux state to component props
  ({ firebase: { auth, profile } }) => ({
    auth,
    profile,
  })
)

const avatarClasses = {
  existing: {
    color: 'white',
    backgroundColor: '#4caf50',
  },
  new: {
    color: 'white',
    backgroundColor: '#25aae1',
  },
}

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
}

const COMPANY_TYPES = {
  fi: [
    { value: 1, title: 'Osakeyhtiö' },
    { value: 2, title: 'Toiminimi' },
    { value: 3, title: 'Yhdistys' },
    { value: 4, title: 'Säätiö' },
    { value: 5, title: 'Kommandiittiyhtiö' },
    { value: 6, title: 'Avoin yhtiö' },
    { value: 7, title: 'Järjestö' },
    { value: 8, title: 'Yhdistys' },
    { value: 9, title: 'Kunta/Kaupunki' },
    { value: 10, title: 'Muu' },
  ],
}

const EINVOICE_OPERATORS = [
  {
    title: 'Ei operaattoria',
    value: null,
  },
  {
    title: '4US Oy (UTMOST)',
    value: 'UTMOST',
  },
  {
    title: 'Apix Messaging Oy (003723327487)',
    value: '003723327487',
  },
  {
    title: 'Basware Oyj (BAWCFI22)',
    value: 'BAWCFI22',
  },
  {
    title: 'CGI Suomi Oy (003703575029)',
    value: '003703575029',
  },
  {
    title: 'Comarch (5909000716438)',
    value: '5909000716438',
  },
  {
    title: 'Crediflow AB (CREDIFLOW)',
    value: 'CREDIFLOW',
  },
  {
    title: 'Dynatos (ROUTTY)',
    value: 'ROUTTY',
  },
  {
    title: 'HighJump AS (885790000000418)',
    value: '885790000000418',
  },
  {
    title: 'InExchange Factorum AB (INEXCHANGE)',
    value: 'INEXCHANGE',
  },
  {
    title: 'Lexmark Expert Systems AB (EXPSYS)',
    value: 'EXPSYS',
  },
  {
    title: 'Maventa (003721291126 tai MAVENTA)',
    value: '003721291126',
  },
  {
    title: 'Netbox Finland Oy (003726044706)',
    value: '003726044706',
  },
  {
    title: 'Opentext Oy (003708599126)',
    value: '003708599126',
  },
  {
    title: 'OpusCapita Solutions Oy (E204503)',
    value: 'E204503',
  },
  {
    title: 'Pagero Oy (003723609900 tai PAGERO)',
    value: '003723609900 tai PAGERO',
  },
  {
    title: 'Posti Messaging Oy (FI28768767)',
    value: 'FI28768767',
  },
  {
    title: 'PostNord Strålfors Oy (003701150617)',
    value: '003701150617',
  },
  {
    title: 'Ropo Capital Oy (003714377140)',
    value: '003714377140',
  },
  {
    title: 'Telia Finland Oyj (003703575029)',
    value: '003703575029',
  },
  {
    title: 'TietoEvry Oyj (003701011385)',
    value: '003701011385',
  },
  {
    title: 'Tradeshift Ab (885060259470028)',
    value: '885060259470028',
  },
  {
    title: 'Ålands Post Ab (003722207029)',
    value: '003722207029',
  },
  { value: 'HELSFIHH', title: 'Aktia (HELSFIHH)' },
  { value: 'DABAFIHH', title: 'Danske Bank (DABAFIHH)' },
  { value: 'DNBAFIHX', title: 'DNB (DNBAFIHX)' },
  { value: 'HANDFIHH', title: 'Handelsbanken (HANDFIHH)' },
  { value: 'NDEAFIHH', title: 'Nordea Pankki (NDEAFIHH)' },
  { value: 'ITELFIHH', title: 'Oma Säästöpankki Oyj (ITELFIHH)' },
  { value: 'OKOYFIHH', title: 'Osuuspankit ja Pohjola Pankki (OKOYFIHH)' },
  { value: 'POPFFI22', title: 'POP Pankki (POPFFI22)' },
  { value: 'SBANFIHH', title: 'S-Pankki (SBANFIHH)' },
  { value: 'TAPIFI22', title: 'S-Pankki (ent. LähiTapiola Pankin) (TAPIFI22)' },
  { value: 'ITELFIHH', title: 'Säästöpankit (ITELFIHH)' },
  { value: 'AABAFI22', title: 'Ålandsbanken (AABAFI22)' },
]

const VAT_MULTIPLIERS = [7, 9, 10, 5, 8, 4, 2]
const validateFinnishVatId = (vatId) => {
  if (!vatId) {
    return false
  }
  vatId = vatId.toUpperCase().replaceAll('-', '')
  const id = vatId.substring(0, 7)
  const idSum = id.split('').reduce((acc, cur, index) => {
    let c = parseInt(cur, 10)
    let multiplier = VAT_MULTIPLIERS[index]
    let sum = c * multiplier
    return acc + sum
  }, 0)
  const checkSum = idSum % 11
  const checkNumber = checkSum === 0 ? 0 : 11 - checkSum
  const vatCheckNumber = parseInt(vatId.substring(7, 8), 10)
  return checkNumber === vatCheckNumber
}

const validateVatId = (vatId) => {
  if (!vatId) {
    return false
  }
  const country = vatId.substring(0, 2)
  const id = vatId.substring(2)
  if (country === 'FI') {
    return validateFinnishVatId(id)
  } else {
    // fallback to Y-tunnus
    return validateFinnishVatId(vatId)
  }
}

function RegScreenOrganization({ auth, profile, dispatch, appconfig }) {
  const firebase = useFirebase()
  const { t } = useTranslation()
  // let defaultLanguage = 'FI'
  // const [cookies] = useCookies(['selectedLanguage'])
  // if (cookies.selectedLanguage) {
  //   defaultLanguage = cookies.selectedLanguage
  // }
  const [screen, setScreen] = useState('signup')
  const [companyName, setCompanyName] = useState()
  const [companyEmail, setCompanyEmail] = useState()
  const [companyPhone, setCompanyPhone] = useState()
  const [companyStreetaddress, setCompanyStreetaddress] = useState()
  const [companyCity, setCompanyCity] = useState()
  const [companyZip, setCompanyZip] = useState()
  const [companyCountry, setCompanyCountry] = useState()
  const [companyBusinessid, setCompanyBusinessid] = useState()
  const [companyEinvoiceAddress, setCompanyEinvoiceAddress] = useState()
  const [companyEinvoiceOperator, setCompanyEinvoiceOperator] = useState()
  const [companyBillingEmail, setCompanyBillingEmail] = useState()
  const [companyUserInput, setCompanyUserInput] = useState()
  const [companyUserInputRequireName, setCompanyUserInputRequireName] = useState(false)
  const [companyUserInputFirstname, setCompanyUserInputFirstname] = useState()
  const [companyUserInputLastname, setCompanyUserInputLastname] = useState()
  const [companyCompanyType, setCompanyCompanyType] = useState()
  const [companyUsers, setCompanyUsers] = useState()
  const [checkingUser, setCheckingUser] = useState(false)
  const [companyBusinessidValid, setCompanyBusinessidValid] = useState(false)
  const [password, setPassword] = useState()
  const [email, setEmail] = useState()
  const [firstname, setFirstname] = useState()
  const [lastname, setLastname] = useState()
  const [phone, setPhone] = useState()
  // eslint-disable-next-line no-unused-vars
  const [streetaddress, setStreetaddress] = useState()
  // eslint-disable-next-line no-unused-vars
  const [city, setCity] = useState()
  // eslint-disable-next-line no-unused-vars
  const [zip, setZip] = useState()
  const [tcmodal, setTCModal] = useState(false)
  // const [errorModalOpen, setErrorModalOpen] = useState(false)
  const [regerror, setRegError] = useState(false)
  const [regerrormessage, setRegErrorMessage] = useState('')
  const [registerationProcess, setRegisterationProcess] = useState(false)
  // const [loginErrorMessage, setLoginErrorMessage] = useState(null)
  const tcToggle = () => setTCModal(!tcmodal)
  // const errorToggle = () => setErrorModalOpen(!errorModalOpen)
  let history = useHistory()
  let isLoggedIn = !isEmpty(auth)
  const { width } = useWindowDimensions()

  const customerAppTitle = width > 1000 ? '' : ''

  const resetFormData = () => {
    setCompanyName(null)
    setCompanyEmail(null)
    setCompanyPhone(null)
    setCompanyStreetaddress(null)
    setCompanyCity(null)
    setCompanyZip(null)
    setCompanyCountry(null)
    setCompanyBusinessid(null)
    setCompanyUsers(null)
    setCompanyBusinessidValid(false)
  }

  const addCompanyUser = () => {
    if (!companyUserInput) return
    if (companyUserInputRequireName) {
      const u = {
        email: companyUserInput,
        role: 'USER',
        status: 'NEW',
        firstname: companyUserInputFirstname,
        lastname: companyUserInputLastname,
      }
      let _companyUsers = companyUsers || []
      if (_companyUsers.find((u) => u.email === companyUserInput)) return
      _companyUsers.push(u)
      setCompanyUsers(_companyUsers)
      setCompanyUserInput('')
      setCompanyUserInputRequireName(false)
    } else {
      // check if user exists
      const u = { email: companyUserInput, role: 'USER', status: 'CHECKING' }
      console.log('addCompanyUser')
      let _companyUsers = companyUsers || []
      if (_companyUsers.find((u) => u.email === companyUserInput)) return
      firebase.functions().region = 'europe-west1'
      let checkUser = firebase.functions().httpsCallable('users-cue')
      setCheckingUser(true)
      checkUser({ email: companyUserInput, c: appconfig.accountkey }).then((result) => {
        console.log(result)
        if (result.data.error) {
          setRegError(true)
          setRegErrorMessage(result.data.error)
        } else {
          if (result.data.existing) {
            u.status = 'EXISTING'
            _companyUsers.push(u)
          } else {
            u.status = 'NEW'
            setCompanyUserInputRequireName(true)
            setCompanyUserInputFirstname('')
            setCompanyUserInputLastname('')
            setCheckingUser(false)
            return
          }
        }
        setCompanyUsers(_companyUsers)
        setCompanyUserInput('')
        setCheckingUser(false)
      })
    }
  }

  const deleteCompanyUser = (email) => {
    let _companyUsers = companyUsers.filter((u) => u.email !== email)
    setCompanyUsers(_companyUsers)
  }

  const onRegisterPress = async () => {
    // console.log('Pressed reset', this.state)
    setRegisterationProcess(true)
    firebase.functions().region = 'europe-west1'
    let registration = firebase.functions().httpsCallable('organisation-registration')
    try {
      const contact = {
        firstname: profile.firstname,
        lastname: profile.lastname,
        email: profile.email,
        phone: profile.phone || null,
        streetaddress: profile.streetaddress || null,
        city: profile.city || null,
        zip: profile.zip || null,
        id: profile.id,
      }
      // remove null values from contact
      Object.keys(contact).forEach((key) => contact[key] == null && delete contact[key])

      const _r = {
        c: appconfig.accountkey,
        name: companyName,
        businessId: companyBusinessid,
        email: companyEmail,
        phone: companyPhone,
        streetaddress: companyStreetaddress,
        city: companyCity,
        zip: companyZip,
        country: companyCountry,
        einvoiceAddress: companyEinvoiceAddress,
        einvoiceOperator: companyEinvoiceOperator,
        billingEmail: companyBillingEmail,
        companyType: companyCompanyType,
        users: companyUsers,
        contact,
      }
      let response = await registration({ c: appconfig.accountkey, o: _r })
      // console.log(response)
      if (response.data.error) {
        setRegErrorMessage(response.data.message)
        setRegError(true)
      }
      resetFormData()
      setRegisterationProcess(false)
      return response
    } catch (error) {
      // console.log(error)
      setRegErrorMessage(error.message)
      setRegError(true)
      resetFormData()
      setRegisterationProcess(false)
      return { error: true, message: error.message }
    }
  }

  const isNewUserNameValid = () => {
    if (companyUserInputRequireName) {
      if (
        companyUserInputFirstname &&
        companyUserInputFirstname.length > 0 &&
        companyUserInputLastname &&
        companyUserInputLastname.length > 0
      ) {
        return true
      } else {
        return false
      }
    } else {
      return true
    }
  }

  const isCompanyDataValid = () => {
    if (
      (companyName &&
        companyName.length > 0 &&
        companyCompanyType &&
        companyCompanyType.length > 0 &&
        companyEmail &&
        companyEmail.length > 0 &&
        companyPhone &&
        companyPhone.length > 0 &&
        companyStreetaddress &&
        companyStreetaddress.length > 0 &&
        companyCity &&
        companyCity.length > 0 &&
        companyZip &&
        companyZip.length > 0,
      companyCountry && companyCountry.length > 0 && validateVatId(companyBusinessid))
    ) {
      if (
        (companyBillingEmail && companyBillingEmail.length > 0) ||
        (companyEinvoiceAddress &&
          companyEinvoiceAddress.length > 0 &&
          companyEinvoiceOperator &&
          companyEinvoiceOperator.length > 0 &&
          companyEinvoiceOperator.length > 0)
      ) {
        return true
      }
    }
    return false
  }

  const changeBusinessId = (e) => {
    setCompanyBusinessid(e.target.value)
    if (e.target.value.length > 6) {
      if (validateVatId(e.target.value)) {
        setCompanyBusinessidValid(true)
      } else {
        setCompanyBusinessidValid(false)
      }
    }
  }

  if (screen === 'signup') {
    const isValidForm = email && firstname && lastname && phone && password && password.length > 6
    return (
      <div className='full-page-background'>
        {/* <TopNavigation /> */}
        <Container className='login-dialog'>
          <Row>
            <Col md='5' className='mx-auto'>
              <Form className='myform'>
                <div className='logo mb-3'>
                  <div className='col-md-12 text-center'>
                    <img src={process.env.PUBLIC_URL + '/imgs/logo.png'} alt='C' className='brandlogo' />
                    <span className='brandtext'>CINTOIA</span>
                    <h2>{t('register_as_company')}</h2>
                    <span className='appname'>{customerAppTitle}</span>
                  </div>
                </div>
                <Typography variant='h5' className='text-center' style={{ marginBottom: 10 }}>
                  {t('company_contact_person')}
                </Typography>
                {isLoggedIn ? (
                  <React.Fragment>
                    <Typography variant='body1' className='text-center' style={{ marginBottom: 10 }}>
                      Tunnusta {profile.email} käytetään yrityksen pääkäyttäjänä. Jos haluat käyttää toista tunnusta,
                      niin kirjaudu ulos ja luo uusi tunnus.
                    </Typography>
                    <Button
                      color='info'
                      block
                      size='sm'
                      onClick={() => {
                        firebase.logout().then(() => {
                          dispatch({
                            type: 'USER_LOGOUT',
                            payload: null,
                          })
                          setScreen('login')
                        })
                      }}
                    >
                      Kirjaudu ulos
                    </Button>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Typography variant='body1' className='text-center'>
                      {t('register_as_company_contact_person')}Jos sinulla on jo tunnus ja haluat käyttää sitä
                      yhteyshenkilönä niin kirjaudu ensin sisään.
                    </Typography>
                    <Button
                      size='small'
                      color='link'
                      block
                      onClick={() => history.push(appconfig.pathPrefix + '/login?r=regcompany&_c' + Date.now())}
                    >
                      Kirjaudu sisään
                    </Button>
                    <FormGroup className='loose-form'>
                      <Label for='firstname'>{t('first_name')} *</Label>
                      <Input
                        type='text'
                        name='firstname'
                        id='firstname'
                        placeholder={t('first_name')}
                        autoComplete={'given-name'}
                        onChange={(event) => setFirstname(event.target.value)}
                      />
                    </FormGroup>
                    <FormGroup className='loose-form'>
                      <Label for='lastname'>{t('last_name')} *</Label>
                      <Input
                        type='text'
                        name='lastname'
                        id='lastname'
                        placeholder={t('last_name')}
                        autoComplete={'family-name'}
                        onChange={(event) => setLastname(event.target.value)}
                      />
                    </FormGroup>
                    <FormGroup className='loose-form'>
                      <Label for='phone'>{t('phone')} *</Label>
                      <Input
                        type='text'
                        name='phone'
                        id='phone'
                        placeholder={t('phone')}
                        autoComplete={'tel'}
                        onChange={(event) => setPhone(event.target.value)}
                      />
                    </FormGroup>
                    <FormGroup className='loose-form'>
                      <Label for='email'>{t('email')} *</Label>
                      <Input
                        type='email'
                        name='email'
                        id='email'
                        placeholder={t('email')}
                        autoComplete={'email'}
                        onChange={(event) => setEmail(event.target.value)}
                      />
                    </FormGroup>
                    <FormGroup className='loose-form'>
                      <Label for='streetaddress'>{t('address')}</Label>
                      <Input
                        type='text'
                        name='streetaddress'
                        id='streetaddress'
                        placeholder={t('address')}
                        onChange={(event) => setStreetaddress(event.target.value)}
                      />
                    </FormGroup>
                    <FormGroup className='loose-form'>
                      <Label for='city'>{t('city')}</Label>
                      <Input
                        type='text'
                        name='city'
                        id='city'
                        placeholder={t('city')}
                        onChange={(event) => setCity(event.target.value)}
                      />
                    </FormGroup>
                    <FormGroup className='loose-form'>
                      <Label for='zip'>{t('zip')}</Label>
                      <Input
                        type='number'
                        name='zip'
                        id='zip'
                        placeholder={t('zip')}
                        onChange={(event) => setZip(event.target.value)}
                      />
                    </FormGroup>
                    <FormGroup className='loose-form'>
                      <Label for='password'>
                        {t('password')} ({t('password_minimum')}) *
                      </Label>
                      <Input
                        type='password'
                        name='password'
                        id='password'
                        invalid={password && password.length < 7}
                        // placeholder='salasana (minimi 7 merkkiä)'
                        autoComplete={'new-password'}
                        onChange={(event) => setPassword(event.target.value)}
                      />
                    </FormGroup>
                    <div className='col-md-12 text-center mb-3'>
                      {t('cintoia_account.part1')}
                      <br />
                      {t('cintoia_account.part2')}
                    </div>

                    <FormGroup className='loose-form'>
                      <p className='text-center'>
                        {' '}
                        <Button color='link' onClick={tcToggle}>
                          {t('register_accepttandc')}
                        </Button>
                      </p>
                    </FormGroup>
                    <Button
                      color={isValidForm ? 'primary' : 'secondary'}
                      block
                      disabled={!isValidForm}
                      onClick={() => onRegisterPress().then((result) => setScreen('regInfo'))}
                    >
                      {isValidForm ? t('rekisteroidy') : t('fill_all_fields')}
                    </Button>
                  </React.Fragment>
                )}
                <Divider style={{ marginTop: 20, marginBottom: 20 }} />
                <Typography variant='h6' className='text-center' style={{ marginBottom: 15 }}>
                  {t('register_as_company_organization_title')}
                </Typography>
                <FormGroup className='loose-form'>
                  <Label for='companyName'>{t('organisation_name')} *</Label>
                  <Input
                    type='text'
                    name='companyName'
                    id='companyName'
                    placeholder={t('organisation_name')}
                    onChange={(event) => setCompanyName(event.target.value)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for='companyCompanyType'>{t('business_type')}</Label>
                  <div>
                    <Select
                      variant='outlined'
                      // inputProps={{ style: { padding: 2 } }}
                      fullWidth
                      id='companyCompanyType'
                      value={companyCompanyType}
                      onChange={(event) => setCompanyCompanyType(event.target.value)}
                      displayEmpty
                      SelectDisplayProps={{ style: { padding: 10 } }}
                    >
                      {COMPANY_TYPES.fi.map((type) => (
                        <MenuItem key={type.value} value={type.value}>
                          {type.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                </FormGroup>

                <FormGroup className='loose-form'>
                  <Label for='companyBusinessid'>{t('business_id')} *</Label>
                  <Input
                    type='text'
                    name='companyBusinessid'
                    id='companyBusinessid'
                    invalid={
                      (companyBusinessid && companyBusinessid.length < 7) ||
                      (companyBusinessid && !companyBusinessidValid)
                    }
                    placeholder={t('business_id')}
                    onChange={changeBusinessId}
                  />
                </FormGroup>
                <FormGroup className='loose-form'>
                  <Label for='companyPhone'>{t('phone')} *</Label>
                  <Input
                    type='text'
                    name='companyPhone'
                    id='companyPhone'
                    placeholder={t('phone')}
                    autoComplete={'tel'}
                    onChange={(event) => setCompanyPhone(event.target.value)}
                  />
                </FormGroup>
                <FormGroup className='loose-form'>
                  <Label for='companyEmail'>{t('email')} *</Label>
                  <Input
                    type='email'
                    name='companyEmail'
                    id='companyEmail'
                    placeholder={t('email')}
                    autoComplete={'email'}
                    onChange={(event) => setCompanyEmail(event.target.value)}
                  />
                </FormGroup>
                <FormGroup className='loose-form'>
                  <Label for='companyStreetaddress'>{t('address')} *</Label>
                  <Input
                    type='text'
                    name='companyStreetaddress'
                    id='companyStreetaddress'
                    placeholder={t('address')}
                    onChange={(event) => setCompanyStreetaddress(event.target.value)}
                  />
                </FormGroup>
                <FormGroup className='loose-form'>
                  <Label for='companyCity'>{t('city')} *</Label>
                  <Input
                    type='text'
                    name='companyCity'
                    id='companyCity'
                    placeholder={t('city')}
                    onChange={(event) => setCompanyCity(event.target.value)}
                  />
                </FormGroup>
                <FormGroup className='loose-form'>
                  <Label for='companyZip'>{t('zip')} *</Label>
                  <Input
                    type='number'
                    name='companyZip'
                    id='companyZip'
                    placeholder={t('zip')}
                    onChange={(event) => setCompanyZip(event.target.value)}
                  />
                </FormGroup>
                <FormGroup className='loose-form'>
                  <Label for='companyCountry'>{t('country')} *</Label>
                  <Input
                    type='number'
                    name='companyCountry'
                    id='companyCountry'
                    placeholder={t('country')}
                    onChange={(event) => setCompanyCountry(event.target.value)}
                  />
                </FormGroup>

                <FormGroup className='loose-form'>
                  <Label for='companyBillingEmail'>{t('billing_email')} *</Label>
                  <Input
                    type='email'
                    name='companyBillingEmail'
                    id='companyBillingEmail'
                    placeholder={t('billing_email')}
                    autoComplete={'email'}
                    onChange={(event) => setCompanyBillingEmail(event.target.value)}
                  />
                </FormGroup>

                <FormGroup className='loose-form'>
                  <Label for='companyEinvoiceAddress'>{t('einvoice_address')}</Label>
                  <Input
                    type='text'
                    name='companyEinvoiceAddress'
                    id='companyEinvoiceAddress'
                    placeholder={t('einvoice_address')}
                    onChange={(event) => setCompanyEinvoiceAddress(event.target.value)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for='companyEinvoiceOperator'>{t('einvoice_operator')}</Label>
                  <div>
                    <Select
                      variant='outlined'
                      // inputProps={{ style: { padding: 2 } }}
                      fullWidth
                      id='einvoice_operator'
                      value={companyEinvoiceOperator}
                      onChange={(event) => setCompanyEinvoiceOperator(event.target.value)}
                      displayEmpty
                      SelectDisplayProps={{ style: { padding: 10 } }}
                    >
                      {EINVOICE_OPERATORS.map((operator) => (
                        <MenuItem key={operator.value} value={operator.value}>
                          {operator.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                </FormGroup>

                <FormGroup className='loose-form'>
                  <Label for='companyUserInput'>
                    {t('company_user')} {t('email')}
                  </Label>
                  <Row>
                    <Col xs={9}>
                      <Input
                        type='text'
                        name='companyUserInput'
                        id='companyUserInput'
                        value={companyUserInput}
                        placeholder={t('email')}
                        onChange={(event) => setCompanyUserInput(event.target.value)}
                      />
                    </Col>
                    <Col className='text-left'>
                      {checkingUser ? (
                        <Spinner size='sm' color='secondary' />
                      ) : (
                        <Button
                          color='secondary'
                          disabled={!(validateEmail(companyUserInput) && isNewUserNameValid())}
                          onClick={() => addCompanyUser()}
                        >
                          {t('add')}
                        </Button>
                      )}
                    </Col>
                  </Row>
                </FormGroup>
                {companyUserInputRequireName ? (
                  <Row>
                    <Col>
                      <Typography variant='subtitle2'>{t('new_user_info')}</Typography>
                      <FormGroup className='loose-form'>
                        <Label for='companyUserInputFirstname'>{t('first_name')} *</Label>
                        <Input
                          type='text'
                          name='companyUserInputFirstname'
                          id='companyUserInputFirstname'
                          placeholder={t('first_name')}
                          autoComplete={'given-name'}
                          onChange={(event) => setCompanyUserInputFirstname(event.target.value)}
                        />
                      </FormGroup>
                      <FormGroup className='loose-form'>
                        <Label for='companyUserInputLastname'>{t('last_name')} *</Label>
                        <Input
                          type='text'
                          name='companyUserInputLastname'
                          id='companyUserInputLastname'
                          placeholder={t('last_name')}
                          autoComplete={'family-name'}
                          onChange={(event) => setCompanyUserInputLastname(event.target.value)}
                        />
                      </FormGroup>
                      <Button
                        color='secondary'
                        disabled={!(validateEmail(companyUserInput) && isNewUserNameValid())}
                        onClick={() => addCompanyUser()}
                      >
                        {t('add')}
                      </Button>
                    </Col>
                  </Row>
                ) : null}
                <List dense={'dense'}>
                  {companyUsers ? (
                    companyUsers.map((user) => (
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar style={user.status === 'NEW' ? avatarClasses.new : avatarClasses.existing}>
                            <PersonIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={user.email ? user.email : null}
                          secondary={
                            user.firstname && user.lastname ? user.firstname + ' ' + user.lastname : t('existing_user')
                          }
                        />
                        <ListItemSecondaryAction>
                          <IconButton edge='end' aria-label='delete' onClick={() => deleteCompanyUser(user.email)}>
                            <DeleteIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))
                  ) : (
                    <Typography>{t('no_users')}</Typography>
                  )}
                </List>

                <Button
                  color='primary'
                  className='mt-4'
                  disabled={!isCompanyDataValid()}
                  onClick={() => onRegisterPress().then((result) => setScreen('regInfo'))}
                >
                  {isValidForm ? t('rekisteroidy') : t('fill_all_fields')}
                </Button>

                <Divider style={{ marginTop: 20 }} />
                <Button
                  style={{ marginTop: 30 }}
                  color='link'
                  className='loose-form'
                  onClick={() => history.push(appconfig.pathPrefix + '/login')}
                >
                  {t('to_login')}
                </Button>
                {/* <Button color='link' className='loose-form' onClick={() => history.push(appconfig.pathPrefix + '/signin')}>
                  Salasana unohtunut
                </Button> */}
                <Row>
                  <Col>
                    {' '}
                    <Button
                      size='small'
                      color='link'
                      className='text-muted'
                      onClick={() => history.push(appconfig.pathPrefix + '/')}
                    >
                      <ChevronLeftIcon /> {t('back_to_reservation')}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Container>
        <Modal isOpen={tcmodal} toggle={tcToggle}>
          <ModalHeader toggle={tcToggle}>{t('tandc.usage_terms')}</ModalHeader>
          <ModalBody>
            <div>
              <strong>{t('tandc.title1')}</strong>
              <p>{t('tandc.body1')}</p>
              <strong>{t('tandc.title2')}</strong>
              <p>{t('tandc.body2')}</p>
              <strong>{t('tandc.title3')}</strong>
              <p>{t('tandc.body3')}</p>
              <p>{t('tandc.body4').replaceAll('{CANCELLIMIT}', appconfig.cancellimit || '24')}</p>{' '}
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color='primary' onClick={tcToggle}>
              Sulje
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          size='sm'
          centered
          isOpen={registerationProcess}
          backdrop={true}
          wrapClassName={'al-wrap'}
          modalClassName={'al-modal'}
          contentClassName={'loading-spinner'}
        >
          <ModalBody className='text-center'>
            <Spinner color='light' />
          </ModalBody>
        </Modal>
      </div>
    )
  } else if (screen === 'regInfo') {
    if (regerror) {
      return (
        <div className='full-page-background'>
          <TopNavigation />
          <Container className='login-dialog'>
            <Row>
              <Col md='5' className='mx-auto'>
                <Form className='myform'>
                  <div className='logo mb-3'>
                    <div className='col-md-12 text-center'>
                      <h2>Rekisteröinti</h2>
                    </div>
                  </div>
                  <FormGroup className='loose-form'>
                    <p className='text-center'>{regerrormessage}</p>
                  </FormGroup>
                  <Button
                    style={{ marginTop: 30 }}
                    color='link'
                    className='loose-form'
                    onClick={() => history.push(appconfig.pathPrefix + '/login')}
                  >
                    Salasanan resetointi
                  </Button>
                  <Button
                    style={{ marginTop: 30 }}
                    color='link'
                    className='loose-form'
                    onClick={() => history.push(appconfig.pathPrefix + '/login')}
                  >
                    Kirjaudu
                  </Button>
                </Form>
              </Col>
            </Row>
          </Container>
        </div>
      )
    } else {
      return (
        <div className='full-page-background'>
          <TopNavigation />
          <Container className='login-dialog'>
            <Row>
              <Col md='5' className='mx-auto'>
                <Form className='myform'>
                  <div className='logo mb-3'>
                    <div className='col-md-12 text-center'>
                      <h2>Rekisteröinti onnistui</h2>
                    </div>
                  </div>
                  <FormGroup className='loose-form'>
                    <p className='text-center'>Voit kirjautua sisään tunnuksella {email} alla olevasta linkistä.</p>
                  </FormGroup>
                  <Button
                    style={{ marginTop: 30 }}
                    color='link'
                    className='loose-form'
                    onClick={() => history.push(appconfig.pathPrefix + '/login')}
                  >
                    Kirjaudu
                  </Button>
                </Form>
              </Col>
            </Row>
          </Container>
        </div>
      )
    }
  } else {
    return (
      <div className='full-page-background'>
        <TopNavigation />
        <Container className='login-dialog'>
          <Row>
            <Col md='5' className='mx-auto'>
              <Form className='myform'>
                <div className='logo mb-3'>
                  <div className='col-md-12 text-center'>
                    <h2>Oops !</h2>
                  </div>
                </div>
                <FormGroup className='loose-form'>
                  <p className='text-center'>Jouduit jonnekkin outoon paikkaan!</p>
                </FormGroup>
                <Button
                  color='link'
                  className='loose-form'
                  onClick={() => history.push(appconfig.pathPrefix + '/login')}
                >
                  Takaisin kirjautumiseen
                </Button>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

export default withAppconfig(enhance(RegScreenOrganization))
