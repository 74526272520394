import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFirebase } from 'react-redux-firebase'
import { useHistory, useParams } from 'react-router-dom'
import withAppconfig from '../AppContext'
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardHeader,
  CardText,
  // Button,
  Spinner,
  Modal,
  ModalBody,
} from 'reactstrap'
import moment from 'moment'
import 'moment/locale/fi'
import { Divider } from '@material-ui/core'
import Payments from '../components/Payments'
// import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import TopNavigation from '../TopNavigation'
import { connect } from 'react-redux'
import { compose } from 'redux'
import PaymentRedirect from './PaymentRedirect'
import PaymentOptions from './PaymentOptions'

const enhance = compose(
  connect((state) => ({ auth: state.firebase.auth }))
)



function OnetimeScreen({ auth, profile, cashsum, sportmoneysum, wallet, dispatch, appconfig, isLoggedIn }) {
  const firebase = useFirebase()
  firebase.functions().region = 'europe-west1'
  let history = useHistory()
  const params = useParams()
  // let defaultLanguage = 'FI'
  const { t } = useTranslation()
  const [loading, setLoading] = useState(true)
  const [summary, setSummary] = useState(null)
  // eslint-disable-next-line no-unused-vars
  const [redirect, setRedirect] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [renderPayments, setRenderPayments] = useState(false)
  const [token, setToken] = useState(null)
  // const [payments, setPayments] = useState(null)
  const [errorNotification, setErrorNotification] = useState(null)
  // eslint-disable-next-line no-unused-vars
  const [onlinepaymentprovider, setOnlinePaymentProvider] = useState(null)

  const getToken = async () => {
    const tokenResult = await firebase.auth().currentUser.getIdTokenResult()
    setToken(tokenResult.token)
  }

  useEffect(() => {
    if (!token) {
      getToken()
    }
  })

  useEffect(() => {
    const fn = appconfig.istest ? 'testonetime-onetimepayment' : 'onetime-onetimepayment'
    firebase.functions().httpsCallable(fn)({ id: params.onetimeid }).then((response) => {
      // console.log(response)
      if (response.data.error) {
        alert(response.data.message)
      }
      const payable = response.data
      payable.coid = params.onetimeid
      dispatch({
        type: 'UPDATE_PAYABLE_RESERVATION',
        payload: payable,
      })
      setSummary(payable)
      setLoading(false)
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const setPaymentProvider = (p) => setOnlinePaymentProvider(p)

  // const setPaymentSummary = (sportmoneysaldo, cashwallet, online) => {
  //   // console.log('setPaymentSummary', sportmoneysaldo, cashwallet, online)
  //   const checkedPayments = []
  //   let selectedPrice = summary.price * 100
  //   // const selectedPrice = selected.price * 100
  //   let leftToPay = selectedPrice
  //   if (sportmoneysum > 0) {
  //     let charged = 0
  //     if (sportmoneysaldo) {
  //       if (leftToPay <= sportmoneysum) {
  //         charged = leftToPay
  //         leftToPay = 0
  //       } else {
  //         charged = sportmoneysum
  //         leftToPay = leftToPay - sportmoneysum
  //       }
  //     }
  //     checkedPayments.push({ type: 'sportmoneysaldo', charged })
  //   }
  //   if (cashsum > 0) {
  //     let charged = 0
  //     if (cashwallet) {
  //       if (leftToPay <= cashsum) {
  //         charged = leftToPay
  //         leftToPay = 0
  //       } else {
  //         charged = cashsum
  //         leftToPay = leftToPay - cashsum
  //       }
  //     }
  //     checkedPayments.push({ type: 'cashwallet', charged })
  //   }
  //   if (leftToPay > 0 && online) {
  //     checkedPayments.push({ type: 'paytrail', charged: leftToPay })
  //   } else {
  //     checkedPayments.push({ type: 'paytrail', charged: 0 })
  //   }
  //   setPayments(checkedPayments)
  // }

  const postpayReservation = async (selectedPayments) => {
    setLoading(true)
    
    console.log(selectedPayments)
    console.log(summary)

    const cartObject = {
      customerid: appconfig.accountkey,
      rids: [{ id: summary.id, occurency: summary.occurency }],
      paymentmethod: selectedPayments,
      onetimepayment: {
        id: summary.coid
      }
    }


    // const _resource = { ...summary }
    // delete _resource.start
    // delete _resource.end
    // _resource.locationid = appconfig.accountkey
    // const reservationObject = {
    //   id: summary.id,
    //   start: summary.start,
    //   duration: summary.duration,
    //   resource: _resource,
    //   paymentmethod: selectedPayments, // online [{ type }]
    // }
    // if (summary.occurency) reservationObject.occurency = summary.occurency
    
    const shouldRenderOptions = selectedPayments.find((x) => x.type === 'online' && x.charged > 0)
    const shouldRedict = selectedPayments.find((x) => x.type === 'online' && x.charged > 0)
    firebase.functions().region = 'europe-west1'
    const fn = appconfig.istest ? 'testreservations-paymultiv4' : 'reservations-paymultiv4'
    const payReservation = firebase.functions().httpsCallable(fn)
    try {
      let response = await payReservation(cartObject)
      console.log('ONETIME', response.data)
      if (response.data.error) {
        setLoading(false)
        setErrorNotification(response.data.error)
      } else if (shouldRenderOptions) {
        setRenderPayments(true)
      } else if (shouldRedict) {
        setRedirect(true)
      } else {
        history.push(appconfig.pathPrefix + '/')
      }
    } catch (error) {
      alert(error.message)
      // console.log(error)
      setLoading(false)
    }
  }


  if (renderPayments) {
    return (
      <PaymentOptions
        selectedGw={'pc'}
        authorization={token}
        provider={onlinepaymentprovider}
        disabledmethods={appconfig.disabledpaymentmethods || []}
      />
    )
  } else if (redirect) {
    return <PaymentRedirect selectedGw={'pc'} authorization={token} />
  }

  if (loading) {
    return (
      <div className='full-page-background'>
        <Container className='login-dialog'>
          <Row><Col className='text-center'><Spinner /></Col></Row>
        </Container>
      </div>
    )
  } else {
    return (
      <div className='full-page-background'>
        <TopNavigation />
        <Container className='login-dialog'>
          <Row>
            <Col md='8' className='mx-auto'>
              <Card>
                <CardHeader tag='h4'>{t('shared_payment')}</CardHeader>
                <CardBody>
                  <CardTitle tag='h5'>{summary.displayName}</CardTitle>
                  <Row className={'loose'}>
                    <Col>
                      {summary.title}
                    </Col>
                  </Row>
                  <Row className={'loose'}>
                    <Col>
                      {summary.message}
                    </Col>
                  </Row>
                  <Divider />
                  <Row className={'loose'}>
                    <Col>{t('day')}</Col>
                    <Col style={{ textTransform: 'capitalize' }} className={'text-right'}>
                      {moment(summary.start).utc().format('dddd D.M.YYYY')}
                    </Col>
                  </Row>
                  <Divider />
                  <Row className={'loose'}>
                    <Col>{t('time')}</Col>
                    <Col className={'text-right'}>
                      {moment(summary.start).utc().format('H:mm')} - {moment(summary.end).utc().format('H:mm')}
                    </Col>
                  </Row>
                  <Divider />
                  <Row className={'loose'}>
                    <Col>{t('price')}</Col>
                    <Col className={'text-right'}>{summary.price} €</Col>
                  </Row>
                  <CardText></CardText>
                  {isLoggedIn ? (
                    <div>
                    <CardText>{t('logged_in')}</CardText>
                      <Payments price={summary.price} selecttype='payable' unallowedmethods={['cashier']} buttonText={t('pay')} buttonAction={postpayReservation} errorNotification={errorNotification} setPaymentProvider={setPaymentProvider} ready={true} />
                  </div>
                  ) : (
                    <div>
                      <CardText>{t('login_to_pay')}</CardText>
                    </div>
                  )}
                  {/* <Button size='small' color='link' className='text-muted' onClick={() => goReservations()}>
                    <ChevronLeftIcon /> {t('back_to_reservation')}
                  </Button> */}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <Modal
          size='sm'
          centered
          isOpen={loading}
          backdrop={true}
          wrapClassName={'al-wrap'}
          modalClassName={'al-modal'}
          contentClassName={'loading-spinner'}
        >
          <ModalBody className='text-center'>
            <Spinner color='light' /> <p style={{ color: 'white' }}>Vahvistetaan</p>
          </ModalBody>
        </Modal>
      </div>
    )
  }

}

export default withAppconfig(enhance(OnetimeScreen))
