import React, { useState, useLayoutEffect } from 'react'
import moment from 'moment'
import 'moment/locale/en-gb'
import 'moment/locale/et'
import 'moment/locale/sv'
import 'moment/locale/fi'
import '../SchoolSchedule.css'
import { Button } from 'reactstrap'
import { yellow } from '@material-ui/core/colors'
import Dialog from '@material-ui/core/Dialog'
import ActivityCard from './ActivityCard'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import DoubleScrollbar from 'react-double-scrollbar'
import { DialogTitle } from '@material-ui/core'
import useWindowDimensions from '../useWindowsDimensions'
import withAppconfig from '../AppContext'
import { useFirebase } from 'react-redux-firebase'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

// import { useTranslation } from 'react-i18next'

function useWindowSize(elementclasses) {
  const [size, setSize] = useState(
    elementclasses.map((ec) => {
      return { [ec]: [0, 0] }
    })
  )
  useLayoutEffect(() => {
    function updateSize() {
      const _ecs = {}
      elementclasses.forEach((elementclass) => {
        const el = document.getElementsByClassName(elementclass)[0]
        if (el) {
          _ecs[elementclass] = el.getBoundingClientRect()
          // console.log('updateSize', _ecs[elementclass])
        }
      })
      if (Object.keys(_ecs).length > 0) {
        setSize(_ecs)
      }
      // setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return size
}

const findMinAndMaxHours = (activities) => {
  let startHour = 12
  let endHour = 12
  activities.forEach((activity) => {
    const { start, end } = activity
    // console.log('activity start', start, 'end', end)
    const startH = moment(start).hour()
    const endH = moment(end).hour() + 1
    if (startH < startHour) {
      startHour = startH
    }
    if (endH > endHour) {
      endHour = endH
    }
  })
  return { startHour, endHour }
}

const groupedByDay = (activities) => {
  console.log('groupedByDay', activities)
  return activities.reduce(
    (acc, activity) => {
      const day = moment(activity.start).isoWeekday()
      if (!acc[day]) {
        acc[day] = []
      }
      acc[day].push(activity)
      return acc
    },
    { 1: [], 2: [], 3: [], 4: [], 5: [], 6: [], 7: [] }
  )
}

function ActionButton({
  activity,
  paymentAction,
  cancelAction,
  buttonSignUpText,
  buttonCancelText,
  hasqueue,
  hasEntry,
  isInQueue,
  isFull,
  hasLittleRoom,
  signUpOpen,
}) {
  if (signUpOpen) {
    if (hasEntry) {
      return (
        <button
          className='schedule-item-button cancelcolor'
          variant='contained'
          color='secondary'
          onClick={cancelAction}
        >
          {buttonCancelText}
        </button>
      )
    } else if (isInQueue) {
      return (
        <button
          className='schedule-item-button cancelcolor'
          variant='contained'
          color='secondary'
          onClick={cancelAction}
        >
          {buttonCancelText}
        </button>
      )
    } else if (!isFull) {
      return (
        <button
          className='schedule-item-button stillroom'
          variant='contained'
          color={'primary'}
          style={hasLittleRoom ? { backgroundColor: yellow[400], color: 'black' } : {}}
          onClick={paymentAction}
        >
          {buttonSignUpText}
        </button>
      )
    } else {
      if (hasqueue) {
        return (
          <button
            className='schedule-item-button toqueue'
            variant='contained'
            color={'primary'}
            onClick={paymentAction}
          >
            Jonoon
          </button>
        )
      } else {
        return (
          <button
            className='schedule-item-button noroom'
            variant='contained'
            color='primary'
            style={{ backgroundColor: yellow[400], color: 'black' }}
            disabled
          >
            Täynnä
          </button>
        )
      }
    }
  } else {
    // signup not open any more
    return (
      <button className='schedule-item-button entryended' variant='contained' color='secondary' disabled>
        Sulkeutunut
      </button>
    )
  }
}

const TOP_OFFSET = 4

// const DAYS = [0, 1, 2, 3, 4, 5, 6]
const headerHeight = 30

function TimeGrid({ start, end, showHalfhour = false }) {
  const startHour = start + 1 //parseInt(start.split(':')[0]) + 1
  const endHour = end + 1 //parseInt(end.split(':')[0])
  const halfhours = showHalfhour
  let hourNumbers = []
  for (let i = startHour; i < endHour; i++) {
    hourNumbers.push(i)
  }
  // console.log('hourNumbers', hourNumbers, start, end)
  // const gridHeight = (end + 1 - start) * 60 - headerHeight - TOP_OFFSET

  return hourNumbers.map((hour, index, org) => (
    <React.Fragment>
      {halfhours && index === 0 ? (
        <div className='schedule-halfhour' style={{ marginTop: TOP_OFFSET + 'px' }}></div>
      ) : null}
      <div
        className={halfhours ? 'schedule-hour' : 'schedule-hourhour'}
        style={!halfhours && index === 0 ? { marginTop: TOP_OFFSET + 'px' } : {}}
      >
        <div className={halfhours ? 'schedule-hourtexttwo' : 'schedule-hourtext'}>
          <span className={'schedule-timeheader'}>{hour < 10 ? '\u00A0' + hour + '\u00A0' : hour}</span>
        </div>
      </div>
      {halfhours && index + 1 !== org.length ? <div className='schedule-halfhour'></div> : null}
    </React.Fragment>
  ))
}

function ScheduleColumn({
  activities,
  day,
  start,
  end,
  size,
  gtime,
  uid,
  onInfoClick,
  paymentAction,
  cancelAction,
  showHalfhour = false,
}) {
  const { width } = useWindowDimensions()
  const border = 1
  const startHour = start
  const columnHeight = (end + 1 - startHour) * 60

  const handleInfoClick = (e, activity) => {
    e.preventDefault()
    e.stopPropagation()
    onInfoClick(activity)
  }

  const handleSignUpClick = (e, activity) => {
    e.preventDefault()
    e.stopPropagation()
    paymentAction(activity)
  }

  const handleCancelClick = (e, activity) => {
    e.preventDefault()
    e.stopPropagation()
    cancelAction(activity.id)
  }

  return (
    <div className='schedule-column' style={{ height: columnHeight + 'px' }}>
      <div className='schedule-header'>
        <div className='schedule-headertext'>{moment().day(day).format('dddd')}</div>
      </div>
      {activities.map((activity) => {
        let { displayName, start, end, participants, queue, hasqueue, maxp } = activity
        if (!participants) participants = []
        if (!queue) queue = []
        const hasEntry = participants.find((x) => x.id === uid)
        const isInQueue = queue.find((x) => x.id === uid)
        const isFull = participants.length >= maxp
        const hasLittleRoom = participants.length > Math.floor(maxp * 0.75) && participants.length < maxp
        // const canSignUp = !hasEntry && !isInQueue && !isFull
        // const canQueue = !hasEntry && !isInQueue && participants.length >= maxp
        const signUpOpen = moment.utc(activity.entryend, 'YYYY-MM-DDTHH:mm').subtract(moment().tz('Europe/Helsinki').utcOffset(), 'minutes').isAfter(gtime.utc())

        const startMoment = moment.utc(start)
        const top =
          startMoment.diff(startMoment.clone().startOf('day'), 'minutes') -
          startHour * 60 +
          headerHeight -
          border +
          TOP_OFFSET // parseInt(time[0]) * 60 + parseInt(time[1]) - startMinutes + topPos + headerHeight
        // let buttonColoringClass = 'stillroom'
        // if (isFull) {
        //   buttonColoringClass = 'noroom'
        // } else if (isLittleRoom) {
        //   buttonColoringClass = 'littleroom'
        // }

        return (
          <div
            class='schedule-item'
            data-time={moment.utc(start).format('HH:mm')}
            data-duration={moment.utc(end).diff(moment.utc(start), 'minutes')}
            style={{
              height: moment.utc(end).diff(moment.utc(start), 'minutes') + 'px',
              width: size.width / 7 - 6 + 'px',
              top: top + 'px',
              backgroundImage: `linear-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.6)), url(${activity.poster})`, //,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              zIndex: activity.zindex || 2,
            }}
            onClick={(e) => {
              handleInfoClick(e, activity)
            }}
          >
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              {moment.utc(start).format('HH:mm')}
              {width > 770 ? ' - ' + moment.utc(end).format('HH:mm') : null}{' '}
              <ActionButton
                activity={activity}
                paymentAction={(e) => {
                  handleSignUpClick(e, activity)
                }}
                cancelAction={(e) => {
                  handleCancelClick(e, activity)
                }}
                buttonSignUpText={'Ilmoittaudu'}
                buttonCancelText={'Peruuta'}
                hasqueue={hasqueue}
                hasEntry={hasEntry}
                isInQueue={isInQueue}
                isFull={isFull}
                hasLittleRoom={hasLittleRoom}
                signUpOpen={signUpOpen}
              />
              {/* <button className={`schedule-item-button ${buttonColoringClass}`} style={{}} onClick={() => {}}>
                Ilmoittaudu
              </button> */}
            </div>
            <div>
              {displayName ? displayName.replace(/Ryhmäliikunta \//gi, '') : ''}{' '}
              {!activity.isexternal && activity.participants
                ? '(' + activity.participants.length + '/' + maxp + ')'
                : null}
            </div>
            {moment.utc(end).diff(moment.utc(start), 'minutes') > 69 ? (
              <div className='schedule-ingress'>{activity.ingress.fi}</div>
            ) : null}
          </div>
        )
      })}
      <TimeGrid start={start} end={end} showHalfhour={true} />
    </div>
  )
}

function _ScheduleGrid({ grouped, startHour, endHour, gtime, uid, appconfig, dispatch, showHalfhour = false }) {
  const [selectedActivity, setSelectedActivity] = useState(null)
  const sizes = useWindowSize(['schedule-grid', 'schedule-timegrid'])
  const firebase = useFirebase()
  const history = useHistory()
  // eslint-disable-next-line no-unused-vars
  const [confirmationOpen, setConfirmationOpen] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false)

  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer')
  }

  const goToPayActivity = (activity) => {
    if (activity.isexternal) {
      openInNewTab(activity.externalurl)
    } else {
      dispatch({
        type: 'UPDATE_PAYABLE_ACTIVITY',
        payload: activity,
      })
      history.push(appconfig.pathPrefix + '/bookactivity')
    }
  }

  const cancelActivity = async (id) => {
    setConfirmationOpen(false)
    setLoading(true)
    const activityObject = {
      id: id,
      customerid: appconfig.accountkey,
    }
    firebase.functions().region = 'europe-west1'
    const fn = appconfig.istest ? 'testactivities-cancel' : 'activities-cancel'
    let cancel = firebase.functions().httpsCallable(fn)
    try {
      const response = await cancel(activityObject)
      setLoading(false)
      if (response.data.error) {
        alert(response.data.message)
      }
    } catch (error) {
      setLoading(false)
      alert(error.message)
    }
  }

  return (
    <div className='schedule-container'>
      <DoubleScrollbar>
        <div className='schedule-grid' data-start={startHour} data-end={endHour}>
          {Object.keys(grouped).map((day) => {
            return (
              <ScheduleColumn
                key={day}
                day={day}
                activities={grouped[day]}
                start={startHour}
                end={endHour}
                size={sizes['schedule-grid'] || { width: 50 }}
                gtime={gtime}
                uid={uid}
                onInfoClick={setSelectedActivity}
                paymentAction={goToPayActivity}
                cancelAction={cancelActivity}
                showHalfhour={showHalfhour}
              />
            )
          })}
        </div>
      </DoubleScrollbar>
      {selectedActivity ? (
        <Dialog
          open={selectedActivity}
          onClose={() => setSelectedActivity(null)}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>
            <IconButton
              aria-label='close'
              style={{ position: 'absolute', top: 0, right: 0, height: 30 }}
              onClick={() => setSelectedActivity(null)}
            >
              <CloseIcon fontSize='small' />
            </IconButton>
          </DialogTitle>
          <ActivityCard
            activity={selectedActivity}
            horizontal={'grid'}
            paymentAction={goToPayActivity}
            cancelAction={() => cancelActivity(selectedActivity.id)}
            uid={uid}
            // shareClick={shareClick}
            gtime={gtime}
            defaultExpanded={true}
          />
        </Dialog>
      ) : // <ActivityCard activity={selectedActivity} onClose={() => setSelectedActivity(null)} />
      null}
    </div>
  )
}

const enhance = connect(
  // Map redux state to component props
  ({ firebase: { auth, profile } }) => ({
    auth,
    profile,
  })
)

const ScheduleGrid = withAppconfig(enhance(_ScheduleGrid))

function SchoolSchedule({ activities, gtime, uid }) {
  const [selectedDay, setSelectedDay] = useState(moment.utc().startOf('isoWeek').toDate())

  const moveSelectedDay = (days) => {
    if (days !== 0) {
      setSelectedDay(moment.utc(selectedDay).add(days, 'days').toDate())
    }
  }

  const filtered = activities
    .filter((activity) => {
      return moment
        .utc(activity.start)
        .isBetween(moment.utc(selectedDay).startOf('isoWeek'), moment.utc(selectedDay).endOf('isoWeek'))
    })
    .map((activity) => {
      if (moment.utc(activity.start).isoWeekday() !== moment.utc(activity.end).isoWeekday()) {
        let splitted = []
        const start = moment.utc(activity.start)
        const end = moment.utc(activity.end)
        let stillSplitting = true
        while (stillSplitting) {
          const clone = JSON.parse(JSON.stringify(activity))
          clone.start = start.format('YYYY-MM-DDTHH:mm')
          start.endOf('day')
          moment.utc(start).endOf('day').isAfter(end)
            ? (clone.end = end.format('YYYY-MM-DDTHH:mm'))
            : (clone.end = start.endOf('day').format('YYYY-MM-DDTHH:mm'))
          start.add(1, 'second')
          clone.zindex = 1
          splitted.push(clone)
          stillSplitting = start.isSameOrBefore(end)
        }
        return splitted
      }
      return activity
    })
    .flat()

  const grouped = groupedByDay(filtered)
  console.log('grouped', grouped)
  const { startHour, endHour } = findMinAndMaxHours(filtered)

  return (
    <React.Fragment>
      <div style={{ marginBottom: 10 }}>
        <Button size='sm' onClick={() => moveSelectedDay(-7)}>
          Edellinen
        </Button>
        {' ' +
          moment.utc(selectedDay).format('D.M.YY') +
          ' - ' +
          moment.utc(selectedDay).endOf('isoWeek').format('D.M.YY') +
          ' '}
        <Button size='sm' onClick={() => moveSelectedDay(7)}>
          Seuraava
        </Button>
      </div>
      <ScheduleGrid grouped={grouped} startHour={startHour} endHour={endHour} gtime={gtime} uid={uid} />
    </React.Fragment>
  )
}

export default SchoolSchedule
