import React, { useState, useEffect } from 'react'
// import { useHistory } from 'react-router-dom' // if you use react-router
import { useFirebase, firestoreConnect } from 'react-redux-firebase'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Row, Col, Button, Spinner } from 'reactstrap'
import { Input, InputAdornment, InputLabel } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import withAppconfig from '../AppContext'
import BalanceSelector from './BalanceSelector'

const withWallet = compose(
  connect((state) => ({ auth: state.firebase.auth })),
  firestoreConnect((props) => [
    {
      collection: 'users',
      doc: `${props.auth.uid}`,
      subcollections: [
        { collection: 'paymentinfo', doc: 'saldo', subcollections: [{ collection: `${props.appconfig.accountkey}` }] },
      ],
      storeAs: 'wallets',
    },
  ]),
  connect((state) => ({ wallets: state.firestore.ordered.wallets }))
)

const quickselections = [25, 50, 100, 200]

function AddEpassiAsset({ appconfig, wallets }) {
  const firebase = useFirebase()
  // const history = useHistory()
  const { t } = useTranslation()
  const [assetPrice, setAssetPrice] = useState(0)
  // eslint-disable-next-line no-unused-vars
  const [paymentCode, setPaymentCode] = useState(null)
  const [creatingCode, setCreatingCode] = useState(false)
  const [confirmed, setConfirmed] = useState(false)
  const [formML, setFormML] = useState(null)
  const [balance, setBalance] = useState(null)

  const [validated, setValidated] = useState(appconfig.balances && appconfig.balances.length > 1 ? false : true)
  
  if (paymentCode && !confirmed) {
    for (let w of wallets) {
      if (w.cash) {
        const t = w.cash.find((c) => c.purchase && c.purchase.paymentcode === paymentCode)
        if (t) {
          setConfirmed(true)
        }
      }
    }
  }

  const setVenueBalance = (venue) => {
    setBalance(venue)
    setValidated(true)
  }

  useEffect(() => {
    if (formML && document.getElementById('ep')) {
      document.getElementById('ep').submit()
    }
  }, [formML])

  const createAssetAdd = () => {
    const paidsum = Math.floor(parseFloat(assetPrice) * 100)
    const venue = balance ? balance : 'default'
    const asset = {
      paidsum,
      cid: appconfig.accountkey,
      venue,
      provider: 'epassi',
    }
    setCreatingCode(true)
    firebase.functions().region = 'europe-west1'
    const fn = appconfig.istest ? 'testassetpayment-payment' : 'assetpayment-payment'
    let createPayment = firebase.functions().httpsCallable(fn)
    createPayment(asset).then((response) => {
      if (response.data.error) {
        alert(response.data.message)
      } else {
        // console.log(response.data)
        setFormML(response.data)
      }
      setCreatingCode(false)
    })
  }

  const setAmount = (e) => {
    setAssetPrice(e.target.value.replace(/^0+/, ''))
  }

  console.log('validated', validated)

  return (
    <React.Fragment>
      <Row className='text-center mb-4'>
        <Col>{t('deposit')}</Col>
      </Row>
      <Row className='text-center mb-4'>
        <Col>{t('quickselections')}</Col>
      </Row>
      <Row className='text-center mb-4'>
        <Col>
          {quickselections.map((q) => (
            <Button key={q} outlined color='primary' onClick={() => setAssetPrice(q)} style={{ marginRight: 15 }}>
              {q} €
            </Button>
          ))}
        </Col>
      </Row>
      <Row className='text-center'>
        <Col lg={12} sm={12} style={{ marginBottom: 20 }}>
          <InputLabel htmlFor='asset-amount'>{t('set_amount')}</InputLabel>
          <Input
            id='asset-amount'
            label={t('set_amount')}
            disabled={paymentCode}
            value={assetPrice}
            onChange={setAmount}
            type='number'
            endAdornment={<InputAdornment position='end'>€</InputAdornment>}
          />
        </Col>
        {appconfig.balances && appconfig.balances.length > 1 ? (
          <Col lg={12} sm={12} style={{ marginBottom: 20 }}>
            <BalanceSelector balances={appconfig.balances} setVenue={setVenueBalance} balance={balance} />
          </Col>
        ) : null}
        <Col lg={12} sm={12}>
          <Button
            style={{ backgroundColor: '#591bff', borderColor: '#591bff' }}
            disabled={!assetPrice || !validated}
            onClick={createAssetAdd}
          >
            {creatingCode ? <Spinner /> : t('pay') + ' '} {assetPrice} €{' '}
            <img src={process.env.PUBLIC_URL + '/imgs/epassi_logo.png'} alt='epassi' style={{ height: 20 }} />
          </Button>
        </Col>
      </Row>
      {/*     <Row style={{ marginTop: 30 }} className='text-center'>
            {!confirmed ? (
              <Col lg={12} sm={12}>
                Odotetaan maksun vahvistusta...
                <Progress animated color='success' value='100' />
              </Col>
            ) : (
              <Col lg={12} sm={12}>
                Maksu vahvistettu!
                <br />
                <br />
                <Button color='success' onClick={() => history.push(appconfig.pathPrefix + '/profile')}>
                  Jatka
                </Button>
              </Col>
            )}
      </Row> */}
      {formML ? <div style={{ display: 'none' }} dangerouslySetInnerHTML={{ __html: formML }} /> : null}
    </React.Fragment>
  )
}

export default withAppconfig(withWallet(AddEpassiAsset))
