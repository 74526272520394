import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom' // if you use react-router
import TopNavigation from '../TopNavigation'
// import { useFirebase, isLoaded, firestoreConnect } from 'react-redux-firebase'
// import { compose } from 'redux'
import { useCookies } from 'react-cookie'
// import { connect } from 'react-redux'
import withAppconfig from '../AppContext'
import { Container, Typography, Card, CardActions, CardContent } from '@material-ui/core'
// import { useParams } from 'react-router-dom/cjs/react-router-dom'
import { applications } from '../demodata.js'
import { useTranslation } from 'react-i18next'
import { Button } from 'reactstrap'
import moment from 'moment'
import 'moment/locale/en-gb'
import 'moment/locale/sv'
import 'moment/locale/fi'
import 'moment/locale/et'
import { t } from 'i18next'


function ApplicationCard({ application, onClick }) {
  const { t } = useTranslation()
  return (
    <Card variant='outlined' style={{ marginTop: 20 }}>
      {/* <CardActionArea onClick={() => onClick(application.id)}> */}
        <CardContent>
          <Typography gutterBottom variant='h5' component='h2'>
            {application && application.title}
          </Typography>
          <div dangerouslySetInnerHTML={{ __html: application && application.description }} />
        </CardContent>
      {/* </CardActionArea> */}
      <CardActions disableSpacing>
        <Button size='small' color='primary' onClick={() => onClick(application.id)} style={{ marginLeft: 'auto' }}>
          {t('open')}
        </Button>
      </CardActions>
    </Card>
  )
}

function FormsScreen({ appconfig }) {
  const history = useHistory()
  let defaultLanguage = 'FI'
  const [cookies] = useCookies(['selectedLanguage'])
  if (cookies.selectedLanguage) {
    defaultLanguage = cookies.selectedLanguage
  }

  useEffect(() => {
    if (defaultLanguage.toLowerCase() === 'se') {
      moment.locale('sv', { week: { dow: 3 } })
    } else if (defaultLanguage.toLowerCase() === 'ee') {
      moment.locale('et', { week: { dow: 3 } })
    } else {
      moment.locale(defaultLanguage.toLowerCase(), { week: { dow: 3 } })
    }
  }, [defaultLanguage])

  const onClick = (id) => {
    history.push(appconfig.pathPrefix + `/application/${id}`)
  }

  return (
    <div className='full-page-background'>
      <TopNavigation />
      <Container className='front' maxWidth={'md'} style={{ paddingTop: 20 }}>
        <Typography variant='h4' component='h2' gutterBottom>
          {t('applications')}
        </Typography>
        {applications && applications.length > 0 ? applications.map((application) => <ApplicationCard application={application} onClick={onClick} />) : null}
      </Container>
    </div>
  )
}

export default withAppconfig(FormsScreen)
