import { getPosterUrl } from './ViewUtils'

function Poster({ data, preferredSize, alt, asUrl, defaultBg }) {
  if (!preferredSize) {
    preferredSize = 600
  }
  if (!data && alt && defaultBg) {
    let logo = defaultBg.find((l) => l.loc === 'navigation')
    if (!logo) {
      logo = defaultBg.find((l) => l.loc === 'default')
    }
    if (logo && logo.type === 'url') {
      return <img height={preferredSize} src={logo.value} alt={alt} />
    }
  }

  if (!data) {
    return null
  }
  let _optimizedPoster = getPosterUrl(data, preferredSize)
  if (_optimizedPoster) {
    if (asUrl) {
      return _optimizedPoster
    } else {
      return <img src={_optimizedPoster} alt={alt} />
    }
  } else {
    return null
  }
}

export default Poster
