
export const firebaseConfig = {
  apiKey: 'AIzaSyB_aqXQI6DuVinrjeKX7doEHWEd-dIN1mQ',
  authDomain: 'falcon-328a1.firebaseapp.com',
  databaseURL: 'https://falcon-328a1.firebaseio.com',
  projectId: 'falcon-328a1',
  storageBucket: 'falcon-328a1.appspot.com',
  messagingSenderId: '640167253821',
  appId: '1:640167253821:web:8bd0d11aa501d630a59754',
  measurementId: 'G-5YLCC5G2WK',
}

export const rrfConfig = {
  userProfile: 'users',
  useFirestoreForProfile: true,
  enableClaims: true,
  // profileFactory: (userData, profileData, firebase) => { // how profiles are stored in database
  //   const { user } = userData
  //   return {
  //     email: user.email,
  //     firstname: user.firstname,
  //     lastname: user.lastname,
  //     created: user.created
  //   }
  // }
  // fileMetadataFactory: (uploadRes, firebase, metadata, downloadURL) => {
  //   // upload response from Firebase's storage upload
  //   const {
  //     metadata: { name, fullPath }
  //   } = uploadRes
  //   // default factory includes name, fullPath, downloadURL
  //   return {
  //     name,
  //     fullPath,
  //     downloadURL
  //   }
}

const keyMap = {
}

export const falconInstance = keyMap[document.location.hostname]

export const RATING_LABELS = {
  tennis: {
    utr: {
      short: 'UTR',
      long: 'Universal Tennis Rating',
      automatic: false,
      type: 'numeric',
      gamezone: 2,
      maxvalue: 12,
      minvalue: 0,
    },
    wtn: {
      short: 'WTN',
      long: 'World Tennis Number',
      automatic: false,
      type: 'numeric',
      gamezone: 2,
      maxvalue: 45,
      minvalue: 1,
    },
    tenfin: {
      short: 'Tennisässä',
      long: 'Suomen Tennisliitton Taso',
      automatic: false,
      type: 'enum',
      gamezone: 1,
      values: ['E2', 'E1', 'D2', 'D1', 'C3', 'C2', 'C1', 'B3', 'B2', 'B1', 'A3', 'A2', 'A1'],
    },
    tennisclub: {
      short: 'Tennisclub',
      long: 'Tennisclub Taso',
      automatic: true,
      clubs: [],
      type: 'enum',
      values: [],
    },
  },
  golf: {
    golfhandicap: {
      short: 'Handicap',
      long: 'Handicap',
      automatic: false,
      type: 'numeric',
      gamezone: 4,
      maxvalue: 54,
      minvalue: -2,
    },
  },
  squash: {
    squashfin: {
      short: 'Squashliitto',
      long: 'Suomen Squashliiton Taso',
      automatic: false,
      type: 'numeric',
    },
  },
  padel: {
    padelution: {
      short: 'Padelution',
      long: 'Padelution Taso',
      automatic: false,
      type: 'numeric',
      gamezone: 300,
      maxvalue: 2400,
      minvalue: 100,
    },
    padelfin: {
      short: 'Padelliitto',
      long: 'Suomen Padelliiton Taso',
      automatic: false,
      type: 'numeric',
      gamezone: 300,
      maxvalue: 2400,
      minvalue: 100,
    },
  },
  badminton: {
    badmintonfin: {
      short: 'Sulkapalloliitto',
      long: 'Suomen Sulkapalloliiton Taso',
      automatic: false,
      type: 'sequence',
      gamezone: 2,
      values: ['A1', 'A2', 'A3', 'B1', 'B2', 'B3', 'C1', 'C2', 'C3', 'D1', 'D2', 'E1', 'E2'],
    },
  },
  biljard: {
    biljardfin: {
      short: 'Biljardiliitto',
      long: 'Suomen Biljardiliiton Taso',
      automatic: false,
      type: 'numeric',
      gamezone: 300,
      maxvalue: 2400,
      minvalue: 100,
    },
  },
}

export const APPINITLANG = 'fi'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  firebaseConfig,
  rrfConfig,
  falconInstance,
  RATING_LABELS,
  APPINITLANG,
}
