import React from 'react'
import TopNavigation from '../TopNavigation'
import { Container, Row, Col } from 'reactstrap'
import withAppconfig from '../AppContext'
import SaveCard from '../components/Paytrail/SaveCard'


function AddCardScreen({ appconfig }) {


  return (
    <div className='full-page-background'>
      <TopNavigation title='Add Card' />
      <Container className='front'>
        <Row className='text-center' style={{ paddingTop: 20, marginBottom: 30 }}>
          <Col>
            Selitystä
          </Col>
        </Row>
        <Row>
          <Col>
            <SaveCard />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default withAppconfig(AddCardScreen)
