import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom' // if you use react-router
import { useFirebase, firestoreConnect } from 'react-redux-firebase'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Row, Col, Button, Spinner, Progress } from 'reactstrap'
import { Input, InputAdornment, InputLabel, TextField } from '@material-ui/core'
import withAppconfig from '../AppContext'

const EdenredGuide = () => (
  <Row style={{ marginBottom: 20 }} className='text-center'>
    <Col lg={12} sm={12}>
      Luodakseni Edenred/Virike tallennuksen tulee Sinun luoda maksukoodi valitsemallesi tallennussummalle.{' '}
      <strong>
        Maksukoodi täytyy laittaa maksua suorittaessa Viesti-kenttää MyEdenred sovelluksessa muuten maksu ei kohdistu
        saldollesi.
      </strong>
    </Col>
  </Row>
)

const withWallet = compose(
  connect((state) => ({ auth: state.firebase.auth })),
  firestoreConnect((props) => [
    {
      collection: 'users',
      doc: `${props.auth.uid}`,
      subcollections: [
        { collection: 'paymentinfo', doc: 'saldo', subcollections: [{ collection: `${props.appconfig.accountkey}` }] },
      ],
      storeAs: 'wallets',
    },
  ]),
  connect((state) => ({ wallets: state.firestore.ordered.wallets }))
)

function AddEdenredAsset({ appconfig, wallets }) {
  const firebase = useFirebase()
  const history = useHistory()
  const [assetPrice, setAssetPrice] = useState()
  const [paymentCode, setPaymentCode] = useState(null)
  const [creatingCode, setCreatingCode] = useState(false)
  const [confirmed, setConfirmed] = useState(false)
  const [timedOut, setTimedout] = useState(false)


  if (paymentCode && !confirmed) {
    for (let w of wallets) {
      if (w.cash) {
        const t = w.cash.find((c) => c.purchase && c.purchase.paymentcode === paymentCode)
        if (t) {
          setConfirmed(true)
        }
      }
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => setTimedout(true), 30000)
    return () => clearTimeout(timer)
  }, [paymentCode])

  const createAssetAdd = () => {
    const paidsum = Math.floor(parseFloat(assetPrice) * 100)
    const asset = {
      paidsum,
      cid: appconfig.accountkey,
      venue: 'default',
    }
    setCreatingCode(true)
    firebase.functions().region = 'europe-west1'
    let loadEdenred = firebase.functions().httpsCallable('edenred-loadEdenred')
    loadEdenred(asset).then((response) => {
      if (response.data.error) {
        alert(response.data.message)
      } else {
        console.log(response.data)
        setPaymentCode(response.data.messagecode)
      }
      setCreatingCode(false)
    })
  }

  const setAmount = (e) => {
    setAssetPrice(e.target.value.replace(/^0+/, ''))
  }

  return (
    <React.Fragment>
      <EdenredGuide />
      <Row className='text-center'>
        <Col lg={12} sm={12} style={{ marginBottom: 20 }}>
          <InputLabel htmlFor='asset-amount'>Tallennettava summa</InputLabel>
          <Input
            id='asset-amount'
            label='Tallennettava summa'
            disabled={paymentCode}
            value={assetPrice}
            onChange={setAmount}
            type='number'
            endAdornment={<InputAdornment position='end'>€</InputAdornment>}
          />
        </Col>
        <Col lg={12} sm={12}>
          {paymentCode ? (
            <TextField
              id='filled-read-only-input'
              label='Maksukoodi'
              value={paymentCode}
              InputProps={{
                readOnly: true,
              }}
              variant='outlined'
              helperText='Tämä koodi pitää syöttää maksua suorittaessa Viesti-kenttään'
            />
          ) : (
            <Button color={'success'} disabled={!assetPrice} onClick={createAssetAdd}>
              {creatingCode ? <Spinner /> : 'Luo maksukoodi'}
            </Button>
          )}
        </Col>
      </Row>
      {paymentCode && (
        <React.Fragment>
          <Row className='text-center' style={{ marginTop: 20 }}>
            <Col lg={12} sm={12}>
              <strong>Näin maksat Edenred Paylla</strong>
              <br />
              Avaa nyt MyEdenred-sovellus ja aloita valitsemalla ’Luo Mobiilimaksu’ tai etsimällä käyttöpaikkaa{' '}
              <b>{appconfig?.edenred?.venuename}</b> Käyttöpaikkahausta. Syötä sovellukseen maksettava summa{' '}
              <b>{assetPrice}€</b> ja lisää maksukoodi <b>{paymentCode}</b> lisätietokenttään.
            </Col>
          </Row>
          <Row style={{ marginTop: 30 }} className='text-center'>
            {!confirmed ? (
              <Col lg={12} sm={12}>
                Odotetaan maksun vahvistusta...
                <Progress animated color='success' value='100' />
              </Col>
            ) : (
              <Col lg={12} sm={12}>
                Maksu vahvistettu!
                <br />
                <br />
                <Button color='success' onClick={() => history.push(appconfig.pathPrefix + '/profile')}>
                  Jatka
                </Button>
              </Col>
            )}
            {timedOut && !confirmed ? (
              <Col lg={12} sm={12} style={{ backgroundColor: '#fbeb55', padding: 20 }}>
                Maksun vahvistus kestää normaalia pidempään. Voit odottaa tai palata myöhemmin tarkistamaan saldosta
                vahvistuksen. Jos vahvistusta ei tule 30 minuutin sisällä, ota yhteyttä hallin asiakaspalveluun.
                <br />
                <br />
                <Button color='success' onClick={() => history.push(appconfig.pathPrefix + '/profile')}>
                  Jatka
                </Button>
                <br />
              </Col>
            ) : null}
          </Row>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export default withAppconfig(withWallet(AddEdenredAsset))
