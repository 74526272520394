import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/firestore'
import 'firebase/functions'
// import 'firebase/analytics'
import 'firebase/performance'
import { ReactReduxFirebaseProvider } from 'react-redux-firebase'
import createStore from './createStore'
import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/css/tesoma.css'
import { firebaseConfig, rrfConfig } from './config'
import { createFirestoreInstance } from 'redux-firestore'
import { CookiesProvider } from 'react-cookie'
import './i18n'
// import * as Sentry from '@sentry/react'
// import { BrowserTracing } from '@sentry/tracing'
// import LogRocket from 'logrocket';

// if (process.env.NODE_ENV !== 'development') console.log = () => {}

const isTest = document.location.hostname === 'utest.cintoia.com' || document.location.hostname === 'localhost'

if (!isTest) {
  console.log = () => { }
  console.warn = () => { }
  console.error = () => { }
  console.info = () => { }
  console.debug = () => { }
}
 
try {
  firebase.initializeApp(firebaseConfig)
  firebase.performance()
} catch (err) {}

const store = createStore()

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance,
}
// Sentry.init({
//   dsn: "https://782033af20bc41c88a3a3f5f7112ddce@o1413116.ingest.sentry.io/6752746",
//   integrations: [new BrowserTracing(), new Sentry.Replay()],
//   tracesSampleRate: 0.1,
//   replaysSessionSampleRate: isTest ? 1.0 : 0.1,
//   replaysOnErrorSampleRate: 1.0,
//   release: "kumpula@12.04.23"
// })

ReactDOM.render(
  // <React.StrictMode>
    <Provider store={store}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <CookiesProvider>
          <App />
        </CookiesProvider>
      </ReactReduxFirebaseProvider>
    </Provider>
  // </React.StrictMode >
  ,
  document.getElementById('root')
)

reportWebVitals()
