import React, { useState } from 'react';
import moment from 'moment-timezone';
import useWindowDimensions from '../useWindowsDimensions'
import { useTranslation } from 'react-i18next'
import { Row, Col, Button, Badge } from 'reactstrap'
// import UpdateIcon from '@material-ui/icons/Update'

const paymentButtonStyle = { marginTop: 3, marginBottom: 3, paddingTop: 1, paddingBottom: 1 }
// const cancelButtonStyle = {
//   marginTop: 3,
//   marginBottom: 3,
//   paddingTop: 1,
//   paddingBottom: 1,
//   backgroundColor: '#ff9216',
//   color: 'white',
// }

function OwnActivity({ r, ispast, isUnpaidList, gtime, onSignin, onChange, onCancel, onDownloadReceipt }) {
  const { width } = useWindowDimensions() 
  const { t } = useTranslation()
  const [selected, setSelected] = useState(null)

  const isSmall = width < 576

  const isSignUpOpen = moment.utc(r.entryend, 'YYYY-MM-DDTHH:mm').subtract(moment().tz('Europe/Helsinki').utcOffset(), 'minutes').isAfter(gtime.utc())

    let _start = moment.utc(r.start)
    let _end = moment.utc(r.end)


    const {
      cost,
      isInvoiced,
      isPaid,
      isPayable,
      isReceiptEnabled,
      isPaidWithBenefitcard,
      missingPayment,
    } = r.vs
    let isSignable = false
    // let title = '' //t('cancel') // Peru'
  let paymentinfo = ''
  if (missingPayment.missing > 0) {
    paymentinfo = t('unpaid') + ' ' + missingPayment.missing / 100 + ' €.'
  } else {
    paymentinfo = t('paid') + (isPaidWithBenefitcard ? ' ' + t('with_benefitcard') : '')
  }

  const toggle = (id) => {
    setSelected(selected === id ? null : id)
    if (onChange) onChange(id, selected === id ? 'remove' : 'add')
  }



  const showSignInQr = (activity) => {
    console.log('showSignInQr', activity)
    onSignin(activity.id)
  }

    return (
      <React.Fragment key={r.id + Math.random().toString()}>
        <Row
          className='border mb-2 rounded-sm'
          style={{
            marginRight: 0,
            marginLeft: 0,
            marginTop: 5,
            paddingBottom: 5,
            backgroundColor: selected ? '#d1f3d1' : 'none',
            boxShadow:
              '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
          }}
          // key={r.id}
        >
          <Col>
            <Row>
              <Col xs={12} sm={6}>
                <Row>
                  <Col style={{ textTransform: 'capitalize', paddingLeft: 5, fontSize: 'small' }} className=''>
                    {r.name.fi} {_start.format('dddd D.M') + ' ' + _start.format('H:mm') + ' - ' + _end.format('H:mm')}{' '}
                  </Col>
                </Row>
                <Row>
                  <Col style={{ textTransform: 'capitalize', paddingLeft: 5, fontSize: 'small' }} className=''>
                    {/* {r.resourcegroups[0].resources[0].displayName}{' '} */}
                    {/* {courtchanged ? <Badge color='info'>{t('change_of_court_at_recurreccy')}</Badge> : null}{' '} */}
                    {/* {showAccessCode && _accesscode ? (
                      <Badge color='info'>
                        {t('accesscode')}: {_accesscode}
                      </Badge>
                    ) : null} */}
                  </Col>
                </Row>
                <Row>
                  <Col style={{ paddingLeft: 5, fontSize: 'small' }}>
                    {cost ? t('price') + ' ' + cost + ' €' : null}
                  </Col>
                </Row>
                  <Row>
                {isSignable && isPaid ? (
                    <Col style={{ paddingLeft: 5, fontSize: 'small' }}>
                    <Button color='success' size={'sm'} style={paymentButtonStyle} onClick={() => showSignInQr(r)}>
                        {t('signin')}
                      </Button>
                    </Col>
                  ) : null}
                  {isSignUpOpen ? (
                    <Col style={{ paddingLeft: 5, fontSize: 'small' }}>
                    <Button color='warning' size={'sm'} style={paymentButtonStyle} onClick={() => onCancel(r)}>
                        {t('cancel')}
                      </Button>
                    </Col>
                ) : null}

                  </Row>

                {isUnpaidList ? (
                  <Row>
                    <Col style={{ paddingLeft: 5, fontSize: 'small' }}>
                      {ispast ? t('past_reservation') : t('upcoming_reservation')}
                    </Col>
                  </Row>
                ) : null}
                {r.items && r.items.length > 0
                  ? r.items.map((x) => (
                      <Row key={x.id}>
                        <Col className='text-left font-italic' style={{ fontSize: 'small' }}>
                          {x.item.displayName} {x.amount} kpl
                        </Col>
                      </Row>
                    ))
                  : null}
                <Row className=''>
                  <Col className='text-left font-italic' style={{ fontSize: 'small' }}>
                    {r.usernotes ? r.usernotes[0].note : null}
                  </Col>
                </Row>
              </Col>
              <Col
                xs={12}
                sm={6}
                className={isSmall ? 'text-left mt-2 border-top pt-1' : 'text-right'}
                style={{ paddingLeft: 5 }}
              >
                <Row>
                  <Col className='' style={{ fontSize: 'small', paddingRight: 3 }}>
                    {paymentinfo}
                    {isReceiptEnabled ? (
                      <Button
                        color='link'
                        size='sm'
                        onClick={() => onDownloadReceipt(r.id)}
                      >
                        {t('receipt')}
                      </Button>
                    ) : null}
                    {isInvoiced ? <Badge color='info'>{t('invoiced')}</Badge> : null}
                  </Col>
                </Row>
                <Row>
                  <Col className='' style={{ fontSize: 'small', paddingRight: 3 }}>
                    {isPayable ? (
                      !selected ? (
                        <Button
                          size={'sm'}
                          color='primary'
                          onClick={() => toggle(r.id)}
                          key={'-bttn-' + Math.random().toString()}
                          style={paymentButtonStyle}
                        >
                          {t('pay') + ' '}
                          <Badge color='success' pill>
                            {missingPayment.amount + ' €'}
                          </Badge>
                        </Button>
                      ) : (
                        // <Button color='primary' style={paymentButtonStyle} size='sm' onClick={() => selectRow(r)}>
                        //   {t('to_cart')}
                        // </Button>
                        <Button
                          size='sm'
                          style={paymentButtonStyle}
                          color='primary'
                          onClick={() => toggle(r.id)}
                        >
                          {t('from_cart')}
                        </Button>
                      )
                    ) : null}{' '}
                    {/* {isPayable && !selected ? (
                      <Button
                        size={'sm'}
                        color='primary'
                        onClick={() => goToPayReservation(r)}
                        key={'-bttn-' + Math.random().toString()}
                        style={paymentButtonStyle}
                      >
                        {t('pay') + ' '}
                        <Badge color='success' pill>
                          {missingPayment.amount + ' €'}
                        </Badge>
                      </Button>
                    ) : null}{' '} */}

                  </Col>
                </Row>
                <Row>
                  <Col className='' style={{ fontSize: 'small', paddingRight: 3 }}>
                    {/* {_isCancellable && !selected ? (
                      <Button
                        color='inherit'
                        size={'sm'}
                        style={cancelButtonStyle}
                        onClick={() => cancelConfirmation(r.selectkey, cancelToState)}
                      >
                        {title}
                      </Button>
                    ) : null}{' '} */}
                  </Col>
                </Row>
              </Col>
            </Row>
            {/* <Row>
              <Col className='' style={{ fontSize: 'small', paddingRight: 3 }}>
                {r.id}
                <code>{JSON.stringify(r.vs, null, 2)}</code>
                <code>{JSON.stringify(r.occurency, null, 2)}</code>
              </Col>
            </Row> */}
          </Col>
        </Row>
      </React.Fragment>
    )
}

export default OwnActivity;