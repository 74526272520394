import React from 'react'
import {
  Container,
  Row,
  Col,
  Alert,
} from 'reactstrap'
import TopNavigation from '../../TopNavigation'
import withAppconfig from '../../AppContext'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firebaseConnect } from 'react-redux-firebase'
import ProductCard from '../../components/Shop/ProductCard'
import { Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const mapStateToProps = (state, props) => {
  const {
    firebase
  } = state
  if(!firebase.ordered.shopcards) return { cards: null }
  const cards = firebase.ordered.shopcards.map((item) => {
    return { id: item.key, ...item.value }
  }).map((item) => {
    if (!item.collection) {
      item.collection = 'activities'
    }
    return item
  })
  .reduce((acc, item) => {
    if (!acc[item.collection]) {
      acc[item.collection] = []
    }
    acc[item.collection].push(item)
    return acc
  }, {})
  return {
    cards,
  }
}

const withProducts = compose(
  connect((state) => ({ auth: state.firebase.auth })),
  firebaseConnect((props) => [
    {
      path: 'shop/' + props.appconfig.accountkey + '/cards',
      queryParams: ['orderByChild=displayName'],
      storeAs: 'shopcards',
    },
  ]),
  connect(mapStateToProps)
)

function CardsScreen({ appconfig, cards, auth }) {
  const { t } = useTranslation()
  const isAuth = auth.isLoaded && !auth.isEmpty
  return (
    <div className='full-page-background'>
      <TopNavigation />
      <Container className='front' style={{ paddingTop: 10 }}>
        {!isAuth ? (<Alert color='warning'>{t('login_to_buy_card')}</Alert>) : null}
        <Typography variant='h6' style={{ paddingBottom: 20 }}>
          Sarjakortit varauksiin
        </Typography>
        {cards && cards.reservations && cards.reservations.length > 0 ? (
          <Row>
            {cards.reservations.map((m) => (
              <Col key={m.id} xs={12} sm={4}>
                <ProductCard product={m} collection={'card'} isAuthRequired={true} isAuth={isAuth} />
              </Col>
            ))}
          </Row>
        ) : (
          null
        )}
      </Container>
    </div>
  )
}

export default withAppconfig(withProducts(CardsScreen))
