import React, { useEffect } from 'react'
import { useCookies } from 'react-cookie'

import LoginScreen from './screens/LoginScreen'
import RegScreen from './screens/RegScreen'
import ReservationScreen from './screens/ReservationScreen'
import PastPaymentScreen from './screens/PastPaymentScreen3'
import ReservationsScreen from './screens/ReservationsScreen'
import ProfileScreen from './screens/ProfileScreen'
import ActivitiesScreen from './screens/ActivitiesScreen'
import OnetimeScreen from './screens/OnetimeScreen'
import AddAssetsScreen from './screens/AddAssetsScreen'
import LeaguesScreen from './screens/LeaguesScreen'
import LeagueScreen from './screens/LeagueScreen'
import { isLoaded, isEmpty, firestoreConnect, useFirebase } from 'react-redux-firebase'
import { connect } from 'react-redux'
import { compose } from 'redux'

import { Switch, Route, Redirect } from 'react-router'
import HomeScreenCalendar from './screens/HomeScreenCalendar'
import withAppconfig from './AppContext'
import ActivityPaymentScreen from './screens/ActivityPaymentScreen'
import SplitterScreen from './screens/SplitterScreen'
import ImpLogScreen from './screens/ImpLogScreen'
import moment from 'moment'
import FormScreen from './screens/FormScreen'
import FormsScreen from './screens/FormsScreen'
import RegScreenOrganization from './screens/RegScreenOrganization'
import OwnActivitiesScreen from './screens/OwnActivitiesScreen'
import Mass from './screens/Mass'
import ShopMembershipsScreen from './screens/Shop/MembershipsScreen'
import ShopCardsScreen from './screens/Shop/CardsScreen'
import MembershipScreen from './screens/Shop/MembershipScreen'
import MembershipPaymentScreen from './screens/Shop/MembershipPaymentScreen'
import InvoicesScreen from './screens/Invoices/InvoicesScreen'
import InvoicePaymentScreen from './screens/Invoices/InvoicePaymentScreen'
// import RecurringSalesScreen from './screens/RecurringSalesScreen'
import RecurringSalesScreen2 from './screens/RecurringSalesScreen2'
import RecurrentCheckoutScreen from './screens/RecurrentCheckoutScreen'
import AddCardScreen from './screens/AddCardScreen'
import AddCardResult from './components/AddCardResult'
import CardScreen from './screens/Shop/CardScreen'
import CardPaymentScreen from './screens/Shop/CardPaymentScreen'
import ReportMaintenance from './screens/Maintenance/ReportMaintenance'
import MatchReservationScreen from './screens/MatchReservationScreen'

const mapStateToProps = (state, props) => {
  const {
    firebase: { auth },
    firestore,
    globaltime: { gtime },
  } = state
  const { appconfig } = props
  let isMember = false
  const { memberships } = firestore.data

  const _membrss = Object.keys(firestore.data.memberships || {}).filter((x) => x !== 'undefined' && x !== 'null').map((k) => { return { ...firestore.data.memberships[k], cacheupdated: gtime } }).filter((v) => v.permanent || (gtime && gtime.isBetween(moment.utc(v.start), moment(v.end), 'day', '[]')))

  // let _ms = null
  if (memberships) {
    const isNotNull = _membrss.length > 0 // Object.values(memberships).some((v) => v !== null)
    if (isNotNull) {
      // _ms = memberships
      if (appconfig.isOnlyMembers && auth.uid) {
        isMember = _membrss.some((v) => appconfig.onlyMembers.memberships.includes(v.membershipid))
      }
    }
  }

  const isTest = document.location.hostname === 'utest.cintoia.com' || document.location.hostname === 'localhost'
  if (isTest) {
    appconfig.istest = true
  }

  return {
    auth,
    appconfig,
    isMember,
    memberships: _membrss,
  }
}

const enhance = compose(
  connect(({ firebase: { auth }, globaltime: { gtime } }) => ({
    auth, gtime
  })),
  firestoreConnect((props) => [
    {
      collection: 'users',
      doc: `${props.auth.uid}`,
      subcollections: [
        { collection: 'paymentinfo', doc: 'saldo', subcollections: [{ collection: `${props.appconfig.accountkey}` }] },
      ],
      storeAs: 'wallets',
    },
    {
      collection: 'users',
      doc: `${props.auth.uid}`,
      subcollections: [
        { collection: 'memberships', doc: 'active', subcollections: [{ collection: `${props.appconfig.accountkey}` }] },
      ],
      storeAs: 'memberships',
    },
  ]),
  connect(mapStateToProps)
)

// const enhance = connect(
//   // Map redux state to component props
//   ({ firebase: { auth, profile } }) => ({
//     auth,
//     profile,
//   })
// )

function PrivateRoute({ children, isLoggedIn, appconfig, ...rest }) {
  // console.log('appconfig', appconfig)

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoggedIn ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: appconfig.pathPrefix + '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  )
}

const isSameArray = (curr, prev) => {
  if (!prev) return false
  if (!curr) return false
  if (curr.length !== prev.length) return false
  return curr.every((v) => {
    const _prev = prev.find((x) => x.id === v.id)
    if (!_prev) return false
    // console.log('isSameArray', v.cacheupdated.diff(_prev.cacheupdated, 'minutes'))
    return v.cacheupdated.diff(_prev.cacheupdated, 'minutes') < 5
  })
}

const AppNavigator = ({ auth, appconfig, isMember, memberships }) => {
  const isLoggedIn = !isEmpty(auth)
  const authLoaded = isLoaded(auth)
  const firebase = useFirebase()
  firebase.functions().region = 'europe-west1'
  // eslint-disable-next-line no-unused-vars
  const [cookies] = useCookies(['selectedGrid'])
  const prevMemeberships = React.useRef()
  const [cug, setCug] = React.useState(null)

  useEffect(() => {
    if (memberships) {
      if (!isSameArray(memberships, prevMemeberships.current)) {
        const umems = memberships.map((v) => v.membershipid) //Object.keys(memberships).map((k) => memberships[k].membershipid)
        const fn = appconfig.istest ? 'testui-get' : 'ui-get'
        const _getMembershipBenefits = firebase.functions().httpsCallable(fn)
        // console.log('calling getMembershipBenefits', { q: 'getMembershipBenefits', customerid: appconfig.accountkey, umems })
        prevMemeberships.current = memberships
        _getMembershipBenefits({ q: 'getMembershipBenefits', customerid: appconfig.accountkey, umems }).then(
          (response) => {
            if (response.data.error) {
              // console.log(response.data)
            } else {
              // console.log('response getMembershipBenefits', response.data)
              // console.log('appconfig.resources', appconfig.resources)
              // setResources(response.data)
              if (response.data.freetimes && response.data.freetimes.index) {
                setCug(response.data.freetimes.index)
              }
            }
          }
        )
      }
    } else {
      setCug(null)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberships])

  if (!authLoaded) {
    return <div style={{ flex: 1 }}>Wait</div>
  } else {
    return (
      <Switch>
        <Route path={appconfig.pathPrefix + '/login'}>
          <LoginScreen />
        </Route>
        <Route path={appconfig.pathPrefix + '/impersonate'}>
          <ImpLogScreen />
        </Route>
        <Route path={appconfig.pathPrefix + '/register'}>
          <RegScreen />
        </Route>
        <Route path={appconfig.pathPrefix + '/regcompany'}>
          <RegScreenOrganization />
        </Route>
        <Route path={appconfig.pathPrefix + '/activities/:aid'}>
          <ActivitiesScreen />
        </Route>
        <Route path={appconfig.pathPrefix + '/applications'}>
          <FormsScreen />
        </Route>
        <Route path={appconfig.pathPrefix + '/application/:aid'}>
          <FormScreen />
        </Route>
        <Route path={appconfig.pathPrefix + '/leagues'}>
          <LeaguesScreen />
        </Route>
        <Route path={appconfig.pathPrefix + '/league/:lid'}>
          <LeagueScreen />
        </Route>
        <Route path={appconfig.pathPrefix + '/shop/memberships'}>
          <ShopMembershipsScreen />
        </Route>
        <Route path={appconfig.pathPrefix + '/shop/cards'}>
          <ShopCardsScreen />
        </Route>
        <Route path={appconfig.pathPrefix + '/recs/all'}>
          <RecurringSalesScreen2 />
        </Route>
        <PrivateRoute
          path={appconfig.pathPrefix + '/recs/checkout'}
          auth={auth}
          appconfig={appconfig}
          isLoggedIn={isLoggedIn}
        >
          <RecurrentCheckoutScreen />
        </PrivateRoute>
        <PrivateRoute
          path={appconfig.pathPrefix + '/shop/membership/:id'}
          auth={auth}
          appconfig={appconfig}
          isLoggedIn={isLoggedIn}
        >
          <MembershipScreen />
        </PrivateRoute>
        <PrivateRoute
          path={appconfig.pathPrefix + '/shop/card/:id'}
          auth={auth}
          appconfig={appconfig}
          isLoggedIn={isLoggedIn}
        >
          <CardScreen />
        </PrivateRoute>
        <PrivateRoute
          path={appconfig.pathPrefix + '/shop/checkout'}
          auth={auth}
          appconfig={appconfig}
          isLoggedIn={isLoggedIn}
        >
          <MembershipPaymentScreen />
        </PrivateRoute>
        <PrivateRoute
          path={appconfig.pathPrefix + '/cards/checkout'}
          auth={auth}
          appconfig={appconfig}
          isLoggedIn={isLoggedIn}
        >
          <CardPaymentScreen />
        </PrivateRoute>
        <PrivateRoute
          path={appconfig.pathPrefix + '/bookactivity'}
          auth={auth}
          appconfig={appconfig}
          isLoggedIn={isLoggedIn}
        >
          <ActivityPaymentScreen />
        </PrivateRoute>
        <PrivateRoute
          path={appconfig.pathPrefix + '/payonly'}
          auth={auth}
          appconfig={appconfig}
          isLoggedIn={isLoggedIn}
        >
          <PastPaymentScreen />
        </PrivateRoute>
        <PrivateRoute
          path={appconfig.pathPrefix + '/matchreservation'}
          auth={auth}
          appconfig={appconfig}
          isLoggedIn={isLoggedIn}
        >
          <MatchReservationScreen />
        </PrivateRoute>
        <PrivateRoute
          path={appconfig.pathPrefix + '/reservation'}
          auth={auth}
          appconfig={appconfig}
          isLoggedIn={isLoggedIn}
        >
          <ReservationScreen />
        </PrivateRoute>
        <PrivateRoute
          path={appconfig.pathPrefix + '/reservations/calendar'}
          auth={auth}
          appconfig={appconfig}
          isLoggedIn={isLoggedIn}
        >
          <Mass />
        </PrivateRoute>
        <PrivateRoute
          path={appconfig.pathPrefix + '/reservations'}
          auth={auth}
          appconfig={appconfig}
          isLoggedIn={isLoggedIn}
        >
          <ReservationsScreen />
        </PrivateRoute>
        <PrivateRoute
          path={appconfig.pathPrefix + '/ownactivities'}
          auth={auth}
          appconfig={appconfig}
          isLoggedIn={isLoggedIn}
        >
          <OwnActivitiesScreen />
        </PrivateRoute>
        <PrivateRoute
          path={appconfig.pathPrefix + '/profile'}
          auth={auth}
          appconfig={appconfig}
          isLoggedIn={isLoggedIn}
        >
          <ProfileScreen />
        </PrivateRoute>
        <PrivateRoute
          path={appconfig.pathPrefix + '/splitpayment'}
          auth={auth}
          appconfig={appconfig}
          isLoggedIn={isLoggedIn}
        >
          <SplitterScreen />
        </PrivateRoute>
        <PrivateRoute
          path={appconfig.pathPrefix + '/addbalance'}
          auth={auth}
          appconfig={appconfig}
          isLoggedIn={isLoggedIn}
        >
          <AddAssetsScreen />
        </PrivateRoute>
        <PrivateRoute
          path={appconfig.pathPrefix + '/invoices/checkout'}
          auth={auth}
          appconfig={appconfig}
          isLoggedIn={isLoggedIn}
        >
          <InvoicePaymentScreen />
        </PrivateRoute>
        <PrivateRoute
          path={appconfig.pathPrefix + '/invoices'}
          auth={auth}
          appconfig={appconfig}
          isLoggedIn={isLoggedIn}
        >
          <InvoicesScreen />
        </PrivateRoute>
        <PrivateRoute
          path={appconfig.pathPrefix + '/addcard/:status'}
          auth={auth}
          appconfig={appconfig}
          isLoggedIn={isLoggedIn}
        >
          <AddCardResult />
        </PrivateRoute>
        <PrivateRoute
          path={appconfig.pathPrefix + '/addcard'}
          auth={auth}
          appconfig={appconfig}
          isLoggedIn={isLoggedIn}
        >
          <AddCardScreen />
        </PrivateRoute>
        <Route path={appconfig.pathPrefix + '/onetime/:onetimeid'} auth={auth} isLoggedIn={isLoggedIn}>
          <OnetimeScreen isLoggedIn={isLoggedIn} />
        </Route>
        <Route path={appconfig.pathPrefix + '/r/:targetid'} auth={auth} isLoggedIn={isLoggedIn}>
          <ReportMaintenance isLoggedIn={isLoggedIn} auth={auth} />
        </Route>
        <Route path={appconfig.pathPrefix + '/'} auth={auth} isLoggedIn={isLoggedIn}>
          <HomeScreenCalendar isMember={isMember} cug={cug} />
        </Route>
      </Switch>
    )
  }
}

export default withAppconfig(enhance(AppNavigator))
