import React from 'react';
// import { useTranslation } from 'react-i18next';
import { useParams /*, useHistory */ } from 'react-router-dom';
import TopNavigation from '../TopNavigation';
import { Container } from 'reactstrap';

function LeagueScreen() {
  const params = useParams()
  // const history = useHistory()
  return (
    <div className='full-page-background'>
      <TopNavigation />
      <Container className='front' style={{ paddingTop: 10 }}>
        {params.lid}
      </Container>
    </div>
    
  )
}

export default LeagueScreen