import React, { useState, useEffect } from 'react'
// import moment from 'moment'
import 'moment/locale/en-gb'
import 'moment/locale/et'
import 'moment/locale/sv'
import 'moment/locale/fi'
import withAppconfig from '../AppContext'
import { connect } from 'react-redux'
import { Row, Col, CardText, Button, Spinner, FormGroup, Label, Input, Alert } from 'reactstrap'
import Checkbox from '@material-ui/core/Checkbox'
import { List, ListItem, ListItemText, Menu, MenuItem, TextField } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'
import { isLoaded, useFirebase } from 'react-redux-firebase'
import { getWallet, getStorage } from './ViewUtils'
import moment from 'moment'
import { ExpandMore } from '@material-ui/icons'

const buttonStyle = {
  backgroundColor: '#FFFFFF',
  // backgroundColor: '#CCCCCC',
  border: 0,
  borderRadius: '0.3rem',
  boxSizing: 'border-box',
  color: '#111827',
  // fontFamily: "Inter var",ui-sans-serif,system-ui,-apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji",
  fontSize: '.875rem',
  fontWeight: 600,
  lineHeight: '1.25rem',
  padding: '0.1rem 0.1rem',
  textAlign: 'center',
  textDecoration: 'none #D1D5DB solid',
  textDecorationThickness: 'auto',
  boxShadow: '0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%)',
  cursor: 'pointer',
  userSelect: 'none',
  touchAction: 'manipulation',
  width: 65,
  height: 35,
  margin: 3,
}

const selectedButton = { ...buttonStyle, borderColor: '#3f51b5', border: null }

const getMinutes = (time) => {
  const t = time.split(':')
  return parseInt(t[0]) * 60 + parseInt(t[1])
}

const isValidSlot = (schedule, selected) => {
  if (schedule === undefined) return false
  if (schedule.validend === null) return false
  if (schedule.validstart === null) return false
  console.log('SPEDE4 isValidSlot', schedule, selected)
  if (moment.utc(selected.start).isBetween(moment.utc(schedule.validstart).startOf('day'), moment.utc(schedule.validend).endOf('day'), undefined, '[]')) {
    const sWeekday = moment.utc(selected.start).day() === 0 ? 7 : moment.utc(selected.start).day()
    const startMinutes = getMinutes(moment.utc(selected.start).format('HH:mm'))
    const endMinutes = startMinutes + selected.duration
    return Object.keys(schedule.slots).some((k) => {
      const slot = schedule.slots[k]
      const slotStartMinutes = getMinutes(slot.starttime)
      const slotEndMinutes = getMinutes(slot.endtime)
      console.log('SPEDE5 isValidSlot', slot, sWeekday, slot.days.includes(sWeekday), slotStartMinutes, '<=',startMinutes, slotEndMinutes, '>=', endMinutes)
      if (slot.days.includes(sWeekday) && slotStartMinutes <= startMinutes && slotEndMinutes >= endMinutes) {
        return true
      }
      return false
    })
  }
  return false
}

const isScheduledAllowPostPayment = (schedules, selected) => {
  if (schedules === undefined) return false
  if (selected === null) return false
  const startMoment = moment.utc(selected.start)
  const endMoment = startMoment.clone().add(selected.duration, 'minutes')
  const sWeekday = startMoment.day() === 0 ? 7 : startMoment.day()
  const startMinutes = getMinutes(startMoment.format('HH:mm'))
  const endMinutes = getMinutes(endMoment.format('HH:mm'))
  const filteredByResource = schedules.filter((s) => s.resources.includes(selected.resourceId))
  const filteredByTime = filteredByResource.filter((s) => {
    const matchingSchedule = s.schedule.find((x) => {
      return x.day === sWeekday && getMinutes(x.start) <= startMinutes && getMinutes(x.end) >= endMinutes
    })
    return matchingSchedule !== undefined
  })
  return filteredByTime.length > 0

}

const mapStateToProps = (state, props) => {
  let selected = null
  if (props.selecttype) {
    if (props.selecttype === 'payable') selected = state.payable.selected
    if (props.selecttype === 'reservation') selected = state.reservation.selected
  }
  console.log('Payments mapStateToProps', selected)
  const {
    // reservation: { selected },
    firebase: { auth, profile },
    firestore,
    globaltime: { gtime },
  } = state
  const { appconfig, businessAccount, unallowedmethods } = props

  let cashsum = 0
  let sportmoneysum = 0
  let cashstoragesum = 0
  let minutestoragesum = 0
  let bl = false
  let blMessage = null
  let allowpostpayment = false
  const isBusiness = businessAccount ? true : false
  let benefitcards = []
  console.log('Payments mapStateToProps', isBusiness, businessAccount)

  const postpaydetails =
    profile.firstname &&
    profile.lastname &&
    profile.email &&
    profile.streetaddress &&
    profile.streetaddress.trim().length > 6 &&
    profile.zip &&
    profile.zip.trim().length === 5 &&
    !isNaN(parseInt(profile.zip)) &&
    profile.city &&
    profile.city.trim().length > 1

  if (appconfig.cashierpayment) allowpostpayment = true
  if (appconfig.cashierpaymentschedules && !allowpostpayment && selected.collection === 'reservations') allowpostpayment = isScheduledAllowPostPayment(appconfig.cashierpaymentschedules, selected)

  if (firestore.data['acls'] && firestore.data['acls'].default) {
    let aclkeys = Object.keys(firestore.data['acls'].default)
    if (aclkeys.includes('blocked')) {
      bl = firestore.data['acls'].default.blocked ? firestore.data['acls'].default.blocked : false
      if (bl) blMessage = firestore.data['acls'].default.blockedMessage || null
    }
    if (aclkeys.includes('allowpostpayment') && !appconfig.cashierpayment) {
      allowpostpayment = firestore.data['acls'].default.allowpostpayment || false
    }
  }
  if (isBusiness) {
    allowpostpayment = true
  }

  if (props.nooverridemetohods) {
    if (props.nooverridemetohods.includes('cashier')) allowpostpayment = false
  }

  if (firestore.data['wallets'] && selected) {
    let wallet = []
    const walletName = getWallet(appconfig, selected)
    console.log('walletName', walletName, appconfig, selected)

    if (firestore.data['wallets'] && firestore.data['wallets'][walletName]) {
      wallet = firestore.data['wallets'][walletName]
    }

    cashsum =
      wallet && wallet.cash
        ? wallet.cash.reduce((acc, curr) => {
            acc = acc + curr.amount
            return acc
          }, 0)
        : 0

    sportmoneysum =
      wallet && wallet.sportmoney
        ? wallet.sportmoney.reduce((acc, curr) => {
            acc = acc + curr.amount
            return acc
          }, 0)
        : 0
  }

  if (firestore.data['storages'] && selected) {

    // console.log('selected s', selected, appconfig.storages, firestore.data['storages'])



    let storage = []
    const shoudseparatesports = { cash: false, minutes: false }
    const selectedSport = selected.sport ? selected.sport.toLowerCase() : (selected.category ? selected.category.toLowerCase() : 'default')
    if (appconfig.storages) {
      appconfig.storages.forEach((s) => {
        if (s.category === 'cash' && s.separatesports) shoudseparatesports.cash = true
        if (s.category === 'minutes' && s.separatesports) shoudseparatesports.minutes = true
      })
    }
    const storageName = getStorage(appconfig, selected)
    if (firestore.data['storages'] && firestore.data['storages'][storageName]) {
      storage = firestore.data['storages'][storageName]
    }
    // if (firestore.data['storages'].default) {
    //   storage = firestore.data['storages'].default
    // }

    cashstoragesum =
      storage && storage.cash
        ? storage.cash.reduce((acc, curr) => {
            if (shoudseparatesports.cash) {
              const currSport = curr.sport ? curr.sport.toLowerCase() : curr.category ? curr.category.toLowerCase() : 'default'
              if (currSport === selectedSport) acc = acc + curr.amount
              return acc
            } else {
              acc = acc + curr.amount
              return acc
            }
          }, 0)
        : 0

    minutestoragesum =
      storage && storage.minutes
        ? storage.minutes.reduce((acc, curr) => {
            if (shoudseparatesports.minutes) {
              const currSport = curr.sport ? curr.sport.toLowerCase() : curr.category ? curr.category.toLowerCase() : 'default'
              if (currSport === selectedSport) acc = acc + curr.amount
              return acc
            } else {
              acc = acc + curr.amount
              return acc
            }
          }, 0)
        : 0
  }

  if (firestore.data['benefitcards'] && selected && (unallowedmethods && !unallowedmethods.includes('benefitcard'))) {
    const isSelectedArray = Array.isArray(selected)
    let _selectedArray = isSelectedArray ? selected : [selected]
    console.log('benefitcards, selectedArray', _selectedArray)
    const isReservationPayment = _selectedArray.every((val) => val.collection === 'reservations')
    if (props.selecttype === 'payable') {
      // map reservations to new reservations format
      // start, end, duration, resourceId
      _selectedArray = _selectedArray.map((r) => {
        if (r.collection !== 'reservations') return r
        const { start, end, resources } = r.resourcegroups[0]
        const duration = moment.utc(end).diff(moment.utc(start), 'minutes')
        const { id } = resources[0]
        const { date } = r.occurency
        const _date = moment.utc(date)
        const _start = moment.utc(start).year(_date.year()).month(_date.month()).date(_date.date())
        return {
          ...r,
          start: _start.format(),
          duration,
          resourceId: id,
        }
      })
    }
    
    console.log('benefitcards, selected', _selectedArray, isReservationPayment)
    console.log('benefitcards, list', firestore.data.benefitcards)
    // const selectedStart = moment.utc(selected.start)
    // console.log('benefitcards firebase', firestore.data['benefitcards'], selected, isSelectedArray)
    benefitcards = Object.keys(firestore.data.benefitcards || {})
      .filter((x) => x !== 'undefined' && x !== 'null')
      .map((k) => {
        return { ...firestore.data.benefitcards[k], id: k }
      })
      .filter((v) =>
        _selectedArray.every((s) => moment.utc(s.start).isSameOrBefore(moment.utc(v.validuntil, 'YYYY-MM-DD'), 'day'))
      )
      // .filter((v) => _selectedArray.every((s) => s.sport === v.sport))
      .filter(
        (v) =>
          (v.type === 'count' && v.remaining > 0) ||
          (v.type === 'time' &&
            _selectedArray.every((s) => moment.utc(v.end, 'YYYY.MM.DD').isSameOrAfter(moment.utc(s.start), 'day')))
      )
      .map((v) => {
        if (v.type === 'time') {
          if (moment.utc(v.end, 'YYYY.MM.DD').isBefore(moment.utc(v.validuntil, 'YYYY-MM-DD'), 'day')) {
            v.lastday = moment.utc(v.end, 'YYYY.MM.DD')
          } else {
            v.lastday = moment.utc(v.validuntil, 'YYYY-MM-DD')
          }
        }
        return v
      })
      .filter((v) => {
        if (v.collection === 'reservations') {
          console.log('SPEDE2 filter benefitcards', v.duration, v.resources, v.schedule, v.name)
          console.log('SPEDE3 filter selected', _selectedArray)
          // check if selected duration is same as benefitcard duration and if selected resource is included in benefitcard resources and if selected time is between benefitcard schedule
          return _selectedArray.every((s) => {
            return (
              v.duration === s.duration &&
              v.resources &&
              v.resources.includes(s.resourceId) &&
              isValidSlot(v.schedule, s) &&
              !s.isrecurrent
            )
          })
        } else {
          return _selectedArray.every((s) => s.sport === v.sport)
        }
      })
    
    console.log('benefitcards which match', benefitcards)
    // seleted sport matches
    // selected type count remaining > 0
    // selected type time end is adter seleted start time
    // console.log('benefitcards', benefitcards)
  }

  const _membrss = Object.keys(firestore.data.memberships || {})
    .filter((x) => x !== 'undefined' && x !== 'null')
    .map((k) => firestore.data.memberships[k])
    .filter((v) => v.permanent || gtime.isBetween(moment.utc(v.start), moment(v.end), 'day', '[]'))

  return {
    auth,
    profile,
    wallet: firestore.data['wallets'] || [],
    cashsum,
    sportmoneysum,
    cashstoragesum,
    minutestoragesum,
    memberships: _membrss,
    benefitcards,
    selected,
    allowpostpayment,
    postpaydetails,
    bl,
    blMessage,
    acls: firestore.data['acls'],
  }
}

const enhance = compose(
  connect((state) => ({ auth: state.firebase.auth })),
  firestoreConnect((props) => [
    {
      collection: 'users',
      doc: `${props.auth.uid}`,
      subcollections: [
        { collection: 'paymentinfo', doc: 'saldo', subcollections: [{ collection: `${props.appconfig.accountkey}` }] },
      ],
      storeAs: 'wallets',
    },
    {
      collection: 'users',
      doc: `${props.auth.uid}`,
      subcollections: [
        { collection: 'memberships', doc: 'active', subcollections: [{ collection: `${props.appconfig.accountkey}` }] },
      ],
      storeAs: 'memberships',
    },
    {
      collection: 'users',
      doc: `${props.auth.uid}`,
      subcollections: [
        { collection: 'acls', doc: 'active', subcollections: [{ collection: `${props.appconfig.accountkey}` }] },
      ],
      storeAs: 'acls',
    },
    {
      collection: 'users',
      doc: `${props.auth.uid}`,
      subcollections: [
        {
          collection: 'paymentinfo',
          doc: 'storage',
          subcollections: [{ collection: `${props.appconfig.accountkey}` }],
        },
      ],
      storeAs: 'storages',
    },
    {
      collection: 'users',
      doc: `${props.auth.uid}`,
      subcollections: [
        {
          collection: 'benefitcards',
          doc: 'active',
          subcollections: [{ collection: `${props.appconfig.accountkey}` }],
        },
      ],
      storeAs: 'benefitcards',
    },
  ]),
  connect(mapStateToProps)
)

const PAYMENT_METHODS = [
  'benefitcard',
  'sportmoneywallet',
  'cashwallet',
  'minutestore',
  'cashstore',
  'online',
  'cashier',
]
const GENDERLIST = [
  { label: 'Mies', value: 'm' },
  { label: 'Nainen', value: 'f' },
  { label: 'Muu', value: 'o' },
  { label: 'En halua kertoa', value: 'n' },
]

function Payments({
  auth,
  profile,
  selected,
  cashsum,
  sportmoneysum,
  cashstoragesum,
  minutestoragesum,
  benefitcards,
  wallet,
  dispatch,
  memberships,
  appconfig,
  bl,
  blMessage,
  allowpostpayment,
  postpaydetails,
  acls,
  price,
  unallowedmethods,
  buttonText,
  buttonAction,
  errorNotification,
  setPaymentProvider,
  ready,
  sport,
  duration,
}) {
  const firebase = useFirebase()
  const { t } = useTranslation()
  const [initDone, setInitDone] = useState(false)
  const [loading, setLoading] = useState(false)
  // const [redirect, setRedirect] = useState(false)
  // const [renderPayments, setRenderPayments] = useState(false)
  const [payments, setPayments] = useState(null)
  // const [errorNotification, setErrorNotification] = useState(null)
  const [cashwalletpayment, setCashwalletPayment] = useState(false)
  const [sportmoneywalletpayment, setSportmoneywalletPayment] = useState(false)
  const [cashstorepayment, setCashstorePayment] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [minutestorepayment, setMinutestorePayment] = useState(false)
  const [onlinepayment, setOnlinePayment] = useState(false)
  const [cashierpayment, setCashierPayment] = useState(false)
  const [onlineprovider, setOnlineprovider] = useState(null)
  const [benefitcardpayment, setBenefitcardPayment] = useState(false)
  const [selectedBenefitcard, setSelectedBenefitcard] = useState(null)

  const [onlineGroups, setOnlineGroups] = useState({})
  const [streetaddress, setStreetAddress] = useState(profile.streetaddress)
  const [zip, setZip] = useState(profile.zip)
  const [city, setCity] = useState(profile.city)
  const [savingAddress, setSavingAddress] = useState(false)
  const [sumprice, setSumprice] = useState(0)

  const [gender, setGender] = useState(profile.gender)
  const [yob, setYob] = useState(profile.yob)
  const [savingPersonInfo, setSavingPersonInfo] = useState(false)

  const [submitted, setSubmitted] = useState(false)

  const [benefitcardAnchorEl, setBenefitcardAnchorEl] = React.useState(null)
  const [selectedBenefitCardIndex, setSelectedBenefitCardIndex] = React.useState(0)

  const loaded = isLoaded(profile) && isLoaded(wallet)

  const isGengerValid = gender !== null
  let isYobValid = false
  if (yob) {
    if (typeof yob === 'string') {
      isYobValid = yob.trim() && yob.trim().length === 4 && !isNaN(parseInt(yob))
    } else {
      isYobValid = yob > 1900
    }
  }
  const isZipValid = zip && zip.trim() && zip.trim().length === 5 && !isNaN(parseInt(zip))
  const isCityValid = city && city.trim() && city.trim().length > 1
  const isStreetValid = streetaddress && streetaddress.trim() && streetaddress.trim().length > 3
  const isAddressValid = isCityValid && isZipValid && isStreetValid
  const isPersonInfoValid = isYobValid && isGengerValid


  console.log({
    auth,
    profile,
    selected,
    cashsum,
    sportmoneysum,
    cashstoragesum,
    minutestoragesum,
    benefitcards,
    wallet,
    dispatch,
    memberships,
    appconfig,
    bl,
    blMessage,
    allowpostpayment,
    postpaydetails,
    acls,
    price,
    unallowedmethods,
    buttonText,
    buttonAction,
    errorNotification,
    setPaymentProvider,
    ready,
    sport,
    duration,
  })

  useEffect(() => {
    // console.log('price changed', price)
    setSumprice(price)
    if (price === 0) {
      setCashwalletPayment(false)
      setPaymentSummary(false, false, false, false, false, false)
    }
    setInitDone(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [price])

  useEffect(() => {
    setLoading(true)
    const gws = Object.keys(appconfig.paymentgateways || {})
    const disableproviders = appconfig.disabledpaymentmethods || []
    firebase.functions().region = 'europe-west1'
    const fn = appconfig.istest ? 'testpaymentv3-getpaymentproviders' : 'paymentv3-getpaymentproviders'
    let getpaymentproviders = firebase.functions().httpsCallable(fn)
    getpaymentproviders().then((response) => {
      let a = {}
      for (const g in response.data) {
        a[g] = response.data[g].filter((x) => gws.includes(x.gateway) && !disableproviders.includes(x.id))
      }
      setOnlineGroups(a)
      setLoading(false)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSaveAddressPress = () => {
    const _p = { streetaddress, zip, city }
    return firebase.updateProfile(_p)
  }

  const onSavePersonInfoPress = () => {
    const _p = { gender, yob }
    return firebase.updateProfile(_p)
  }

  const buttonActionProxy = (params) => {
    // params is list of payments
    const p = params.map((x) => {
      if (x.type === 'benefitcard') {
        return { ...x, benefitcard: selectedBenefitcard }
      }
      return { ...x }
    })
    setSubmitted(true)
    buttonAction(p)
  }

  const getAddressFields = () => {
    return (
      <React.Fragment>
        <FormGroup className='loose-form'>
          <Label for='streetaddress'>{t('address')}</Label>
          <Input
            invalid={!isStreetValid}
            type='text'
            name='streetaddress'
            id='streetaddress'
            value={streetaddress || ''}
            placeholder=''
            autoComplete={'address'}
            onChange={(event) => setStreetAddress(event.target.value)}
          />
        </FormGroup>
        <FormGroup className='loose-form'>
          <Label for='zip'>{t('zip')}</Label>
          <Input
            invalid={!isZipValid}
            type='text'
            name='zip'
            id='zip'
            value={zip || ''}
            placeholder=''
            autoComplete={'zip'}
            onChange={(event) => setZip(event.target.value)}
          />
        </FormGroup>
        <FormGroup className='loose-form'>
          <Label for='city'>{t('city')}</Label>
          <Input
            invalid={!isCityValid}
            type='text'
            name='city'
            id='city'
            value={city || ''}
            placeholder=''
            autoComplete={'city'}
            onChange={(event) => setCity(event.target.value)}
          />
        </FormGroup>
        <Button
          color={'success'}
          disabled={!isAddressValid}
          onClick={() => {
            setSavingAddress(true)
            onSaveAddressPress().then((result) => {
              setSavingAddress(false)
            })
          }}
        >
          {savingAddress ? <Spinner /> : t('save')}
        </Button>
      </React.Fragment>
    )
  }

  const handleGenderChange = (event) => {
    setGender(event.target.value)
  }

  const getPersonInfoFields = () => {
    return (
      <React.Fragment>
        <FormGroup className='loose-form'>
          <TextField
            id='standard-select-type'
            select
            label={t('gender')}
            value={gender}
            onChange={handleGenderChange}
            style={{ width: '100%' }}
          >
            {GENDERLIST.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </FormGroup>
        <FormGroup className='loose-form'>
          <Label for='yob'>{t('yob')}</Label>
          <Input
            invalid={!isYobValid}
            type='number'
            name='yob'
            id='yob'
            value={yob || ''}
            placeholder=''
            autoComplete={'yob'}
            onChange={(event) => setYob(event.target.value)}
          />
        </FormGroup>
        <Button
          color={'success'}
          disabled={!isPersonInfoValid}
          onClick={() => {
            setSavingPersonInfo(true)
            onSavePersonInfoPress().then((result) => {
              setSavingPersonInfo(false)
            })
          }}
        >
          {savingPersonInfo ? <Spinner /> : t('save')}
        </Button>
      </React.Fragment>
    )
  }

  const getMissingAddress = (customText) => {
    return (
      <React.Fragment>
        <Row className='mt-2' key={'def9'}>
          <Col>
            <div>
              {!customText
                ? 'Ohittaaksesi etukäteismaksun tulee sinun päivittää osoitetietosi.'
                : 'Varauksen tehdäkseen tämä klubi vaatii osoitetiedot.'}
            </div>
          </Col>
        </Row>
        <Row>
          <Col>{getAddressFields()}</Col>
        </Row>
        <Row className='mt-2' key={'def7'}>
          <Col xs='6' sm='6' md='6' lg='6' xl='6'></Col>
          <Col xs='4' sm='4' md='4' lg='5' xl='5'></Col>
          <Col xs='2' sm='2' md='2' lg='1' xl='1' className={'text-right'}></Col>
        </Row>
      </React.Fragment>
    )
  }

  // eslint-disable-next-line no-unused-vars
  const getCustomerPersonInfo = () => {
    return (
      <React.Fragment>
        <Row className='mt-2' key={'def9'}>
          <Col>
            <div>
              Kohdentaaksemme tapahtumatarjontaa paremmin keräämme sukupuoli- ja ikätietoa.
            </div>
          </Col>
        </Row>
        <Row>
          <Col>{getPersonInfoFields()}</Col>
        </Row>
        <Row className='mt-2' key={'def7'}>
          <Col xs='6' sm='6' md='6' lg='6' xl='6'></Col>
          <Col xs='4' sm='4' md='4' lg='5' xl='5'></Col>
          <Col xs='2' sm='2' md='2' lg='1' xl='1' className={'text-right'}></Col>
        </Row>
      </React.Fragment>
    )
  }

  const setPaymentSummary = (sportmoneywallet, cashwallet, online, cashier, cashstore, benefitcard, minutestore) => {
    console.log('setPaymentSummary', sportmoneywallet, cashwallet, online, cashier, cashstore, benefitcard, minutestore)
    // console.log('setPaymentSummary', payments)
    const checkedPayments = []
    const selectedPrice = sumprice * 100
    let leftToPay = selectedPrice
    if (sportmoneysum > 0) {
      let charged = 0
      if (sportmoneywallet) {
        if (leftToPay <= sportmoneysum) {
          charged = leftToPay
          leftToPay = 0
        } else {
          charged = sportmoneysum
          leftToPay = leftToPay - sportmoneysum
        }
        checkedPayments.push({ type: 'sportmoneywallet', charged })
      }
    }
    if (cashsum > 0) {
      let charged = 0
      if (cashwallet && leftToPay > 0) {
        if (leftToPay <= cashsum) {
          charged = leftToPay
          leftToPay = 0
        } else {
          charged = cashsum
          leftToPay = leftToPay - cashsum
        }
        // default selection, but if price is 0, then don't select
        checkedPayments.push({ type: 'cashwallet', charged })
      }
    }
    if (cashstoragesum > 0) {
      // console.log('cashstore', cashstore, 'cashstoragesum', cashstoragesum, 'leftToPay', leftToPay)
      let charged = 0
      if (cashstore && leftToPay > 0) {
        if (leftToPay <= cashstoragesum) {
          charged = leftToPay
          leftToPay = 0
        } else {
          charged = cashstoragesum
          leftToPay = leftToPay - cashstoragesum
        }
        checkedPayments.push({ type: 'cashstore', charged })
      }
    }
    if (minutestoragesum > 0) {
      console.log('minutestore', minutestore, 'minutestoragesum', minutestoragesum, 'leftToPay', leftToPay)
      let charged = 0
      if (minutestore) {
        if (duration <= minutestoragesum) {
          charged = duration
          leftToPay = 0
        }
        checkedPayments.push({ type: 'minutestore', charged, cash: selectedPrice })
      }
      console.log('minutestore', minutestore, 'minutestoragesum', minutestoragesum, 'leftToPay', leftToPay, 'charged', charged)
    }
    if (leftToPay > 0 && online) {
      checkedPayments.push({ type: 'online', charged: leftToPay })
    } else if (leftToPay > 0 && cashier) {
      checkedPayments.push({ type: 'cashier', charged: leftToPay })
    } else if (leftToPay > 0 && benefitcard) {
      checkedPayments.push({ type: 'benefitcard', charged: leftToPay })
    }
    if (selectedPrice > 0) {
      setPayments(checkedPayments)
    } else {
      setPayments([])
    }
  }

  const setPaymentRow = (type, status) => {
    // console.log('setPaymentRow', type, status)
    if (type === 'sportmoneywallet') {
      setPaymentSummary(status, cashwalletpayment, onlinepayment, cashierpayment, cashstorepayment, benefitcardpayment, minutestorepayment)
      setSportmoneywalletPayment(status)
    } else if (type === 'cashwallet') {
      setPaymentSummary(
        sportmoneywalletpayment,
        status,
        onlinepayment,
        cashierpayment,
        cashstorepayment,
        benefitcardpayment,
        minutestorepayment
      )
      setCashwalletPayment(status)
    } else if (type === 'cashstore') {
      setPaymentSummary(
        sportmoneywalletpayment,
        cashwalletpayment,
        onlinepayment,
        cashierpayment,
        status,
        benefitcardpayment,
        minutestorepayment
      )
      setCashstorePayment(status)
    } else if (type === 'minutestore') {
      console.log('minutestore', status)
        setPaymentSummary(
          sportmoneywalletpayment,
          cashwalletpayment,
          onlinepayment,
          cashierpayment,
          cashstorepayment,
          benefitcardpayment,
          status
        )
        setMinutestorePayment(status)
    } else if (type === 'online') {
      setPaymentSummary(
        sportmoneywalletpayment,
        cashwalletpayment,
        status,
        cashierpayment,
        cashstorepayment,
        benefitcardpayment,
        minutestorepayment
      )
      setOnlinePayment(status)
    } else if (type === 'cashier') {
      setPaymentSummary(
        sportmoneywalletpayment,
        cashwalletpayment,
        onlinepayment,
        status,
        cashstorepayment,
        benefitcardpayment,
        minutestorepayment
      )
      setCashierPayment(status)
    } else if (type === 'benefitcard') {
      console.log('benefitcard', status)
      setPaymentSummary(
        sportmoneywalletpayment,
        cashwalletpayment,
        onlinepayment,
        cashierpayment,
        cashstorepayment,
        status,
        minutestorepayment
      )
      setBenefitcardPayment(status)
    }
  }

  const getY = (payment, method) => {
    if (payment) {
      if (method !== 'minutestore') {
        return payment.charged > 0 ? t('charging') + ' ' + (payment.charged / 100).toFixed(2) + ' €' : null
      } else {
        return payment.charged > 0 ? t('charging') + ' ' + payment.charged + ' min' : null
      }
    } else {
      return null
    }
  }

  const setOnlinePaymentType = (p) => {
    if (onlinepayment) {
      if (onlineprovider && p.id !== onlineprovider.id) {
        setOnlineprovider(p)
        setPaymentProvider(p)
      } else if (onlineprovider && p.id === onlineprovider.id) {
        setOnlineprovider(null)
        setPaymentProvider(null)
        setPaymentRow('online', !onlinepayment)
      } else if (!onlineprovider) {
        setOnlineprovider(p)
        setPaymentProvider(p)
      }
    } else {
      setPaymentRow('online', !onlinepayment)
      setOnlineprovider(p)
      setPaymentProvider(p)
    }
  }

  const getOnlineGroups = (allCovered, payment) => {
    const isDisabled = allCovered && !payment
    const _style = JSON.parse(JSON.stringify(buttonStyle))
    // const _selectedstyle = JSON.parse(JSON.stringify(buttonStyle))
    if (!isDisabled) {
      _style.backgroundColor = '#FFFFFF'
    } else {
      _style.backgroundColor = '#CCCCCC'
    }
    return Object.keys(onlineGroups).map((g) => {
      return (
        <Row key={g}>
          {onlineGroups[g].map((p, i) => {
            return (
              <button
                key={p.id + '-' + i}
                style={onlinepayment && onlineprovider && onlineprovider.id === p.id ? selectedButton : _style}
                disabled={isDisabled}
                onClick={() => setOnlinePaymentType(p)}
              >
                <img src={p.svg ? p.svg : p.icon} style={!p.svg ? { width: '80%' } : {}} alt={p.name} />
              </button>
            )
          })}
        </Row>
      )
    })
  }

  const handleClickListItem = (event) => {
    setBenefitcardAnchorEl(event.currentTarget)
  }

  const handleMenuItemClick = (event, index) => {
    setSelectedBenefitCardIndex(index)
    setBenefitcardAnchorEl(null)
    setSelectedBenefitcard(benefitcards[index])
    setPaymentRow('benefitcard', true)
  }

  const handleBenefitcardClose = () => {
    setBenefitcardAnchorEl(null)
  }

  const getPaymentMethods = (chargedSum, allCovered) => {
    console.log('Payments unallowedmethods', unallowedmethods, allowpostpayment)
    console.log('Payments payments', payments, sport)
    if (cashierpayment && !allowpostpayment) {
      setPaymentRow('cashier', false)
    }
    unallowedmethods.forEach((m) => {
      if (m === 'sportmoneywallet' && sportmoneywalletpayment) {
        setPaymentRow('sportmoneywallet', false)
      } else if (m === 'cashwallet' && cashwalletpayment) {
        setPaymentRow('cashwallet', false)
      } else if (m === 'minutestore' && minutestorepayment) {
        setPaymentRow('minutestore', false)
      } else if (m === 'cashstore' && cashstorepayment) {
        setPaymentRow('cashstore', false)
      }
    })
    return PAYMENT_METHODS.filter((m) => {
      if (m === 'cashier' && allowpostpayment) {
        return true
      } else {
        return !unallowedmethods.includes(m)
      }
    })
      .map((method) => {
        const payment = payments.find((p) => p.type === method)
        if (method === 'sportmoneywallet') {
          if (sportmoneysum <= 0) return null
          return (
            <Row key={'sportmoneywallet'}>
              <Col xs='6' sm='6' md='6' lg='6' xl='6'>
                <div>Liikuntaraha</div>
                <div>
                  <small>Saldo {sportmoneysum / 100} €</small>
                </div>
              </Col>
              <Col xs='4' sm='4' md='4' lg='5' xl='5' className={'text-right pt-2'}>
                {getY(payment, method)}
              </Col>
              <Col xs='2' sm='2' md='2' lg='1' xl='1' className={'text-right'}>
                <Checkbox
                  checked={sportmoneywalletpayment}
                  onChange={() => setPaymentRow('sportmoneywallet', !sportmoneywalletpayment)}
                  color='primary'
                  disabled={allCovered && !payment}
                />
              </Col>
            </Row>
          )
        } else if (method === 'cashwallet') {
          // console.log('cashwallet cashsum', cashsum)
          if (cashsum <= 0) return null
          return (
            <Row className='mt-2' key={'cashwallet'}>
              <Col xs='6' sm='6' md='6' lg='6' xl='6'>
                <div style={{ cursor: 'pointer' }} onClick={() => setPaymentRow('cashwallet', !cashwalletpayment)}>
                  {t('balances')}
                </div>
                <div>
                  <small>
                    {t('balance')} {(cashsum / 100).toFixed(2)} €
                  </small>
                </div>
              </Col>
              <Col xs='4' sm='4' md='4' lg='5' xl='5' className={'text-right pt-2'}>
                {getY(payment, method)}
              </Col>
              <Col xs='2' sm='2' md='2' lg='1' xl='1' className={'text-right'}>
                <Checkbox
                  checked={cashwalletpayment}
                  onChange={() => setPaymentRow('cashwallet', !cashwalletpayment)}
                  color='primary'
                  disabled={allCovered && !payment}
                />
              </Col>
            </Row>
          )
        } else if (method === 'minutestore') {
          if (minutestoragesum <= 0) return null
          if (duration > minutestoragesum) return null
          return (
            <Row key={'minutestore'}>
              <Col xs='6' sm='6' md='6' lg='6' xl='6'>
                <div>Varasto</div>
                <div>
                  <small>{minutestoragesum} min</small>
                </div>
              </Col>
              <Col xs='4' sm='4' md='4' lg='5' xl='5' className={'text-right pt-2'}>
                {getY(payment, method)}
              </Col>
              <Col xs='2' sm='2' md='2' lg='1' xl='1' className={'text-right'}>
                <Checkbox
                  checked={minutestorepayment}
                  onChange={() => setPaymentRow('minutestore', !minutestorepayment)}
                  color='primary'
                  disabled={allCovered && !payment}
                />
              </Col>
            </Row>
          )
        } else if (method === 'cashstore') {
          if (cashstoragesum <= 0) return null
          return (
            <Row className='mt-2' key={'cashstore'}>
              <Col xs='6' sm='6' md='6' lg='6' xl='6'>
                <div>Pankki / Varasto</div>
                <div>
                  <small>{(cashstoragesum / 100).toFixed(2)} €</small>
                </div>
              </Col>
              <Col xs='4' sm='4' md='4' lg='5' xl='5' className={'text-right pt-2'}>
                {getY(payment, method)}
              </Col>
              <Col xs='2' sm='2' md='2' lg='1' xl='1' className={'text-right'}>
                <Checkbox
                  checked={cashstorepayment}
                  onChange={() => setPaymentRow('cashstore', !cashstorepayment)}
                  color='primary'
                  disabled={allCovered && !payment}
                />
              </Col>
            </Row>
          )
        } else if (method === 'online' /*&& payment.charged > 0*/) {
          return (
            <React.Fragment key={'online-frag'}>
              <Row className='mt-2' key={'online'}>
                <Col xs='6' sm='6' md='6' lg='6' xl='6'>
                  <div>{t('online_payment')}</div>
                </Col>
                <Col xs='4' sm='4' md='4' lg='5' xl='5' className={'text-right pt-2'}>
                  {getY(payment, method)}
                </Col>
                <Col xs='2' sm='2' md='2' lg='1' xl='1' className={'text-right'}>
                  {/* <Checkbox disabled checked color='primary' /> */}
                  <Checkbox
                    checked={onlinepayment}
                    onChange={() => setPaymentRow('online', !onlinepayment)}
                    color='primary'
                    disabled={allCovered && !payment}
                  />
                </Col>
              </Row>
              <Row>
                <Col>{getOnlineGroups(allCovered, payment)}</Col>
              </Row>
              {!postpaydetails && appconfig.requireaddress ? getMissingAddress(appconfig.requireaddress) : null}
            </React.Fragment>
          )
        } else if (method === 'cashier' /*&& payment.charged > 0*/) {
          console.log('cashier allowpostpayment', allowpostpayment)
          if (!allowpostpayment) return null
          const addressmissing = allowpostpayment && !postpaydetails
          return (
            <React.Fragment key={'cashier-frag'}>
              <Row className='mt-2' key={'cashier'}>
                <Col xs='6' sm='6' md='6' lg='6' xl='6'>
                  <div>Maksu kassalle</div>
                </Col>
                <Col xs='4' sm='4' md='4' lg='5' xl='5' className={'text-right pt-2'}>
                  {getY(payment, method)}
                </Col>
                <Col xs='2' sm='2' md='2' lg='1' xl='1' className={'text-right'}>
                  {/* <Checkbox disabled checked color='primary' /> */}
                  <Checkbox
                    checked={cashierpayment}
                    onChange={() => setPaymentRow('cashier', !cashierpayment)}
                    color='primary'
                    disabled={allCovered && !payment}
                  />
                </Col>
              </Row>
              {addressmissing && (cashierpayment || appconfig.requireaddress)
                ? getMissingAddress(appconfig.requireaddress)
                : null}
            </React.Fragment>
          )
        } else if (method === 'benefitcard') {
          // if (benefitcardsum <= 0) return null
          if (benefitcards.length === 0) return null

          return (
            <React.Fragment>
              <Row className='mt-2' key={'benefitcard'}>
                <Col xs='6' sm='6' md='6' lg='6' xl='6'>
                  <List component='nav' aria-label='Sarjakortti' style={{ paddingLeft: 0 }}>
                    <ListItem
                      style={{ paddingLeft: 0 }}
                      button
                      aria-haspopup='true'
                      aria-controls='lock-menu'
                      aria-label='Sarjakortti'
                      onClick={handleClickListItem}
                      disabled={allCovered && !payment}
                    >
                      <ListItemText
                        style={{ paddingLeft: 0 }}
                        primary={benefitcards[selectedBenefitCardIndex].name}
                        secondary={
                          benefitcards[selectedBenefitCardIndex].type === 'count'
                            ? [t('remaining'), benefitcards[selectedBenefitCardIndex].remaining, t('counts')].join(' ')
                            : [
                                t('valid'),
                                benefitcards[selectedBenefitCardIndex].lastday
                                  ? benefitcards[selectedBenefitCardIndex].lastday.format('D.M.YY')
                                  : '',
                                t('until'),
                              ].join(' ')
                        }
                      />
                      <ExpandMore />
                    </ListItem>
                  </List>
                  <Menu
                    id='lock-menu'
                    anchorEl={benefitcardAnchorEl}
                    keepMounted
                    open={Boolean(benefitcardAnchorEl)}
                    onClose={handleBenefitcardClose}
                    
                  >
                    {benefitcards.map((benefitcard, index) => (
                      <MenuItem
                        key={benefitcard.id}
                        selected={index === selectedBenefitCardIndex}
                        onClick={(event) => handleMenuItemClick(event, index)}
                      >
                        <ListItemText
                          style={{ paddingLeft: 16 }}
                          primary={benefitcard.name}
                          secondary={benefitcard.type === 'count'
                          ? [benefitcard.name, t('remaining'), benefitcard.remaining, t('counts')].join(' ')
                          : [
                              benefitcard.name,
                              t('valid'),
                              benefitcard.lastday ? benefitcard.lastday.format('D.M.YY') : '',
                              t('until'),
                            ].join(' ')} />
                      </MenuItem>
                    ))}
                  </Menu>

                  {/* {benefitcards.map((benefitcard, i) => {
                    return (
                      <div key={'bcard-' + i}>
                        <div>{benefitcard.name}</div>
                        <div>
                          <small>
                            {benefitcard.type === 'count'
                              ? [t('remaining'), benefitcard.remaining, t('counts')].join(' ')
                              : [
                                  t('valid'),
                                  benefitcard.lastday ? benefitcard.lastday.format('D.M.YY') : '',
                                  t('until'),
                                ].join(' ')}
                          </small>
                        </div>
                      </div>
                    )
                  })} */}
                </Col>
                <Col xs='4' sm='4' md='4' lg='5' xl='5' className={'text-right pt-2'}></Col>
                <Col xs='2' sm='2' md='2' lg='1' xl='1' className={'text-right'} style={{ margin: 'auto' }}>
                  <Checkbox
                    checked={benefitcardpayment}
                    onChange={() => {
                      if (benefitcardpayment) {
                        setSelectedBenefitcard(null)
                      } else {
                        setSelectedBenefitcard(benefitcards[selectedBenefitCardIndex])
                      }
                      setPaymentRow('benefitcard', !benefitcardpayment)
                    }}
                    color='primary'
                    disabled={allCovered && !payment}
                  />
                </Col>
              </Row>
            </React.Fragment>
          )
        } else {
          return null
        }
      })
      .filter((x) => x)
  }

  const getPaymentSum = () => {
    if (!payments) return 0
    return payments.reduce((acc, cur) => {
      if (cur.type === 'minutestore') return acc + cur.cash
      return cur.charged + acc
    }, 0)
  }


  const renderPaymentSummary = () => {
    if (!payments) return null
    if (!selected) return null
    if (sumprice === 0) return null
    const chargedSum = getPaymentSum()
    const allCovered = sumprice * 100 === chargedSum
    return <React.Fragment>{getPaymentMethods(chargedSum, allCovered)}</React.Fragment>
  }

  if (loaded && sumprice && !initDone) {
    console.log('loaded', loaded, 'sumprice', sumprice, 'initDone', initDone)
    if (benefitcards && benefitcards.length > 0) {
      setSelectedBenefitcard(benefitcards[0])
      setPaymentSummary(false, false, false, false, false, true)
      setBenefitcardPayment(true)
      console.log('setting default benefitcard', benefitcards[0])
    } else if (
      (cashsum > 0 && !unallowedmethods.includes('cashwallet')) ||
      (sportmoneysum > 0 && !unallowedmethods.includes('sportmoneywallet'))
    ) {
      setPaymentSummary(false, true, false, false, false, false, false)
      setCashwalletPayment(true)
    } else {
      setPaymentSummary(false, false, false, false, false, false, false)
    }
    setInitDone(true)
  }

  if (loading) {
    return (
      <React.Fragment>
        <Row className='text-center'>
          <Col>
            <Spinner />
          </Col>
        </Row>
      </React.Fragment>
    )
  } else {
    let errorMessage = null
    if (errorNotification && typeof errorNotification === 'object') {
      errorMessage = errorNotification.fi
    } else if (errorNotification && typeof errorNotification === 'string') {
      errorMessage = 'Virhe varausta tehdessä: ' +  errorNotification
    }
    // console.log('ready', ready, 'submitted', submitted, 'sumprice', sumprice * 100, 'payments', payments, (payments ? payments.reduce((acc, cur) => cur.charged + acc, 0) : 0), 'cashierpayment', cashierpayment, 'allowpostpayment', allowpostpayment, 'postpaydetails', postpaydetails)
    const buttonDisabledByPrice =
      sumprice > 0 ? sumprice * 100 !== getPaymentSum() : false
    console.log('buttonDisabledByPrice', ready, submitted, buttonDisabledByPrice, cashierpayment, allowpostpayment, postpaydetails)
    return (
      <React.Fragment>
        {renderPaymentSummary()}
        {errorMessage ? (
          <CardText>
            <Alert className='mt-4' color='danger'>{errorMessage}</Alert>
          </CardText>
        ) : (
          <Button
            block
            disabled={
              !ready || submitted || buttonDisabledByPrice || (cashierpayment && allowpostpayment && !postpaydetails)
            }
            color='primary'
            className='confirm-btn'
            onClick={() => buttonActionProxy(payments)}
          >
            {submitted ? <Spinner size={'sm'} /> : null} {buttonText}
          </Button>
        )}
        {/* <pre>{JSON.stringify(payments, null, 2)}</pre> */}
      </React.Fragment>
    )
  }
}

export default withAppconfig(enhance(Payments))
