import React, { useState, useRef, useLayoutEffect, useEffect } from 'react'
import moment from 'moment'
import 'moment/locale/en-gb'
import 'moment/locale/et'
import 'moment/locale/sv'
import 'moment/locale/fi'
import { useHistory } from 'react-router-dom'
import withAppconfig from '../AppContext'
import { connect } from 'react-redux'
import { getTimeStops, getResources, getResourceTimes, formatSlot, formatResourceName } from './ViewUtils'
import useWindowDimensions from '../useWindowsDimensions'
import DurationPopover from './DurationPopover'
import DoubleScrollbar from 'react-double-scrollbar'
import { t } from 'i18next'
// const APPINITLANG = 'fi'


const enhance = connect(
  // Map redux state to component props
  ({ firebase: { auth, profile } }) => ({
    auth,
    profile,
  })
)

const styles = {
  tdempty: {
    borderLeft: '1px solid #ebebeb',
  },
  td: {
    fontSize: 'x-small',
    // borderTop: '1px solid black',
    // borderLeft: '1px solid black',
    // borderRight: '1px solid black',
    border: '0px solid white',
    // transform: 'rotate(-90deg)',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: 'center',
    width: 30,
    backgroundColor: '#007bff', //'#47c75a',//'lightskyblue',
    color: 'white',
    borderRadius: 10,
    cursor: 'pointer',
  },
  tr: { borderBottom: '0px solid #ebebeb' },
  th: {
    fontSize: 'small',
    textAlign: 'center',
    width: 50,
    minWidth: 50,
    // borderTop: '1px solid black',
    // borderLeft: '1px solid black',
    // borderRight: '1px solid black',
    // border: '1px solid black',
    // transform: 'rotate(-90deg)',
  },
  thtime: {
    fontSize: 'small',
    height: '25px',
    paddingRight: 10,
    borderBottom: '0px solid white',
    lineHeight: '25px',
    // borderTop: '1px solid black',
    // borderLeft: '1px solid black',
    // borderRight: '1px solid black',
    // border: '1px solid black',
    // transform: 'rotate(-90deg)',
  },
  table: {
    borderCollapse: 'collapse',
    // transform: 'rotate(90deg)',
    // transformOrigin: 'top left'
    // borderLeft: '1px solid black',
    // borderRight: '1px solid black',
  },
  verticalresource: {
    fontSize: 'small',
    borderBottom: '0px solid white',
  },
}

const selectorCellStyle = ({ hover, start, end, w }) => ({
  fontSize: 'x-small',
  // borderTop: start ? '0px solid white' : '0px solid white',
  // borderBottom: end ? '0px solid white' : '0px solid white',
  borderTop: start ? '0px' : '0px',
  borderBottom: end ? '0px' : '0px',
  borderLeft: '1px solid white',
  borderRight: '1px solid white',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  textAlign: 'center',
  verticalAlign: 'top',
  width: w ? w : 30,
  background:
    start && end && !hover ? 'repeating-linear-gradient(45deg,#007bff,#007bff 4px,#465298 4px,#465298 8px)' : 'none',
  backgroundColor: hover ? '#47c75a' : '#007bff', //'lightskyblue',
  color: 'white',
  borderTopLeftRadius: start ? '0.3rem' : '0rem',
  borderBottomLeftRadius: end ? '0.3rem' : '0rem',
  borderTopRightRadius: start ? '0.3rem' : '0rem',
  borderBottomRightRadius: end ? '0.3rem' : '0rem',
  cursor: 'pointer',
})

const SelectorCell = ({ mykey, span, cell, onClick, time, start, end, rowspan, width, amount }) => {
  const [hover, setHover] = useState(false)

  const select = (cell) => {
    setHover(true)
    onClick(cell)
  }
  const w = Math.floor(width / amount - 0)
  return (
    <td
      key={mykey}
      onClick={() => select(cell)}
      colSpan={span || 1}
      rowSpan={rowspan || 1}
      style={selectorCellStyle({ hover, start, end, w })}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {time ? time.replace(/^0/, '') : ''}
      <br />
      <span>Varaa</span>
    </td>
  )
}

// const countSpan = (c0, c1) => {
//   if (!c1) return c0.times[0].duration / 30
//   const diff = moment.utc(c1.start).diff(moment.utc(c0.start), 'minutes', true)
//   if (diff === 30) {
//     return 1
//   } else {
//     return c0.times[0].duration / 30
//   }
// }

function transpose(matrix) {
  return matrix[0].map((col, i) => matrix.map((row) => row[i]))
}

function VerticalView({ dailyFreeTimes, selectedvenue, servertime, appconfig, weather, dispatch }) {
  const history = useHistory()
  const ref = useRef(null)
  const { width } = useWindowDimensions()
  const [tablewidth, setTableWidth] = useState(0)
  const [vgrid, setVgrid] = useState([])

  useLayoutEffect(() => {
    function updateSize() {
      if (ref.current) {
        setTableWidth(ref.current.clientWidth)
      }
    }
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])

  const [durationDialogOpen, setDurationDialogOpen] = useState(false)
  const [selectedSlot, setSelectedSlot] = useState(null)

  const toggle = () => setDurationDialogOpen(!durationDialogOpen)

  const handleClick = (slot) => {
    if (slot.times && slot.times.length > 1) {
      setSelectedSlot(slot)
      setDurationDialogOpen(true)
    } else if (slot.times && slot.times.length === 1) {
      const d = slot.times[0].duration
      const _slot = formatSlot(slot)
      _slot.view = 'vertical'
      selectReservation(_slot, d)
    }
  }

  const selectReservation = (slot, d) => {
    slot.view = 'vertical'
    slot.selectedduration = d
    dispatch({
      type: 'UPDATE_RESERVATION',
      payload: slot,
    })
    history.push(appconfig.pathPrefix + '/reservation')
  }

  useEffect(() => {
    const resources = getResources(dailyFreeTimes, selectedvenue, appconfig).sort((a, b) => {
      if (a.order) {
        return a.order - b.order
      } else {
        return a.courtname.localeCompare(b.courtname, undefined, { numeric: true, sensitivity: 'base' })
      }
    })
    // console.log('resources', resources)
    let daystart = '06:00'
    let dayend = '23:00'
    let intervals = 30
    if (appconfig.times) {
      daystart = appconfig.times.s || daystart
      dayend = appconfig.times.e || dayend
      intervals = appconfig.times.i || intervals
    }
    const times = getTimeStops(servertime ? servertime : daystart, dayend, intervals)
    const grid = []
    const _t = times.map((r) => {
      if (r.indexOf(':00') > -1) {
        return { type: 'timeheader', key: 'timerow' + r, value: r.split(':')[0].replace(/^0/, '') }
      } else {
        return { type: 'timeheader', key: 'timerow' + r, value: null }
      }
    })
    _t.unshift({ type: 'nullcorner' })
    grid.push(_t)
    const resourceTimes = getResourceTimes(dailyFreeTimes, selectedvenue, appconfig)
    for (let resource of resources) {
      const __row = times.map((t) => {
        const _t = moment.utc(t, 'HH:mm')
        if (resourceTimes[resource.id] && resourceTimes[resource.id].length > 0) {
          const dateSetter = resourceTimes[resource.id][0].startmoment
          _t.year(dateSetter.year()).month(dateSetter.month()).date(dateSetter.date())
        }
        const isStart = resourceTimes[resource.id].find((r) => t === r.startmoment.format('HH:mm'))
        const isVacancy = resourceTimes[resource.id].find((r) => {
          if (r.times && r.times.length > 0) {
            const duration = r.times[r.times.length - 1].duration
            const _s = moment.utc(r.startmoment)
            return _t.isBetween(_s, moment.utc(r.startmoment).add(duration, 'minutes'), null, '[)')
          } else {
            return false
          }
        })
          ? true
          : false
        return { t, isStart, isVacancy }
      })

      const __rowCells = []
      __rowCells.push({ type: 'resourceheader', resource })
      __row.forEach((r, i) => {
        const end = __row[i + 1] && __row[i + 1].isVacancy ? false : true
        const spanstart = __row[i - 1] && __row[i - 1].isVacancy ? false : true
        if (r.isStart) {
          const span = 1
          let popupLocationIndicator = 'po' + r.isStart.id + moment.utc(r.isStart.start).format('HHmm')
          __rowCells.push({
            type: 'cell',
            key: popupLocationIndicator + '' + Math.random().toString().replace('.', ''),
            reservation: r.isStart,
            span,
            start: spanstart,
            end,
          })
        } else if (r.isVacancy) {
          __rowCells.push({ type: 'cell', reservation: null, vrender: true })
        } else {
          __rowCells.push({ type: 'cell', reservation: null, vrender: false })
        }
      })
      grid.push(__rowCells)
    }
    const verticalGrid = transpose(grid)
    setVgrid(verticalGrid)
  }, [selectedvenue, dailyFreeTimes, appconfig, servertime])

  const resourceHeaders =
    vgrid && vgrid.length > 0
      ? vgrid[0].map((r, i) => {
        if (r.type === 'nullcorner') {
          return <th key={'vert-head-' + i} style={styles.th}></th>
        } else {
          return (
            <th key={'vert-head-' + i} style={styles.th}>
              {formatResourceName(r.resource.courtname, width, vgrid[0].length - 1, 'vertical')}
            </th>
          )
        }
      })
      : null
  let isEmptyCategory = true
  const cal =
    vgrid && vgrid.length > 0
      ? vgrid.map((r) => {
        return r.map((c, i) => {
          if (!c) return null
          if (c.type === 'cell') {
            if (c.reservation !== null) {
              isEmptyCategory = false
              return (
                <SelectorCell
                  key={c.key}
                  mykey={c.key}
                  cell={c.reservation}
                  onClick={handleClick}
                  rowspan={c.span}
                  time={moment.utc(c.reservation.start).format('H:mm')}
                  start={c.start}
                  end={c.end}
                  width={tablewidth}
                  amount={vgrid[0].length}
                />
              )
            } else {
              const w = tablewidth / vgrid[0].length || 1
              if (c.vrender) {
                return (
                  <td
                    key={'cell' + i + Math.random().toString().replace('.', '')}
                    style={selectorCellStyle({ hover: false, start: false, end: c.end, w })}
                  />
                )
              } else {
                return <td key={'cell' + i + Math.random().toString().replace('.', '')} style={{ width: w }} />
              }
            }
          } else {
            if (c.value !== null) {
              return (
                <td key={c.key || 'tf-' + Math.random().toString().replace('.','')} style={styles.thtime}>
                  {c.value}
                </td>
              )
            } else {
              return <th key={'vempty-' + i + Math.random().toString().replace('.','')} style={styles.thtime}></th>
            }
          }
        })
      })
      : null
  
  console.log('cal', cal)
  
  if (isEmptyCategory) {
    return (
      <div key={'empty-cat-' + Math.random().toString()} style={{ textAlign: 'center', marginTop: 20 }}>{t('no_vacancy')}</div>
    )
  } else {
    return (
      <React.Fragment>
        <div id='jjj' ref={ref}>
          <DoubleScrollbar>
            <table style={styles.table}>
              <thead>
                <tr>{resourceHeaders}</tr>
              </thead>
              <tbody>{cal && cal.map((r, i) => <tr key={'cal-tr-' + i}>{r}</tr>)}</tbody>
            </table>
          </DoubleScrollbar>
        </div>
        <DurationPopover
          isOpen={durationDialogOpen}
          toggle={toggle}
          slot={selectedSlot}
          select={selectReservation}
          view={'vertical'}
        />
      </React.Fragment>
    )
  }
}
export default withAppconfig(enhance(VerticalView))
