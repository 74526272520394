const moment = require('moment')


const isPast = (activity, gtime) => {
  const _end = moment.utc(activity.end)
  const _now = gtime ? gtime : moment.utc()
  return _end.isBefore(_now)
}

const isUpcoming = (activity, gtime) => {
  const _start = moment.utc(activity.start)
  const _now = gtime ? gtime : moment.utc()
  return _start.isAfter(_now)
}

export const getStatus = (activity, gtime) => {

  const _cost = activity.price ? activity.price : 0

  const _missingPayment = { missing: activity.paymentstatus.unpaid, amount: parseFloat((activity.paymentstatus.unpaid / 100).toFixed(2)), haspayments: false }
  // const _refundedPayment = refundedPayment(activity, _cost * 100)
  const _isPast = isPast(activity, gtime)
  // const _isCancelled = isCancelled(activity)
  const _isUpcoming = isUpcoming(activity, gtime)
  // const _isStarted = isStarted(activity)
  const _isPaid = activity.paymentstatus.unpaid <= 0
  // const _isCancellable = _isStarted && !_isCancelled && !_isOnSale && !_isRecurring && !_isStored && !_isInvoiced
  // const _isSellable = _isStarted && !_isCancelled && !_isOnSale && !_isStored
  // const _isStorable = _isStarted && !_isCancelled && _isRecurring && !_isStored
  // const _showAccessCode = _isUpcoming && !_isCancelled && !_isOnSale
  // const _isSplittable = false // !_isCancelled && !_isOnSale && _isRecurring && !_isPaid
  // const _isRefunded = _refundedPayment.refunded === true
  const _isPayable = !_isPaid
  const _isPaidWithBenefitcard = activity.payments && activity.payments.some((p) => p.type === 'benefitcard')
  const _isReceiptEnabled = _isPaid && _cost > 0 && !_isPaidWithBenefitcard
  return {
    cost: _cost,
    // isCancellable: _isCancellable,
    // isCancelled: _isCancelled,
    // isInvoiced: _isInvoiced,
    isPaid: _isPaid,
    isPast: _isPast,
    isPayable: _isPayable,
    isReceiptEnabled: _isReceiptEnabled,
    isPaidWithBenefitcard: _isPaidWithBenefitcard,
    // isRefunded: _isRefunded,
    // isStarted: _isStarted,
    isUpcoming: _isUpcoming,
    missingPayment: _missingPayment,
  }
}
