import React, { useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Row, Col, Button } from 'reactstrap'
import ViewColumnIcon from '@material-ui/icons/ViewColumn'
import ViewListIcon from '@material-ui/icons/ViewList'
import CalendarViewDayIcon from '@material-ui/icons/CalendarViewDay'
import { useTranslation } from 'react-i18next'
import { Typography } from '@material-ui/core'

function SelectDefaultViewDialog({ setOpenDialog, openDefaultViewDialog, updateDefaultView, cancelDialog }) {
  const { t } = useTranslation()
  const [selectedViewDescription, setSelectedViewDescription] = useState('simple')

  const selectDefault = () => {
    updateDefaultView(selectedViewDescription)
    setTimeout(() => {
      setOpenDialog(false)
    }, 700)
  }

  return (
    <Dialog
      open={openDefaultViewDialog}
      onClose={() => cancelDialog()}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>{'Valitse oletusnäkymä'}</DialogTitle>
      <DialogContent>
        <Row className='text-center'>
          <Col>
            <DialogContentText id='alert-dialog-description'>
              Valitse näkymä jota haluat käyttää oletusnäkymänä kun teet selaat varauksia. Voit vaihtaa oletusnäkymää
              myöhemmin "Omat tiedot"-kohdasta.
            </DialogContentText>
          </Col>
        </Row>

        <Row className='text-center'>
          <Col
            xs={4}
            className={selectedViewDescription === 'simple' ? 'text-center  border-top' : 'text-center'}
            onClick={() => setSelectedViewDescription('simple')}
          >
            <Typography variant='caption' component={'p'}>
              Pikavalinta
            </Typography>
            <CalendarViewDayIcon
              // color={selectedViewDescription === 'simple' ? 'black' : 'action'} /*color={selectedViewDescription === 'simple' ? 'black' : 'action'}*/
              style={selectedViewDescription === 'simple' ? { color: 'rgba(0,0,0,1)' } : { color : 'rgba(0,0,0,0.54)'}}
            />
          </Col>
          <Col
            xs={4}
            className={selectedViewDescription === 'horizontal' ? 'text-center  border-top' : 'text-center'}
            onClick={() => setSelectedViewDescription('horizontal')}
          >
            <Typography variant='caption' component={'p'}>
              Vaakakalenteri
            </Typography>
            <ViewListIcon
              // color={selectedViewDescription === 'horizontal' ? 'black' : 'action'}
              style={selectedViewDescription === 'horizontal' ? { color: 'rgba(0,0,0,1)' } : { color : 'rgba(0,0,0,0.54)'}}
            />
          </Col>
          <Col
            xs={4}
            className={selectedViewDescription === 'vertical' ? 'text-center  border-top' : 'text-center'}
            onClick={() => setSelectedViewDescription('vertical')}
          >
            <Typography variant='caption' component={'p'}>
              Pystykalenteri
            </Typography>
            <ViewColumnIcon
              // color={selectedViewDescription === 'vertical' ? 'black' : 'action'}
              style={selectedViewDescription === 'vertical' ? { color: 'rgba(0,0,0,1)' } : { color : 'rgba(0,0,0,0.54)'}}
            />
          </Col>
        </Row>
        <Row className='text-center mt-3'>
          <Col className={selectedViewDescription === 'simple' ? '' : 'd-none'}>
            Pikavalinnassa näet alkuajoittain kaikki vapaat kentät ja vapaan ajan keston.
          </Col>
          <Col className={selectedViewDescription === 'horizontal' ? '' : 'd-none'}>
            Vaakakalenterissa vasemmalla on kenttien nimet ja ylhäällä kellon ajat. Vuoron kesto valitaan kun alkuaika
            on varattu.
          </Col>
          <Col className={selectedViewDescription === 'vertical' ? '' : 'd-none'}>
            Pystykalenterissa vasemmalla on kellonajat ja ylhäällä kenttien nimet. Vuoron kesto valitaan kun alkuaika on
            varattu.
          </Col>
        </Row>
        <Row className='text-center'>
          <Col>
            <Button onClick={selectDefault} className={'mt-4 mb-1'} size={'sm'} color={'primary'}>
              Aseta tämä oletukseksi
            </Button>
          </Col>
        </Row>
        <Row className='text-center'>
          <Col>
            <img width='100%' src={`/imgs/selectdefault/${selectedViewDescription}-1-fi.png`} alt={'d'} />
          </Col>
        </Row>
      </DialogContent>
      <DialogActions>
        <Button color={'link'} onClick={() => cancelDialog()}>
          {t('close')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SelectDefaultViewDialog
