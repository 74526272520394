
import React, { useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Button, FormGroup, Label, Input, Spinner } from 'reactstrap';
import { useFirebase } from 'react-redux-firebase';
import { useTranslation } from 'react-i18next';
import { TextField, MenuItem, Typography, Divider } from '@material-ui/core';

const GENDERLIST = [
  { label: 'Mies', value: 'm' },
  { label: 'Nainen', value: 'f' },
  // { label: 'Muu', value: 'o' },
  // { label: 'En halua kertoa', value: 'n' },
]

const YEARS = Array.from({ length: 120 }, (_, i) => i + new Date().getFullYear() - 120).reverse()

const enhance = compose(
  connect(({ firebase: { profile } }) => ({ profile }))
)

const validateStreetAddress = (streetaddress) => {
  if (!streetaddress) return false
  return streetaddress && streetaddress.trim() && streetaddress.trim().length > 3
}

const validateZip = (zip) => {
  if (!zip) return false
  return zip && zip.trim() && zip.trim().length === 5 && !isNaN(parseInt(zip))
}

const validateCity = (city) => {
  if (!city) return false
  return city && city.trim() && city.trim().length > 1
}

const validateYob = (yob) => {
  if (!yob) return false
  let _yob = yob
  if (typeof yob === 'string') {
    _yob = parseInt(yob)
    if(isNaN(_yob)) return false
  }
  return _yob && !isNaN(_yob) && _yob > new Date().getFullYear() - 120 && _yob < new Date().getFullYear()
}

const validateGender = (gender) => {
  if(!gender) return false
  return GENDERLIST.find((g) => gender === g.value) !== undefined
}

function ProfileInput({ profile, onValid, label }) {
  const firebase = useFirebase()
  const { t } = useTranslation()
  const [savingAddress, setSavingAddress] = useState(false)
  const [savingPersonInfo, setSavingPersonInfo] = useState(false)

  const [gender, setGender] = useState(profile.gender)
  const [yob, setYob] = useState(profile.yob)
  const [streetaddress, setStreetAddress] = useState(profile.streetaddress)
  const [zip, setZip] = useState(profile.zip)
  const [city, setCity] = useState(profile.city)

  const isProfileAddressValid = profile && validateCity(profile.city) && validateStreetAddress(profile.streetaddress) && validateZip(profile.zip)
  const isProfilePersonInfoValid = profile && validateYob(profile.yob) && validateGender(profile.gender)
  const isProfileValid = isProfileAddressValid && isProfilePersonInfoValid

  const isGengerValid = validateGender(gender)
  const isYobValid = validateYob(yob)
  const isZipValid = validateZip(zip)
  const isCityValid = validateCity(city)
  const isStreetValid = validateStreetAddress(streetaddress)
  const isAddressValid = isCityValid && isZipValid && isStreetValid
  const isPersonInfoValid = isYobValid && isGengerValid

  React.useEffect(() => {
    if (!onValid) return
    if (isProfileValid) {
      onValid(true)
    }
    else {
      onValid(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProfileValid])

  const handleGenderChange = (event) => {
    setGender(event.target.value)
  }

  const handleYobChange = (event) => {
    setYob(event.target.value)
  }

  const onSaveAddressPress = () => {
    const _p = { streetaddress, zip, city }
    return firebase.updateProfile(_p)
  }

  const onSavePersonInfoPress = () => {
    const _p = { gender, yob }
    return firebase.updateProfile(_p)
  }


  const getAddressFields = () => {
    return (
      <React.Fragment>
        <FormGroup className='loose-form'>
          <Label for='streetaddress'>{t('address')}</Label>
          <Input
            invalid={!isStreetValid}
            type='text'
            name='streetaddress'
            id='streetaddress'
            value={streetaddress || ''}
            placeholder=''
            autoComplete={'address'}
            onChange={(event) => setStreetAddress(event.target.value)}
          />
        </FormGroup>
        <FormGroup className='loose-form'>
          <Label for='zip'>{t('zip')}</Label>
          <Input
            invalid={!isZipValid}
            type='text'
            name='zip'
            id='zip'
            value={zip || ''}
            placeholder=''
            autoComplete={'zip'}
            onChange={(event) => setZip(event.target.value)}
          />
        </FormGroup>
        <FormGroup className='loose-form'>
          <Label for='city'>{t('city')}</Label>
          <Input
            invalid={!isCityValid}
            type='text'
            name='city'
            id='city'
            value={city || ''}
            placeholder=''
            autoComplete={'city'}
            onChange={(event) => setCity(event.target.value)}
          />
        </FormGroup>
        <Button
          color={'success'}
          disabled={!isAddressValid}
          onClick={() => {
            setSavingAddress(true)
            onSaveAddressPress().then((result) => {
              setSavingAddress(false)
            })
          }}
        >
          {savingAddress ? <Spinner /> : t('save')}
        </Button>
      </React.Fragment>
    )
  }

  const getPersonInfoFields = () => {
    return (
      <React.Fragment>
        <FormGroup className='loose-form'>
          <TextField
            id='select-gender'
            select
            error={!isGengerValid}
            label={t('gender')}
            value={gender || ''}
            onChange={handleGenderChange}
            style={{ width: '100%' }}
          >
            {GENDERLIST.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </FormGroup>
        <FormGroup className='loose-form'>
        <TextField
            id='select-yob'
            select
            error={!isYobValid}
            label={t('yob')}
            value={yob || ''}
            onChange={handleYobChange}
            style={{ width: '100%' }}
          >
            {YEARS.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </FormGroup>
        <Button
          color={'success'}
          disabled={!isPersonInfoValid}
          onClick={() => {
            setSavingPersonInfo(true)
            onSavePersonInfoPress().then((result) => {
              setSavingPersonInfo(false)
            })
          }}
        >
          {savingPersonInfo ? <Spinner /> : t('save')}
        </Button>
      </React.Fragment>
    )
  }

  return (
    <div>
      {!isProfileValid ? 
      <Typography variant='subtitle2' gutterBottom>
        {label || ''}
      </Typography> : null}
      {!isProfileAddressValid ? getAddressFields() : null}
      {!isProfilePersonInfoValid ? getPersonInfoFields() : null}
      <Divider style={{ marginTop: 20, marginBottom: 20 }} />
    </div>
  );
}

export default enhance(ProfileInput)