import React, { useState } from 'react'
import { isLoaded, useFirebase, firestoreConnect } from 'react-redux-firebase'
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardHeader,
  CardText,
  Button,
  Spinner,
  Modal,
  ModalBody,
  FormGroup,
  Label,
  Input,
} from 'reactstrap'
import { Redirect, useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
// import Checkbox from '@material-ui/core/Checkbox'

import moment from 'moment'
import 'moment/locale/fi'
import { Divider } from '@material-ui/core'
import PaymentRedirect from './PaymentRedirect'
import PaymentOptions from './PaymentOptions'
import { useEffect } from 'react'
import { compose } from 'redux'
import { useTranslation } from 'react-i18next'
import { useCookies } from 'react-cookie'
import withAppconfig from '../AppContext'
import Payments from '../components/Payments'
import { getWallet } from '../components/ViewUtils'
import Giftcode from '../components/Giftcode'

const enhance = compose(
  connect((state) => ({ auth: state.firebase.auth })),
  firestoreConnect((props) => [
    {
      collection: 'users',
      doc: `${props.auth.uid}`,
      subcollections: [
        { collection: 'paymentinfo', doc: 'saldo', subcollections: [{ collection: `${props.appconfig.accountkey}` }] },
      ],
      storeAs: 'wallets',
    },
  ]),
  connect(({ payable: { selected }, firebase: { auth, profile }, firestore }, { appconfig }) => {
    if (selected) {
      // console.log('selected', selected)
      const summary = { price: 0 }
      let wallet = []
      const walletName = getWallet(appconfig, summary)
      if (firestore.data['wallets'] && firestore.data['wallets'][walletName]) {
        wallet = firestore.data['wallets'][walletName]
      }
      const cashsum =
        wallet && wallet.cash
          ? wallet.cash.reduce((acc, curr) => {
              acc = acc + curr.amount
              return acc
            }, 0)
          : 0

      const sportmoneysum =
        wallet && wallet.sportmoney
          ? wallet.sportmoney.reduce((acc, curr) => {
              acc = acc + curr.amount
              return acc
            }, 0)
          : 0

      return {
        auth,
        profile,
        selected,
        summary,
        cashsum,
        sportmoneysum,
        wallet: firestore.data['wallets'] || [],
        memberships: []
      }
    } else {
      return {
        auth,
        profile,
        selected,
        summary: {},
        cashsum: 0,
        sportmoneysum: 0,
        memberships: [],
      }
    }
  })
)

const ActivityPaymentScreen = ({
  auth,
  profile,
  selected,
  summary,
  cashsum,
  sportmoneysum,
  wallet,
  dispatch,
  appconfig,
  memberships
}) => {
  const firebase = useFirebase()
  let history = useHistory()
  let defaultLanguage = 'FI'
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [redirect, setRedirect] = useState(false)
  const [renderPayments, setRenderPayments] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [token, setToken] = useState(null)
  // const [payments, setPayments] = useState(null)
  const [errorNotification, setErrorNotification] = useState(null)
  const [onlinepaymentprovider, setOnlinePaymentProvider] = useState(null)
  const [initdone, setInitDone] = useState(false)
  const [usernote, setUserNote] = useState(null)
  const [membershipprices, setMembershipPrices] = useState(null)
  const [giftcoderedeem, setGiftcoderedeem] = useState(null)
  const [pricescheck, setPricesCheck] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie] = useCookies(['selectedLanguage'])
  if (cookies.selectedLanguage) {
    defaultLanguage = cookies.selectedLanguage
  }
  // console.log('saldo', saldo, isLoaded(saldo))

  useEffect(() => {
    moment.locale(defaultLanguage.toLowerCase(), { week: { dow: 3 } })
  }, [defaultLanguage])
  const loaded = isLoaded(profile) && isLoaded(wallet)

  if (loaded && !initdone) {
    setInitDone(true)
  }

  const goActivities = () => {
    dispatch({
      type: 'CLEAR_PAYABLE_ACTIVITY',
      payload: null,
    })
    history.push(appconfig.pathPrefix + '/activities/all')
  }

  const setPaymentProvider = (p) => setOnlinePaymentProvider(p)

  const payActivity = async (selectedPayments) => {
    setLoading(true)
    const activityObject = {
      id: selected.id,
      queue: false,
      customerid: appconfig.accountkey,
      paymentmethod: selectedPayments,
      usernote: usernote,
    }

    if (giftcoderedeem) {
      activityObject.giftcode = giftcoderedeem
    }

    const shouldRenderOptions =
      selected.price > 0 ? selectedPayments.find((x) => x.type === 'online' && x.charged > 0) : false
    const shouldRedict = selected.price > 0 ? selectedPayments.find((x) => x.type === 'online' && x.charged > 0) : false
    firebase.functions().region = 'europe-west1'
    const fn = appconfig.istest ? 'testactivities-paymulti' : 'activities-paymulti'
    const activityPayment = firebase.functions().httpsCallable(fn)
    try {
      let response = await activityPayment(activityObject)
      // console.log(response.data)
      if (response.data.error) {
        setLoading(false)
        setErrorNotification(response.data.error)
      } else if (shouldRenderOptions) {
        setRenderPayments(true)
      } else if (shouldRedict) {
        setRedirect(true)
      } else {
        setLoading(false)

        history.push(appconfig.pathPrefix + '/activities/all')
      }
    } catch (error) {
      alert(error.message)
      // console.log(error)
      setLoading(false)
    }
  }

  const getNoteRow = () => {
    if (appconfig.activityextrafields) {
      return (
        <React.Fragment>
          <Divider />
          <Row className={'loose'}>
            <Col>
              <FormGroup className='loose-form'>
                <Label for='usernote'>{t('additionalinfo')}</Label>
                <Input
                  type='text'
                  name='usernote'
                  id='usernote'
                  value={usernote || ''}
                  placeholder=''
                  onChange={(event) => setUserNote(event.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>
        </React.Fragment>
      )
    } else {
      return null
    }
  }

  // const setPaymentSummary = (sportmoneywallet, cashwallet, online, cashier, cashstore) => {
  //   const checkedPayments = []
  //   const selectedPrice = selected.price * 100
  //   let leftToPay = selectedPrice
  //   if (sportmoneysum > 0) {
  //     let charged = 0
  //     if (sportmoneywallet) {
  //       if (leftToPay <= sportmoneysum) {
  //         charged = leftToPay
  //         leftToPay = 0
  //       } else {
  //         charged = sportmoneysum
  //         leftToPay = leftToPay - sportmoneysum
  //       }
  //       checkedPayments.push({ type: 'sportmoneywallet', charged })
  //     }
  //   }
  //   if (cashsum > 0) {
  //     // console.log('cashsum', cashsum, 'leftToPay', leftToPay)
  //     let charged = 0
  //     if (cashwallet && leftToPay > 0) {
  //       if (leftToPay <= cashsum) {
  //         charged = leftToPay
  //         leftToPay = 0
  //       } else {
  //         charged = cashsum
  //         leftToPay = leftToPay - cashsum
  //       }
  //       checkedPayments.push({ type: 'cashwallet', charged })
  //     }
  //   }
  //   // if (cashstoragesum > 0) {
  //   //   // console.log('cashstore', cashstore, 'cashstoragesum', cashstoragesum, 'leftToPay', leftToPay)
  //   //   let charged = 0
  //   //   if (cashstore && leftToPay > 0) {
  //   //     if (leftToPay <= cashstoragesum) {
  //   //       charged = leftToPay
  //   //       leftToPay = 0
  //   //     } else {
  //   //       charged = cashstoragesum
  //   //       leftToPay = leftToPay - cashstoragesum
  //   //     }
  //   //     checkedPayments.push({ type: 'cashstore', charged })
  //   //   }
  //   // }
  //   if (leftToPay > 0 && online) {
  //     checkedPayments.push({ type: 'paytrail', charged: leftToPay })
  //   } else if (leftToPay > 0 && cashier) {
  //     checkedPayments.push({ type: 'cashier', charged: leftToPay })
  //   } else {
  //     // checkedPayments.push({ type: 'cashier', charged: 0 })
  //     // checkedPayments.push({ type: 'paytrail', charged: 0 })
  //   }
  //   // setPayments(checkedPayments)
  // }

  const updateItemPricing = (prices) => {
    if (prices && prices.length > 0) {
      prices.sort((a, b) => a.price - b.price)
    }
    let membershipids =  memberships.map((v) => v.membershipid ) //Object.keys(memberships || {}).map((key) => {
    //   return memberships[key].membershipid
    // })
    let _membershipprices = prices.filter((x) => {
      if (x.membershipid) {
        return membershipids.includes(x.membershipid)
      } else {
        return true
      }
    })
    // console.log('membershipprices', _membershipprices, 'selected', { ...selected })
    const originalPrice = selected.price
    if (_membershipprices && _membershipprices.length > 0) {
      selected.price = _membershipprices[0].price
    }
    if (_membershipprices.length > 0) {
      selected.overridecost = true
      const gen = {
        pricing: 'general', price: originalPrice
      }
      _membershipprices.push(gen)
      // membershipprices.find((x) => x.pricing === 'general')
      // selected.general = gen
    }
    setMembershipPrices(_membershipprices)
  }

  const onValidGiftcode = (membershipprices, giftcode) => {
    // setMembershipPrices(membershipprices)
    setGiftcoderedeem(giftcode)
    updateItemPricing(membershipprices)
    // setPaymentSummary(false, true, false, false, false)
  }

  const cancelEntry = async (id) => {
    console.log('cancelEntry', id)
    setLoading(true)
    const activityObject = {
      id,
      customerid: appconfig.accountkey,
    }

    firebase.functions().region = 'europe-west1'
    const fn = appconfig.istest ? 'testactivities-cancel' : 'activities-cancel'
    const cancel = firebase.functions().httpsCallable(fn)
    try {
      const response = await cancel(activityObject)
      setLoading(false)
      if (response.data.error) {
        alert(response.data.message)
      }
      history.push(appconfig.pathPrefix + '/')
    } catch (error) {
      setLoading(false)
      alert(error.message)
    }
  }

  if (!selected) {
    return <Redirect to='/' />
  }

  if (renderPayments) {
    return (
      <PaymentOptions
        selectedGw={'pc'}
        authorization={token}
        provider={onlinepaymentprovider}
        disabledmethods={appconfig.disabledpaymentmethods || []}
        onCancel={cancelEntry}
        cancellable={{ id: selected.id, type: 'activity' }}

      />
    )
  } else if (redirect) {
    return <PaymentRedirect selectedGw={'pc'} authorization={token} />
  }

  if (!loaded) {
    return (
      <div className='full-page-background'>
        <Container className='login-dialog'>
          <Spinner /> Loading ...
        </Container>
      </div>
    )
  } else {
    return (
      <div className='full-page-background'>
        <Container className='login-dialog'>
          <Row>
            <Col md='8' className='mx-auto'>
              <Card>
                <CardHeader tag='h4'>{t('payment')}</CardHeader>
                <CardBody>
                  <CardTitle tag='h5'>{selected.name.fi}</CardTitle>
                  <Row className={'loose'}>
                    <Col>{t('day')}</Col>
                    <Col style={{ textTransform: 'capitalize' }} className={'text-right'}>
                      {moment(selected.start).format('dddd D.M.YYYY')}
                    </Col>
                  </Row>
                  <Divider />
                  <Row className={'loose'}>
                    <Col>{t('time')}</Col>
                    <Col className={'text-right'}>{moment(selected.start).format('H:mm')}</Col>
                  </Row>
                  <Divider />
                  {/* <Row className={'loose'}>
                    <Col>{t('price')}</Col>
                    <Col className={'text-right'}>{selected.price} €</Col> */}
                  {pricescheck ? (
                    <Spinner size='sm' />
                  ) : membershipprices ? (
                    membershipprices.map((p, i) => {
                      return (
                        <Row key={'membership-prices-' + i} className={'loose'}>
                          <Col>{p.pricing === 'general' ? t('price') : p.pricing}</Col>
                          <Col className={'text-right'}>
                            {i >= 1 ? (
                              <s>{p.price.toFixed(2).replace('.', ',')} €</s>
                            ) : (
                              p.price.toFixed(2).replace('.', ',') + ' €'
                            )}
                            {p.lastminute ? ' ' + t('lastminute_discount') : ''}
                          </Col>
                        </Row>
                      )
                    })
                  ) : (
                    <Row className={'loose'} key={'price'}>
                      <Col>{t('price')}</Col>
                      <Col className={'text-right'}>{selected.price.toFixed(2).replace('.', ',')} €</Col>
                    </Row>
                  )}

                  {/* </Row> */}
                  {getNoteRow()}
                  <Divider />
                  <Row className={'loose'}>
                    <Col xs={4} sm={3}>
                      <Giftcode
                        selected={selected}
                        membershipprices={membershipprices}
                        pricesCheckState={setPricesCheck}
                        onValidGiftcode={onValidGiftcode}
                        producttype='activity'
                      />
                    </Col>
                  </Row>
                  <CardText></CardText>
                  <Payments
                    price={selected.price}
                    selecttype='payable'
                    unallowedmethods={['cashier', 'cashstore', 'minutestore']}
                    buttonText={selected.price > 0 ? t('pay') : t('confirm')}
                    buttonAction={payActivity}
                    errorNotification={errorNotification}
                    setPaymentProvider={setPaymentProvider}
                    ready={true}
                  />
                  <Button size='small' color='link' className='text-muted' onClick={() => goActivities()}>
                    <ChevronLeftIcon /> {t('back_to_activities')}
                  </Button>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <Modal
          size='sm'
          centered
          isOpen={loading}
          backdrop={true}
          wrapClassName={'al-wrap'}
          modalClassName={'al-modal'}
          contentClassName={'loading-spinner'}
        >
          <ModalBody className='text-center'>
            <Spinner color='light' /> <p style={{ color: 'white' }}>Vahvistetaan</p>
          </ModalBody>
        </Modal>
      </div>
    )
  }
}

export default withAppconfig(enhance(ActivityPaymentScreen))
