// import { Link, Typography } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import Carousel from 'react-material-ui-carousel'
import moment from 'moment'
// import { useHistory } from 'react-router'
import withAppconfig from '../AppContext'
import { useUiGet } from '../utils/useUiGet'

// const textInfoStyle = {
//   // backgroundColor: '#cff4fc',
//   background:
//     'linear-gradient(rgb(48 173 255), rgb(109, 213, 250), rgb(195 233 255))',
//   borderRadius: 5,
//   padding: 8,
//   borderColor: '#b6effb',
//   borderStyle: 'solid',
//   borderWidth: 1,
// }

// const textAdStyle = {
//   backgroundColor: '#ffffff',
//   borderRadius: 0,
//   padding: 8,
//   borderColor: '#cccccc',
//   borderStyle: 'solid',
//   borderWidth: 1,
//   textAlign: 'left',
// }

// const activityAdStyle = {
//   backgroundColor: '#ffffff',
//   borderRadius: 0,
//   padding: 8,
//   borderColor: '#cccccc',
//   borderStyle: 'solid',
//   borderWidth: 0,
//   textAlign: 'left',
//   backgroundRepeat: 'no-repeat',
//   backgroundPosition: 'center',
//   backgroundSize: 'cover',
//   height: 80,
//   cursor: 'pointer',
// }

function Banner(props) {
  // const history = useHistory()
  const { id, title, clickurl, cpc } = props.banner
  let { creativeurl } = props.banner
  // const { format, content, linktitle, activities } = props.banner
  if (creativeurl && creativeurl.indexOf('https') === -1) {
    creativeurl = 'https://' + creativeurl
  }
  return (
    <img
      src={creativeurl}
      alt={title}
      onClick={() => props.handleBannerClick(clickurl, id, cpc)}
      width='80%'
      className='mx-auto d-block'
      style={{ maxWidth: '370px', margin: 'auto' }}
      onLoad={props.onImgLoad}
    />
  )

  // if (format === 'info') {
  //   return (
  //     <Typography variant='body2' style={textInfoStyle}>
  //       {content}
  //     </Typography>
  //   )
  // } else if (format === 'textad') {
  //   if (link === null) return null
  //   const { hostname } = new URL(link)
  //   if (externalctt) {
  //     link = externalctt
  //   }
  //   return (
  //     <div style={textAdStyle}>
  //       <Typography variant='caption' style={{ color: 'green' }}>
  //         <div style={{ margin: 0, padding: 0, marginBottom: -10 }}>
  //           <Link href={link} target='_blank' rel='noopener' variant='body1' style={{ color: 'blue' }}>
  //             {linktitle}
  //           </Link>
  //         </div>
  //         {hostname}
  //       </Typography>
  //       <Typography variant='body2'>{content}</Typography>
  //     </div>
  //   )
  // } else if (format === 'activitiesroll') {
  //   if (!activities) return null
  //   return (
  //     <div style={{ ...activityAdStyle, backgroundImage: `linear-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.6)), url(${activities.image})` }}
  //       onClick={(e) => {
  //         history.push(props.appconfig.pathPrefix + '/activities/all')
  //             // handleInfoClick(e, activity)
  //           }}
  //     >
  //       <Typography variant='caption' style={{ color: 'green' }}>{activities.title}</Typography>
  //       <Typography variant='body2'>{activities.description}</Typography>
  //     </div>
  //   )
  // }
}




function BannerCarouselPlacement({
  banners,
  date,
  selectedCategory,
  appconfig,
}) {
  // const [selectedTextBanner, setSelectedTextBanner] = useState(null)
  const [currentBanners, setCurrentBanners] = useState([])
  const [maxheight, setMaxHeight] = useState(0)
  // const [index, setIndex] = useState(0)
  const uiGet = useUiGet({ appconfig })
 
  useEffect(() => {
    if (!banners) return
    if (banners.length === 0) return
    let _b = banners
      .filter((b) =>
        moment
          .utc(date)
          .isBetween(moment.utc(b.start), moment.utc(b.end), undefined, '[]')
      )
      .map((_b) => {
        _b.hascats = false
        if (_b.categories && _b.categories.length > 0) {
          _b.cats = _b.categories
            .toLowerCase()
            .split(',')
            .map((c) => c.trim())
          _b.hascats = true
        }
        return _b
      })
      .filter((b) => !b.hascats || b.cats.includes(selectedCategory))
    setCurrentBanners(_b)
  }, [banners, date, selectedCategory])

  const onImgLoad = ({ target: img }) => {
    if (img.offsetHeight > maxheight) {
      setMaxHeight(img.offsetHeight)
    }
  }

  const handleBannerClick = (url, id, cpc) => {
    console.log(url, id, cpc)
    if (cpc) {
      uiGet({ q: 'getToken', customerid: appconfig.accountkey, ca: id }).then((response) => {
        console.log(response.data)
        if (response.data.error) {
          // console.log(response.data)
        } else {
          // window.location = url + '?ct=' + response.data.token + '&cb=' + Math.random().toString(36).substring(2)
          // console.log(url + '?ct=' + response.data.t + '&cb=' + Math.random().toString(36).substring(2))
          window.open(url + '?ct=' + response.data.t + '&cb=' + Math.random().toString(36).substring(2), '_blank')
        }
      })
    } else {
      window.open(url, '_blank')
      // window.location = url
    }
  }

  if (!currentBanners) return null
  if (currentBanners.length === 0) {
    return null
  } else {
    return (
      <div style={{ height: maxheight }}>
        <Carousel
          autoPlay={true}
          indicators={false}
          animation='fade'
          interval={7000}
          navButtonsAlwaysVisible={false}
          navButtonsAlwaysInvisible={true}
          timeout={500}
          cycleNavigation={true}
          index={0}
        >
          {currentBanners.map((banner, i) => (
            <Banner key={i} banner={banner} appconfig={appconfig} onImgLoad={onImgLoad} handleBannerClick={handleBannerClick} />
          ))}
        </Carousel>
      </div>
    )
  }
}

export default withAppconfig(BannerCarouselPlacement)
